import Vue from 'vue';

/*
  自定义节流
<el-button v-throttle="[searchcs,'click',5000]">节流</el-button>

*/
Vue.directive('throttle', {
  inserted: function (el, binding) {
    const [fn, event, time] = binding.value;
    let timer;
    el.addEventListener(event, () => {
      if (timer) return;
      timer = setTimeout(() => {
        fn();
        timer = null;
      }, time);
    });
  },
});
