import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import ElementUI from 'element-ui';
import { Message } from 'element-ui';
import { Loading } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import permission from '@/directive/permission.js';
import '@/directive/debounce.js';
import '@/directive/throttle.js';
import * as echarts from 'echarts';
import wx from 'weixin-js-sdk';

Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
Vue.prototype.$message = Message;
Vue.prototype.$axios = axios;
Vue.prototype.wx = wx;
Vue.use(ElementUI);
Vue.use(Loading.directive);
Vue.directive('permission', permission);

// window.addEventListener('popstate', function () {
//   history.pushState(null, null, document.URL);
// });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
