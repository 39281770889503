import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import store from '@/store';
import 'nprogress/nprogress.css';

const Login = () => import('@/views/login/Login.vue');
const Backstage = () => import('@/views/backstage/Backstage.vue');
const Home = () => import('@/views/backstage/home/Home.vue'); // 首页
const Online = () => import('@/views/backstage/content/online/Online.vue'); // 线上课程
const Offline = () => import('@/views/backstage/content/offline/Offline.vue'); // 线下培训
const Material = () =>
  import('@/views/backstage/content/material/Material.vue'); // 素材库
const Certificate = () =>
  import('@/views/backstage/content/certificate/Certificate.vue'); // 证书管理
const Product = () => import('@/views/backstage/product/Product.vue'); // 产品中心
const Training = () => import('@/views/backstage/training/Training.vue'); // 培训管理
const Project = () => import('@/views/backstage/project/Project.vue'); // 项目管理
const Organization = () =>
  import('@/views/backstage/organization/Organization.vue'); // 机构管理
const Role = () => import('@/views/backstage/permission/role/Role.vue'); // 角色管理
const Menu = () => import('@/views/backstage/permission/menu/Menu.vue'); // 菜单管理
const Permission = () =>
  import('@/views/backstage/permission/permission/Permission.vue'); // 权限管理
const Student = () => import('@/views/backstage/user/student/Student.vue'); // 学员列表
const Teacher = () => import('@/views/backstage/user/teacher/Teacher.vue'); // 导师列表
const TeacherAudit = () =>
  import('@/views/backstage/user/teacherAudit/TeacherAudit.vue'); // 导师列表
const SuppliesList = () =>
  import('@/views/backstage/supplies/suppliesList/SuppliesList.vue'); // 物资列表
const SuppliesGroup = () =>
  import('@/views/backstage/supplies/suppliesGroup/SuppliesGroup.vue'); // 物资组合
const Tweet = () => import('@/views/backstage/publish/tweet/Tweet.vue'); // 推文管理

const WxTweet = () => import('@/views/wxTweet/WxTweet.vue'); // 小程序推文页面
const Questionnaire = () =>
  import('@/views/backstage/content/questionnaire/Questionnaire.vue'); // 调查问卷
const Examine = () => import('@/views/backstage/content/examine/Examine.vue'); // 随堂考核
const DataDic = () => import('@/views/backstage/content/dataDic/DataDic.vue'); // 数据词典
const Schedule = () => import('@/views/backstage/schedule/Schedule.vue'); // 日程安排
const PhotoAudit = () =>
  import('@/views/backstage/content/photoAudit/PhotoAudit.vue'); // 照片审核
const Order = () => import('@/views/backstage/content/order/Order.vue'); // 订单管理
const Reserve = () => import('@/views/backstage/reserve/Reserve.vue'); // 培训预约
const Reservation = () => import('@/views/reservation/Reservation.vue'); // 培训预约h5
const Fund = () => import('@/views/backstage/fund/Fund.vue'); // 资金流水
const Resource = () =>
  import('@/views/backstage/content/resource/Resource.vue'); // 导师资源
const Model2 = () => import('@/views/model/model2/index.vue'); // 模板2
const Model1 = () => import('@/views/model/model1/index.vue'); // 模板1
const Model3 = () => import('@/views/model/model3/index.vue'); // 模板3

let map = new Map();
map.set('home', Home);
map.set('online', Online);
map.set('offline', Offline);
map.set('organization', Organization);
map.set('role', Role);
map.set('menu', Menu);
map.set('permission', Permission);
map.set('material', Material);
map.set('certificate', Certificate);
map.set('product', Product);
map.set('training', Training);
map.set('project', Project);
map.set('student', Student);
map.set('teacher', Teacher);
map.set('teacherAudit', TeacherAudit);
map.set('suppliesList', SuppliesList);
map.set('suppliesGroup', SuppliesGroup);
map.set('tweet', Tweet);
map.set('questionnaire', Questionnaire);
map.set('examine', Examine);
map.set('dataDic', DataDic);
map.set('schedule', Schedule);
map.set('photoAudit', PhotoAudit);
map.set('order', Order);
map.set('reserve', Reserve);
map.set('fund', Fund);
map.set('resource', Resource);

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'hash',
  routes: [
    {
      path: '/',
      redirect: '/login',
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/backstage',
      name: 'Backstage',
      component: Backstage,
      children: [],
    },
    {
      path: '/wxTweet',
      name: 'WxTweet',
      component: WxTweet,
    },
    {
      path: '/reservation',
      name: 'Reservation',
      component: Reservation,
    },
    {
      path: '/model2',
      name: 'Model2',
      component: Model2,
    },
    {
      path: '/model1',
      name: 'Model1',
      component: Model1,
    },
    {
      path: '/model3',
      name: 'Model3',
      component: Model3,
    },
  ],
  // scrollBehavior: () => {
  //   history.pushState(null, null, document.URL);
  // },
});

NProgress.configure({ showSpinner: false });
router.beforeEach((to, from, next) => {
  let isLogin = store.state.userId;
  if (to.path === '/login') return next();
  if (to.path === '/wxTweet') return next();
  if (to.path === '/reservation') return next();
  if (to.path === '/model2') return next();
  if (to.path === '/model1') return next();
  if (to.path === '/model3') return next();
  if (!isLogin) return next('/login');
  NProgress.start();
  next();
});
router.afterEach(() => {
  NProgress.done();
});
router.onError((err) => {
  const pattern = /Loading chunk (\S)+ failed/g;
  const isChunkLoadFailed = err.message.match(pattern);
  console.log(err.message);
  console.log(isChunkLoadFailed);
  console.log(pattern);
  console.log(Boolean(isChunkLoadFailed));
  // if (isChunkLoadFailed) {
  //   location.reload();
  // }
});

export function addR(menu) {
  menu.forEach((element) => {
    // console.log(element);
    //父级路由
    let parent = 'Backstage';
    //获取子路由名称
    let name = element.router.slice(parent.length + 2);
    /* console.log(typeof element.router);
    console.log(path); */

    if (element.router == '') {
      addR(element.subMenus);
    }

    // 组装路由配置
    const temp = {
      name,
      path: element.router,
      component: map.get(name),
    };
    // 动态添加为 index 的子路由
    router.addRoute(parent, temp);
  });
}

export default router;
