<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { addR } from '@/router'
import DevicePixelRatio from '@/utils/devicePixelRatio.js'
export default {
  name: 'app',
  created () {
    new DevicePixelRatio().init()
    let data = JSON.parse(window.sessionStorage.getItem("vuex"))
    if (data.root) {
      addR(data.root.menuList)
    }
    this.$store.commit('getAddress')
  },
}
</script>


<style lang="scss">
@import url("./assets/css/base.scss");
</style>
