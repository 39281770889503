export const area = {
  广东省: [
    {
      广州市: [
        {
          古丈县:
            '古阳镇、默戎镇、罗依溪镇、红石林镇、岩头寨镇、断龙山乡、高峰乡、河蓬乡、坪坝乡、山枣乡、双溪乡、高望界乡',
        },
        {
          越秀区:
            '洪桥街道、北京街道、六榕街道、流花街道、光塔街道、人民街道、东山街道、农林街道、大东街道、大塘街道、珠光街道、白云街道、建设街道、华乐街道、梅花村街道、黄花岗街道、矿泉街道、登峰街道',
        },
        {
          荔湾区:
            '沙面街道、岭南街道、华林街道、多宝街道、昌华街道、逢源街道、龙津街道、金花街道、彩虹街道、南源街道、西村街道、站前街道、桥中街道、白鹤洞街道、冲口街道、花地街道、石围塘街道、茶滘街道、东漖街道、海龙街道、东沙街道、中南街道',
        },
        {
          海珠区:
            '赤岗街道、新港街道、晓港街道、小港街道、江南中街道、滨江街道、素社街道、纺织街道、基立街道、宝岗街道、跃龙街道、二龙街道、海幢街道、南华西街道、洪德街道、凤凰街道、沙园街道、昌岗中街道、南石头街道、新滘镇街道',
        },
        {
          天河区:
            '五山街道、员村街道、车陂街道、沙河街道、石牌街道、兴华街道、沙东街道、林和街道、棠下街道、猎德街道、冼村街道、天园街道、天河南街道、元岗街道、黄村街道、龙洞街道、长兴街道、凤凰街道、 前进街道、珠吉街道、新塘街道',
        },
        {
          白云区:
            '三元里街道、松洲街道、景泰街道、黄石街道、同德街道、棠景街道、新市街道、同和街道、京溪街道、永平街道、均禾街道、嘉禾街道、金沙街道、石井街道、云城街道、白云湖街道、石门街道、鹤龙街道、江高镇、人和镇、太和镇、钟落潭镇',
        },
        {
          黄埔区:
            '黄埔街道、红山街道、鱼珠街道、大沙街道、文冲街道、穗东街道、南岗街道、荔联街道、长洲街道',
        },
        {
          番禺区:
            '市桥街道、桥南街道、东环街道、沙头街道、洛浦街道、大石街道、小谷围街道、钟村街道、石壁街道、大龙街道、南村镇、沙湾镇、石碁镇、石楼镇、新造镇、化龙镇',
        },
        {
          花都区:
            '新华街道、新雅街道、秀全街道、花城街道、花山镇、赤坭镇、炭步镇、狮岭镇、梯面镇、花东镇',
        },
        {
          南沙区:
            '南沙街道、龙穴街道、珠江街道、万顷沙镇、黄阁镇、横沥镇、榄核镇、大岗镇、东涌镇',
        },
        {
          增城区:
            '荔城街道、增江街道、朱村街道、永宁街道、中新镇、石滩镇、新塘镇、小楼镇、派潭镇、正果镇、仙村镇',
        },
        {
          从化区:
            '街口街道、城郊街道、江埔街道、太平镇、温泉镇、良口镇、吕田镇、鳌头镇',
        },
      ],
      深圳市: [
        {
          福田区:
            '园岭街道、南园街道、福田街道、沙头街道、梅林街道、华富街道、香蜜湖街道、莲花街道、华强北街道、福保街道',
        },
        {
          罗湖区:
            '翠竹街道、桂园街道、黄贝街道、东门街道、南湖街道、笋岗街道、东湖街道、莲塘街道、东晓街道、清水河街道',
        },
        {
          南山区:
            '南山街道、南头街道、西丽街道、沙河街道、蛇口街道、招商街道、粤海街道、桃源街道',
        },
        { 盐田区: '沙头角街道、海山街道、盐田街道、梅沙街道' },
        {
          宝安区:
            '新安街道、西乡街道、航城街道、福永街道、福海街道、沙井街道、新桥街道、松岗街道、燕罗街道、石岩街道',
        },
        {
          龙岗区:
            '平湖街道、坂田街道、布吉街道、南湾街道、横岗街道、龙城街道、龙岗街道、坪地街道、吉华街道、园山街道、宝龙街道',
        },
        {
          光明区: '公明街道、光明街道、新湖街道、凤凰街道、玉塘街道、马田街道',
        },
        {
          龙华区: '观湖街道、民治街道、龙华街道、大浪街道、福城街道、观澜街道',
        },
        {
          坪山区: '坪山街道、马峦街道、碧岭街道、石井街道、坑梓街道、龙田街道',
        },
        { 大鹏新区: '大鹏街道、南澳街道、葵涌街道' },
        { 深汕特别合作区: '鹅埠镇、鲘门镇、小漠镇、赤石镇' },
      ],
      珠海市: [
        {
          香洲区:
            '拱北街道、吉大街道、翠香街道、狮山街道、香湾街道、梅华街道、前山街道、湾仔街道、一个镇南屏镇',
        },
        { 斗门区: '白藤街道、井岸镇、白蕉镇、斗门镇、乾务镇、莲洲镇' },
        { 金湾区: '三灶镇、红旗镇、平沙镇、南水镇' },
      ],
      汕头市: [
        {
          金平区:
            '石炮台街道、金砂街道、金厦街道、东方街道、大华街道、同益街道、光华街道、东墩街道、广厦街道、海安街道、新福街道、乌桥街道、岐山街道、永祥街道、鮀莲街道、鮀江街道、月浦街道',
        },
        {
          龙湖区:
            '珠池街道、金霞街道、新津街道、龙祥街道、鸥汀街道、外砂镇、新溪镇',
        },
        {
          澄海区:
            '东里镇、盐鸿镇、莲华镇、溪南镇、隆都镇、莲上镇、莲下镇、上华镇、凤翔街道、澄华街道、广益街道',
        },
        {
          濠江区:
            '达濠街道、礐石街道、广澳街道、马滘街道、河浦街道、玉新街道、滨海街道',
        },
        {
          潮阳区:
            '文光街道、城南街道、棉北街道、金浦街道、海门镇、河溪镇、和平镇、西胪镇、关埠镇、金灶镇、谷饶镇、贵屿镇、铜盂镇',
        },
        {
          潮南区:
            '峡山街道、井都镇、陇田镇、雷岭镇、成田镇、红场镇、胪岗镇、两英镇、仙城镇、陈店镇、司马浦镇',
        },
        { 南澳县: '后宅镇' },
      ],
      佛山市: [
        { 禅城区: '祖庙街道、石湾街道、张槎街道、南庄镇' },
        { 南海区: '桂城街道、大沥镇、里水镇、狮山镇、丹灶镇、西樵镇、九江镇' },
        {
          顺德区:
            '大良街道、容桂街道、伦教街道、勒流街道、陈村镇、均安镇、杏坛镇、龙江镇、乐从镇、北滘镇',
        },
        {
          三水区:
            '西南街道、云东海街道、白坭镇、乐平镇、大塘镇、芦苞镇、南山镇',
        },
        { 高明区: '荷城街道、杨和镇、更合镇、明城镇' },
      ],
      韶关市: [
        {
          浈江区:
            '车站街道、东河街道、南门街道、和平街道、太平街道、曲仁街道、田螺冲办街道、乐园镇、新韶镇、十里亭镇、犁市镇、花坪镇',
        },
        {
          武江区: '新华街道、惠民街道、西联镇、西河镇、龙归镇、江湾镇、重阳镇',
        },
        {
          曲江区:
            '马坝镇、罗坑镇、樟市镇、乌石镇、沙溪镇、大塘镇、小坑镇、枫湾镇、白土镇',
        },
        {
          乐昌市:
            '乐城街道、北乡镇、九峰镇、廊田镇、长来镇、梅花镇、三溪镇、坪石镇、黄圃镇、五山镇、两江镇、沙坪镇、云岩镇、秀水镇、大源镇、庆云镇、白石镇',
        },
        {
          南雄市:
            '雄州街道、乌迳镇、界址镇、坪田镇、黄坑镇、邓坊镇、油山镇、南亩镇、水口镇、江头镇、湖口镇、珠玑镇、主田镇、古市镇、全安镇、百顺镇、澜河镇、帽子峰镇',
        },
        {
          始兴县:
            '沈所镇、罗坝镇、城南镇、顿岗镇、隘子镇、澄江镇、太平镇、马市镇、司前镇、深渡水瑶族乡',
        },
        {
          仁化县:
            '丹霞街道、闻韶镇、扶溪镇、长江镇、城口镇、红山镇、石塘镇、董塘镇、大桥镇、周田镇、黄坑镇',
        },
        {
          翁源县:
            '龙仙镇、坝仔镇、江尾镇、官渡镇、翁城镇、新江镇、周陂镇、铁龙林场',
        },
        { 新丰县: '丰城街道、黄礤镇、马头镇、梅坑镇、沙田镇、遥田镇、回龙镇' },
        {
          乳源瑶族自治县:
            '乳城镇、桂头镇、大桥镇、大布镇、洛阳镇、一六镇、必背镇、游溪镇、东坪镇',
        },
      ],
      湛江市: [
        {
          赤坎区:
            '调顺街道、涉农街道、寸金街道、中山街道、中华街道、民主街道、沙湾街道',
        },
        {
          霞山区:
            '解放街道、爱国街道、工农街道、友谊街道、新兴街道、海滨街道、建设街道、东新街道、新园街道、海头街道、泉庄街道、乐华街道',
        },
        {
          坡头区: '南调街道、麻斜街道、坡头镇、龙头镇、乾塘镇、南三镇、官渡镇',
        },
        {
          麻章区:
            '麻章镇、太平镇、湖光镇、东山镇、东简镇、民安镇、硇洲镇、湖光农场',
        },
        { 湛江经济技术开发区: '泉庄街道、东山街道' },
        {
          吴川市:
            '梅菉街道、博铺街道、海滨街道、塘尾街道、大山江街道、兰石镇、覃巴镇、吴阳镇、黄坡镇、振文镇、樟铺镇、塘土叕镇、长岐镇、浅水镇、王村港镇',
        },
        {
          雷州市:
            '雷城街道、新城街道、西湖街道、沈塘镇、东里镇、雷高镇、调风镇、英利镇、南兴镇、龙门镇、松竹镇、覃斗镇、乌石镇、北和镇、企水镇、唐家镇、杨家镇、纪家镇、白沙镇、客路镇、附城镇',
        },
        {
          廉江市:
            '罗州街道、城北街道、城南街道、石角镇、河唇镇、良垌镇、石城镇、新民镇、安铺镇、营仔镇、车板镇、横山镇、高桥镇、和寮镇、雅塘镇、青平镇、石岭镇、石颈镇、吉水镇、长山镇、塘蓬镇',
        },
        {
          遂溪县:
            '遂城镇、黄略镇、建新镇、岭北镇、城月镇、河头镇、江洪镇、乐民镇、乌塘镇、北坡镇、港门镇、草潭镇、洋青镇、杨柑镇、界炮镇',
        },
        {
          徐闻县:
            '海安镇、下桥镇、龙塘镇、前山镇、曲界镇、下洋镇、锦和镇、和安镇、新寮镇、迈陈镇、西连镇、南山镇、角尾乡、城北乡',
        },
      ],
      肇庆市: [
        { 端州区: '黄岗街道、睦岗街道、城东街道、城西街道' },
        {
          鼎湖区:
            '坑口街道、桂城街道、广利街道、莲花镇、永安镇、凤凰镇、沙浦镇',
        },
        {
          广宁县:
            '南街镇、江屯镇、赤坑镇、北市镇、坑口镇、螺岗镇、潭布镇、石咀镇、古水镇、洲仔镇、宾亨镇、横山镇、五和镇、木格镇、排沙镇',
        },
        {
          怀集县:
            '怀城镇、闸岗镇、坳仔镇、汶朗镇、甘洒镇、凤岗镇、洽水镇、梁村镇、大岗镇、岗坪镇、冷坑镇、马宁镇、蓝钟镇、永固镇、诗洞镇、桥头镇、中洲镇、连麦镇、下帅壮族瑶族乡',
        },
        {
          封开县:
            '江口镇、南丰镇、长安镇、金装镇、白垢镇、莲都镇、渔涝镇、杏花镇、罗董镇、长岗镇、平凤镇、大洲镇、都平镇、江川镇、大玉口镇、河儿口镇',
        },
        {
          德庆县:
            '新圩镇、官圩镇、马圩镇、高良镇、莫村镇、永丰镇、武垄镇、播植镇、凤村镇、九市镇、悦城镇、回龙镇',
        },
        {
          高要区:
            '南岸街道、金渡镇、金利镇、蚬岗镇、白土镇、回龙镇、蛟塘镇、莲塘镇、活道镇、新桥镇、白诸镇、大湾镇、禄步镇、小湘镇、河台镇、乐城镇、水南镇',
        },
        {
          四会市:
            '东城街道、城中街道、贞山街道、龙甫镇、地豆镇、威整镇、罗源镇、迳口镇、大沙镇、石狗镇、黄田镇、下茆镇、江谷镇',
        },
      ],
      江门市: [
        {
          蓬江区:
            '棠下镇、荷塘镇、杜阮镇、环市街道、潮连街道、北街街道、堤东街道、仓后街道、白沙街道',
        },
        { 江海区: '外海街道、礼乐街道、江南街道、滘头街道、滘北街道' },
        {
          新会区:
            '会城街道、大泽镇、司前镇、沙堆镇、古井镇、三江镇、崖门镇、双水镇、罗坑镇、大鳌镇、睦洲镇',
        },
        {
          台山市:
            '台城街道、大江镇、水步镇、四九镇、都斛镇、赤溪镇、冲蒌镇、斗山镇、广海镇、川岛镇、端芬镇、海宴镇、汶村镇、三合镇、北陡镇、深井镇、白沙镇}',
        },
        {
          开平市:
            '长沙街道、三埠街道、月山镇、水口镇、赤水镇、金鸡镇、蚬冈镇、百合镇、赤坎镇、塘口镇、大沙镇、马冈镇、龙胜镇、苍城镇、沙塘镇',
        },
        {
          鹤山市:
            '沙坪街道、雅瑶镇、龙口镇、古劳镇、桃源镇、共和镇、址山镇、鹤城镇、云乡镇、宅梧镇、双合镇',
        },
        {
          恩平市:
            '恩城街道、平石街道）、10个镇（良西镇、牛江镇、圣堂镇、沙湖镇、东成镇、君堂镇、横陂镇、大槐镇、那吉镇、大田镇',
        },
      ],
      茂名市: [
        {
          茂南区:
            '红旗街道、河西街道、河东街道、露天矿街道、新华街道、官渡街道、站前街道、金塘镇、公馆镇、新坡镇、镇盛镇、鳌头镇、袂花镇、高山镇、山阁镇',
        },
        {
          电白区:
            '南海街道、高地街道、水东镇、陈村镇、沙院镇、小良镇、七迳镇、坡心镇、羊角镇、林头镇、霞洞镇、黄岭镇、那霍镇、沙琅镇、罗坑镇、观珠镇、望夫镇、马踏镇、岭门镇、麻岗镇、旦场镇、树仔镇、电城镇、博贺镇',
        },
        {
          高州市:
            '潘州街道、山美街道、宝光街道、石仔岭街道、金山街道、谢鸡镇、新垌镇、云潭镇、根子镇、分界镇、泗水镇、石鼓镇、镇江镇、沙田镇、长坡镇、平山镇、深镇镇、古丁镇、马贵镇、大坡镇、曹江镇、荷塘镇、南塘镇、石板镇、荷花镇、潭头镇、大井镇、东岸镇',
        },
        {
          化州市:
            '长岐镇、同庆镇、杨梅镇、良光镇、笪桥镇、丽岗镇、新安镇、官桥镇、林尘镇、中垌镇、合江镇、那务镇、播扬镇、宝圩镇、平定镇、文楼镇、江湖镇、鉴江开发区、北岸开发区、河西街道、东山街道、下郭街道、石湾街道、南盛街道',
        },
        {
          信宜市:
            '东镇街道、镇隆镇、池洞镇、北界镇、水口镇、朱砂镇、贵子镇、怀乡镇、丁堡镇、金垌镇',
        },
      ],
      惠州市: [
        {
          惠城区:
            '辖桥东街道、桥西街道、江南街道、江北街道、龙丰街道、水口街道、河南岸街道、小金口街道、三栋镇、汝湖镇、马安镇、横沥镇、芦洲镇',
        },
        {
          惠阳区:
            '淡水街道、秋长街道、三和街道、新圩镇、镇隆镇、沙田镇、永湖镇、良井镇、平潭镇',
        },
        {
          惠东县:
            '平山街道、大岭镇、白花镇、梁化镇、稔山镇、铁涌镇、平海镇、吉隆镇、黄埠镇、多祝镇、白盆珠镇、安墩镇、高潭镇、宝口镇、巽寮旅游度假区、港口旅游度假区',
        },
        {
          博罗县:
            '罗阳镇、龙溪镇、龙华镇、园洲镇、石湾镇、福田镇、长宁镇、湖镇镇、横河镇、柏塘镇、公庄镇、观音阁镇、杨侨镇、麻陂镇、石坝镇、泰美镇、杨村镇',
        },
        {
          龙门县:
            '龙城街道、麻榨镇、永汉镇、龙华镇、龙江镇、平陵镇、龙田镇、地派镇、龙潭镇、蓝田瑶族乡',
        },
      ],
      梅州市: [
        {
          梅江区:
            '金山街道、江南街道、西郊街道、长沙镇、三角镇、城北镇、西阳镇',
        },
        {
          梅县区:
            '程江镇、南口镇、畲江镇、水车镇、梅南镇、梅西镇、石坑镇、大坪镇、城东镇、石扇镇、丙村镇、雁洋镇、松口镇、隆文镇、桃尧镇、松源镇、白渡镇',
        },
        {
          兴宁市:
            '兴田街道、福兴街道、宁新街道、合水镇、龙田镇、大坪镇、叶塘镇、新陂镇、刁坊镇、径南镇、新圩镇、水口镇、石马镇、黄槐镇、罗浮镇、罗岗镇、宁中镇、坭陂镇、永和镇、黄陂镇、兴宁经济技术开发区、兴华新区',
        },
        {
          平远县:
            '大柘镇、仁居镇、东石镇、石正镇、八尺镇、差干镇、上举镇、泗水镇、长田镇、热柘镇、中行镇、河头镇',
        },
        {
          蕉岭县:
            '蕉城镇、广福镇、文福镇、新铺镇、三圳镇、南礤镇、蓝坊镇、长潭镇',
        },
        {
          大埔县:
            '湖寮镇、茶阳镇、西河镇、百侯镇、枫朗镇、光德镇、桃源镇 、高陂镇、大麻镇、三河镇、大东镇、洲瑞镇、银江镇、青溪镇',
        },
        {
          丰顺县:
            '埔寨镇、八乡山镇、汤南镇、汤西镇、汤坑镇、北斗镇、建桥镇、丰良镇、龙岗镇、潘田镇、留隍镇、黄金镇、大龙华镇、小胜镇、潭江镇、砂田镇',
        },
        {
          五华县:
            '水寨镇、河东镇、郭田镇、双华镇、棉洋镇、安流镇、梅林镇、龙村镇、横陂镇、华阳镇、周江镇、长布镇、潭下镇、岐岭镇、转水镇、华城镇',
        },
      ],
      汕尾市: [
        {
          城区: '香洲街道、新港街道、凤山街道、田墘街道、东洲街道、遮浪街道、马宫街道、捷胜镇、红草镇、东涌镇',
        },
        {
          陆丰市:
            '东海街道、城东街道、河西街道、甲子镇 、大安镇、八万镇、陂洋镇、博美镇、南塘镇、甲东镇、湖东镇、碣石镇、金厢镇、潭西镇、桥冲镇、甲西镇、上英镇、西南镇、内湖镇、河东镇',
        },
        {
          海丰县:
            '海城镇、汕尾镇、梅陇镇、公平镇、联安镇、红草镇、马宫镇、陶河镇、田墘镇、东涌镇、捷胜镇、遮浪镇、赤坑镇、可塘镇、黄羌镇、平东镇、大湖镇、莲花山乡、城东乡、附城乡',
        },
        {
          陆河县:
            '河田镇、水唇镇、河口镇、新田镇、上护镇、螺溪镇、东坑镇、南万镇',
        },
        { 红海湾经济开发区: '田墘街道、遮浪街道、东洲街道、龟岭岛' },
      ],
      河源市: [
        {
          源城区:
            '上城街道、东埔街道、新江街道、源西街道、高埔岗街道、源南镇、埔前镇',
        },
        {
          紫金县:
            '紫城镇、附城镇、乌石镇、中坝镇、敬梓镇、水墩镇、龙窝镇、苏区镇、南岭镇、瓦溪镇、九和镇、蓝塘镇、凤安镇、好义镇、上义镇、古竹镇、义容镇、临江镇、柏埔镇、黄塘镇',
        },
        {
          龙川县:
            '老隆、四都、黄石、细坳、车田、贝岭、黎咀、上坪、丰稔、赤光、龙母、逥龙、田心、铁场、登云、通衢、鹤市、黄布、紫市、佗城、岩镇、新田、附城、义都、麻布岗',
        },
        {
          和平县:
            '阳明镇、彭寨镇、东水镇、林寨镇、热水镇、大坝镇、上陵镇、下车镇、长塘镇、贝墩镇、古寨镇、礼士镇、公白镇、合水镇、青州镇、浰源镇、优胜镇',
        },
        {
          东源县:
            '仙塘镇、灯塔镇、骆湖镇、船塘镇、顺天镇、上莞镇、曾田镇、柳城镇、义合镇、蓝口镇、黄田镇、叶潭镇、黄村镇、康禾镇、锡场镇、新港镇、双江镇、涧头镇、半江镇、新回龙镇、漳溪畲族乡',
        },
        {
          连平县:
            '元善镇、上坪镇、内莞镇、陂头镇、溪山镇、隆街镇、田源镇、油溪镇、忠信镇、高莞镇、大湖镇、三角镇、绣缎镇',
        },
      ],
      阳江市: [
        {
          江城区:
            '南恩街道、城南街道、城东街道、岗列街道、中洲街道、城北街道、城西街道、白沙街道、埠场镇、双捷镇',
        },
        { 海陵岛经济开发试验区: '海陵镇、闸坡镇' },
        { 阳江高新技术产业开发区: '阳江高新技术产业开发区' },
        {
          阳春市:
            '春城街道、河塱镇、松柏镇、陂面镇、合水镇、春湾镇、岗美镇、河口镇、潭水镇、八甲镇、双窖镇、永宁镇、圭岗镇、石望镇、马水镇、三甲镇',
        },
        {
          阳东区:
            '东城镇、北惯镇、合山镇、大沟镇、东平镇、雅韶镇、塘坪镇、大八镇、红丰镇、那龙镇、新洲镇',
        },
        {
          阳西县:
            '织篢镇、程村镇、上洋镇、沙扒镇、儒洞镇、新圩镇、塘口镇、溪头镇',
        },
      ],
      清远市: [
        {
          清城区:
            '凤城街道、东城街道、洲心街道、横荷街道、源潭镇、石角镇、飞来峡镇、龙塘镇',
        },
        {
          清新区:
            '太和镇、山塘镇、太平镇、三坑镇、禾云镇、龙颈镇、浸潭镇、石潭镇',
        },
        {
          英德市:
            '沙口镇、望埠镇、桥头镇、青塘镇、东华镇、白沙镇、鱼湾镇、大站镇、黎溪镇、水边镇、西牛镇、九龙镇、大湾镇、大洞镇、黄花镇、波罗镇、横石水镇、连江口镇、石灰铺镇、石牯塘镇、横石塘镇、英红镇、下石太镇、英城街道',
        },
        {
          连州市:
            '连州镇、保安镇、星子镇、龙坪镇、西岸镇、东陂镇、丰阳镇、西江镇、九陂镇、大路边镇、瑶安瑶族乡、三水瑶族乡',
        },
        { 佛冈县: '石角镇、汤塘镇、迳头镇、水头镇、高岗镇、龙山镇' },
        {
          阳山县:
            '阳城镇、岭背镇、青莲镇、七拱镇、太平镇、黎埠镇、小江镇、黄坌镇、江英镇、杜步镇、杨梅镇、大崀镇、秤架瑶族乡',
        },
        { 连南瑶族自治县: '三江、寨岗、大麦山、香坪、大坪、涡水、三排' },
        {
          连山壮族瑶族自治县:
            '吉田镇、太保镇、福堂镇、小三江镇、禾洞镇、永和镇、上帅镇',
        },
      ],
      东莞市: [
        {
          东莞市:
            '莞城街道、南城街道、东城街道、万江街道、石龙镇、石排镇、茶山镇、企石镇、桥头镇、东坑镇、横沥镇、常平镇,虎门镇、长安镇、沙田镇、厚街镇,寮步镇、大岭山镇、大朗镇、黄江镇,樟木头镇、谢岗镇、塘厦镇、清溪镇、凤岗镇,麻涌镇、中堂镇、高埗镇、石碣镇、望牛墩镇、洪梅镇、道滘镇',
        },
      ],
      中山市: [
        {
          中山市:
            '石岐街道、东区街道、西区街道、南区街道、五桂山街道、中山火炬高技术产业开发区、黄圃镇、南头镇、东凤镇、阜沙镇、小榄镇、东升镇、古镇镇、横栏镇、三角镇、民众镇、南朗镇、港口镇、大涌镇、沙溪镇、三乡镇、板芙镇、神湾镇、坦洲镇',
        },
      ],
      潮州市: [
        {
          湘桥区:
            '凤新街道、湘桥街道、西湖街道、金山街道、太平街道、南春街道、西新街道、桥东街道、城西街道、意溪镇、磷溪镇、官塘镇、铁铺镇',
        },
        {
          潮安县:
            '庵埠镇、文祠镇、凤凰镇、江东镇、东凤镇、龙湖镇、枫溪镇、彩塘镇、金石镇、浮洋镇、沙溪镇、凤塘镇、古巷镇、登塘镇、赤凤镇、归湖镇、万峰林场',
        },
        {
          饶平县:
            '黄冈镇、钱东镇、海山镇、大埕镇、所城镇、柘林镇、汫洲镇、樟溪镇、浮山镇、汤溪镇、三饶镇、联饶镇、新圩镇、新丰镇、饶洋镇、上饶镇、建饶镇、高堂镇、浮滨镇、新塘镇、东山镇',
        },
        {
          枫溪区:
            '长德街道、路东街道、路西街道、怀德社区、石桥社区、洲园社区、长美社区、福安社区、南苑社区、枫溪一村、枫溪二村、长美一村、长美二村、湖厦村、藏龙村、詹厝村、李厝村、槐山岗村、山边村、白塔村、池湖村、蔡陇村、云步村、上东埔村、堤头村、前进村、古板头村、全福村、英塘村、洋头村、高厦村、西边村、下东埔村、田头村、田头何村',
        },
      ],
      揭阳市: [
        {
          榕城区:
            '新兴街道、榕华街道、中山街道、榕东街道、西马街道、仙桥街道、梅云街道、东兴街道、东升街道、东阳街道',
        },
        {
          揭东区:
            '曲溪街道（含揭东开发区）、玉窖镇、云路镇、埔田镇、锡场镇、新亨镇、玉湖镇',
        },
        {
          蓝城区:
            '磐东街道、桂岭镇、月城镇、霖磐镇、白塔镇、龙尾镇、高新技术开发区',
        },
        {
          揭阳空港经济区:
            '地都镇、炮台镇、登岗镇、渔湖镇、凤美街道、京冈街道、溪南街道',
        },
        {
          揭西县:
            '河婆街道、龙潭镇、南山镇、五经富镇、京溪园镇、灰寨镇、塔头镇、东园镇、凤江镇、棉湖镇、钱坑镇、金和镇、大溪镇、坪上镇、五云镇、上砂镇、良田乡',
        },
        {
          惠来县:
            '惠城镇、华湖镇、仙庵镇、靖海镇、周田镇、前詹镇、神泉镇、东陇镇、岐石镇、隆江镇、溪西镇、鳌江镇、东港镇、葵潭镇、大南山华侨管理区',
        },
        {
          普宁市:
            '流沙东街道、流沙西街道、流沙南街道、流沙北街道、池尾街道、大南山街道、燎原街道、赤岗镇、大坝镇、洪阳镇、南溪镇、广太镇、麒麟镇、南径镇、占陇镇、高埔镇、下架山镇、军埠镇、云落镇、大坪镇、船埔镇、梅林镇、里湖镇、梅塘镇、鲘溪乡、普宁华侨管理区',
        },
      ],
      云浮市: [
        {
          云城区:
            '云城街道、河口街道、高峰街道、安塘街道、腰古镇、思劳镇、前锋镇、南盛镇',
        },
        { 云安区: '六都镇、高村镇、白石镇、镇安镇、富林镇、石城镇、都杨镇' },
        {
          新兴县:
            '新城镇、水台镇、车岗镇、东成镇、稔村镇、太平镇、六祖镇、大江镇、河头镇、天堂镇、簕竹镇、里洞镇',
        },
        {
          郁南县:
            '都城镇、东坝镇、宋桂镇、连滩镇、河口镇、大湾镇、建城镇、千官镇、通门镇、桂圩镇、平台镇、宝珠镇、历洞镇、大方镇、南江口镇',
        },
        {
          罗定市:
            '罗城街道、素龙街道、附城街道、双东街道、罗镜镇、太平镇、分界镇、罗平镇、船步镇、两塘镇、苹塘镇、金鸡镇、围底镇、华石镇、榃滨镇、黎少镇、生江镇、连州镇、泗纶镇、加益镇、龙湾镇',
        },
      ],
    },
  ],
  河南省: [
    {
      郑州市: [
        {
          中原区:
            '林山寨街道办事处、建设路街道办事处、棉纺路街道办事处、秦岭路街道办事处、桐柏路街道办事处、三官庙街道办事处、绿东村街道办事处、汝河路街道办事处、航海西路街道办事处、中原西路街道办事处、西流                      湖街道办事处、须水街道办事处、石佛镇、沟赵乡',
        },
        {
          二七区:
            '淮河路街道办事处、解放路街道办事处、铭功路街道办事处、一马路街道办事处、蜜蜂张街道办事处、五里堡街道办事处、大学路街道办事处、建中街街道办事处、福华街街道办事处、德化街街道办事处、嵩山路街                      道办事处、京广路街道办事处、长江路街道办事处、马寨镇、侯寨乡',
        },
        {
          管城回族区:
            '北下街街道办事处、西大街街道办事处、南关街道办事处、城东路街道办事处、东大街街道办事处、二里岗街道办事处、陇海路街道办事处、紫荆山南路街道办事处、航海东路街道办事处、十八里河镇、南曹乡、                         圃田乡、经济开发区明湖街道办事处、经济开发区潮河街道办事处、京航街道办事处、商都路街道办事处',
        },
        {
          金水区:
            '经八路街道办事处、花园路街道办事处、人民路街道办事处、杜岭街道办事处、大石桥街道办事处、南阳路街道办事处、南阳新村街道办事处、文化路街道办事处、丰产路街道办事处、东风路街道办事处、北林路街                      道办事处、未来路街道办事处、凤凰台街道办事处、龙子湖街道办事处、祭城路街道办事处、兴达路街道办事处、国基路街道办事处、杨金路街道办事处、丰庆路街道办事处、如意湖街道办事处、博学路街道办事处',
        },
        {
          上街区:
            '济源路街道办事处、中心路街道办事处、新安路街道办事处、工业路街道办事处、矿山街道办事处、峡窝镇',
        },
        {
          惠济区:
            '新城街道办事处、刘寨街道办事处、老鸦陈街道办事处、长兴路街道办事处、迎宾路街道办事处、大河路街道办事处、花园口镇、古荥镇',
        },
        {
          中牟县:
            '青年路街道办事处、东风路街道办事处、广惠街街道办事处、韩寺镇、官渡镇、狼城岗镇、万滩镇、白沙镇、郑庵镇、张庄镇、黄店镇、大孟镇、九龙镇、刘集镇、八岗镇、雁鸣湖镇、姚家镇、三官庙镇、刁家乡',
        },
        {
          巩义市:
            '新华路街道办事处、杜甫路街道办事处、永安路街道办事处、孝义街道办事处、紫荆路街道办事处、米河镇、新中镇、小关镇、竹林镇、大峪沟镇、河洛镇、站街镇、康店镇、北山口镇、西村镇、芝田镇、回郭镇、                      鲁庄镇、夹津口镇、涉村镇、豫联工业园区管委会',
        },
        {
          荥阳市:
            '索河街道办事处、京城街道办事处、乔楼镇、豫龙镇、广武镇、王村镇、汜水镇、高山镇、刘河镇、崔庙镇、贾峪镇、城关乡、高村乡、金寨回族乡',
        },
        {
          新密市:
            '青屏街街道办事处、新华路街道办事处、西大街街道办事处、城关镇、米村镇、牛店镇、平陌镇、超化镇、苟堂镇、大隗镇、刘寨镇、白寨镇、岳村镇、来集镇、曲梁镇、袁庄乡、矿区街道办事处、尖山风景区管理                      委员会、郑州曲梁产业集聚区管理委员会',
        },
        {
          新郑市:
            '新建路街道办事处、新华路街道办事处、新烟街道办事处、新村镇、辛店镇、观音寺镇、梨河镇、和庄镇、薛店镇、孟庄镇、郭店镇、龙湖镇、城关乡、八千乡、龙王乡、新港办事处、郑港办事处、具茨山国家级森                       林公园管理委员会、中心城区新区建设管理委员会',
        },
        {
          登封市:
            '嵩阳街道办事处、少林街道办事处、中岳街道办事处、大金店镇、颖阳镇、卢店镇、告成镇、阳城区镇、大冶镇、宣化镇、徐庄镇、东华镇、白坪乡、君召乡、石道乡、唐庄乡、送表矿区',
        },
        { 郑东新区: '郑东新区' },
        { 高新区: '长椿路腊梅路、长椿路药厂街、长椿路梧桐街' },
        { 经开区: '经开第三大街、航海东路、朝凤路、经北二路' },
        { 航空港区: '郑港十一路、富三街' },
      ],
      开封市: [
        {
          龙亭区:
            '北书店街道办事处、午朝门街道办事处、大兴街道办事处、北道门街道办事处、北郊乡、柳园口乡、城西街道、梁苑街道、杏花营镇、西郊乡、水稻乡、杏花营农场、',
        },
        {
          顺河回族区:
            '清平街道、铁塔街道、曹门街道、宋门街道、工业街道、苹果园街道、东郊乡、土柏岗乡',
        },
        {
          鼓楼区:
            '相国寺街道、新华街道、卧龙街道、州桥街道、西司门街道、南苑街道、五一街道、仙人庄街道',
        },
        {
          禹王台区:
            '三里堡街道、新门关街道、繁塔街道、官坊街道、菜市街道、南郊乡、汪屯乡',
        },
        {
          祥符区:
            '城关镇、陈留镇、仇楼镇、八里湾镇、曲兴镇、朱仙镇、半坡店乡、罗王乡、刘店乡、袁坊乡、杜良乡、兴隆乡、西姜寨乡、万隆乡、范村乡',
        },
        {
          杞县: '城关镇、五里河镇、付集镇、于镇镇、高阳镇、葛岗镇、阳固镇、邢口镇、裴村店乡、宗店乡、板木乡、竹林乡、官庄乡、湖岗乡、苏木乡、沙沃乡、平城乡、泥沟乡、柿元乡、西寨乡、城郊乡、工业园区',
        },
        {
          通许县:
            '城关镇、竖岗镇、玉皇庙镇、四所楼镇、朱砂镇、长智镇、冯庄乡、孙营乡、大岗李乡、邸阁乡、练城乡、厉庄乡',
        },
        {
          尉氏县:
            '城关镇、洧川镇、朱曲镇、蔡庄镇、永兴镇、张市镇、十八里镇、水坡镇、大营镇、邢庄乡、庄头乡、大马乡、岗李乡、门楼任乡、大桥乡、南曹乡、小陈乡',
        },
        {
          兰考县:
            '城关镇、固阳镇、南彰镇、张君墓镇、红庙镇、城关乡、三义寨乡、坝头乡、爪营乡、谷营乡、小宋乡、孟寨乡、许河乡、葡萄架乡、闫楼乡、仪封乡、中原石油勘探三公司、仪封园艺场、造纸林场、柳林林场、西                      关农场、畜牧良种农场、固阳农科所',
        },
      ],
      洛阳市: [
        {
          老城区:
            '西关街道办事处、西南隅街道办事处、西北隅街道办事处、东南隅街道办事处、东北隅街道办事处、南关街道办事处、洛浦街道办事处、邙山街道办事处',
        },
        {
          西工区:
            '王城路街道办事处、金谷园街道办事处、西工街道办事处、邙岭路街道办事处、道北路街道办事处、唐宫路街道办事处、汉屯路街道办事处、凯旋东路街道办事处、洛北乡、红山乡',
        },
        {
          瀍河回族区:
            '东关街道办事处、五股路街道办事处、北窑街道办事处、塔湾街道办事处、杨文街道办事处、华林新村街道办事处、瀍河回族区',
        },
        {
          涧西区:
            '湖北路街道办事处、天津路街道办事处、长春路街道办事处、南昌路街道办事处、长安路街道办事处、重庆路街道办事处、郑州路街道办事处、武汉路街道办事处、徐家营街道办事处、珠江路街道办事处、周山路街                      道办事处、工农街道办事处、辛店街道办事处、瀛洲街道办事处、创业路街道办事处',
        },
        { 吉利区: '大庆路街道办事处、吉利乡' },
        {
          洛龙区:
            '安乐街道办事处、开元路街道办事处、龙门石窟街道办事处、关林镇、龙门镇、安乐镇、白马寺镇、李楼镇、诸葛镇、李村镇、庞村镇、寇店镇、佃庄镇、古城乡、镇北路街道办事处',
        },
        {
          孟津县:
            '城关镇、会盟镇、平乐镇、送庄镇、白鹤镇、朝阳镇、小浪底镇、麻屯镇、横水镇、常袋镇',
        },
        {
          新安县:
            '城关镇、石寺镇、五头镇、磁涧镇、铁门镇、南李村镇、北冶镇、仓头镇、正村镇、石井镇、曹村乡、洛阳市洛新产业集聚区管理委员会',
        },
        {
          栾川县:
            '城关镇、赤土店镇、合峪镇、潭头镇、三川镇、冷水镇、陶湾镇、石庙镇、庙子镇、狮子庙镇、白土镇、叫河镇、栾川乡、秋扒乡',
        },
        {
          嵩县: '城关镇、田湖镇、旧县镇、车村镇、闫庄镇、德亭镇、大章镇、白河镇、纸房镇、大坪乡、库区乡、何村乡、饭坡乡、九店乡、黄庄乡、木植街乡、陶村林场、五马寺林场、王莽寨林场',
        },
        {
          汝阳县:
            '城关镇、上店镇、付店镇、小店镇、三屯镇、刘店镇、内埠镇、柏树乡、十八盘乡、靳村乡、王坪乡、陶营乡、蔡店乡、大安工业园区',
        },
        {
          宜阳县:
            '城关镇、丰李镇、柳泉镇、韩城镇、白杨镇、香鹿山镇、锦屏镇、三乡镇、张坞镇、莲庄镇、盐镇乡、高村乡、花果山乡、上观乡、赵保乡、董王庄乡、樊村乡',
        },
        {
          洛宁县:
            '城关镇、王范回族镇、上戈镇、下峪镇、河底镇、兴华镇、东宋镇、马店镇、故县镇、赵村镇、城郊乡、小界乡、长水乡、罗岭乡、底张乡、山底乡、陈吴乡、涧口乡',
        },
        {
          伊川县:
            '城关镇、鸣皋镇、水寨镇、彭婆镇、白沙镇、江左镇、高山镇、吕店镇、半坡镇、鸦岭乡、平等乡、酒后乡、葛寨乡、白元乡',
        },
        {
          偃师市:
            '商城街道办事处、工业区街道办事处、首阳山街道办事处、翟镇、岳滩镇、顾县镇、缑氏镇、府店镇、高龙镇、山化镇、邙岭乡、大口乡',
        },
        { 伊滨区: '诸葛镇、李村镇、庞村镇、佃庄镇、寇店镇' },
        { 洛阳新区: '牡丹大道、金城寨街、王城大道' },
        { 高新区: '河洛路、孙辛路' },
      ],
      平顶山市: [
        {
          新华区:
            '曙光街街道办事处、光明路街道办事处、中兴路街道办事处、矿工路街道办事处、西市场街道办事处、新新街街道办事处、青石山街道办事处、湛河北路街道办事处、湖滨路街道办事处、西高皇街道办事处、焦店                       镇、香山管委会、滨湖管委会、应滨管委会',
        },
        {
          卫东区:
            '东安路街道办事处、优越路街道办事处、五一路街道办事处、建设路街道办事处、东环路街道办事处、东工人镇街道办事处、光华路街道办事处、鸿鹰街道办事处、皇台街道办事处、北环路街道办事处、东高皇街道办事处、蒲城街道办事处',
        },
        {
          石龙区:
            '高庄街道办事处、龙兴街道办事处、人民路街道办事处、龙河街道办事处',
        },
        {
          湛河区:
            '马庄街道、南环路街道、姚孟街道、九里山街道、轻工路街道、高阳路街道、北渡镇、曹镇乡',
        },
        {
          宝丰县:
            '铁路街道办事处、城关镇、周庄镇、闹店镇、石桥镇、商酒务镇、大营镇、张八桥镇、杨庄镇、肖旗乡、赵庄乡、前营乡、李庄乡',
        },
        {
          叶县: '昆阳镇、任店镇、保安镇、仙台镇、遵化店镇、叶邑镇、廉村镇、常村镇、辛店镇、城关乡、夏李乡、马庄回族乡、田庄乡、龚店乡、龙泉乡、水寨乡、洪庄杨乡、邓李乡',
        },
        {
          鲁山县:
            '露峰街道办事处、琴台街道办事处、鲁阳街道办事处、汇源街道办事处、下汤镇、梁洼镇、张官营镇、张良镇、尧山镇、赵村乡、四棵树乡、团城乡、熊背乡、让河乡、瓦屋乡、观音寺乡、昭平台库区乡、背孜乡                        、仓头乡、董周乡、张店乡、辛集乡、滚子营乡、马楼乡',
        },
        {
          郏县: '龙山街道办事处、东城街道办事处、冢头镇、安良镇、堂街镇、薛店镇、长桥镇、茨芭镇、黄道镇、李口镇、王集乡、姚庄、回族乡、白庙乡、广阔天地乡、渣元乡',
        },
        {
          舞钢市:
            '垭口街道办事处、寺坡街道办事处、朱兰街道办事处、院岭街道办事处、矿建街道办事处、尚店镇、八台镇、尹集镇、枣林镇、庙街乡、铁山乡、武功乡、杨庄乡',
        },
        {
          汝州市:
            '煤山街道办事处、风穴路街道办事处、钟楼街道办事处、洗耳河街道办事处、汝南街道办事处、寄料镇、温泉镇、临汝镇、小屯镇、杨楼镇、蟒川镇、庙下镇、米庙镇、陵头镇、王寨乡、纸坊乡、骑岭乡、大峪乡、夏店乡、焦村乡',
        },
      ],
      安阳市: [
        {
          文峰区:
            '东大街街道办事处、头二三街道办事处、甜水井街道办事处、东关街道办事处、南关街道办事处、西大街街道办事处、北大街街道办事处、西关街道办事处、紫薇大道街道办事处、光华路街道办事处、永明路街道办                      事处、中华路街道办事处、宝莲寺镇、高庄乡、步行街综合治理办公室、北大街综合治理办公室、开发区峨嵋大街街道办事处、开发区银杏大街街道办事处、开发区商颂大街街道办事处',
        },
        {
          北关区:
            '红旗路街道办事处、解放路街道办事处、灯塔路街道办事处、豆腐营街道办事处、洹北街道办事处、彰东街道办事处、彰北街道办事处、民航路街道办事处、曙光路街道办事处',
        },
        {
          殷都区:
            '梅园庄街道办事处、李珍街道办事处、电厂路街道办事处、纱厂路街道办事处、铁西路街道办事处、水冶街道办事处、清风街街道办事处、北蒙街道办事处、相台街道办事处、西郊乡',
        },
        {
          龙安区:
            '田村街道办事处、彰武街道办事处、文昌大道街道办事处、文明大道街道办事处、太行小区街道办事处、中州路街道办事处、龙泉镇、东风乡、马投涧乡',
        },
        {
          安阳县:
            '水冶镇、铜冶镇、善应镇、柏庄镇、白璧镇、曲沟镇、吕村镇、伦掌镇、蒋村镇、崔家桥镇、辛村镇、韩陵镇、都里乡、磊口乡、许家沟乡、马家乡、安丰乡、洪河屯乡、永和乡、瓦店乡、北郭乡',
        },
        {
          汤阴县:
            '城关镇、菜园镇、任固镇、五陵镇、宜沟镇、白营镇、伏道镇、韩庄乡、古贤乡、瓦岗乡',
        },
        {
          滑县: '道口镇、城关镇、白道口镇、留固镇、上官镇、牛屯镇、万古镇、高平镇、王庄镇、老店镇、枣村乡、四间房乡、八里营乡、赵营乡、大寨乡、桑村乡、老爷庙乡、慈周寨乡、瓦岗寨乡、焦虎乡、半坡店乡、小铺乡、新区管委会',
        },
        {
          内黄县:
            '城关镇、东庄镇、井店镇、梁庄镇、后河镇、楚旺镇、田氏镇、张龙乡、马上乡、高堤乡、亳城乡、二安乡、六村乡、中召乡、宋村乡、石盘屯乡、豆公乡',
        },
        {
          林州市:
            '开元街道办事处、振林街道办事处、龙山街道办事处、桂园街道办事处、合涧镇、临淇镇、东姚镇、横水镇、河顺镇、任村镇、姚村镇、陵阳镇、原康镇、五龙镇、采桑镇、东岗镇、桂林镇、城郊乡、茶店乡、石板岩乡',
        },
        {
          安阳新区: '白璧镇、高庄乡、峨眉大街街道、银杏大街街道、商颂大街街道',
        },
        { 高新区: '峨眉大街街道、银杏大街街道、商颂大街街道' },
        { 林州经济开发区: '卫生路' },
      ],
      鹤壁市: [
        {
          鹤山区:
            '中北街道、中山路街道、新华街街道、鹤山街街道、九矿广场街道、鹤壁集镇、姬家山乡',
        },
        {
          山城区:
            '红旗街道、长风中路街道、山城路街道、汤河街街道、鹿楼街道、宝山街道办事处、大胡街道办事处、石林镇',
        },
        {
          淇滨区:
            '金山办事处、大赉店镇、钜桥镇、上峪乡、大河涧乡、九州路街道、黎阳路街道、长江路街道、海河路办事处、渤海路办事处、东杨办事处',
        },
        {
          浚县: '卫溪街道办事处、浚州街道办事处、黎阳街道办事处、 伾山街道办事处、善堂镇、屯子镇、新镇镇、小河镇、卫贤镇、王庄镇、白寺乡',
        },
        {
          淇县: '朝歌街道办事处、桥盟街道办事处、卫都街道办事处、灵山街道办事处、高村镇、北阳镇、西岗镇、庙口镇、黄洞乡',
        },
      ],
      新乡市: [
        {
          红旗区:
            '西街街道办事处、东街街道办事处、渠东街道办事处、文化街街道办事处、向阳小区街道办事处、洪门镇、小店镇、开发区关堤乡、小店工业园区、开发区街道办事处',
        },
        {
          卫滨区:
            '胜利路街道办事处、解放路街道办事处、中同街街道办事处、健康路街道办事处、自由路街道办事处、南桥街道办事处、铁西街道办事处、平原乡',
        },
        { 凤泉区: '宝西街道办事处、宝东街道办事处、大块镇、潞王坟乡、耿黄乡' },
        {
          牧野区:
            '东干道街道办事处、荣校路街道办事处、北干道街道办事处、花园街道办事处、卫北街道办事处、新辉路街道办事处、和平路街道办事处、王村镇、牧野乡、新乡化学与物理电源产业园区管理委员会',
        },
        {
          新乡县:
            '翟坡镇、小冀镇、七里营镇、朗公庙镇、古固寨镇、大召营镇、合河乡、新乡经济开发区',
        },
        {
          获嘉县:
            '城关镇、照境镇、黄堤镇、中和镇、徐营镇、冯庄镇、亢村镇、史庄镇、位庄乡、大新庄乡、太山乡、原种场、园艺场、农场、西工区管理委员会',
        },
        {
          原阳县:
            '龙源街道办事处、城关镇、原武镇、师寨镇、齐街镇、太平镇、福宁集镇、葛埠口乡、祝楼乡、桥北乡、韩董庄乡、蒋庄乡、官厂乡、大宾乡、陡门乡、路寨乡、阳阿乡、靳堂乡',
        },
        {
          延津县:
            '城关镇、东屯镇、丰庄镇、僧固乡、石婆固乡、位邱乡、司寨乡、王楼乡、马庄乡、胙城乡、榆林乡、小潭乡、国营林场、新兴农场、新乡市食品工业聚集区、新乡市纺织工业聚集区',
        },
        {
          封丘县:
            '城关镇、黄陵镇、黄德镇、应举镇、陈桥镇、赵岗镇、留光镇、潘店镇、城关乡、回族乡、王村乡、陈固乡、居厢乡、鲁岗乡、荆宫乡、曹岗乡、李庄乡、尹岗乡、冯村乡',
        },
        {
          长垣县:
            '蒲西街道办事处、蒲东街道办事处、南蒲街道办事处、蒲北街道办事处、魏庄街道办事处、丁栾镇、樊相镇、恼里镇、常村镇、赵堤镇、孟岗镇、满村镇、苗寨镇、张三寨镇、芦岗乡、方里乡、武邱乡、佘家乡、河南长垣起重工业园区管理委员会',
        },
        {
          卫辉市:
            '汲水镇、太公镇、孙杏村镇、后河镇、李源屯镇、唐庄镇、上乐村镇、狮豹头乡、安都乡、顿坊店乡、柳庄乡、庞寨乡、城郊乡、原种场、东风农场、苗圃场、农科所、五四农场',
        },
        {
          辉县市:
            '城关街道办事处、胡桥街道办事处、薄壁镇、峪河镇、百泉镇、孟庄镇、常村镇、吴村镇、南村镇、南寨镇、上八里镇、北云门镇、占城镇、冀屯镇、黄水乡、拍石头乡、高庄乡、张村乡、赵固乡、西平罗乡、洪洲乡、沙窑乡',
        },
        { 平原新区: '原阳县祝楼乡、桥北乡、原武镇、韩董庄、师寨镇、葛埠口乡' },
      ],
      焦作市: [
        {
          解放区:
            '民生街道办事处、民主街道办事处、新华街道办事处、焦西街道办事处、焦南街道办事处、焦北街道办事处、七百间街道办事处、上白作街道办事处、王褚街道办事处',
        },
        {
          中站区:
            '李封街道办事处、王封街道办事处、朱村街道办事处、冯封街道办事处、龙洞街道办事处、月山街道办事处、丹河街道办事处、许衡街道办事处、府城街道办事处、龙翔街道办事处',
        },
        {
          马村区:
            '马村街道办事处、北山街道办事处、冯营街道办事处、九里山街道办事处、待王街道办事处、安阳城街道办事处、演马街道办事处',
        },
        {
          山阳区:
            '东方红街道办事处、焦东街道办事处、百间房街道办事处、太行街道办事处、艺新街道办事处、光亚街道办事处、定和街道办事处、中星街道办事处、新城街道办事处、李万街道办事处、文苑街道办事处、阳庙镇、宁郭镇、苏家作乡',
        },
        {
          修武县:
            '城关镇、七贤镇、郇封镇、高村乡、周庄乡、五里源乡、岸上乡、西村乡、小营工贸区',
        },
        {
          博爱县:
            '博爱县清化镇、博爱县柏山镇、博爱县月山镇、博爱县许良镇、博爱县磨头镇、博爱县孝敬镇、博爱县寨豁乡、博爱县金城乡、国营博爱农场',
        },
        {
          武陟县:
            '木城街道办事处、龙源街道办事处、龙泉街道办事处、木栾街道办事处、詹店镇、西陶镇、谢旗营镇、大封镇、嘉应观乡、乔庙乡、圪当店乡、三阳乡、小董乡、大虹桥乡、北郭乡、武陟宁郭农场',
        },
        {
          温县: '温泉镇、祥云镇、番田镇、黄庄镇、武德镇、赵堡镇、南张羌镇、岳村乡、招贤乡、北冷乡、国营温县农场',
        },
        {
          沁阳市:
            '覃怀街道、怀庆街道、太行街道、沁园街道、崇义镇、西向镇、西万镇、柏香镇、山王庄镇、紫陵镇、常平乡、王召乡、王曲乡',
        },
        {
          孟州市:
            '大定街道、会昌街道、河雍街道、河阳街道、化工镇、南庄镇、城伯镇、谷旦镇、赵和镇、西虢镇、槐树乡',
        },
      ],
      濮阳市: [
        {
          华龙区:
            '中原路街道办事处、胜利路街道办事处、建设路街道办事处、人民路街道办事处、大庆路街道办事处、黄河路街道办事处、任丘路街道办事处、长庆路街道办事处、昆吾路街道办事处、皇甫路街道办事处、开州街道                      办事处、王助镇、岳村乡、孟轲乡、胡村乡、新习乡、中原油田街道办事处、开发区街道办事处',
        },
        { 开发区: '开发区' },
        {
          清丰县:
            '城关镇、马庄桥镇、瓦屋头镇、仙庄镇、柳格镇、六塔乡、巩营乡、马村乡、高堡乡、古城乡、大流乡、韩村乡、大屯乡、固城乡、双庙乡、纸房乡、阳邵乡',
        },
        {
          南乐县:
            '城关镇、韩张镇、元村镇、福坎镇、杨村乡、张果屯乡、千口乡、谷金楼乡、西邵乡、寺庄乡、梁村乡、近德固乡',
        },
        {
          范县: '城关镇、濮城镇、龙王庄镇、高码头镇、辛庄乡、杨集乡、陈庄乡、白衣阁乡、王楼乡、颜村铺乡、陆集乡、张庄乡',
        },
        {
          台前县:
            '城关镇、侯庙镇、孙口镇、打渔陈镇、后方乡、清水河乡、马楼乡、夹河乡、吴坝乡',
        },
        {
          濮阳县:
            '城关镇、濮阳县柳屯镇、濮阳县文留镇、濮阳县庆祖镇、濮阳县八公桥镇、濮阳县徐镇镇、濮阳县户部寨镇、濮阳县鲁河镇、濮阳县清河头乡、濮阳县梁庄乡、濮阳县王称固乡、濮阳县白罡乡、濮阳县梨园乡、濮阳                      县五星乡、濮阳县子岸乡、濮阳县胡状乡、濮阳县郎中乡、濮阳县海通乡、濮阳县渠村乡、濮阳县习城乡',
        },
      ],
      许昌市: [
        {
          魏都区:
            '西大街道办事处、东大街道办事处、西关街道办事处、南关街道办事处、北大街道办事处、五一路街道办事处、高桥营街道办事处、丁庄街道办事处、七里店街道办事处、文峰街道办事处、新兴街道办事处、半截河                      街道办事处、许昌经济开发区、东城区管委会',
        },
        {
          许昌县:
            '将官池镇、五女店镇、尚集镇、苏桥镇、蒋李集镇、张潘镇、灵井镇、陈曹乡、邓庄乡、小召乡、河街乡、桂村乡、椹涧乡、榆林乡、长村张乡、艾庄回族乡',
        },
        {
          鄢陵县:
            '安陵镇、马栏镇、柏梁镇、陈化店镇、望田镇、大马镇、陶城镇、张桥镇、南坞乡、只乐乡、彭店乡、马坊乡',
        },
        {
          襄城县:
            '城关镇、颍桥回族镇、麦岭镇、颍阳镇、王洛镇、紫云镇、库庄镇、十里铺镇、湛北乡、山头店乡、茨沟乡、丁营乡、姜庄乡、范湖乡、双庙乡、汾陈乡',
        },
        {
          禹州市:
            '颍川街道办事处、夏都街道办事处、韩城街道办事处、钧台街道办事处、火龙镇、顺店镇、方山镇、鸿畅镇、梁北镇、古城镇、无梁镇、文殊镇、鸠山镇、褚河镇、范坡镇、郭连镇、朱阁镇、浅井镇、方岗镇、花石                     镇、苌庄乡、磨街乡、张得乡、小吕乡、山货回族乡',
        },
        {
          长葛市:
            '建设路街道办事处、长兴路街道办事处、长社路街道办事处、金桥路街道办事处、和尚桥镇、坡胡镇、后河镇、石固镇、老城镇、南席镇、大周镇、董村镇、增福庙乡、官亭乡、石象乡、古桥乡',
        },
      ],
      漯河市: [
        {
          源汇区:
            '老街街道、马路街街道、顺河街街道、干河陈街道、大刘镇、阴阳赵镇、空冢郭镇、问十乡',
        },
        {
          郾城区:
            '沙北街道办事处、城关镇、孟庙镇、商桥镇、裴城镇、新店镇、龙城镇、李集镇、黑龙潭乡',
        },
        {
          召陵区:
            '天桥街街道、翟庄办事处、召陵镇、邓襄镇、万金镇、老窝镇、姬石镇、后谢乡、青年村乡',
        },
        {
          舞阳县:
            '舞泉镇、吴城镇、北舞渡镇、莲花镇、辛安镇、孟寨镇、太尉镇、侯集镇、文峰乡、保和乡、马村乡、姜店乡、九街乡、章化乡',
        },
        {
          临颍县:
            '城关镇、繁城镇、杜曲镇、王岗镇、台陈镇、巨陵镇、瓦店镇、三家店镇、窝城镇、大郭乡、皇帝庙乡、固厢乡、石桥乡、陈庄乡、王孟乡',
        },
        { 经济开发区: '赣江路、珠江路、衡山路' },
        { 西城区: '西城区' },
      ],
      三门峡市: [
        {
          湖滨区:
            '湖滨街道、前进街道、车站街道、涧河街道、大安街道、会兴街道、崖底街道、交口乡、磁钟乡、高庙乡、河南三门峡经济开发区',
        },
        {
          渑池县:
            '城关镇、英豪镇、张村镇、洪阳镇、天池镇、仰韶镇、仁村乡、果园乡、陈村乡、坡头乡、段村乡、南村乡',
        },
        {
          陕县: '大营镇、原店镇、西张村镇、观音堂镇、张汴乡、张湾乡、菜园乡、张茅乡、王家后乡、硖石乡、西李村乡、宫前乡、店子乡',
        },
        {
          卢氏县:
            '城关镇、杜关镇、五里川镇、官道口镇、朱阳关镇、官坡镇、范里镇、东明镇、双龙湾镇、文峪乡、横涧乡、双槐树乡、汤河乡、瓦窑沟乡、狮子坪乡、沙河乡、徐家湾乡、潘河乡、木桐乡',
        },
        {
          义马市:
            '千秋路街道、朝阳路街道、新义街街道、常村路街道、泰山路街道、新区街道、东区街道',
        },
        {
          灵宝市:
            '城关镇、尹庄镇、朱阳镇、阳平镇、故县镇、豫灵镇、大王镇、阳店镇、函谷关镇、焦村镇、川口乡、寺河乡、苏村乡、五亩乡、西阎乡、灵宝市园艺场、涧东区管委会、涧西区管委会',
        },
      ],
      南阳市: [
        {
          宛城区:
            '东关街道办事处、新华街道办事处、汉冶街道办事处、仲景街道办事处、白河街道办事处、枣林街道办事处、赤虎街道办事处、官庄镇、瓦店镇、红泥湾镇、黄台岗镇、溧河乡、汉冢乡、金华乡、茶庵乡、高庙乡、新店乡',
        },
        {
          卧龙区:
            '七一街道办事处、卧龙岗街道办事处、武侯街道办事处、梅溪街道办事处、车站街道办事处、光武街道办事处、靳岗街道办事处、张衡街道办事处、百里奚街道办事处、石桥镇、潦河镇、安皋镇、蒲山镇、陆营镇、                      青华镇、英庄镇、七里园乡、谢庄乡、王村乡、龙兴乡、龙王沟风景区办事处',
        },
        {
          南召县:
            '城关镇、留山镇、云阳镇、皇路店镇、南河店镇、板山坪镇、乔端镇、白土岗镇、城郊乡、小店乡、皇后乡、太山庙乡、石门乡、四棵树乡、马市坪乡、崔庄乡、河南红阳机械厂、河南向东机械厂、河南红宇机械厂                     、国营东风机械厂、国营长江机械厂',
        },
        {
          方城县:
            '凤瑞街道办事处、释之街道办事处、独树镇、博望镇、拐河镇、小史店镇、赵河镇、广阳镇、券桥乡、杨集乡、二郎庙乡、古庄店乡、杨楼乡、清河乡、柳河乡、四里店乡、袁店回族乡、河南中南机械厂、方城大寺林场',
        },
        {
          西峡县:
            '白羽街道办事处、紫金街道办事处、莲花街道办事处、丹水镇、西坪镇、双龙镇、回车镇、丁河镇、桑坪镇、米坪镇、五里桥镇、重阳镇、太平镇、阳城镇、二郎坪镇、田关乡、寨根乡、石界河乡、军马河乡',
        },
        {
          镇平县:
            '涅阳街道办事处、雪枫街道办事处、玉都街道办事处、石佛寺镇、晁陂镇、贾宋镇、侯集镇、老庄镇、卢医镇、遮山镇、高丘镇、曲屯镇、枣园镇、杨营镇、安字营镇、张林镇、柳泉铺镇、二龙乡、王岗乡、马庄乡、彭营乡、郭庄回族乡',
        },
        {
          内乡县:
            '城关镇、夏馆镇、师岗镇、马山口镇、湍东镇、赤眉镇、瓦亭镇、王店镇、灌涨镇、桃溪镇、板场乡、大桥乡、赵店乡、七里坪乡、余关乡、砟岖乡',
        },
        {
          淅川县:
            '龙城街道办事处、商圣街道办事处、荆紫关镇、老城镇、香花镇、厚坡镇、丹阳镇、盛湾镇、金河镇、寺湾镇、仓房镇、上集镇、马蹬镇、西簧乡、毛堂乡、大石桥乡、滔河乡',
        },
        {
          社旗县:
            '赵河街道办事处、潘河街道办事处、赊店镇、桥头镇、饶良镇、兴隆镇、晋庄镇、李店镇、苗店镇、郝寨镇、朱集镇、下洼镇、太和镇、大冯营镇、陌陂乡、唐庄乡',
        },
        {
          唐河县:
            '滨河街道办事处、文峰街道办事处、东兴街道办事处、源潭镇、张店镇、郭滩镇、湖阳镇、黑龙镇、大河屯镇、龙潭镇、桐寨铺镇、苍台镇、上屯镇、毕店镇、少拜寺镇、城郊乡、桐河乡、昝岗乡、祁仪乡、马振抚乡、古城乡、东王集乡',
        },
        {
          新野县:
            '汉城街道办事处、汉华街道办事处、王庄镇、沙堰镇新、甸铺镇、施庵镇、歪子镇五、星镇、溧河铺镇、王集镇、城郊乡、前高庙乡、樊集乡、上庄乡、上港乡',
        },
        {
          桐柏县:
            '城关镇、月河镇、吴城镇、固县镇、毛集镇、大河镇、埠江镇、平氏镇、淮源镇、黄岗镇、安棚镇、城郊乡回、龙乡、朱庄乡、程湾乡、新集乡',
        },
        {
          邓州市:
            '古城街道办事处、花洲街道办事处、湍河街道办事处、罗庄镇、汲滩镇、穰东镇、孟楼镇、林扒镇、构林镇、十林镇、张村镇、都司镇、赵集镇、刘集镇、桑庄镇、彭桥镇、张楼乡、白牛乡、夏集乡、裴营乡、文渠                      乡、高集乡、陶营乡、小杨营乡、腰店乡、龙堰乡、九龙乡、南阳市黄牛良种繁育场、杏山旅游管理区杏山街道办事处',
        },
        { 高新区: '高新区' },
        { 南阳新区: '南阳新区' },
        { 官庄工区: '官庄工区' },
        { 鸭河工区: '鸭河工区' },
      ],
      商丘市: [
        {
          梁园区:
            '前进街道、长征街道、八八街道东、风街道、中州街道、白云街道、平原街道、建设街道、平台街道办事处、平安街道办事处、谢集镇、双八镇、张阁镇、周集乡、水池铺乡、观堂乡、王楼乡、李庄乡、孙福集乡、刘口乡',
        },
        {
          睢阳区:
            '古城街道办事处、文化街道办事处、东方街道办事处、新城街道办事处、古宋街道办事处、宋城街道办事处、宋集镇、郭村镇、李口镇、高辛镇、坞墙镇、冯桥镇、路河镇、闫集乡、包公庙乡、娄店乡、毛固堆乡、勒马乡、临河店乡',
        },
        {
          商丘新区:
            '商丘经济开发区、豫东综合物流产业集聚区、平安街道办事处、平台街道办事处、中州街道办事处、张阁镇、周集镇、贾寨镇',
        },
        {
          民权县:
            '城关镇、人和镇、龙塘镇、北关镇、程庄镇、王庄寨镇、孙六镇、白云寺镇、王桥镇、庄子镇、伯党乡、花园乡、野岗乡、双塔乡、林七乡、胡集乡、老颜集乡、商丘市农场、商丘市林场',
        },
        {
          睢县: '长岗镇、平岗镇、周堂镇、蓼堤镇、西陵寺镇、城关镇、潮庄镇、尚屯镇、后台乡、河集乡、孙聚寨乡、白楼乡、河堤乡、白庙乡、胡堂乡、尤吉屯乡、董店乡、涧岗乡、匡城乡、城郊乡',
        },
        {
          宁陵县:
            '城关镇、张弓镇、柳河镇、逻岗镇、石桥镇、黄岗镇、华堡乡、刘楼乡、程楼乡、乔楼乡、城郊乡、阳驿乡、孔集乡、赵村乡',
        },
        {
          柘城县:
            '长江新城街道办事处、浦东街道办事处、柘城县城关镇、陈青集镇、起台镇、胡襄镇、慈圣镇、安平镇、远襄镇、岗王镇、邵元乡、张桥乡、洪恩乡、老王集乡、大仵乡、马集乡、牛城乡、惠济乡、伯岗乡、申桥乡、李原乡、皇集乡',
        },
        {
          虞城县:
            '城关镇、界沟镇、营郭镇、杜集镇、谷熟镇、大杨集镇、贾寨镇、利民镇、张集镇、站集镇、黄冢乡、沙集乡、店集乡、闻集乡、芒种桥乡、刘店乡、大候乡、城郊乡、郑集乡、李老家乡、稍岗乡、镇里固乡、古王集乡、刘集乡、乔集乡、田庙乡',
        },
        {
          夏邑县:
            '城关镇、会亭镇、马头镇、济阳镇、李集镇、车站镇、杨集镇、韩道口镇、太平镇、罗庄镇、火店镇、曹集乡、胡桥乡、歧河乡、郭店乡、业庙乡、中峰乡、桑固乡、何营乡、王集乡、刘店集乡、骆集乡、孔庄乡、北镇乡',
        },
        {
          永城市:
            '演集镇、城关镇、芒山镇、高庄镇、裴桥镇、马桥镇、薛湖镇、蒋口镇、陈集镇、十八里镇、太邱镇、李寨镇、苗桥镇、顺和镇、茴村镇、龙岗镇、马牧镇、城厢乡、候岭乡、黄口乡、新桥乡、双桥乡、王集乡、卧                      龙乡、条河乡、刘河乡、陈官庄乡',
        },
      ],
      信阳市: [
        {
          浉河区:
            '老城街道办事处、民权街道办事处、车站街道办事处、五里墩街道办事处、五星街道办事处、湖东南湾街道办事处、金牛山街道办事处、双井街道办事处、李家寨镇、吴家店镇、东双河镇、董家河镇、游河乡、谭家河乡、柳林乡、十三里桥乡',
        },
        {
          平桥区:
            '羊山街道办事处、前进街道办事处、南京路办事处、平桥街道办事处、甘岸街道办事处、五里店街道办事处、平西街道办事处、明港镇、五里镇、邢集镇、平昌镇、洋河镇、肖王乡、龙井乡、胡店乡、彭家湾乡、长                      台乡、肖店乡、王岗乡、高粱店乡、查山乡、天梯办事处、明港工业管理区、震雷山风景管理局、平桥工业园、城阳城址保护区、龙飞山办事处、北湖风景管理区、城东办事处、农村改革发展综合试验核心区',
        },
        { 羊山新区: '龙飞山办事处' },
        {
          罗山县:
            '城关镇、周党镇、竹竿镇、灵山镇、子路镇、楠杆镇、青山镇、潘新镇、彭新镇、莽张镇、东卜镇、龙山乡、庙仙乡、定远乡、山店乡、铁铺乡、朱堂乡、尤店乡、高店乡、河南省五一农场',
        },
        {
          光山县:
            '弦山街道办事处、紫水街道办事处、十里镇、寨河镇、孙铁铺镇、马畈镇、泼陂河镇、白雀园镇、砖桥镇、仙居乡、北向店乡、罗陈乡、殷棚乡、南向店乡、晏河乡、凉亭乡、斛山乡、槐店乡、文殊乡',
        },
        {
          新县: '新集镇、沙窝镇、吴陈河镇、苏河镇、八里畈镇、周河乡、陡山河乡、浒湾乡、千斤乡、卡房乡、郭家河乡、陈店乡、箭厂河乡、泗店乡、田铺乡',
        },
        {
          商城县:
            '赤城街道办事处、鲇鱼山街道办事处、上石桥镇、鄢岗镇双、椿铺镇、汪桥镇、余集镇、达权店镇、丰集镇、河风桥乡、李集乡、金刚台乡、苏仙石乡、汪岗乡、伏山乡、观庙乡、吴河乡、冯店乡、长竹园乡、汤泉池管理处',
        },
        {
          固始县:
            '蓼城街道办事处、番城街道办事处、秀水街道办事处、陈淋子镇、黎集镇、蒋集镇、往流镇、郭陆滩镇、胡族铺镇、方集镇、三河尖镇、段集镇、汪棚镇、张广庙镇、陈集镇、武庙集镇、分水亭镇、石佛店镇、洪埠                     乡、杨集乡、马罡集乡、草庙集乡、南大桥乡、赵岗乡、祖师庙乡、张老埠乡、泉河铺乡、沙河铺乡、徐集乡、李店乡、丰港乡、柳树店乡、观堂乡',
        },
        {
          潢川县:
            '春申街道办事处、定城街道办事处、弋阳街道办事处、老城街道办事处、双柳树镇、伞陂镇、卜塔集镇、仁和镇、付店镇、踅孜镇、桃林铺镇、黄寺岗镇、江家集镇、传流店乡、魏岗乡、张集乡、来龙乡、隆古乡、                      谈店乡、上油岗乡、白店乡、黄湖农场、河南省潢川经济技术开发区',
        },
        {
          淮滨县:
            '城关镇、马集镇、栏杆镇、防胡镇、新里镇、期思镇、赵集镇、台头乡、王家岗乡、固城乡、三空桥乡、张里乡、邓湾乡、张庄乡、王店乡、谷堆乡、芦集乡',
        },
        {
          息县: '谯楼街道办事处、龙湖街道办事处、淮河街道办事处、包信镇、夏庄镇、东岳镇、项店镇、小茴店镇、孙庙乡、路口乡、彭店乡、杨店乡、张陶乡、白土店乡、岗李店乡、长陵乡、陈棚乡、临河乡、关店乡、曹黄林乡、八里岔乡、地矿局',
        },
        { 南湾湖风景区: '南湾湖风景区' },
        { 上天梯管理区: '上天梯管理区' },
        { 鸡公山管理区: '鸡公山管理区' },
        { 工业新区: '工业新区' },
        {
          潢川经济开发区:
            '七里岗村、王香铺村、金星村、沙河店村、刘靛行村、奚店社区',
        },
        { 高新区: '高新区' },
      ],
      周口市: [
        {
          川汇区:
            '陈州回族街道办事处、七一路街道办事处、荷花街道办事处、人和街道办事处、小桥街道办事处、城南办事处、城北办事处、文昌办事处、搬口办事处、金海路办事处、太昊路办事处、淮河路办事处、李埠口乡、许湾乡',
        },
        {
          扶沟县:
            '城关镇、崔桥镇、江村镇、白潭镇、韭园镇、练寺镇、大新镇、包屯镇、汴岗镇、曹里乡、柴岗乡、固城乡、吕潭乡、大李庄乡、城郊乡、国营农牧场',
        },
        {
          西华县:
            '娲城、箕子台、昆山、西夏亭镇、逍遥镇、奉母镇、红花集镇、聂堆镇、东夏亭镇、西华营镇、址坊镇、田口乡、清河驿乡、皮营乡、东王营乡、大王庄乡、李大庄乡、叶埠口乡、迟营乡、黄土桥乡、艾岗乡、黄泛区农场、五二农场',
        },
        {
          商水县:
            '新城街道办事处、东城街道办事处、老城街道办事处、黄寨镇、练集镇、魏集镇、固墙镇、白寺镇、巴村镇、谭庄镇、邓城镇、胡吉镇、城关乡、平店乡、袁老乡、化河乡、姚集乡、舒庄乡、大武乡、张明乡、郝岗                      乡、张庄乡、汤庄乡、商水县农场',
        },
        {
          沈丘县:
            '东城办事处、北城办事处、槐店回族镇、刘庄店镇、留福集镇、老城镇、赵德营镇、付井镇、纸店镇、新安集镇、白集镇、刘湾镇、连池乡、石槽集乡、范营乡、李老庄乡、大邢庄乡、冯营乡、周营乡、洪山乡、北杨集乡、卞路口乡',
        },
        {
          郸城县:
            '洺南办事处、洺北办事处、新城办事处、吴台镇、南丰镇、白马镇、宁平镇、宜路镇、钱店镇、汲冢镇、石槽镇、城郊乡、虎头岗乡、汲水乡、张完集乡、丁村乡、双楼乡、秋渠乡、东风乡、巴集乡、李楼乡、胡集乡',
        },
        {
          淮阳县:
            '城关回族镇、新站镇、鲁台镇、四通镇、临蔡镇、安岭镇、白楼镇、朱集乡、豆门乡、冯塘乡、刘振屯乡、王店乡、大连乡、葛店乡、黄集乡、齐老乡、郑集乡、曹河乡',
        },
        {
          太康县:
            '城关回族镇、常营镇、逊母口镇、老冢镇、朱口镇、马头镇、龙曲镇、板桥镇、符草楼镇、马厂镇、毛庄镇、张集镇、清集镇、城郊乡、杨庙乡、王集乡、高贤乡、芝麻洼乡、独塘乡、大许寨乡、五里口乡、高朗乡、转楼乡',
        },
        {
          鹿邑县:
            '真源办事处、谷阳办事处、卫真办事处、鸣鹿办事处、涡北镇、玄武镇、宋河镇、太清宫镇、王皮溜镇、试量镇、辛集镇、马铺镇、贾滩镇、杨湖口镇张、店镇、观堂镇、生铁冢镇、郑家集乡、赵村乡、任集乡、唐集乡、高集乡、邱集乡、穆店乡',
        },
        {
          项城市:
            '花园办事处、水寨办事处、东方办事处、莲花办事处、千佛阁办事处、光武办事处、南顿镇、孙店镇、李寨镇、贾岭镇、高寺镇、新桥镇、付集镇、官会镇、丁集镇、郑郭镇、秣陵镇、王明口镇、范集镇、三店镇、永丰镇',
        },
      ],
      驻马店市: [
        {
          驿城区:
            '人民街道办事处、东风街道办事处、西园街道办事处、新华街道办事处、南海街道办事处、老街街道办事处、橡林街道办事处、雪松街道办事处、顺河街道办事处、刘阁街道办事处、香山街道办事处、水屯镇、沙                        河店镇、板桥镇、诸市镇、蚁蜂镇、老河乡、朱古洞乡、胡庙乡、古城乡、关王庙乡、金河街道办事处、金桥街道办事处、金山街道办事处、开源办事处',
        },
        {
          西平县:
            '柏城街道办事处、柏亭街道办事处、五沟营镇、权寨镇、师灵镇、出山镇、盆尧镇、环城乡、重渠乡、人和乡、宋集乡、谭店乡、吕店乡、芦庙乡、杨庄乡、专探乡、二郎乡、蔡寨回族乡、焦庄乡、老王坡农场、产业集聚区',
        },
        {
          上蔡县:
            '蔡都街道办事处、芦岗街道办事处、重阳街道办事处、卧龙街道办事处、黄埠镇、杨集镇、洙湖镇、党店镇、朱里镇、华陂镇、塔桥镇、东洪镇、邵店镇、大路李乡、无量寺乡五、龙乡、杨屯乡、和店乡、蔡沟乡                       、齐海乡、崇礼乡、韩寨乡、东岸乡、小岳寺乡、西洪乡、百尺乡',
        },
        {
          平舆县:
            '古槐街道办事处、清河街道办事处、东皇街道办事处、杨埠镇、东和店镇、庙湾镇、射桥镇、西洋店镇、阳城镇、郭楼镇、李屯镇、万金店镇、高杨店镇、万冢镇、十字路乡、玉皇庙乡、老王岗乡、辛店乡、双庙乡',
        },
        {
          正阳县:
            '真阳镇、寒冻镇、汝南埠镇、铜钟镇、陡沟镇、熊寨镇、大林镇、永兴镇、慎水乡、傅寨乡、袁寨乡、新阮店乡、油坊店乡、雷寨乡、王勿桥乡、吕河乡、皮店乡、彭桥乡、兰青乡',
        },
        {
          确山县:
            '盘龙镇、竹沟镇、任店镇、新安店镇、留庄镇、刘店镇、瓦岗镇、双河镇、石滚河镇、李新店镇、三里河乡、普会寺乡',
        },
        {
          泌阳县:
            '泌水镇、羊册镇、马谷田镇、春水镇、官庄镇、赊湾镇、高店乡、盘古乡、高邑乡、王店乡、铜山乡、下碑寺乡、象河乡、付庄乡、贾楼乡、黄山口乡、郭集乡、泰山庙乡、杨家集乡、双庙街乡花、园乡、工业园区管委会、石材开发区管委会',
        },
        {
          汝南县:
            '汝宁街道办事处、三门闸街道办事处、古塔街道办事处、王岗镇、梁祝镇、和孝镇、老君庙镇、留盆镇、金铺镇、东官庄镇、常兴镇、罗店镇、韩庄镇、三桥镇、张楼镇、南余店乡、板店乡',
        },
        {
          遂平县:
            '瞿阳街道办事处、车站街道办事处、莲花湖街道办事处、玉山镇、查岈山镇、石寨铺镇和、兴镇沈寨镇、阳丰镇、常庄乡、槐树乡、花庄乡、文城乡、褚堂乡、工业园区、查岈山风景区、凤鸣谷风景区',
        },
        {
          新蔡县:
            '古吕街道办事处、今是街道办事处、月亮湾街道办事处、砖店镇、陈店镇、佛阁寺镇、练村镇、棠村镇、韩集镇、龙口镇、李桥回族镇、黄楼镇、孙召镇、余店镇、河坞乡、关津乡、宋岗乡、顿岗乡、涧头乡、杨                        庄户乡、化庄乡、栎城乡、弥陀寺乡',
        },
      ],
      济源市: [
        {
          济源市:
            '沁园、济水、北海、天坛、玉泉、克井镇、五龙口镇、轵城镇、承留镇、邵原镇、坡头镇、梨林镇、大峪镇、思礼镇、王屋镇、下冶镇',
        },
      ],
    },
  ],
  江西省: [
    {
      南昌市: [
        {
          新建区:
            '松湖镇、石岗镇、西山镇、石埠乡、生米镇、 流湖乡、厚田乡、望城镇、 长堎镇、乐化镇、溪霞镇、樵舍镇、大塘坪乡、金桥乡、铁河乡、象山镇、联圩乡、昌邑乡、南矶乡',
        },
        {
          南昌县:
            '莲塘镇、向塘镇、昌东镇、蒋巷镇、三江镇、幽兰镇、塘南镇、冈上镇、武阳镇、广福镇、麻丘镇、南新乡、塔城乡、泾口乡、八一乡、黄马乡、富山乡、东新乡',
        },
        {
          进贤县:
            '民和镇、李渡镇、温圳镇、文港镇、梅庄镇、张公镇、罗溪镇、架桥镇、前坊镇、三里乡、二塘乡、钟陵乡、池溪乡、南台乡、三阳集乡、七里乡、下埠集乡、衙前乡、白圩乡、长山晏乡、泉岭乡、五里垦殖场',
        },
        {
          安义县:
            '龙津镇、鼎湖镇、万埠镇、石鼻镇、东阳镇、长埠镇、黄洲镇、乔乐乡、新民乡、长均乡、万埠垦殖场',
        },
        {
          东湖区:
            '公园街道、滕王阁街道、百花洲街道、八一桥街道、墩子塘街道、豫章街道、大院街道、董家窑街道、彭家桥街道、贤士湖',
        },
        {
          西湖区:
            '南站街道、南浦街道、西湖街道、朝阳洲街道、十字街街道、桃源街道、广润门街道、系马桩街道、丁公路街道、绳金塔街道、桃花镇、朝阳农场',
        },
        {
          青云谱区:
            '洪都街道、京山街道、三家店街道、岱山街道、徐家坊街道、青云谱镇',
        },
        { 湾里区: '站前街道、幸福街道、招贤镇、梅岭镇、罗亭镇、太平镇' },
        {
          青山湖区:
            '青山路街道、上海路街道、南钢街道、京东镇、湖坊镇、塘山镇、罗家镇、蛟桥镇、扬子洲乡',
        },
      ],
      九江市: [
        { 浔阳区: '甘棠街道、湓浦街道、白水湖街道、金鸡坡街道、人民路街道' },
        {
          庐山区:
            '十里街道、赛阳镇、新港镇、五里街道、莲花镇、姑塘镇、虞家河乡、威家镇、海会镇、牯岭镇',
        },
        {
          九江县:
            '沙河街镇、马回岭镇、江洲镇、城子镇、港口街镇、新合镇、狮子镇、永安乡、涌泉乡、新塘乡、城门乡、岷山乡、新洲垦殖场赛城湖水产场、岷山林场、省农科院棉花研究所、赤湖水产场、沙河经济技术开发区',
        },
        { 共青城市: '茶山街道、甘露镇、金湖镇、江益镇、苏家垱乡、泽泉乡' },
        {
          瑞昌市:
            '湓城街道、桂林街道、码头镇、白杨镇、南义镇、横港镇、范镇、肇陈镇、高丰镇、夏畈镇、武蛟镇、乐园乡、洪一乡、花园乡、洪下乡、横立山乡、黄金乡、南阳乡、赛湖农场、青山林场、大德山林场',
        },
        {
          永修县:
            '涂埠镇、吴城镇、柘林镇、虬津镇、艾城镇、滩溪镇、白槎镇、梅棠镇、燕坊镇、马口镇、三溪桥镇、三角乡、九合乡、立新乡、江上乡',
        },
        {
          德安县:
            '蒲亭镇、宝塔乡、河东乡、丰林镇、高塘乡、林泉乡、聂桥镇、吴山镇、磨溪乡、爱民乡、邹桥乡、塘山乡、车桥镇',
        },
        {
          星子县:
            '南康镇、白鹿镇、温泉镇、蓼花镇、华林镇、蛟塘镇、横塘镇、蓼南乡、苏家垱乡、泽泉乡、沙湖山管理处、东牯山林场',
        },
        {
          都昌县:
            '都昌镇、周溪镇、三汊港镇、中馆镇、大沙镇、万户镇、南峰镇、土塘镇、大港镇、蔡岭镇、徐埠镇、左里镇、和合乡、阳峰乡、西源乡、芗溪乡、狮山乡、鸣山乡、春桥乡、苏山乡、多宝乡、汪墩乡、北山乡、大树乡',
        },
        {
          湖口县:
            '双钟镇、流泗镇、马影镇、武山镇、城山镇、大垅乡、凰村乡、张青乡、文桥乡、付垅乡、舜德乡、流芳乡',
        },
        {
          彭泽县:
            '龙城镇、棉船镇、马当镇、定山镇、杨梓镇、芙蓉墩镇、浪溪镇、黄花镇、黄花镇、东升镇、天红镇、浩山乡、太平关乡、黄岭乡、太泊湖区、芙蓉农场、原种场、上十岭垦殖场、红光建材场',
        },
        {
          武宁县:
            '豫宁街道、新宁镇、泉口镇、鲁溪镇、船滩镇、澧溪镇、罗坪镇、石门楼镇、宋溪镇、大洞乡、横路乡、官莲乡、巾口乡、东林乡、上汤乡、甫田乡、清江乡、石渡乡、杨洲乡、罗溪乡',
        },
        {
          修水县:
            '义宁镇、白岭镇、全丰镇、古市镇、大桥镇、渣津镇、马坳镇、杭口镇、港口镇、溪口镇、西港镇、山口镇、黄沙镇、黄港镇、何市镇、上奉镇、四都镇、太阳升镇、路口乡、黄龙乡、上衫乡、余段乡、水源乡、石坳乡、东港乡、上杭乡、新湾乡、布甲乡、漫江乡、复原乡、竹坪乡、征村乡、庙岭乡、黄坳乡、大椿乡、程坊乡',
        },
      ],
      上饶区: [
        {
          信州区:
            '水南街道、东市街道、西市街道、北门街道、茅家岭街道、灵溪镇、沙溪镇、秦峰乡、朝阳镇',
        },
        {
          上饶县:
            '旭日街道、罗桥街道、田墩镇、上泸镇、茶亭镇、皂头镇、花厅镇、五府山镇、望仙乡、石人乡、石狮乡、湖村乡、应家乡、黄沙岭乡、董团乡、枫岭头镇、兴园街道、华坛山镇、四十八镇、郑坊镇、清水乡、尊桥乡、煌固镇、铁山乡',
        },
        {
          广丰县:
            '永丰街道、丰溪街道、芦林街道、五都镇、洋口镇、横山镇、桐畈镇、湖丰镇、大南镇、排山镇、毛村镇、枧底镇、泉波镇、壶峤镇、霞峰镇、下溪镇、吴村镇、沙田镇、铜钹山镇、大石乡、东阳乡、嵩峰乡、少阳乡',
        },
        {
          玉山县:
            '冰溪镇、临湖镇、必姆镇、横街镇、文成镇、下镇镇、岩瑞镇、双明镇、紫湖镇、仙岩镇、樟村镇、南山乡、怀玉乡、下塘乡、四股桥乡、六都乡、金山工业园',
        },
        {
          铅山县:
            '河口镇、永平镇、石塘镇、鹅湖镇、湖坊镇、武夷山镇、汪二镇、陈坊乡、虹桥乡、新滩乡、葛仙山乡、稼轩乡、英将乡、紫溪乡、太源畲族乡、天柱山乡、篁碧畲族乡',
        },
        {
          横峰县:
            '岑阳镇、葛源镇、铺前镇、新篁乡、青板乡、龙门畈乡、港边乡、姚家乡、司铺乡、莲荷乡',
        },
        {
          弋阳县:
            '弋江镇、圭峰镇、叠山镇、漆工镇、曹溪镇、中畈乡、葛溪乡、三县岭场',
        },
        {
          余干县:
            '鹭鸶港乡、古埠镇、三塘乡、杨埠乡、峡山林场、白马桥、社庚乡、大溪乡、瑞洪镇、玉亭镇、枫港乡、东塘乡、康山垦殖场、黄金埠镇、洪家嘴乡、江埠乡、信丰垦殖场、石口镇、鱼池湖水产场、梅港乡、李梅林场、九龙乡、乌泥镇、大塘乡',
        },
        {
          鄱阳县:
            '鄱阳镇、谢家滩镇、石门街镇、四十里街镇、油墩街镇、田畈街镇、金盘岭镇、高家岭镇、凰岗镇、双港镇、古县渡镇、饶丰镇、乐丰镇、饶埠镇、侯家岗乡、珠湖乡、柘港乡、三庙前乡、莲花山乡、白沙洲乡、鸦鹊湖乡、莲湖乡、响水滩乡、团林乡、银宝湖乡、芦田乡、枧田街乡、昌洲乡、游城乡',
        },
        {
          万年县:
            '陈营镇、梓埠镇、石镇镇、大源镇、青云镇、裴梅镇、湖云乡、珠田乡、齐埠乡、汪家乡、上坊乡、苏桥乡',
        },
        {
          婺源县:
            '蚺城街、紫阳镇、清华镇、秋口镇、江湾镇、思口镇、中云镇、赋春镇、镇头镇、许村镇、太白镇、溪头乡、段莘乡、浙源乡、沱川乡、大鄣山乡、珍珠山乡',
        },
        {
          德兴市:
            '银城街道、泗洲镇、花桥镇、绕二镇、海口镇、新岗山镇、黄柏乡、万村乡、张村乡、皈大乡、李宅乡、龙头山乡',
        },
      ],
      抚州区: [
        {
          临川区:
            '青云街道、西大街街道、荆公路街道、六水桥街道、文昌街道、城西街道、钟岭街道、上顿渡镇、温泉镇、高坪镇、秋溪镇、荣山镇、龙溪镇、崇岗镇、大岗镇、云山镇、唱凯镇、罗针镇、罗湖镇、太阳镇、东馆镇、腾桥镇、青泥镇、孝桥镇、抚北镇、展坪乡、连城乡、桐源乡、湖南乡、七里岗乡、嵩湖乡、鹏田乡、茅排乡、河埠乡、七垦垦殖场、荣垦垦殖场',
        },
        {
          南城县:
            '建昌镇、株良镇、上唐镇、里塔镇、洪门镇、沙洲镇、龙湖镇、新丰街镇、万坊镇、徐家乡、天井源乡、浔溪乡',
        },
        {
          黎川县:
            '日峰镇、宏村镇、洵口镇、熊村镇、龙安镇、德胜镇、潭溪乡、湖坊乡、荷源乡、厚村乡、社苹乡、樟溪乡、西城乡、中田乡、华山垦殖场',
        },
        {
          南丰县:
            '琴城镇、太和镇、白舍镇、市山镇、洽湾镇、桑田镇、紫霄镇、三溪乡、东坪乡、莱溪乡、太源乡、傅坊乡',
        },
        {
          崇仁县:
            '巴山镇、相山镇、航埠镇、孙坊镇、河上镇、礼陂镇、马鞍镇；8乡：石庄乡、六家桥乡、白路乡、三山乡、白陂乡、桃源乡、郭圩乡、许坊乡',
        },
        {
          乐安县:
            '招携镇、增田镇、公溪镇、山砀镇、龚坊镇、戴坊镇、万崇镇、牛田镇、敖溪镇、罗陂乡、湖坪乡、金竹乡、谷岗乡、湖溪乡、南村乡',
        },
        {
          宜黄县:
            '凤冈镇、二都镇、黄陂镇、中港镇、东陂镇、棠阴镇、梨溪镇、桃陂镇、新丰乡、神岗乡、圳口乡、南源乡、桃陂乡、黄柏岭垦殖场、青年垦殖场、江西宜黄工业园区',
        },
        {
          金溪县:
            '秀谷镇、浒湾镇、双塘镇、何源镇、合市镇、琅琚镇、左坊镇、黄通乡、对桥乡、陆坊乡、陈坊积乡、琉璃乡、石门乡',
        },
        {
          资溪县:
            '鹤城镇、马头山镇、高阜镇、嵩市镇、乌石镇、高田乡、石峡乡、高阜林场、马头山林场、石峡林场、陈坊林场、株溪林场',
        },
        {
          东乡县:
            '孝岗镇、小璜镇、圩上桥镇、马圩镇、詹圩镇、岗上积镇、杨桥殿镇、黎圩镇、王桥镇、珀玕乡、邓家乡、虎圩乡、瑶圩乡',
        },
        { 广昌县: '旴江镇、头陂镇、赤水镇、驿前镇、苦竹镇、甘竹镇' },
      ],
      宜春区: [
        {
          袁州区:
            '灵泉街道、秀江街道、湛郎街道、珠泉街道、化成街道、官园街道、下浦街道、凤凰街道、金园街道、彬江镇、西村镇、金瑞镇、温汤镇、三阳镇、慈化镇、天台镇、洪塘镇、渥江镇、新坊镇、寨下镇',
        },
        {
          高安市:
            '瑞州街道、筠阳街道、蓝坊镇、荷岭镇、黄沙岗镇、新街镇、八景镇、独城镇、太阳镇、建山镇、田南镇、相城镇、灰埠镇、石脑镇、龙潭镇、杨圩镇、村前镇、伍桥镇、祥符镇、大城镇、上湖乡、汪家圩乡、华林山风景区',
        },
        {
          丰城市:
            '剑光街道、剑南街道、河洲街道、曲江街道、尚庄街道、同田乡、泉港镇、董家镇、梅林镇、隍城镇、湖塘镇、上塘镇、白土镇、小港镇、筱塘镇、段潭镇、袁渡镇、拖船镇、荣塘镇、孙渡街道、丽村镇、桥东镇、杜市镇、秀市镇、淘沙镇、张巷镇、石滩镇、洛市镇、铁路镇、蕉坑镇、石江镇、荷湖镇',
        },
        {
          樟树市:
            '淦阳街道、大桥街道、鹿江街道、福城街道、张家山街道、临江镇、观上镇、昌傅镇、永泰镇、刘公庙镇、义成镇、黄土岗镇、经楼镇、阁山镇、店下镇、洲上乡、中洲乡、洋湖乡、吴城乡',
        },
        {
          奉新县:
            '冯川镇、上富镇、干洲镇、罗市镇、宋埠镇、赤田镇、澡下镇、赤岸镇、会埠镇、甘坊镇、澡溪乡、仰山乡、柳溪乡、东风乡、干垦乡、百丈山管委会、石溪办事外、农牧渔场',
        },
        {
          万载县:
            '康乐街道办事处、株潭镇、黄茅镇、潭埠镇、双桥镇、三兴镇、罗城镇、高村镇、高城镇、白良镇、鹅峰乡、马步乡、赤兴乡、岭东乡、白水乡、仙源乡、茭湖乡',
        },
        {
          上高县:
            '敖阳街道、田心镇、徐家渡镇、锦江镇、泗溪镇、翰堂镇、南港镇、芦洲乡、塔下乡、蒙山乡、镇渡乡、野市乡、新界埠乡',
        },
        {
          宜丰县:
            '新昌镇、澄塘镇、棠浦镇、新庄镇、花桥乡、同安乡、天宝乡、潭山镇、双峰林场、黄岗乡、车上林场、石花尖垦殖场、芳溪镇、石市镇、桥西乡、黄岗山垦殖场',
        },
        {
          靖安县:
            '双溪镇、仁首镇、宝峰镇、高湖镇、璪都镇、香田乡、水口乡、罗湾乡、中源乡、雷公尖乡、三爪仑乡',
        },
        {
          铜鼓县:
            '永宁镇、温泉镇、棋坪镇、排埠镇、三都镇、大段镇、带溪乡、高桥乡、港口乡、花山林场、大沩山林场、龙门林场、茶山林场',
        },
      ],
      吉安区: [
        {
          吉州区:
            '古南街道、永叔街道、文山街道、习溪桥街道、北门街道、白塘街道、兴桥镇、樟山镇、长塘镇、禾埠乡、曲濑乡',
        },
        {
          青原区:
            '河东街道、天玉镇、值夏镇、新圩镇、富滩镇、富田镇、文陂乡、东固畲族乡',
        },
        {
          吉安县:
            '敦厚镇、永阳镇、天河镇、横江镇、固江镇、万福镇、永和镇、桐坪镇、凤凰镇、油田镇、敖城镇、北源乡、大冲乡、里田乡、梅塘镇、登龙乡、安塘乡、官田乡、指阳乡',
        },
        {
          井冈山市:
            '厦坪镇、大陇镇、龙市镇、古城镇、新城镇、茨坪镇、长坪乡、柏露乡、 荷花乡、拿山乡、坳里乡、茅坪乡、东上乡、黄坳乡、鹅岭乡、葛田乡、睦村乡、下七乡',
        },
        {
          吉水县:
            '文峰镇、八都镇、双村镇、醪桥镇、阜田镇、盘谷镇、枫江镇、黄桥镇、金滩镇、乌江镇、丁江镇、白水镇、白沙镇、螺田镇、水南镇、水田乡、尚贤乡、冠山乡',
        },
        {
          新干县:
            '金川镇、三湖镇、大洋洲镇、七琴镇、麦斜镇、溧江镇、界埠镇、桃溪乡、城上乡、潭丘乡、神政桥乡、沂江乡、荷浦乡',
        },
        {
          永丰县:
            '恩江镇、坑田镇、沿陂镇、古县镇、瑶田镇、藤田镇、石马镇、沙溪镇、佐龙乡、八江乡、潭城乡、鹿冈乡、七都乡、陶塘乡、中村乡、上溪乡、潭头乡、三坊乡、上固乡、君埠乡、龙冈畲族乡',
        },
        {
          泰和县:
            '澄江镇、碧溪镇、桥头镇、禾市镇、螺溪镇、石山乡、南溪乡、苏溪镇、马市镇、沿溪镇、塘洲镇、冠朝镇、上模乡、沙村镇、水槎乡、上圯乡、老营盘镇、中龙乡、小龙镇、灌溪镇、苑前镇、万合镇、武山垦殖场、泰和垦殖场',
        },
        {
          遂川县:
            '泉江镇、雩田镇、碧洲镇、草林镇、堆子前镇、左安镇、高坪镇、大汾镇、衙前镇、禾源镇、汤湖镇、珠田乡、巾石乡、大坑乡、枚江乡、双桥乡、新江乡、五斗江乡、西溪乡、南江乡、黄坑乡、戴家埔乡、营盘圩乡',
        },
        {
          万安县:
            '芙蓉镇、五丰镇、枧头镇、窑头镇、百嘉镇、高陂镇、潞田镇、沙坪镇、夏造镇、罗塘乡、弹前乡、武术乡、宝山乡、涧田乡、顺峰乡、韶口乡',
        },
        {
          安福县:
            '平都镇、浒坑镇、洲湖镇、横龙镇、洋溪镇、严田镇、洋门镇、枫田镇、竹江乡、瓜畲乡、赤谷乡、山庄乡、金田乡、彭坊乡、泰山乡、寮塘乡、甘洛乡、章庄乡',
        },
        {
          永新县:
            '禾川镇、石桥镇、坳南乡、曲白乡、才丰乡、在中乡、烟阁乡、龙源口镇、三湾乡、澧田镇、龙门镇、台岭乡、沙市镇、龙田乡、文竹镇、高溪乡、埠前镇、高桥楼镇、怀忠镇、芦溪乡、莲洲乡、高 市乡、象形乡、七溪岭林场、综合垦殖场',
        },
        {
          峡江县:
            '水边镇、马埠镇、巴邱镇、仁和镇、砚溪镇、罗田镇、桐林乡、福民乡、戈坪乡、金江乡',
        },
      ],
      赣州市: [
        {
          章贡区:
            '水东镇、水南镇、水西镇、沙石镇、沙河镇、解放街道、赣江街道、南外街道、东外街道',
        },
        {
          南康区:
            '蓉江镇、金鸡镇、潭口镇、唐江镇、凤岗镇、赤土镇、横市镇、龙回镇、三益镇、潭东镇、龙岭镇、镜坝镇、浮石乡、西华乡、横寨乡、朱坊乡、太窝乡、大岭乡、三江乡、龙华乡、唐西乡、平田乡、内潮乡、十八塘乡、圩下乡、麻双乡、隆木乡、大坪乡、坪市乡',
        },
        {
          信丰县:
            '嘉定镇、大塘埠镇、古陂镇、大桥镇、新田镇、安西镇、小江镇、铁石口镇、大阿镇、油山镇、小河镇、西牛镇、正平镇、虎山乡、崇仙乡、万隆乡',
        },
        {
          大余县:
            '南安镇、新城镇、樟斗镇、池江镇、青龙镇、左拔镇、黄龙镇、吉村镇、浮江乡、河洞乡、内良乡',
        },
        {
          赣县: '梅林镇、王母渡镇、沙地镇、江口镇、田村镇、南塘镇、茅店镇、吉埠镇、五云镇、韩坊乡、阳埠乡、大埠乡、长洛乡、大田乡、湖江乡、储潭乡、石芫乡、三溪乡、白鹭乡',
        },
        {
          龙南县:
            '龙南镇、武当镇、杨村镇、汶龙镇、程龙镇、关西镇、里仁镇、渡江镇、桃江乡、东江乡、临塘乡、南亨乡、夹湖乡、安基山林场、九连山林场',
        },
        { 定南县: '历市镇、岿美山镇、老城镇、天九镇、龙塘镇、岭北镇、鹅公镇' },
        {
          全南县:
            '城厢镇、大吉山镇、陂头镇、金龙镇、南迳镇、龙源坝镇、中寨乡、社迳乡、龙下乡',
        },
        {
          寻乌县:
            '长宁镇、吉潭镇、澄江镇、南桥镇、留车镇、晨光镇、桂竹帽镇7个镇和文峰乡、三标乡、水源乡、罗珊乡、项山乡、丹溪乡、龙廷乡、菖蒲乡',
        },
        {
          安远县:
            '欣山镇、孔田镇、版石镇、天心镇、龙布镇、鹤子镇、三百山镇、车头镇、镇岗乡、凤山乡、新龙乡、蔡坊乡、重石乡、长沙乡、浮槎乡、双芫乡、塘村乡、高云山乡',
        },
        {
          瑞金市:
            '象湖镇、瑞林镇、壬田镇、九堡镇、沙洲坝镇、谢坊镇、武阳镇、叶坪乡、丁陂乡、大柏地乡、岗面乡、日东乡、万田乡、黄柏乡、云石山乡、泽覃乡、拔英乡',
        },
        {
          宁都县:
            '梅江镇、青塘镇、长胜镇、黄陂镇、固村镇、赖村镇、石上镇、东山坝镇、洛口镇、小布镇、黄石镇、田头镇、竹笮乡、对坊乡、固厚乡、田埠乡、会同乡、湛田乡、安福乡、东韶乡、肖田乡、钓峰乡、大沽乡、蔡江乡',
        },
        {
          于都县:
            '禾丰镇、贡江镇、铁山垅镇、盘古山镇、祁禄山镇、梓山镇、银坑镇、岭背镇、罗坳镇、罗江乡、小溪乡、利村乡、新陂乡、靖石乡、黄麟乡、沙心乡、宽田乡、葛坳乡、桥头乡、马安乡、仙下乡、车溪乡、段屋乡',
        },
        {
          会昌县:
            '文武坝镇、筠门岭镇、西江镇、周田镇、麻州镇、庄口镇、清溪乡、右水乡、高排乡、晓龙乡、珠兰乡、洞头乡、中村乡、站塘乡、永隆乡、富城乡、小密乡、庄埠乡、白鹅乡',
        },
        {
          石城县:
            '琴江镇、小松镇、屏山镇、横江镇、高田镇、木兰乡、丰山乡、大由乡、龙岗乡、珠坑乡',
        },
        {
          上犹县:
            '东山镇、陡水镇、社溪镇、营前镇、黄埠镇、寺下镇、梅水乡、油石乡、安和乡、双溪乡、水岩乡、平富乡、五指峰乡、紫阳乡',
        },
        {
          兴国县:
            '潋江镇、长冈乡、鼎龙乡、崇贤乡、枫边乡、良村镇、南坑乡、方太乡、埠头乡、龙口镇、永丰乡、均村乡、隆坪乡、杰村乡、社富乡、东村乡、兴莲乡、兴江乡、古龙岗镇、梅窖镇、樟木乡、江背镇、城岗乡、高兴镇、茶园乡',
        },
        {
          崇义县:
            '横水镇、扬眉镇、过埠镇、铅厂镇、长龙镇、关田镇、龙勾乡、杰坝乡、金坑乡、思顺乡、麟潭乡、上堡乡、聂都乡、文英乡、乐洞乡、丰州乡',
        },
      ],
      景德镇市: [
        {
          昌江区:
            '西郊街道、新枫街道、竟成镇、鲇鱼山镇、吕蒙乡、丽阳乡、荷塘乡',
        },
        {
          珠山区:
            '石狮埠街道、新厂街道、里村街道、周路口街道、昌江街道、新村街道、珠山街道、太白园街道、昌河街道、竟成镇',
        },
        {
          浮梁县:
            '浮梁镇、瑶里镇、鹅湖镇、湘湖镇、寿安镇、洪源镇、三龙镇、蛟潭镇、经公桥镇、西湖乡、勒功乡、江村乡、兴田乡、峙滩乡、黄坛乡、庄湾乡、王港乡',
        },
        {
          乐平市:
            '洎阳街道、塔山街道、镇桥镇、乐港镇、涌山镇、众埠镇、接渡镇、洪岩镇、礼林镇、后港镇、塔前镇、双田镇、临港镇、高家镇、名口镇、浯口镇、鸬鹚乡、十里岗乡',
        },
      ],
      萍乡市: [
        {
          安源区:
            '凤凰街道、八一街道、后埠街道、丹江街道、东大街道、白源街道、安源镇、高坑镇、五陂镇、青山镇',
        },
        {
          湘东区:
            '峡山口街道、湘东镇、荷尧镇、老关镇、下埠镇、腊市镇、麻山镇、排上镇、东桥镇、广寒寨乡、白竺乡',
        },
        {
          莲花县:
            '琴亭镇、坊楼镇、良坊镇、路口镇、升坊镇、高洲乡、六市乡、南岭乡、荷塘乡、神泉乡、三板桥乡、湖上乡、闪石乡、海潭垦殖场',
        },
        {
          上栗县:
            '上栗镇、桐木镇、金山镇、福田镇、彭高镇、赤山镇、鸡冠山乡、长平乡、东源乡、杨岐乡',
        },
        {
          芦溪县:
            '芦溪镇、宣风镇、上埠镇、南坑镇、银河镇、源南乡、长丰乡、张佳坊乡、新泉乡、万龙山乡',
        },
      ],
      新余市: [
        {
          渝水区:
            '城南办、通州办、南安乡、水北、新溪乡、界水乡、下村镇、鹄山乡、罗坊镇、人和乡、仙来办、袁河办、珠珊镇、姚圩镇、良山镇、城北办、观巢镇、欧里镇',
        },
        {
          分宜县:
            '分宜镇、杨桥镇、湖泽镇、双林镇、钤山镇、洋江镇、凤阳乡、洞村乡、高岚乡、操场乡、钤东办事处',
        },
      ],
      鹰潭市: [
        {
          月湖区:
            '江边街道、交通街道、东湖街道、梅园街道、四青街道、白露街道、童家镇、夏埠乡',
        },
        {
          余江县:
            '邓埠镇、锦江镇、画桥镇、潢溪镇、中童镇、马荃镇、黄庄乡、春涛乡、平定乡、杨溪乡、洪湖乡、高公寨林场、水产场、大桥良种场、张公桥农场、塘潮源林场、青年农场、刘垦场',
        },
        {
          贵溪市:
            '东门街道、雄石街道、花园街道、泗沥镇、河潭镇、周坊镇、鸿塘镇、志光镇、流口镇、罗河镇、金屯镇、塘湾镇、文坊镇、冷水镇、龙虎山镇、上清镇、滨江镇、白田乡、雷溪乡、彭湾乡、樟坪畲族乡、耳口乡、余家乡、塔桥园艺场、河潭垦殖场、西窑林场、双圳林场、耳口林场、冷水林场、三县岭林场、上清林场',
        },
      ],
    },
  ],
  山西省: [
    {
      太原市: [
        {
          杏花岭区:
            '巨轮街道、三桥街道、鼓楼街道、杏花岭街道、坝陵桥街道、大东关街道、职工新街街道、敦化坊街道、涧河街道、杨家峪街道、中涧河乡、小返乡',
        },
        {
          迎泽区:
            '柳巷街道、文庙街道、庙前街道、迎泽街道、桥东街道、老军营街道、郝庄镇',
        },
        {
          小店区:
            '小店街道、坞城街道、北营街道、营盘街道、平阳路街道、黄陵街道、北格镇；西温庄乡、刘家堡乡、太原高新技术产业开发区、太原经济技术开发区',
        },
        {
          尖草坪区:
            '尖草坪街道、光社街道、上兰街道、南寨街道、迎新街街道、古城街道、汇丰街道、柴村街道、新城街道、向阳镇、阳曲镇、马头水乡、柏板乡、西墕乡',
        },
        {
          万柏林区:
            '千峰街道、下元街道、小井峪街道、和平街道、万柏林街道、兴华街道、南寒街道、白家庄街道、杜儿坪街道、西铭街道、东社街道、化客头街道、长风街道、神堂沟街道、王封乡',
        },
        { 晋源区: '晋源街道、义井街道、罗城街道、金胜镇、晋祠镇、姚村镇' },
        {
          清徐县:
            '东湖街道、清源镇、徐沟镇、东于镇、盂封镇、马峪乡、柳杜乡、西谷乡、王答乡、集义乡',
        },
        {
          阳曲县:
            '新阳东街社区居委会、商贸新街社区居委会、南坡街社区居委会、首邑路社区居委会、黄寨镇、大盂镇、东黄水镇、泥屯镇、高村乡、侯村乡、凌井店乡、西凌井乡、北小店乡、杨兴乡',
        },
        {
          娄烦县:
            '娄烦镇、杜交曲镇、静游镇、庙湾乡、马家庄乡、盖家庄乡、米峪镇乡、天池乡',
        },
        {
          古交市:
            '东曲街道、西曲街道、桃园街道、屯兰街道、河口镇、镇城底镇、马兰镇、嘉乐泉乡、梭峪乡、邢家社乡、阁上乡、岔口乡、原相乡、常安乡',
        },
      ],
      大同市: [
        {
          城区: '东街街道、西街街道、南街街道、北街街道、南关街道、北关街道、新建南路街道、新建北路街道、新华街街道、振华南街街道、大庆路街道、西花园街道、老平旺街道、向阳里街道、开源街道',
        },
        {
          矿区: '新胜街道、新平旺街道、煤峪口街道、永定庄街道、马口街道、同家梁街道、四老沟街道、平泉路街道',
        },
        {
          南郊区:
            '平旺乡、鸦儿崖乡、西韩岭乡、马军营乡、水泊寺乡、新旺乡、口泉乡、高山镇、云冈镇、古店镇',
        },
        {
          新荣区:
            '新荣镇、花园湾屯乡、堡子湾乡、郭家窑乡、西村乡、上深涧乡、破鲁堡乡',
        },
        {
          阳高县:
            '龙泉镇、罗文皂镇、大白登镇、王官屯镇、古城镇、东小村镇、友宰镇、狮子屯镇、长城乡、北徐屯乡、下深井乡、马家皂乡、鳌石乡',
        },
        {
          天镇县:
            '玉泉镇、谷前堡镇、米薪关镇、逯家湾镇、新平堡镇、三十里铺乡、南河堡乡、贾家屯乡、赵家沟乡、南高崖乡、张西河乡',
        },
        {
          广灵县:
            '壶泉镇、南村镇、作疃乡、加斗乡、蕉山乡、宜兴乡、梁庄乡、望孤乡、斗泉乡',
        },
        {
          灵丘县:
            '武灵镇、东河南镇、上寨镇、落水河乡、赵北乡、独峪乡、下关乡、白崖台乡、史庄乡、石家乡、柳科乡、红石塄乡',
        },
        {
          浑源县:
            '大仁庄乡、大仁庄乡、下韩村乡、西留村乡、裴村乡、黄花滩乡、南榆林乡、官儿乡、吴城乡、东坊城乡、青磁窑乡、千佛岭乡、大磁窑镇、蔡村镇、西坊城镇、王庄堡镇、沙圪坨镇、永安镇',
        },
        {
          左云县:
            '云兴镇、鹊儿山镇、店湾镇、管家堡乡、张家场乡、三屯乡、马道头乡、水窑乡、酸茨河、小京庄乡',
        },
        {
          大同县:
            '湖东街道、东街街道、西街街道、西坪镇、周士庄镇、倍加皂镇、吉家庄乡、峰峪乡、杜庄乡、党留庄乡、瓜园乡、聚乐堡乡、许堡乡',
        },
      ],
      阳泉市: [
        {
          城区: '上站街道、下站街道、北大街街道、南山路街道、义井街道、坡底街道',
        },
        {
          矿区: '平潭街道、沙坪街道、蔡洼街道、赛鱼街道、桥头街道、贵石沟街道',
        },
        {
          郊区: '荫营镇、河底镇、平坦镇、义井镇、西南舁乡、杨家庄乡、李家庄乡、旧街乡',
        },
        {
          盂县: '秀水镇、孙家庄镇、路家村镇、南娄镇、牛村镇、苌池镇、上社镇、西烟镇、仙人乡、北下庄乡、下社乡、梁家寨乡、西潘乡、东梁乡',
        },
        {
          平定县:
            '冠山镇、冶西镇、锁簧镇、张庄镇、东回镇、柏井镇、娘子关镇、巨城镇、石门口乡、岔口乡',
        },
      ],
      长治市: [
        {
          长治县:
            '韩店镇、苏店镇、贾掌镇、荫城镇、八义镇、西火镇、南宋乡、西池乡、东和乡、郝家庄乡、北呈乡',
        },
        {
          襄垣县:
            '古韩镇、王桥镇、候堡镇、夏店镇、虒亭镇、西营镇、王村镇、下良镇、善福乡、北底乡、上马乡',
        },
        {
          长子县:
            '丹朱镇、鲍店镇、石哲镇、大堡头镇、慈林镇、色头镇、南漳镇、岚水乡、碾张乡、常张乡、常张乡、宋村乡',
        },
        {
          屯留县:
            '麟绛镇、上村镇、渔泽镇、余吾镇、吾元镇、张店镇、丰宜镇、李高乡、路村乡、河神庙乡、西贾乡、西流寨开发区、康庄工业园区、上莲开发区',
        },
        {
          壶关县:
            '龙泉镇、百尺镇、店上镇、晋庄镇、树掌镇、集店乡、黄山乡、东井岭乡、石坡乡、五龙山乡、鹅屋乡、桥上乡、常平经济开发区',
        },
        {
          黎城县:
            '黎侯镇、东阳关镇、上遥镇、西井镇、黄崖洞镇、西仵乡、停河铺乡、程家山乡、洪井乡',
        },
        {
          平顺县:
            '青羊镇、龙溪镇、杏城镇、石城镇、苗庄镇、西沟乡、阳高乡、中五井乡、北社乡、东寺头乡、虹梯关乡、北耽车乡',
        },
        {
          武乡县:
            '监漳镇、洪水镇、蟠龙镇、丰州镇、故城镇、韩北乡、大有乡、贾豁乡、上司乡、故县乡、分水岭乡、涌泉乡、墨镫乡、石北乡、石盘农业开发区',
        },
        {
          沁县: '定昌镇、郭村镇、故县镇、新店镇、漳源镇、册村镇、段柳乡、松村乡、次村乡、牛寺乡、南里乡、南泉乡、杨安乡',
        },
        {
          沁源县:
            '沁河镇、郭道镇、灵空山镇、王和镇、李元镇、中峪乡、法中乡、交口乡、聪子峪乡、韩洪乡、官滩乡、景凤乡、赤石桥乡、王陶乡',
        },
        {
          城区: '东街街道、西街街道、英雄南路街道、英雄中路街道、紫金街街道、太行东街街道、太行西街街道、延安南路街道、常青街道、五马街道',
        },
        {
          郊区: '长北街道、故县街道、老顶山镇、堠北庄镇、大辛庄镇、马厂镇、黄碾镇、西白兔乡',
        },
        {
          潞城市:
            '潞华街道、成家川街道、店上镇、微子镇、辛安泉镇、翟店镇、合室乡、黄牛蹄乡、史回乡',
        },
      ],
      晋城市: [
        {
          城区: '东街街道、南街街道、西街街道、北街街道、矿区街道、钟家庄街道、西上庄街道、北石店镇、晋城经济技术开发区',
        },
        {
          泽州县:
            '巴公镇、大阳镇、高都镇、金村镇、南村镇、犁川镇、晋庙铺镇、周村镇、大东沟镇、下村镇、北义城镇、柳口镇、山河镇、大箕镇、南岭乡、川底乡、李寨乡',
        },
        {
          阳城县:
            '东城街道、凤城镇、北留镇、润城镇、町店镇、芹池镇、次营镇、横河镇、河北镇、蟒河镇、东冶镇、白桑乡、寺头乡、西河乡、演礼乡、固隆乡、董封乡、驾岭乡',
        },
        {
          沁水县:
            '龙港镇、中村镇、郑庄镇、端氏镇、嘉峰镇、郑村镇、柿庄镇、樊村河乡、土沃乡、张村乡、苏庄乡、胡底乡、固县乡、十里乡',
        },
        {
          陵川县:
            '崇文镇、礼义镇、附城镇、西河底镇、平城镇、杨村镇、潞城镇、秦家庄乡、夺火乡、马圪当乡、古郊乡、六泉乡',
        },
        {
          高平市:
            '东城街街道、南城街街道、北城街街道、寺庄镇、河西镇、米山镇、马村镇、北诗镇、陈区镇、三甲镇、神农镇、野川镇、原村乡、建宁乡、永录乡、石末乡',
        },
      ],
      朔州市: [
        {
          朔城区:
            '北城街道、南城街道、神头街道、北旺庄街道、神头镇、利民镇、下团堡乡、小平易乡、滋润乡、福善庄乡、南榆林乡、贾庄乡、沙塄河乡、窑子头乡、张蔡庄乡',
        },
        {
          平鲁区:
            '井坪镇、凤凰城镇、白堂乡、陶村乡、下水头乡、双碾乡、阻虎乡、高石庄乡、西水界乡、下面高乡、下木角乡、向阳堡乡、榆岭乡',
        },
        {
          山阴县:
            '岱岳镇、玉井镇、北周庄镇、古城镇、吴马营乡、马营乡、下喇叭乡、合盛堡乡、岱岳乡、安荣乡、薛圐圙乡、后所乡、张家庄乡、马营庄乡',
        },
        {
          应县: '金城镇、南河种镇、下社镇、镇子梁乡、义井乡、臧寨乡、大黄巍乡、杏寨乡、下马峪乡、南泉乡、大临河乡、白马石乡',
        },
        {
          右玉县:
            '新城镇、右卫镇、威远镇、元堡子镇、牛心堡乡、白头里乡、高家堡乡、丁家窑乡、杨千河乡、李达窑乡',
        },
        {
          怀仁县:
            '云中镇、吴家窑镇、金沙滩镇、毛家皂镇、何家堡乡、新家园乡、亲和乡、海北头乡、马辛庄乡、河头乡',
        },
      ],
      晋中市: [
        {
          榆次区:
            '晋华街道办事处、经纬街道办事处、锦纶街道办事处、北关街道办事处、新建街道办事处、新华街道办事处、路西街道办事处、西南街道办事处、安宁街道办事处、郭家堡乡、乌金山镇、张庆乡、修文镇、东阳镇、北田镇、庄子乡、长凝镇、东赵乡、什贴镇',
        },
        {
          太谷县:
            '任村乡、侯城乡、小白乡、水秀乡、北汪乡、阳邑乡、范村镇、胡村镇、明星镇',
        },
        {
          祁县: '开发区、昌源城区、丹枫城区、麓台城区、昭馀镇、西六支乡、东观镇、古县镇、城赵镇、贾令镇、峪口乡、来远镇',
        },
        {
          平遥县:
            '古城街道、城东街道、城西街道、古陶镇、段村镇、东泉镇、洪善镇、宁固镇、南政乡、中都乡、岳壁乡、卜宜乡、孟山乡、朱坑乡、襄垣乡、杜家庄乡、香乐乡',
        },
        {
          灵石县:
            '翠峰镇、静升镇、两渡镇、夏门镇、南关镇、段纯镇、马和乡、英武乡、王禹乡、坛镇乡、梁家焉乡、交口乡',
        },
        {
          榆社县:
            '箕城镇、云竹镇、郝北镇、社城镇、河峪乡、北寨乡、西马乡、兰峪乡、讲堂乡',
        },
        {
          左权县:
            '辽阳镇、桐峪镇、麻田镇、芹泉镇、拐儿镇、寒王乡、石匣乡、龙泉乡、粟城乡、羊角乡',
        },
        {
          和顺县:
            '义兴镇、李阳镇、松烟镇、青城镇、横岭镇、喂马乡、平松乡、牛川乡、马坊乡、阳光占乡',
        },
        {
          昔阳县:
            '乐平镇、皋落镇、东冶头镇、沾尚镇、大寨镇、李家庄乡、界都乡、三都乡、赵壁乡、孔氏乡、闫庄乡、西寨乡',
        },
        {
          寿阳县:
            '朝阳镇、宗艾镇、平头镇、南燕竹镇、西洛镇、松塔镇、尹灵芝镇、解愁乡、平舒乡、马首乡、上湖乡、羊头崖乡、景尚乡、温家庄乡',
        },
        {
          介休市:
            '北关街道、西关街道、东南街道、西南街道、北坛街道、义安镇、张兰镇、连福镇、洪山镇、龙凤镇、绵山镇、义棠镇、城关乡、宋古乡、三佳乡',
        },
        { 晋中经济技术开发区: '晋中经济技术开发区' },
      ],
      运城市: [
        {
          盐湖区:
            '中城街道、东城街道、西城街道、南城街道、北城街道、安邑街道、大渠街道、姚孟街道、解州镇、龙居镇、北相镇、泓芝驿镇、三路里镇、陶村镇、东郭镇、席张乡、金井乡、王范乡、冯村乡、上郭乡、上王乡',
        },
        {
          永济市:
            '城西街道、城北街道、城东街道、虞乡镇、卿头镇、开张镇、栲栳镇、蒲州镇、韩阳镇、张营镇',
        },
        {
          河津市:
            '城区街道、清涧街道、樊村镇、僧楼镇、小梁乡、柴家乡、赵家庄乡、下化乡、阳村乡',
        },
        {
          绛县: '古绛镇、横水镇、南樊镇、安峪镇、大交镇、么里镇、陈村镇、卫庄镇、郝庄乡、冷口乡',
        },
        {
          夏县: '瑶峰镇、裴介镇、庙前镇、泗交镇、埝掌镇、水头镇、禹王乡、胡张乡、尉郭乡、南大里乡、祁家河乡',
        },
        {
          新绛县:
            '龙兴镇、三泉镇、泽掌镇、北张镇、古交镇、万安镇、阳王镇、泉掌镇、横桥乡、商贸经济开发区',
        },
        { 稷山县: '稷峰镇、西社镇、化峪镇、翟店镇、清河镇、蔡村乡、太阳乡' },
        {
          芮城县:
            '古巍镇、风陵渡镇、陌南镇、西陌镇、永乐镇、大王镇、阳城镇、东垆乡、南卫乡、学张乡、风陵渡经济开发区',
        },
        {
          临猗县:
            '嵋阳镇、七级镇、耽子镇、三管镇、庙上乡、北辛乡、角杯乡、北景乡、楚侯乡、牛杜镇、闫家庄工贸区、卓里工贸区',
        },
        {
          万荣县:
            '解店镇、通化镇、汉薛镇、荣河镇、万泉乡、里望乡、西村乡、南张乡、高村乡、皇甫乡、贾村乡、王显乡、光华乡、裴庄乡',
        },
        {
          闻喜县:
            '桐城镇、郭家庄镇、凹底镇、薛店镇、东镇镇、礼元镇、河底镇、神柏乡、阳隅乡、候村乡、裴社乡、后宫乡、石门乡',
        },
        {
          垣曲县:
            '新城镇、毛家湾镇、皋落乡、长直乡、王茅镇、解峪乡、古城镇、华峰乡、英言乡、蒲掌乡、历山镇',
        },
        {
          平陆县:
            '圣人涧镇、常乐镇、张店镇、张村镇、曹川镇、三门镇、洪池乡、杜马乡、部官乡、坡底乡、开发区',
        },
      ],
      忻州市: [
        {
          忻府区:
            '南城街道、新建路街道、长征街街道、董村镇、播明镇、奇村镇、三交镇、庄磨镇、豆罗镇、紫岩乡、西张乡、东楼乡、北义井乡、曹张乡、高城乡、秦城乡、解原乡、上社乡、合索乡、温村乡、杨胡乡、寺坪乡、后河堡乡、阳坡乡、上寺乡、牛尾庄乡、傅家庄乡、下佐乡、野峪乡、兰村乡',
        },
        {
          原平市:
            '北城街道、南城街道、轩煤矿区街道、长梁沟镇、轩岗镇、崞阳镇、大牛店镇、苏龙口镇、阎庄镇、东社镇、新原乡、段家堡乡、沿沟乡、西镇乡、王家庄乡、子干乡、中阳乡、南白乡、大林乡、解村乡、楼板寨乡',
        },
        {
          代县: '上馆镇、阳明堡镇、峨口镇、聂营镇、枣林镇、滩上镇、新高乡、峪口乡、磨坊乡、胡峪乡、雁门关乡',
        },
        {
          繁峙县:
            '繁城镇、砂河镇、大营镇、杏园乡、光裕堡乡、下茹越乡、集义庄乡、东山乡、金山铺乡、横涧乡、柏家庄乡、神堂堡乡、岩头乡',
        },
        {
          静乐县:
            '鹅城镇、杜家村镇、康家会镇、丰润镇、堂尔上乡、中庄乡、双路乡、段家寨乡、辛村乡、王村乡、神峪沟乡、娘子神乡、娑婆乡、赤泥洼乡',
        },
        {
          定襄县:
            '河边镇、宏道镇、晋昌镇、蒋村乡、受录乡、南王乡、神山乡、季庄乡、杨芳乡',
        },
        {
          五台县:
            '五台山风景名胜区、驼梁景区、台城镇、白家庄镇、豆村镇、东冶镇、台怀镇、耿镇镇、东雷乡、沟南乡、茹村乡、阳白乡、神西乡、建安乡、灵境乡、高洪口乡、门限石乡、陈家庄乡、石咀乡、蒋坊乡',
        },
        {
          神池县:
            '龙泉镇、义井镇、八角镇、东湖乡、太平庄乡、虎北乡、贺职乡、大严备乡、烈堡乡、长畛乡',
        },
        {
          五寨县:
            '砚城镇、三岔镇、小河头镇、前所乡、经堂办事处、孙家坪乡、梁家坪乡、杏岭子乡、刘台办事处、胡会乡、李家坪乡、新寨乡、韩家楼乡、东秀庄乡',
        },
        {
          岢岚县:
            '岚漪镇、三井镇、神堂坪乡、高家会乡、李家沟乡、水峪贯乡、西豹峪乡、温泉乡、阳坪乡、大涧乡、宋家沟乡、王家岔乡',
        },
        {
          偏关县:
            '城关镇、老营镇、天峰坪镇、万家寨、镇水泉乡、陈家营乡、楼沟乡、南堡子乡、窑头乡、马家埝乡、黄龙池乡、教儿墕乡、尚峪乡、曹家村乡、大石窊乡',
        },
        {
          河曲县:
            '文笔镇、楼子营镇、刘家塔镇、巡镇、鹿固乡、前川乡、单寨乡、土沟乡、旧县乡、沙坪乡、社梁乡、沙泉乡、赵家沟乡',
        },
        {
          保德县:
            '东关镇、桥头镇、义门镇、杨家湾镇、南河沟乡、土崖塔乡、孙家沟乡、腰庄乡、尧圪台乡、窑洼乡、冯家川乡、林遮峪乡、韩家川乡',
        },
        {
          宁武县:
            '凤凰镇、东寨镇、阳方口镇、石家庄镇、薛家洼乡、余庄乡、涔山乡、化北屯乡、西马坊乡、新堡乡、迭台寺乡、圪廖乡、怀道乡、东马坊乡',
        },
      ],
      临汾市: [
        {
          尧都区:
            '解放路街道办事处、鼓楼西街街道办事处、水塔街街道办事处、南街街道办事处、乡贤街街道办事处、辛寺街街道办事处、铁路东街街道办事处、车站街街道办事处、汾河街道办事处、滨河办事处、屯里镇、乔李镇、大阳镇、县底镇、刘村镇、金殿镇、吴村镇、土门镇、魏村镇、尧庙镇、段店乡、贺家庄乡、贾得乡、一平垣乡、枕头乡、河底乡',
        },
        { 曲沃县: '乐昌镇、高显镇、史村镇、曲村镇、里村镇、杨谈乡、北董乡' },
        {
          翼城县:
            '唐兴镇、南梁镇、里寨镇、隆化镇、桥上镇、西闫镇、中卫乡、南唐乡、王庄乡、浇底乡',
        },
        {
          襄汾县:
            '新城镇、赵康镇、汾城镇、南贾镇、古城镇、襄陵镇、邓庄镇、陶寺乡、永固乡、景毛乡、西贾乡、南辛店乡、大邓乡',
        },
        {
          洪洞县:
            '大槐树镇、甘亭镇、曲亭镇、苏堡镇、广胜寺镇、明姜镇、赵城镇、万安镇、刘家垣镇、淹底乡、兴唐寺乡、堤村乡、辛村乡、龙马乡、山头乡、左木乡',
        },
        { 古县: '北平镇、古阳镇、岳阳镇、旧县镇、石壁乡、永乐乡、南垣乡' },
        { 安泽县: '府城镇、和川镇、唐城镇、冀氏镇、杜村乡、马壁乡、良马乡' },
        {
          浮山县:
            '天坛镇、响水河镇、东张乡、槐埝乡、米家垣乡、张庄乡、北王乡、北韩乡、寨圪塔乡',
        },
        {
          吉县: '城关镇、屯里镇、壶口镇、车城乡、文城乡、东城乡、柏山寺乡、中垛乡',
        },
        {
          乡宁县:
            '昌宁镇、光华镇、台头镇、管头镇、西坡镇、双鹤乡、关王庙乡、尉庄乡、西交口乡、枣岭乡',
        },
        { 大宁县: '昕水镇、曲峨镇、太德乡、三多乡、徐家垛乡、太古乡' },
        {
          隰县: '龙泉镇、黄土镇、午城镇、城南乡、阳头升乡、寨子乡、下李乡、陡坡乡',
        },
        { 永和县: '芝河镇、桑壁镇、打石腰乡、坡头乡、阁底乡、交口乡、南庄乡' },
        {
          蒲县: '蒲城镇、薛关镇、黑龙关镇、克城镇、山中乡、古县乡、红道乡、乔家湾乡、太林乡',
        },
        {
          汾西县:
            '永安镇、对竹镇、勍香镇、和平镇 、僧念镇、佃坪乡、团柏乡、邢家要乡、',
        },
        {
          侯马市:
            '路东街道办事处、路西街道办事处、浍滨街道办事处、上马街道办事处、张村街道办事处、新田乡、高村乡、凤城乡',
        },
        {
          霍州市:
            '开元街道、南环路街道、北环路街道、鼓楼街道、退沙街道、辛置镇、白龙镇、大张镇、李曹镇、师庄乡、三教乡、陶唐峪乡',
        },
      ],
      吕梁市: [
        {
          离石区:
            '滨河街道、凤山街道、莲花池街道、城北街道、田家会街道、交口街道、西属巴街道、吴城镇、信义镇、红眼川乡、枣林乡、坪头乡',
        },
        {
          文水县:
            '凤城镇、开栅镇、南庄镇、南安镇、刘胡兰镇、下曲镇、孝义镇、南武乡、西城乡、北张乡、马西乡、西槽头乡',
        },
        {
          交城县:
            '天宁镇、夏家营镇、西营镇、水峪贯镇、西社镇、庞泉沟镇、洪相乡、岭底乡、东坡底乡、会立乡',
        },
        {
          兴县: '蔚汾镇、魏家滩镇、瓦塘镇、康宁镇、高家村镇、罗峪口镇、蔡家会镇、交楼申乡、恶虎滩乡、东会乡、固贤乡、奥家湾乡、蔡家崖乡、贺家会乡、孟家坪乡、赵家坪乡、圪达上乡',
        },
        {
          临县: '木瓜坪乡、安业乡、玉坪乡、大禹乡、车赶乡、安家庄乡、石白头乡、青凉寺乡、雷家碛乡、八堡乡、白文镇、城庄镇、临泉镇、三交镇、湍水头镇、林家坪镇、碛口镇、招贤镇、刘家会镇、丛罗峪镇、曲峪镇、兔坂镇、克虎镇',
        },
        {
          柳林县:
            '柳林镇、穆村镇、薛村镇、孟门镇、成家庄镇、庄上镇、留誉镇、三交镇、陈家湾乡、金家庄乡、李家湾乡、贾家垣乡、高家沟乡、石西乡、西王家沟乡',
        },
        {
          石楼县:
            '灵泉镇、罗村镇、义牒镇、小蒜镇、龙交乡、和合乡、前山乡、曹家垣乡、裴沟乡',
        },
        {
          岚县: '东村镇、岚城镇、普明镇、界河口镇、土峪乡、上明乡、王狮乡、梁家庄乡、顺会乡、社科乡、大蛇头乡',
        },
        {
          方山县:
            '圪洞镇、马坊镇、峪口镇、大武镇、开府乡、方山乡、麻地会乡、石站头乡、张家塔乡、下昔乡、店坪乡',
        },
        {
          中阳县:
            '城关镇、金罗镇、暖泉镇、枝柯镇、武家庄镇、吴家峁乡、下枣林乡、张家庄乡、张子山乡、刘家坪乡、苏村乡',
        },
        { 交口县: '水头镇、康城镇、双池镇、桃红坡镇、石口乡、回龙乡、温泉乡' },
        {
          孝义市:
            '新义街道、中阳楼街道、振兴街道、崇文街道、胜溪湖办事处、东许办事处、兑镇镇、阳泉曲镇、下堡镇、西辛庄镇、高阳镇、梧桐镇、柱濮镇、大孝堡乡、下栅乡、驿马乡、南阳乡、杜村乡',
        },
        {
          汾阳市:
            '太和桥街道、文峰街道、南薰街道、西河街道、辰北街道、杏花村镇、贾家庄镇、峪道河镇、冀村镇、肖家庄镇、演武镇、三泉镇、石庄镇、杨家庄镇、阳城乡、栗家庄乡',
        },
      ],
    },
  ],
  辽宁省: [
    {
      沈阳市: [
        {
          和平区:
            '浑河湾街道、新华街道、西塔街道、八经街道、马路湾街道、集贤街道、南湖街道、长白街道、太原街街道、北市场街道、南市场街道',
        },
        {
          沈河区:
            '新北站街道、朱剪炉街道、皇城街道、大西街道、山东庙街道、风雨坛街道、滨河街道、万莲街道、大南街道、五里河街道、南塔街道、泉园街道、丰乐街道、东陵街道、马官桥街道',
        },
        {
          皇姑区:
            '黄河街道、三台子街道、明廉街道、新乐街道、华山街道、塔湾街道、寿泉街道、三洞桥街道、舍利塔街道、北塔街道、陵东街道、方溪湖村、四台子村',
        },
        {
          大东区:
            '长安街道、万泉街道、小东街道、东塔街道、新东街道、津桥街道、大北街道、东站街道、洮昌街道、北海街道、上园街道、二台子街道、文官街道、前进街道',
        },
        {
          铁西区:
            '昆明湖街道、工人村街道、七路街道、启工街道、笃工街道、兴华街道、兴工街道、霁虹街道、凌空街道、重工街道、艳粉街道、保工街道、兴顺街道、贵和街道、翟家街道、大青中朝友谊街道、大潘街道、西三环街道、彰驿街道、高花街道',
        },
        {
          浑南区:
            '英达街道、高坎街道、满堂街道、王滨街道、五三街道、东湖街道、浑河站东街道、营城子街道、汪家街道、深井子街道、白塔街道、桃仙街道、祝家街道、李相街道',
        },
        {
          于洪区:
            '迎宾路街道、陵西街道、城东湖街道、于洪街道、南阳湖街道、北陵街道、沙岭街道、平罗街道、马三家街道、造化街道、大兴街道、光辉街道',
        },
        {
          沈北新区:
            '新城子街道、清水台街道、辉山街道、道义街道、虎石台街道、财落街道、望滨街道、兴隆台街道、清泉街道、沈北街道、黄家锡伯族街道、石佛寺朝鲜族锡伯族街道、尹家街道、马刚街道',
        },
        {
          苏家屯区:
            '解放街道、民主街道、中兴街道、湖西街道、临湖街道、八一街道、红菱街道、林盛街道、沙河街道、十里河街道、陈相街道、姚千街道、王纲街道、永乐街道、大沟街道、白清街道、佟沟街道',
        },
        {
          新民市:
            '大红旗镇、梁山镇、大柳屯镇、公主屯镇、兴隆镇、兴隆堡镇、胡台镇、法哈牛镇、前当堡镇、大民屯镇、柳河沟镇、高台子镇、张家屯镇、罗家房镇、三道岗子镇、陶家屯镇、东蛇山子镇、周坨子镇、金五台子乡、红旗乡、卢屯乡、姚堡乡、新农村乡、于家窝堡乡、东城街道、西城街道、辽滨街道、新柳街道、新城街道',
        },
        {
          辽中县:
            '辽中镇、于家房镇、朱家房镇、冷子堡镇、刘二堡镇、茨榆坨镇、满都户镇、杨士岗镇、肖寨门镇、城郊镇、六间房镇、养士堡镇、潘家堡镇、老观坨镇、老大房乡、大黑岗子乡牛心坨乡、铁西区',
        },
        {
          康平县:
            '胜利街道、东关屯街道、北三家子街道、小城子镇、张强镇、方家屯镇、郝官屯镇、二牛所口镇、北四家子乡、两家子乡、海洲窝堡乡、沙金台蒙古族满族乡、柳树屯蒙古族满族乡、西关屯蒙古族满族乡、东升满族蒙古族乡、滨湖新城区、康平经济开发区',
        },
        {
          法库县:
            '丁家房镇、孟家镇、十间房镇、依牛堡镇、冯贝堡镇、包家屯镇、五台子镇、柏家沟镇、大孤家子镇、三面船镇、公主屯镇、登仕堡子镇、叶茂台镇、秀水河子镇、法库经济开发区、十间房乡、慈恩寺乡、卧牛石乡、四家子蒙古族乡、孟家乡',
        },
      ],
      大连市: [
        {
          中山区:
            '海军广场街道、人民路街道、桂林街道、青泥洼桥街道、昆明街道、葵英街道、桃源街道、老虎滩街道',
        },
        {
          西岗区:
            '香炉礁街道、站北街道、日新街道、北京街道、八一路街道、人民广场街道、白云街道',
        },
        {
          沙河口区:
            '中山公园街道、白山路街道、星海湾街道、黑石礁街道、南沙河口街道、马栏街道、李家街道、兴工街道、春柳街道',
        },
        {
          甘井子区:
            '周水子街道、椒金山街道、甘井子街道、南关岭街道、泡崖街道、中华路街道、兴华街道、机场街道、辛寨子街道、红旗街道、凌水街道、大连湾街道、泉水街道、革镇堡镇、营城子街镇',
        },
        {
          旅顺口区:
            '得胜街道、光荣街道、登峰街道、水师营街道、铁山街道、双岛湾街道、三涧堡街道、长城街道、龙头街道、北海街道、经济开发区',
        },
        {
          金州区:
            '拥政街道、友谊街道、光明街道、马桥子街道、海青岛街道、大孤山街道、中长街道、站前街道、先进街道、董家沟街道、金石滩街道、湾里街道、二十里堡街道、三十里堡街道、亮甲店街道、登沙河街道、石河街道、大魏家街道、杏树街道、七顶山街道、华家街道、向应街道、大李家街道、得胜街道',
        },
        { 长海县: '大长山岛镇、獐子岛镇、小长山乡、广鹿乡、海洋乡' },
        {
          瓦房店市:
            '文兰街道、共济街道、铁东街道、岭东街道、祝华街道、岗店街道、新华街道、太阳街道、九龙街道、复州城镇、松树镇、得利寺镇、万家岭镇、许屯镇、永宁镇、谢屯镇、老虎屯镇、红沿河镇、李官镇、仙浴湾镇、土城乡、闫店乡、西阳乡、泡崖乡、驼山乡、赵屯乡、杨家满族乡、三台满族乡、大连瓦房店工业园区、瓦房店祝华工业园区、沿海经济区、红沿河核电循环经济区、太平湾临港经济区、龙门温泉旅游经济区',
        },
        {
          普兰店市:
            '丰荣街道、铁西街道、太平街道、经济开发区管理委员会、皮口街道、城子坦街道、大刘家街道、杨树房街道、大谭街道、唐家房街道、莲山街道、双塔镇、安波镇、四平镇、沙包镇、星台镇、墨盘乡、同益乡、乐甲满族乡',
        },
        {
          庄河市:
            '城关街道、新华街道、兴达街道、昌盛街道、徐岭镇、荷花山镇、大营镇、黑岛镇、长岭镇、塔岭镇、城山镇、栗子房镇、吴炉镇、青堆镇、蓉花山镇、光明山镇、仙人洞镇、大郑镇、兰店乡、太平岭满族乡、步云山乡、 三架山满族乡、桂云花满族乡、鞍子山乡、明阳街道',
        },
      ],
      鞍山市: [
        {
          铁东区:
            '东长甸街道、长甸街道、湖南街道、常青街道、解放街道、山南街道、园林街道、对炉街道、站前街道、钢城街道、和平街道、胜利街道、新兴街道',
        },
        {
          铁西区:
            '昆明湖街道、工人村街道、七路街道、启工街道、笃工街道、兴华街道、兴工街道、霁虹街道、凌空街道、重工街道、艳粉街道、保工街道、兴顺街道、贵和街道、翟家街道、大青中朝友谊街道、大潘街道、西三环街道、彰驿街道、高花街道',
        },
        {
          立山区:
            '立山街道、友好街道、双山街道、曙光街道、灵山街道、深北街道、深南街道、沙河街道、沙河镇',
        },
        { 千山区: '汤岗子街道、东鞍山街道、唐家房镇、大屯镇、甘泉镇' },
        {
          海城市:
            '海州街道、兴海街道、响堂街道、东四街道、孤山镇、岔沟镇、接文镇、析木镇、马风镇、牌楼镇、英落镇、八里镇、毛祁镇、王石镇、南台镇、西柳镇、感王镇、中小镇、牛庄镇、腾鳌镇、耿庄镇、西四镇、高坨镇、 望台镇、温香镇',
        },
        {
          台安县:
            '高力房镇、黄沙坨镇、新开河镇、桑林镇、韭菜台镇、新台镇、富家镇、桓洞镇、西佛镇、达牛镇',
        },
        {
          岫岩满族自治县:
            '阜昌街道、三家子镇、石庙子镇、黄花甸镇、大营子镇、苏子沟镇、偏岭镇、哈达碑镇、新甸镇、洋河镇、杨家堡镇、汤沟镇、石灰窑镇、前营子镇、龙潭镇、牧牛镇、药山镇、大房身镇、朝阳镇、红旗营子乡、岭沟乡、哨子河乡',
        },
      ],
      抚顺市: [
        {
          新抚区:
            '新抚街道、福民街道、千金街道、站前街道、永安台街道、南阳街道、东公园街道、榆林街道、南花园街道、刘山街道',
        },
        {
          顺城区:
            '长春街道、河东街道、新华街道、抚顺城街道、将军堡街道、葛布街道、前甸镇、河北乡、会元乡',
        },
        {
          望花区:
            '田屯街道、工农街道、建设街道、和平街道、光明街道、朴屯街道、演武街道、创业园区、新民街道、塔峪镇',
        },
        {
          东洲区:
            '新屯街道、万新街道、龙凤街道、平山街道、东洲街道、老虎台街道、刘山街道、抚东街道、南花园街道、张甸街道、章党街道、搭连街道、碾盘乡、兰山乡、章党镇、哈达镇',
        },
        {
          抚顺县:
            '章党镇、石文镇、后安镇、哈达镇、峡河乡、救兵乡、拉古满族乡、海浪乡、马圈子乡、上马乡、兰山乡、汤图满族乡',
        },
        {
          新宾满族自治县:
            '旺清门镇、永陵镇、平顶山镇、大四平镇、苇子峪镇、木奇镇、上夹河镇、南杂木镇、新宾镇、响水河子乡、红庙子乡、北四平乡、榆树乡、下夹河乡、红升乡',
        },
        {
          清原满族自治县:
            '清原镇、红透山镇、南口前满族镇、大孤家满族镇、夏家堡满族镇、草市镇、南山城镇、湾甸子镇、英额门镇、土口子满族乡、北三家满族乡、敖家堡乡、大苏河乡、枸乃甸乡',
        },
        { 抚顺经济开发区: '抚顺经济开发区' },
        { 抚顺胜利经济开发区: '抚顺胜利经济开发区' },
        { 抚顺高新技术产业开发: '抚顺高新技术产业开发' },
      ],
      本溪市: [
        {
          平山区:
            '东明街道、站前街道、崔东街道、工人街道、南地街道、平山街道、千金街道、北台街道、桥头街道',
        },
        {
          溪湖区:
            '河东街道、河西街道、彩屯街道、竖井街道、彩北街道、东风街道、火连寨镇、歪头山镇、石桥子镇、张其寨乡',
        },
        {
          明山区:
            '金山街道、北地街道、高峪街道、明山街道、东兴街道、新明街道、牛心台街道、卧龙街道、高台子镇',
        },
        { 南芬区: '南芬街道、铁山街道、郭家街道、下马塘镇、思山岭满族乡' },
        {
          本溪满族自治县:
            '观音阁街道、草河掌镇、草河城镇、草河口镇、连山关镇、高官镇、清河城镇、田师付镇、南甸子镇、碱厂镇、东营坊乡',
        },
        {
          桓仁满族自治县:
            '八卦城街道、二棚甸子镇、沙尖子镇、五里甸子镇、普乐堡镇、华来镇、木盂子镇、八里甸子镇、古城镇、雅河朝鲜族乡、向阳乡、黑沟乡、北甸子乡',
        },
      ],
      丹东市: [
        {
          振兴区:
            '头道桥街道、站前街道、临江街道、永昌街道、六道沟街道、帽盔山街道、纤维街道、花园街道、西城街道、汤池镇',
        },
        {
          元宝区:
            '六道口街道、七道街道、八道街道、九道街道、兴东街道、广济街道、金山镇',
        },
        {
          振安区:
            '鸭绿江街道、金矿街道、珍珠街道、太平湾街道、同兴镇、五龙背镇、楼房镇、九连城镇、汤山城镇',
        },
        {
          凤城市:
            '凤凰城街道、凤山街道、草河街道、宝山镇、白旗镇、沙里寨镇、红旗镇、蓝旗镇、边门镇、大堡蒙古族乡、东汤镇、石城镇、大兴镇、爱阳镇、赛马镇、弟兄山镇、鸡冠山镇、刘家河镇、通远堡镇、四门子镇、青城子镇',
        },
        {
          东港市:
            '新兴街道、大东街道、新城街道、前阳镇、长安镇、十字街镇、长山镇、北井子镇、椅圈镇、黄土坎镇、孤山镇、菩萨庙镇、马家店镇、龙王庙镇、小甸子镇、黑沟镇、新农镇、合隆满族乡',
        },
        {
          宽甸满族自治县:
            '宽甸镇、灌水镇、硼海镇、红石镇、毛甸子镇、长甸镇、永甸镇、太平哨镇、青山沟镇、牛毛坞镇、大川头镇、青椅山镇、杨木川镇、虎山镇、振江镇、步达远镇、大西岔镇、八河川镇、双山子镇、石湖沟乡、古楼子乡、下露河朝鲜族乡',
        },
      ],
      锦州市: [
        {
          古塔区:
            '天安街道、石油街道、北街街道、敬业街道、保安街道、南街街道、饶阳街道、站前街道、士英街道、钟屯乡',
        },
        {
          凌河区:
            '正大街道、石桥街道、龙江街道、榴花街道、铁新街道、凌安街道、菊园街道、康宁街道、锦铁街道、马家街道、紫荆街道',
        },
        {
          太和区:
            '新民乡、女儿河乡、大薛乡、营盘乡、兴隆、太和街道、凌西街道、汤河子街道、薛家街道',
        },
        { 松山新区: '凌南街道办事处、松山办事处、巧鸟办事处、锦州市果树农场' },
        { 龙栖湾新区: '龙栖湾新区' },
        { 锦州经济技术开发区: '锦州经济技术开发区' },
        {
          黑山县:
            '黑山街道、大虎山街道、八道壕镇、新立屯镇、白厂门镇、白厂门镇、无梁殿镇、半拉门镇、绕阳河镇、励家镇、姜屯镇、常兴镇、芳山镇、四家子镇、新兴镇、太和镇、段家乡、薛屯乡、英城子乡、大兴乡、镇安镇、小东镇、辽宁省锦州市小东原种场',
        },
        {
          义县: '义州街道办事处、城关街道办事处、刘龙台镇、七里河镇、大榆树堡镇、稍户营子镇、九道岭镇、高台子镇、瓦子峪镇、头台满族镇、头道河满族乡、地藏寺满族乡、留龙沟满族乡、大定堡满族乡、前杨乡、白庙子乡、聚粮屯满族乡、张家堡乡',
        },
        {
          凌海市:
            '大凌河街道、金城街道、石山镇、余积镇、双羊镇、班吉塔镇、沈家台镇、三台子镇、阎家镇、新庄子镇、翠岩镇、安屯镇、大业镇、右卫满族镇、西八千乡、建业乡、白台子乡、谢屯乡、板石沟乡、温滴楼满族乡、辽宁省金城原种场、大有农场',
        },
        {
          北镇市:
            '北镇街道、观音阁街道、大市镇、罗罗堡镇、常兴店镇、正安镇、闾阳镇、中安镇、廖屯镇、赵屯镇、青堆子镇、高山子镇、沟帮子镇、广宁乡、富屯乡、鲍家乡、大屯乡、吴家乡、柳家乡',
        },
      ],
      营口市: [
        {
          站前区:
            '八田地街道、建丰街道、建设街道、跃进街道、东风街道、新兴街道、新建街道、光明村、董家村、新兴村、大兴村',
        },
        {
          西市区:
            '胜利街道、清华街道、得胜街道、五台子街道、渔市街道、河北街道、滨海街道、沿海街道',
        },
        {
          鲅鱼圈区:
            '海星街道、红海街道、海东街道、望海街道、熊岳镇、芦屯镇、红旗满族镇',
        },
        { 老边区: '老边街道、城东街道、边城镇、柳树镇、路南镇' },
        { 沿海新区: '沿海街道' },
        {
          大石桥市:
            '南楼街道、青花街道、石桥街道、钢都街道、金桥街道、高坎镇、水源镇、沟沿镇、石佛镇、旗口镇、虎庄镇、官屯镇、永安镇、博洛铺镇、汤池镇、周家镇、黄土岭镇、建一镇',
        },
        {
          盖州市:
            '西城街道、东城街道、鼓楼街道、西海街道、太阳升街道、团山街道、万福镇、青石岭镇、沙岗镇、高屯镇、团甸镇、暖泉镇、榜式堡镇、高屯镇、徐屯镇、双台镇、陈屯镇、杨运镇、梁屯镇、什字街镇、矿洞沟镇、卧龙泉镇、九寨镇、二台乡、果园乡、小石棚乡',
        },
      ],
      阜新市: [
        {
          海州区:
            '新兴街道、和平街道、西山街道、河北街道、站前街道、西阜新街道、五龙街道、平安西部街道、工人村街道、东梁街道、韩家店镇',
        },
        { 新邱区: '街基街道、北部街道、中部街道、南部街道、长营子镇' },
        {
          太平区: '红树街道、煤海街道、高德街道、孙家湾街道、城南街道、水泉镇',
        },
        { 清河门: '六台街道、新北街道、清河街道、艾友街道、乌龙坝镇、河西镇' },
        { 细河区: '北苑街道、东苑街道、学苑街道、中苑街道、四合镇' },
        {
          彰武县:
            '彰武镇、哈尔套镇、章古台镇、五峰镇、冯家镇、后新秋镇、东六家子镇、阿尔乡镇、苇子沟蒙古族乡、二道河子蒙古族乡、西六家子蒙古族满族乡、两家子乡、双庙乡、平安乡、满堂红乡、四堡子乡、丰田乡、大德乡、大冷蒙古族乡、前福兴地乡、兴隆堡乡、兴隆山乡、大四家子乡、四合城乡',
        },
        {
          阜新蒙古族自治县:
            '东梁镇、佛寺镇、伊玛图镇、旧庙镇、务欢池镇、建设镇、大巴镇、泡子镇、十家子镇、王府镇、于寺镇、富荣镇、新民镇、福兴地镇、平安地镇、沙拉镇、大固本镇、大五家子镇、大板镇、蜘蛛山乡、卧凤沟乡、八家子乡、哈达户稍乡、塔营子乡、扎兰营子乡、招束沟乡、苍土乡、老河土乡、七家子乡、红帽子乡、紫都台乡、化石戈乡、太平乡、国华乡',
        },
      ],
      辽阳市: [
        {
          白塔区:
            '跃进街道、星火街道、站前街道、卫国路街道、胜利街道、文圣街道、武圣街道、襄平街道、南门街道、东兴街道、新华街道',
        },
        { 文圣区: '新城街道、庆阳街道、东京陵街道、罗大台镇、小屯镇' },
        { 宏伟区: '长征街道、新村街道、工农街道、光华街道、曙光镇、兰家镇' },
        { 弓长岭区: '苏家街道、团山子街道、安平街道、汤河镇、安平乡' },
        { 太子河区: '望水台街道、铁西街道、祁家镇、沙岭镇、王家镇、东宁卫乡' },
        {
          灯塔市:
            '烟台街道、万宝桥街道、古城子街道、佟二堡镇、铧子镇、张台子镇、西大窑镇、沈旦堡镇、西马峰镇、柳条寨镇、柳河子镇、大河南镇、五星镇、鸡冠山乡',
        },
        {
          辽阳县:
            '首山镇、穆家镇、兴隆镇、柳壕镇、八会镇、唐马寨镇、寒岭镇、河栏镇、小北河镇、刘二堡镇、黄泥洼镇、隆昌镇、下达河乡、甜水满族乡、吉洞峪满族乡',
        },
      ],
      盘锦市: [
        {
          兴隆台区:
            '新工街道、于楼街道、振兴街道、兴隆街道、高升街道、渤海街道、红村街道、曙光街道、友谊街道、欢喜街道、平安街道、新生街道、锦采街道、沈采街道、茨采街道、创新街道、兴海街道、兴盛街道',
        },
        {
          双台子区:
            '东风街道、胜利街道、建设街道、红旗街道、辽河街道、陆家乡、化工街道、铁东街道、双盛街道',
        },
        {
          大洼县:
            '大洼镇、田庄台镇、二界沟镇、田家镇、新开镇、东风镇、西安镇、清水镇、新立镇、新兴镇、唐家镇、平安镇、荣兴镇、王家镇、赵圈河镇',
        },
        {
          盘山县:
            '胡家镇、太平镇、得胜镇、高升镇、陈家镇、吴家镇、坝墙子镇、古城子镇、沙岭镇、甜水镇',
        },
      ],
      铁岭市: [
        {
          银州区:
            '工人街道、岭东街道、柴河街道、铁西街道、铜钟街道、辽海街道、红旗街道、龙山乡',
        },
        {
          清河区:
            '水渡口街道、柳树湾街道、淮海街道、长东街道、长西街道、府前街道、白鹭湖街道',
        },
        { 铁岭新城区: '铁岭新城区' },
        { 调兵山市: '兀术街街道、调兵山街道、晓明镇、大明镇、晓南镇' },
        {
          开原市:
            '新城街道、老城街道、兴开街道、八宝镇、庆云堡镇、靠山镇、业民镇、金沟子镇、中固镇、八棵树镇、莲花镇、威远堡镇、松山堡镇、马家寨镇、李家台乡、城东乡、下肥地满族乡、上肥地满族乡、林丰满族乡、黄旗寨满族乡',
        },
        {
          铁岭县:
            '凡河镇、新台子镇、阿吉镇、蔡牛镇、镇西堡镇、腰堡镇、平顶堡镇、大甸子镇、李千户镇、熊官屯镇、横道河子镇、双井子乡、鸡冠山乡、白旗寨满族乡、铁岭县种畜场',
        },
        {
          西丰县:
            '德兴满族乡、钓鱼乡、明德满族乡、陶然乡、成平满族乡、营厂满族乡、和隆满族乡、金星满族乡、更刻乡、西丰镇、平岗镇、郜家店镇、凉泉镇、振兴镇、安民镇、天德镇、房木镇、柏榆镇',
        },
        {
          昌图县:
            '八面城镇、昌图镇、老城镇、毛家店镇、宝力镇、亮中桥镇、三江口镇、双庙子镇、泉头镇、金家镇、朝阳镇、头道镇、马仲河镇、此路树镇、老四平镇、大洼镇、傅家镇、四合镇、古榆树镇、七家子镇、前双井子镇、四面城镇、东嘎镇、曲家店镇、通江口镇、大四家子镇、下二台乡、平安堡乡、十八家子乡、长发乡、太平乡、大兴乡、后窑乡、宝力农场、傅家林场、新乡农场、虻牛马场、两家子农场办事处、三江口农场办事处',
        },
        { 铁岭经济技术开发区: '铁岭经济技术开发区' },
        { 铁岭市高新技术产业开发区: '铁岭市高新技术产业开发区' },
      ],
      朝阳市: [
        {
          双塔区:
            '南塔街道、北塔街道、前进街道、凌河街道、光明街道、凌凤街道、龙山街道、站南街道、红旗街道、燕北街道、燕都街道、桃花吐镇、他拉皋镇、孙家湾镇、长宝营子乡',
        },
        {
          龙城区:
            '向阳街道、半拉山街道、马山街道、新华街道、龙泉街道、七道泉子镇、西大营子镇、召都巴镇、大平房镇、边杖子乡、联合乡',
        },
        {
          北票市:
            '城关街道、南山街道、冠山街道、桥北街道、三宝街道、双河街道、台吉街道、西官营镇、大板镇、上园镇、宝国老镇、黑城子镇、五间房镇、台吉镇、长皋乡、小塔子乡、马友营蒙古族乡、蒙古营乡、泉巨永乡、大三家子乡、东官营乡、龙潭乡、哈尔脑乡、南八家子乡、章吉营乡、三宝营乡、巴图营乡、台吉营乡、北塔子乡、娄家店乡、北四家乡、凉水河蒙古族乡、三宝乡',
        },
        {
          凌源市:
            '城关街道、南街街道、东城街道、红山街道、热水汤街道、北街街道、凌北街道、兴源街道、宋杖子镇、三十家子镇、万元店镇、松岭子镇、刀尔登镇、杨杖子镇、沟门子镇、四合当镇、四官营子镇、乌兰白镇、小城子镇、瓦房店镇、前进乡、大河北乡、三道河子乡、北炉乡、牛营子乡、河坎子乡、佛爷洞乡、三家子乡、刘杖子乡、大王杖子乡',
        },
        {
          朝阳县:
            '波罗赤镇、二十家子镇、羊山镇、六家子镇、瓦房子镇、大庙镇、柳城镇、南双庙乡、古山子乡、西五家子乡、北沟门子乡、杨树湾乡、东大道乡、乌兰河硕蒙古族乡、台子乡、胜利乡、东大屯乡、松岭门蒙古族乡、根德营子乡、七道岭乡、北四家子乡、王营子乡、黑牛营子乡、尚志乡、长在营子乡',
        },
        {
          建平县:
            '叶柏寿街道、红山街道、铁南街道、万寿街道、富山街道、小塘镇、奎德素镇、沙海镇、深井镇、榆树林子镇、朱碌科镇、喀喇沁镇、张家营子镇、建平镇、北二十家子镇、黑水镇、昌隆镇、老官地镇、哈拉道口镇、青峰山镇、马场镇、三家蒙古族乡、太平庄乡、白山乡、罗福沟乡、烧锅营子乡、义成功乡、杨树岭乡、青松岭乡、热水畜牧农场、八家国营农场',
        },
        {
          喀喇沁左翼蒙古族自治县:
            '中三家镇、公营子镇、羊角沟镇、十二德堡镇、老爷庙镇、南公营子镇、白塔子镇、平房子镇、山嘴子镇、六官营子镇、水泉乡、卧虎沟乡、甘招乡、兴隆庄乡、尤杖子乡、东哨乡、草场乡、坤都营子乡、大营子乡、大城子街道、利州街道、南哨街道、官大海农场',
        },
      ],
      葫芦岛市: [
        {
          龙港区:
            '葫芦岛街道、东街道、西街道、望海寺街道、龙湾街道、滨海街道、双龙街道、玉皇街道、连湾街道、北港街道',
        },
        {
          连山区:
            '站前街道、石油街道、渤海街道、连山街道、化工街道、化机街道、兴工街道、水泥街道、锦郊街道、钢屯镇、金星镇、虹螺岘镇、台集屯镇、寺儿堡镇、新台门镇、张相公屯乡、大兴乡、塔山乡、沙河营乡、山神庙乡、白马石乡、孤竹营乡、杨郊乡',
        },
        {
          南票区:
            '九龙街道、赵家屯街道、邱皮沟街道、沙锅屯街道、苇子沟街道、三家子街道、缸窑岭镇、暖池塘镇、沙锅屯乡、黄土坎乡和乌金塘水库、高桥镇、金星镇虹螺岘镇、台集屯镇、大兴乡、张相公屯乡',
        },
        {
          兴城市:
            '古城街道、宁远街道、城东街道、温泉街道、钓鱼台街道、华山街道、四家屯街道、临海街道、曹庄镇、沙后所镇、东辛庄镇、郭家镇、羊安满族乡、元台子满族乡、白塔满族乡、望海满族乡、刘台子满族乡、大寨满族乡、南大山满族乡、围屏满族乡、高家岭满族乡、碱厂满族乡、三道沟满族乡、红崖子镇 、旧门满族乡、药王满族乡、徐大堡镇、菊花岛乡',
        },
        {
          绥中县:
            '绥中镇、宽帮镇、大王庙镇、万家镇、前所镇、高岭镇、前卫镇、荒地镇、塔山屯镇、高台镇、王宝镇、沙河镇、小庄子镇、西甸子镇、西平坡满族乡、葛家满族乡、高甸子满族乡、范家满族乡、明水满族乡、秋子沟乡、加碑岩乡、永安堡乡、李家堡乡、网户满族乡、城郊乡',
        },
        {
          建昌县:
            '建昌镇、八家子镇、喇嘛洞镇、药王庙镇、汤神庙镇、玲珑塔镇、大屯镇、牤牛营子乡、素珠营子乡、石佛乡、王宝营子乡、老达杖子乡、要路沟乡、魏家岭乡、西碱厂乡、头道营子乡、新开岭乡、贺杖子乡、养马甸子乡、和尚房子乡、杨树湾子乡、黑山科乡、雷家店乡、小德营子乡、二道湾子蒙古族乡、巴什罕乡、娘娘庙乡、谷杖子乡',
        },
      ],
    },
  ],
  吉林省: [
    {
      长春市: [
        {
          南关区:
            '南岭街道、自强街道、民康街道、新春街道、长通街道、全安街道、曙光街道、永吉街道、桃源街道、临河街道、永兴街道、净月街道、鸿城街道、明珠街道、新立城镇、新湖镇、玉潭镇、幸福乡',
        },
        {
          宽城区:
            '新发街道、南广街道、东广街道、站前街道、柳影街道、群英街道、凯旋街道、团山街道、兴业街道、兰家镇、兴隆山镇、米沙子镇、万宝镇、农安县合隆镇、奋进乡',
        },
        {
          朝阳区:
            '清和街道、南站街道、重庆街道、桂林街道、南湖街道、红旗街道、永昌街道、湖西街道、富锋街道、永春镇、乐山镇、双德乡、长春工业经济开发区',
        },
        {
          二道区:
            '东盛街道、吉林街道、荣光街道、东站街道、远达街道、八里堡街道、英俊镇、劝农山镇、泉眼镇、四家乡',
        },
        {
          绿园区:
            '合心镇、西新镇、城西镇、铁西街道、正阳街道、青年路街道、普阳街道、春城街道同心街道、林园街道、长春绿园经济开发区、长春绿园西新工业集中区、长春皓月清真产业园区、长春西部新城开发区、长春绿园服务业经济区',
        },
        {
          双阳区:
            '云山街道、平湖街道、奢岭街道、山河街道、太平镇、鹿乡镇、齐家镇、双营回族乡',
        },
        {
          九台区:
            '九台街道、营城街道、九郊街道、西营城街道、上河湾镇、其塔木镇、沐石河镇、城子街镇、纪家镇、土们岭镇、波泥河镇、兴隆镇、苇子沟镇、胡家回族乡、莽卡满族乡、卡伦湖镇、龙嘉镇、东湖镇',
        },
        {
          农安县:
            '农安镇、合隆镇、哈拉海镇、高家店镇、三盛玉镇、伏龙泉镇、巴吉垒镇、开安镇、华家镇、烧锅镇、靠山镇、三岗镇、万金塔乡、小城子乡、新农乡、黄鱼圈乡、万顺乡、杨树林乡、永安乡、龙王乡、前岗乡、青山口乡	',
        },
        {
          榆树市:
            '长春五棵树经济开发区、吉林省榆树环城工业集中区、正阳街道、培英街道、华昌街道、城郊街道、八号镇、弓棚镇、大坡镇、秀水镇、闵家镇、刘家镇、五棵树镇、保寿镇、黑林镇、新立镇、土桥镇、大岭镇、新庄镇、于家镇、泗河镇、育民乡、红星乡、太安乡、先锋乡、恩育乡、城发乡、环城乡、青山乡、延和朝鲜族',
        },
        {
          德惠市:
            '惠发街道、建设街道、胜利街道、夏家店街道、大青咀镇、郭家镇、松花江镇、达家沟镇、大房身镇、岔路口镇、朱城子镇、布海镇、天台镇、菜园子镇、同太乡、边岗乡、五台乡、朝阳乡',
        },
      ],
      吉林市: [
        {
          ' 船营区':
            '欢喜乡、越北镇、大绥河镇、搜登站镇、船营经济开发区、大东街道、青岛街道、南京街道、向阳街道、德胜街道、北极街道、致和街道、北山街道、临江街道、长春路街道、黄旗街道',
        },
        {
          龙潭区:
            '龙华街道、土城子街道、东城街道、泡子沿街道、龙潭街道、新吉林街道、山前街道、遵义街道、榆树街道、靠山街道、汉阳街道、承德街道、新安街道、铁东街道、乌拉街满族镇、缸窑镇、江密峰镇、大口钦满族镇、江北乡、金珠乡、兴华经济管理区、阿拉底经济管理区、棋盘经济管理区、天太经济管理区、吉林化学工业循环经济示范园区',
        },
        {
          昌邑区:
            '站前街道、文庙街道、双吉街道、民主街道、延安街道、通江街道、莲花街道、兴华街道、东局子街道、新地号街道、哈达湾街道、新建街道、延江街道、怡春里街道、维昌街道、孤店子镇、左家镇、桦皮厂镇、两家子满族乡、土城子满族朝鲜族乡、东市商贸示范区、虹园经济管理区、幸福经济管理区',
        },
        {
          丰满区:
            '江南乡、小白山乡、前二道乡、旺起镇 、江南街道、泰山街道、沿丰街道、石井街道、丰满街道、红旗街道、经济开发区',
        },
        {
          永吉县:
            '口前镇、北大湖镇、西阳镇、双河镇、岔路河镇、一拉溪镇、万昌镇、黄榆乡、金家满族乡、永吉经济开发区、中新吉林食品区',
        },
        {
          磐石市:
            '河南街道、东宁街道、福安街道、烟筒山镇、红旗岭镇、明城镇、石嘴镇、驿马镇、牛心镇、呼兰镇、吉昌镇、松山镇、黑石镇、朝阳山镇、富太镇、取柴河镇、宝山乡、磐石经济开发区、明城经济开发区',
        },
        {
          桦甸市:
            '胜利街道、启新街道、明桦街道、新华街道、永吉街道、红石砬子镇、夹皮沟镇、二道甸子镇、八道河子镇、金沙镇、常山镇、横道河子乡、公吉乡、桦郊乡、吉林桦甸经济开发区',
        },
        {
          蛟河市:
            '民主街道、长安街道、河南街道、奶子山街道、新农街道、拉法街道、中岗街道、河北街道、新站镇、天岗镇、白石山镇、漂河镇、黄松甸镇、天北镇、松江镇、庆岭镇、乌林朝鲜族乡、前进乡',
        },
        {
          舒兰市:
            '北城街道、南城街道、环城街道、铁东街道、吉舒街道、白旗镇、朝阳镇、上营镇、平安镇、水曲柳镇、法特镇、溪河镇、小城镇、开原镇、金马镇、莲花乡、亮甲山乡、新安乡、七里乡、天德乡、经济开发区',
        },
      ],
      四平市: [
        {
          铁西区:
            '昆明湖街道、工人村街道、七路街道、启工街道、笃工街道、兴华街道、兴工街道、霁虹街道、凌空街道、重工街道、艳粉街道、保工街道、兴顺街道、贵和街道、翟家街道、大青中朝友谊街道、大潘街道、西三环街道、彰驿街道、高花街道',
        },
        {
          铁东区:
            '东长甸街道、长甸街道、湖南街道、常青街道、解放街道、山南街道、园林街道、胜利街道、站前街道、钢城街道、和平街道、对炉街道、新兴街道',
        },
        {
          双辽市:
            '郑家屯街道、辽东街道、辽南街道、辽西街道、辽北街道、红旗街道、卧虎镇、服先镇、王奔镇、茂林镇、兴隆镇、东明镇、双山镇、玻璃山镇、那木斯蒙古族乡、柳条乡、新立乡、永加乡',
        },
        {
          梨树县:
            '梨树镇、郭家店镇、榆树台镇、小城子镇、喇嘛甸镇、蔡家镇、刘家馆子镇、十家堡镇、孟家岭镇、万发镇、东河镇、沈洋镇、林海镇、小宽镇、白山乡、泉眼岭乡、胜利乡、四棵树乡、双河乡、金山乡',
        },
        {
          伊通满族自治县:
            '伊通镇、营城子镇、二道镇、西苇镇、景台镇、河源镇、伊丹镇、小孤山镇、大孤山镇、马鞍山镇、靠山镇、黄岭子镇、莫里青乡、三道乡、新兴乡',
        },
        { 辽河农垦管理区: '辽河农垦管理区' },
      ],
      辽源市: [
        {
          龙山区:
            '南康街道、北寿街道、东吉街道、西宁街道、站前街道、新兴街道、福镇街道、向阳街道、寿山镇、工农乡',
        },
        {
          西安区:
            '仙城街道、太安街道、东山街道、安家街道、先锋街道、富国街道、灯塔乡',
        },
        {
          东丰县:
            '东丰镇、大阳镇、横道河镇、那丹伯镇、猴石镇、杨木林镇、小四平镇、黄河镇、拉拉河镇、沙河镇、南屯基镇、大兴镇、三合满族朝鲜族乡、二龙山乡',
        },
        {
          东辽县:
            '白泉镇、渭津镇、安石镇、辽河源镇、泉太镇、建安镇、安恕镇、平岗镇、云顶镇、凌云乡、甲山乡、足民乡、金州乡',
        },
      ],
      通化市: [
        {
          东昌区:
            '光明街道、民主街道、东昌街道、龙泉街道、新站街道、老站街道、团结街道、金厂镇、江东乡、环通乡',
        },
        {
          二道江区: '东通化街道、桃园街道、五道江镇、铁厂镇、鸭园镇、二道江乡',
        },
        {
          梅河口市:
            '新华街道、解放街道、和平街道、福民街道、光明街道、山城镇、海龙镇、红梅镇、新合镇、曙光镇、中和镇、进化镇、一座营镇、水道镇、康大营镇、牛心顶镇、黑山头镇、李炉乡、杏岭乡、双兴乡、兴华乡、吉乐乡、小杨满族朝鲜族乡、湾龙乡',
        },
        {
          集安市:
            '团结街道、黎明街道、通胜街道、城东街道、青石镇、榆林镇、花甸镇、头道镇、清河镇、台上镇、财源镇、大路镇、太王镇、麻线乡、凉水朝鲜族乡',
        },
        {
          通化县:
            '快大茂镇、二密镇、果松镇、石湖镇、大安镇、光华镇、兴林镇、英额布镇、三棵榆树镇、西江镇、富江乡、四棚乡、东来乡、大泉源满族朝鲜族乡、金斗朝鲜族满族乡',
        },
        {
          辉南县:
            '朝阳镇、辉南镇、杉松岗镇、金川镇、石道河镇、辉发城镇、样子哨镇、庆阳镇、抚民镇、团林镇、楼街朝鲜族乡',
        },
        {
          柳河县:
            '柳河镇、孤山子镇、五道沟镇、驼腰岭镇、三源浦朝鲜族镇、圣水镇、凉水',
        },
        {
          通化经济技术开发区:
            '长胜村、张家村、自安村、湾湾川村、桃源经济合作社、建红社区',
        },
        { 集安经济技术开发区: '集安经济技术开发区' },
        { 梅河口经济贸易开发区: '梅河口经济贸易开发区' },
      ],
      白山市: [
        {
          长白朝鲜族自治县:
            '长白镇、八道沟镇、十四道沟镇、马鹿沟镇、宝泉山镇、新房子镇、金华乡、十二道沟乡',
        },
        {
          抚松县:
            '抚松镇、松江河镇、泉阳镇、露水河镇、仙人桥镇、万良镇、新屯子镇、东岗镇、漫江镇、北岗镇、兴参镇、兴隆乡、抽水乡、沿江乡',
        },
        {
          靖宇县:
            '靖宇镇、三道湖镇、花园口镇、龙泉镇、那尔轰镇、景山镇、赤松镇、濛江乡',
        },
        {
          江源区:
            '松树镇、湾沟镇、大阳岔镇、砟子镇、石人镇、大石人镇、孙家堡子街道、江源街道、城墙街道、正岔街道',
        },
        {
          临江市:
            '建国街道、新市街道、兴隆街道、大湖街道、森工街道、大栗子街道、桦树镇、六道沟镇、苇沙河镇、花山镇、闹枝镇、四道沟镇、蚂蚁河乡',
        },
        {
          浑江区:
            '七道江镇、六道江镇、红土崖镇、三道沟镇、红旗街道、新建街道、东兴街道、通沟街道、城南街道、板石街道、江北街道、河口街道',
        },
      ],
      白城市: [
        {
          洮北区:
            '东风乡、三合乡、东胜乡、金祥乡、德顺蒙古族乡、平安镇、平台镇、青山镇、林海镇、洮河镇、到保镇。海明街道、长庆街道、瑞光街道、明仁街道、铁东街道、新立街道、新华街道',
        },
        {
          洮南市:
            '团结街道、富文街道、光明街道、兴隆街道、永康街道、通达街道、向阳街道、洮府街道、瓦房镇、万宝镇、黑水镇、那金镇、安定镇、福顺镇、胡力吐蒙古族乡、万宝乡、东升乡、野马乡、聚宝乡、永茂乡、蛟流河乡、大通乡、二龙乡、呼和车力蒙古族乡',
        },
        {
          大安市:
            '慧阳街道、临江街道、长虹街道、锦华街道、安北街道、月亮泡镇、安广镇、丰收镇、新平安镇、两家子镇、舍力镇、大岗子镇、叉干镇、龙沼镇、太山镇、四棵树乡、联合乡、乐胜乡、大赉乡、红岗子乡、烧锅镇乡、海坨乡、新艾里蒙古族乡',
        },
        {
          镇赉县:
            '镇赉镇、坦途镇、东屏镇、大屯镇、沿江镇、五棵树镇、黑鱼泡镇、哈吐气蒙古族乡、莫莫格蒙古族乡、建平乡、嘎什根乡',
        },
        {
          通榆县:
            '开通镇、瞻榆镇、双岗镇、兴隆山镇、边昭镇、鸿兴镇、新华镇、乌兰花镇、新发乡、新兴乡、向海蒙古族乡、包拉温都蒙古族乡、团结乡、十花道乡、八面乡、苏公坨乡',
        },
      ],
      松原市: [
        { 宁江区: '大洼镇、善友镇、毛都站镇、伯都乡、兴原乡' },
        {
          扶余市:
            '三岔河镇、长春岭镇、五家站镇、陶赖昭镇、蔡家沟镇、弓棚子镇、三井子镇、增盛镇、大林子镇、新万发镇、新源镇、得胜镇、三骏乡、永平乡、新站乡、更新乡、肖家乡',
        },
        {
          乾安县:
            '乾安镇、大布苏镇、水字镇、让字镇、所字镇、安字镇、余字乡、道字乡、严字乡、赞字乡',
        },
        {
          长岭县:
            '太平川镇、巨宝山镇、太平山镇、前七号镇、新安镇、三青山镇、大兴镇、北正镇、流水镇、永久镇、利发盛镇、集体乡、光明乡、三县堡乡、海青乡、前进乡、东岭乡、腰坨子乡、八十八号乡、三团乡、三十号乡',
        },
        {
          前郭尔罗斯蒙古族自治县:
            '前郭镇、长山镇、海渤日戈镇、乌兰图嘎镇、查干花镇、王府站镇、八郎镇、哈拉毛都镇、宝甸乡、平凤乡、蒙古艾里乡、达里巴乡、吉拉吐乡、白依拉嘎乡、洪泉乡、额如乡、套浩太乡、长龙乡、乌兰塔拉乡、东三家子乡、浩特芒哈乡、乌兰敖都乡',
        },
      ],
      延边朝鲜族自治州: [
        {
          延吉市:
            '河南街道、北山街道、建工街道、新兴街道、进学街道、公园街道、小营镇、依兰镇、三道湾镇、朝阳川镇',
        },
        {
          图们市:
            '向上街道、新华街道、月宫街道、月晴镇、凉水镇、长安镇、石岘镇',
        },
        {
          敦化市:
            '江南镇、大石头镇、黄泥河镇、官地镇、贤儒镇、江源镇、大蒲柴河镇、秋梨沟镇、额穆镇、沙河沿镇、雁鸣湖镇、大桥乡、翰章乡、红石乡、黑石乡、青沟子乡、渤海街道、胜利街道、民主街道、丹江街道、经济开发区',
        },
        {
          龙井市:
            '开山屯镇、老头沟镇、三合镇、东盛涌镇、智新镇、德新乡、白金乡、安民街道、龙门街道',
        },
        {
          珲春市:
            '靖和街道、新安街道、河南街道、近海街道、 春化镇、敬信镇、板石镇、英安镇、马川子乡、密江乡、哈达门乡、杨泡满族乡、三家子满族乡',
        },
        {
          和龙市:
            '八家子镇、福洞镇、头道镇、西城镇、南坪镇、东城镇、崇善镇、龙城镇、民惠街道、光明街道、文化街道',
        },
        {
          汪清县:
            '汪清镇、大兴沟镇、天桥岭镇、罗子沟镇、百草沟镇、春阳镇、复兴镇、东光镇、鸡冠乡',
        },
        {
          安图县:
            '明月镇、松江镇、二道白河镇、两江镇、石门镇、万宝镇、亮兵镇、新合乡、永庆乡',
        },
      ],
      长白山保护开发区管理委员会: [
        { 经济管理区: '池西经济管理区、池北经济管理区、池南经济管理区' },
      ],
      梅河口市: [
        {
          梅河口市:
            '新华街道、解放街道、和平街道、光明街道、福民街道、山城镇、红梅镇、海龙镇、新合镇、水道镇、中和镇、黑山头镇、曙光镇、进化镇、牛心顶镇、一座营镇、康大营镇、兴华镇、吉乐乡、李炉乡、杏岭乡、湾龙乡、双兴乡、小杨满族朝鲜族乡',
        },
      ],
      公主岭: [
        {
          公主岭:
            '河南街道、河北街道、东三街道、岭东街道、铁北街道、岭西街道、苇子沟街道、刘房子街道、南崴子街道、环岭街道、黑林子镇、陶家屯镇、范家屯镇、响水镇、大岭镇、怀德镇、双城堡镇、双龙镇、杨大城子镇、毛城子镇、玻璃城子镇、朝阳坡镇、大榆树镇、秦家屯镇、八屋镇、十屋镇、桑树台镇、二十家子满族镇、永发乡、龙山满族乡',
        },
      ],
    },
  ],
  黑龙江省: [
    {
      哈尔滨市: [
        {
          道里区:
            '兆麟街道、新阳路街道、抚顺街道、共乐街道、新华街道、城乡路街道、工农街道、尚志街道、斯大林街道、通江街道、经纬街道、工程街道、安静街道、安和街道、正阳河街道、建国街道、康安街道、爱建街道、群力街道、新发街道、太平镇、新农镇、榆树镇',
        },
        {
          南岗区:
            '燎原街道、大成街道、松花江街道、花园街道、曲线街道、通达街道、七政街道、和兴街道、哈西街道、保健街道、荣市街道、奋斗街道、芦家街道、革新街道、文化街道、先锋路街道、新春街道、跃进街道、王岗镇、红旗满族镇',
        },
        {
          道外区:
            '靖宇街道、太古街道、东莱街道、滨江街道、仁里街道、崇俭街道、振江街道、东原街道、大兴街道、胜利街道、南马街道、民强街道、大有坊街道、南直路街道、化工街道、火车头街道、新一街道、三棵树大街街道、水泥路街道、太平大街街道、黎华街道、新乐街道、团结镇、永源镇、巨源镇、民主乡、平房区青年农场',
        },
        {
          平房区:
            '平房村、工农村、韩家店村、黎明村、哈达村、曙光村、新华村、平乐村、东福村、高潮村、韩祯村',
        },
        { 松北区: '松北镇、松浦镇、万宝镇、对青山镇、乐业镇' },
        {
          香坊区:
            '香坊大街街道、安埠街道、通天街道、新香坊街道、铁东街道、新成街道、红旗街道、六顺街道、建筑路街道、哈平路街道、安乐街道、健康路街道、大庆路街道、进乡街道、通乡街道、和平路街道、民生路街道、文政街道、王兆街道、黎明街道、成高子镇、幸福镇、朝阳镇、向阳乡',
        },
        {
          呼兰区:
            '腰堡街道、兰河街道、利民街道、呼兰街道、康金镇、沈家镇、方台镇、白奎镇、石人镇、二八镇、莲花镇、大用镇、双井镇、长岭镇、许堡乡、杨林乡、孟家乡',
        },
        {
          阿城区:
            '蜚克图镇、平山镇、松峰山镇、红星镇、金龙山镇、杨树镇、料甸镇、金城街道办事处、金都街道办事处、通城街道办事处、河东街道办事处、阿什河街道办事处、玉泉街道办事处、新利街道办事处、双丰街道办事处、舍利街道办事处、亚沟镇街道办事处、交界街道办事处、小岭街道办事处',
        },
        {
          双城区:
            '双城镇、兰棱镇、东官镇、周家镇、新兴镇、五家镇、单城镇、杏山镇、韩甸镇、西官镇、公正镇、农丰满族锡伯族镇、朝阳乡、万隆乡、永胜乡、临江乡、水泉乡、金城乡、青岭满族乡、联兴满族乡、同心满族乡、希勤满族乡、乐群满族乡、幸福满族乡',
        },
        {
          尚志市:
            '尚志镇、一面坡镇、苇河镇、亚布力镇、帽儿山镇、亮河镇、庆阳镇、石头河子镇、元宝镇、黑龙宫镇、长寿乡、乌吉密乡、鱼池朝鲜族乡、珍珠山乡、老街基乡、马延乡、河东朝鲜族乡',
        },
        {
          五常市:
            '拉林镇、山河镇、小山子镇、杜家镇辖、向阳镇、冲河镇、背荫河镇、安家镇、沙河子镇、牛家满族镇、民乐朝鲜族乡、营城子满族乡',
        },
        {
          依兰县:
            '依兰镇、达连河镇、道台桥镇、三道岗镇、江湾镇、宏克力镇、团山子乡、愚公乡、迎兰朝鲜族乡',
        },
        {
          方正县:
            '方正镇、会发镇、大罗密镇、天门乡、宝兴乡、德善乡、松南乡、伊汉通乡',
        },
        {
          宾县: '宾州镇、居仁镇、宾西镇、糖坊镇、宾安镇、新甸镇、胜利镇、宁远镇、摆渡镇、平坊镇、满井镇、常安镇、永和乡、鸟河乡、民和乡、经建乡、三宝乡',
        },
        {
          巴彦县:
            '巴彦镇、兴隆镇、西集镇、万发镇、龙泉镇、天增镇、龙庙镇、洼兴镇、黑山镇、巴彦港镇、松花江乡、富江乡、红光乡、德祥乡、丰乐乡、山后乡、镇东乡、华山乡',
        },
        {
          木兰县:
            '木兰镇、东兴镇、大贵镇、利东镇、柳河镇、新民镇、建国乡、吉兴乡',
        },
        {
          通河县:
            '通河镇、乌鸦泡镇、清河镇、浓河镇、凤山镇、祥顺镇、三站乡、富林乡',
        },
        {
          延寿县:
            '延寿镇、六团镇、中和镇、加信镇、延河镇、安山乡、寿山乡、玉河乡、青川乡',
        },
      ],
      齐齐哈尔市: [
        {
          龙沙区:
            '正阳街道办事处、五龙街道办事处、彩虹街道办事处、江安街道办事处、湖滨街道办事处、南航街道办事处、大民村、五福玛村、三合村、新光村、爱国村、明星村',
        },
        {
          建华区:
            '中华街街道、建设街道、卜奎街道、文化街道、西大桥街道、光明村、黎明村、曙光村、星光村',
        },
        {
          铁锋区:
            '站前街道、南浦街道、通东街道、光荣街道、龙华街道、北局宅街道、东湖街道、扎龙乡',
        },
        {
          昂昂溪区:
            '新兴街道、新建街道、道北街道、三间房街道、化工街道、林机街道、榆树屯镇、水师营满族镇、昂昂溪乡',
        },
        {
          富拉尔基区:
            '红岸街道、沿江街道、电力街道、幸福街道、红宝石街道、北兴街道、铁北街道、和平街道、长青乡、杜尔门沁达斡尔族乡',
        },
        {
          碾子山区:
            '繁荣街道、富强街道、跃进街道、东安街道、丰荣村、九里村、兴华村、龙华村、三江省村、三皇庙村、华丰村、春光村、前进村',
        },
        {
          梅里斯达斡尔族区:
            '梅里斯街道、雅尔塞镇、卧牛吐达斡尔族镇、达呼店镇、共和镇、梅里斯镇、莽格吐达斡尔族乡',
        },
        {
          讷河市:
            '讷河镇、拉哈镇、二克浅镇、学田镇、龙河镇、讷南镇、六合镇、长发镇、通南镇、同义镇、九井镇、老莱镇、孔国乡、和盛乡、同心乡、兴旺鄂温克族乡',
        },
        {
          龙江县:
            '龙江镇、景星镇、龙兴镇、山泉镇、七棵树镇、白山乡、黑岗乡、广厚乡、华民乡、哈拉海乡、杏山乡、头站乡、鲁河乡、济沁河乡',
        },
        {
          依安县:
            '富饶乡、阳春乡、太东乡、解放乡、新发乡、上游乡、先锋乡、新屯乡、红星乡、新兴镇、依龙镇、三兴镇、依安镇、双阳镇、中心镇',
        },
        {
          泰来县:
            '泰来镇、平洋镇、汤池镇、江桥蒙古族镇、塔子城镇、大兴镇、和平镇、克利镇、胜利蒙古族乡、宁姜蒙古族乡',
        },
        {
          甘南县:
            '甘南镇、兴十四镇、平阳镇、东阳镇、巨宝镇、长山乡、中兴乡、兴隆乡、宝山乡、查哈阳乡',
        },
        {
          富裕县:
            '富裕镇、富路镇、富海镇、二道湾镇、龙安桥镇、塔哈镇、繁荣乡、友谊乡、绍文乡、忠厚乡',
        },
        {
          克山县:
            '克山镇、北兴镇、西城镇、古城镇、北联镇、西河镇、河南乡、双河乡、河北乡、古北乡、西联乡、发展乡、西建乡、向华乡、曙光乡',
        },
        { 克东县: '克东镇、宝泉镇、乾丰镇、玉岗镇、蒲峪路镇、润津乡、昌盛乡' },
        {
          拜泉县:
            '拜泉镇、三道镇、兴农镇、长春镇、龙泉镇、国富镇 、富强镇、新生乡 、兴国乡、华光乡、上升乡、兴华乡、建国乡、大众乡、新建乡、长荣乡、丰产乡、永勤乡、自强乡、爱农乡',
        },
      ],
      牡丹江市: [
        { 东安区: '新安街道、长安街道、七星街道、五星街道、兴隆镇' },
        {
          西安区:
            '灯塔镇、仙城街道、东山街道、富国街道、先锋街道、安家街道、太安街道、灯塔乡',
        },
        {
          阳明区:
            '阳明街道办事处、前进街道办事处、铁岭镇、桦林镇、磨刀石镇、五林镇',
        },
        {
          爱民区:
            '向阳街道、黄花街道、铁北街道、新华街道、大庆街道、兴平街道、北山街道、三道关镇',
        },
        { 绥芬河市: '绥芬河镇、阜宁镇' },
        {
          海林市:
            '海林镇、长汀镇、横道镇、山市镇、柴河镇、二道镇、新安朝鲜族镇、三道镇',
        },
        {
          宁安市:
            '宁安镇、东京城镇、渤海镇、石岩镇、海浪镇、沙兰镇、兰岗镇、江南乡、马河乡、三陵乡、卧龙乡、镜泊乡',
        },
        {
          穆棱市:
            '八面通镇、下城子镇、马桥河镇、穆棱镇、兴源镇、河西镇、福禄乡、共和乡',
        },
        {
          林口县:
            '林口镇、古城镇、刁翎镇、朱家镇、柳树镇、三道通镇、龙爪镇、莲花镇、青山镇、五林镇、奎山乡、建堂乡',
        },
        {
          东宁县: '东宁镇、绥阳镇、老黑山镇、大肚川镇、道河镇、三岔口朝鲜族镇',
        },
      ],
      佳木斯市: [
        {
          前进区:
            '站前街道、永安街道、奋斗街道、南岗街道、亮子河街道、田园街道',
        },
        {
          向阳区:
            '西林街道、保卫街道、桥南街道、西南岗街道、建设街道、长安街道、万发村、和平村、新丰村、长青村、江南村、上柳村、下柳村、马场村',
        },
        { 东风区: '佳东街道、晓云街道、佳南街道、建国街道、造纸街道' },
        {
          郊区: '莲江口镇、长发镇、望江镇、敖其镇、大来镇、建国乡、松木河乡、平安乡、高峰乡、群胜乡、西格木乡、沿江乡、四丰乡、松江乡、长青乡',
        },
        {
          桦南县:
            '桦南镇、土龙山镇、孟家岗镇、闫家镇、石头河子镇、驼腰子镇、梨树乡、金沙乡、明义乡、大八浪乡',
        },
        {
          桦川县:
            '悦来镇、横头山镇、横头山镇、苏家店镇、新城镇、东河乡、创业乡、梨丰乡、四马架乡、星火朝鲜族乡',
        },
        {
          汤原县:
            '香兰镇、鹤立镇、竹帘镇、汤原镇、汤旺朝鲜族乡、胜利乡、吉祥乡、振兴乡、太平川乡、永发乡',
        },
        {
          抚远县:
            '抚远镇、浓桥镇、抓吉镇、寒葱沟镇、别拉洪乡、海青乡、鸭南乡、浓江乡、通江乡',
        },
        {
          同江市:
            '同江镇、乐业镇、三村镇、临江镇、向阳乡、青河乡、街津口赫哲族乡、八岔赫哲族乡、金川乡、银川乡',
        },
        {
          富锦市:
            '城东街道、城西街道、富锦镇、二龙山镇、向阳川镇、锦山镇、头林镇、兴隆岗镇、宏胜镇、上街基镇、砚山镇、长安镇、大榆树镇',
        },
      ],
      大庆市: [
        {
          萨尔图区:
            '萨尔图街道、铁人街道、友谊街道、富强街道、拥军街道、会战街道、火炬街道、东风街道、东安街道',
        },
        {
          让胡路区:
            '奋斗街道、银浪街道、龙岗街道、乘风街道、西宾街道、庆新街道、喇嘛甸镇',
        },
        {
          红岗区:
            '红岗街道、八百垧街道、杏南街道、解放街道、创业街道、杏树岗镇',
        },
        {
          龙凤区:
            '龙凤街道、兴化村街道、卧里屯街道、东光街道、三永街道、黎明街道、龙凤镇',
        },
        { 高新技术开发区: '高新技术开发区' },
        {
          大同区:
            '庆葡街道、高台子镇街道、林源镇街道、立志街道、新华街道、大同镇街道、大同镇、高台子镇、太阳升镇、林源镇、祝三乡、老山头乡、八井子乡、双榆树乡',
        },
        {
          肇州县:
            '肇州镇、永乐镇、丰乐镇、朝阳沟镇、兴城镇、二井镇、双发乡、托古乡、朝阳乡、永胜乡、榆树乡、新福乡、乐园良种场、卫星畜种场',
        },
        {
          肇源县:
            '福兴乡、三站镇、薄荷台乡、二站镇、和平乡、肇源镇、头台镇、大兴乡、浩德蒙古族乡、义顺蒙古族乡、古龙镇、新站镇、民意乡、茂兴镇、超等蒙古族乡、古恰镇',
        },
        {
          林甸县:
            '林甸镇、红旗镇、花园镇、四季青镇、东兴乡、宏伟乡、三合乡、四合乡、黎明乡',
        },
        {
          杜尔伯特蒙古族自治县:
            '胡吉吐莫镇、烟筒屯镇、他拉哈镇、一心乡、克尔台乡、白音诺勒乡、江湾乡、敖林西伯乡、巴彦查干乡、腰新乡',
        },
      ],
      伊春市: [
        { 伊春区: '旭日街道、红升街道、前进街道、朝阳街道、东升街道' },
        { 带岭区: '带岭街道、大青川街道、省带岭林业实验局虚拟街道、大兴乡' },
        {
          南岔区:
            '晨明镇、浩良河镇、迎春乡、西水街道、联合街道、东升街道、梧桐街道',
        },
        { 金山屯区: '奋斗街道、金山街道' },
        { 西林区: '西林街道、新兴街道、苔青街道、' },
        {
          美溪区:
            '美溪街道、大西林林场、顺利河林场、青山口林场、金沙河林场、桦皮羌子林场、碧仓库林场、卧龙河林场',
        },
        {
          乌马河区:
            '乌马河街道、伊敏林场、伊东经营所、东方红经营所、翠岭经营所、河北经营所、青山林场、育苗经营所、安全经营所、伊青经营所、前进经营所、西岭林场、伊林经营所',
        },
        { 翠峦区: '向阳街道、曙光街道' },
        {
          友好区:
            '友好街道、双子河街道、铁林街道、三合经营所、松园林场、中心经营所、永青经营所、碧云经营所、友谊经营所、青杨经营所、岭峰林场、奋斗林场、东卡林场、鸡爪河林场、绿源林场、广川经营所、朝阳经营所',
        },
        {
          新青区:
            '新青街道、兴安街道、红林经营所、松林经营所、乌拉嘎经营所、泉林林场、水源林场、笑山林场、汤林林场、桦林林场、青林林场、结源林场、北沟林场、南沟林场、柳树河林场',
        },
        {
          上甘岭区:
            '红山街道、山峰林场、阳光林场、新风林场、卫国林场、青山农场、查山经营所、长青经营所、溪水经营所、蔚兰经营所、永绪经营所、美林经营所、红山经营所',
        },
        {
          五营区:
            '五营街道、五星街道、翠北林场、前丰林场、永丰经营所、平原经营所、丽丰林场、杨树河林场、平山经营所、丽林经营所',
        },
        {
          红星区:
            '红星街道、清水河林场、五星河经营所、汤洪岭林场、汤北林场、霍吉河林场、二杨河经营所、三杨林场、汤南经营所、共青经营所、库斯特经营所、二皮河经营所、曙光经营所',
        },
        {
          汤旺河区:
            '河南街道、河北街道、日新林场、石林林场、东升林场、二龙山林场、高峰林场、二清河林场、守虎山林场、泉石林场、峻岭林场、育林经营所、团结经营所、磨石山经营所、中心苗圃、克林经营所',
        },
        {
          乌伊岭区:
            '乌伊岭街道、上游林场、美峰林场、美林林场、建新林场、移山林场、林海林场、桔源经营所、东克林林场、福民林场、阿廷河林场、永胜经营所、前卫林场',
        },
        {
          铁力市:
            '铁力镇、双丰镇、桃山镇、朗乡镇、年丰朝鲜族乡、工农乡、王杨乡',
        },
        {
          嘉荫县:
            '朝阳镇、乌云镇、乌拉嘎镇、常胜乡、向阳乡、沪嘉乡、向阳乡、红光乡、保兴乡、青山乡',
        },
      ],
      鸡西市: [
        {
          鸡冠区:
            '向阳街道、南山街道、立新街道、东风街道、红军路街道、西鸡西街道、西山街道、红星乡、西郊乡',
        },
        {
          城子河区:
            '城子河街道、正阳街道、东海街道、城西街道、杏花街道、长青乡、永丰朝鲜族乡',
        },
        {
          恒山区:
            '奋斗街道、大恒山街道、小恒山街道、二道河街道、张新街道 、柳毛街道、桦木林街道、红旗乡、柳毛乡、民主乡',
        },
        {
          滴道区:
            '兰岭乡、滴道河乡、东兴街道、矿里街道、洗煤厂街道、大通沟街道',
        },
        { 梨树区: '街里街道、穆棱街道、平岗街道、碱场街道、石磷街道' },
        { 麻山区: '麻山乡、麻山街道' },
        {
          虎林市:
            '虎林镇、东方红镇、迎春镇、虎头镇、杨岗镇、东诚镇、宝东镇、新乐乡、伟光乡、珍宝岛乡、阿北乡、小木河乡',
        },
        {
          密山市:
            '黑台镇、知一镇、兴凯镇、当壁镇、密山镇、连珠山镇、裴德镇、柳毛乡、杨木乡、兴凯湖朝鲜族乡、承紫河乡、白泡子乡、二人班乡、太平乡、和平乡、富源乡',
        },
        {
          鸡东县:
            '鸡东镇、平阳镇、向阳镇、哈达镇、永安镇、永和镇、东海镇、兴农镇、鸡林朝鲜族乡、明德朝鲜族乡、下亮子乡',
        },
      ],
      鹤岗市: [
        { 兴山区: '沟南街道、沟北街道、岭南街道、岭北街道' },
        { 向阳区: '光明街道、红军街道、胜利街道、南翼街道、北山街道' },
        {
          工农区: '育才街道、团结街道、湖滨街道、解放街道、新南街道、红旗街道',
        },
        {
          南山区:
            '铁西街道、铁东街道、六号街道、大陆街道、富力街道、鹿林山街道',
        },
        {
          兴安区: '竣德路街道、河东路街道、兴长路街道、兴安路街道、兴建路街道 ',
        },
        {
          东山区:
            '东山街道、三街街道、工人村街道、新一街道、新华镇、红旗乡、蔬园乡、东方红乡、鹤岗林业局、新华农场',
        },
        {
          萝北县:
            '凤翔镇、鹤北镇、名山镇、团结镇、肇兴镇、东明朝鲜族乡、太平沟乡、环山乡',
        },
        {
          绥滨县:
            '绥滨镇、 绥东镇、忠仁镇、连生乡、北岗乡、富强乡、北山乡、福兴满族乡、新富乡',
        },
      ],
      双鸭山市: [
        {
          尖山区:
            '二马路街道、八马路街道、中心站街道、富安街道、窑地街道、长安街道、铁西街道、安邦乡 ',
        },
        {
          岭东区:
            '中山街道、北山街道、南山街道、东山街道、中心街道、西山街道、长胜乡',
        },
        { 四方台区: '振兴中路街道、振兴东路街道、集贤街道、东荣街道、太保镇' },
        {
          宝山区:
            '红旗街道、跃进街道、东保卫街道、七星街道、双阳街道、新安街道、上游街道、七星镇',
        },
        {
          集贤县:
            '福利镇、集贤镇、升昌镇、丰乐镇、太平镇、腰屯乡、兴安乡、永安乡',
        },
        {
          友谊县:
            '友谊镇、兴隆镇、龙山镇、风岗镇、兴盛乡、东建乡、庆丰乡、建设乡、友邻乡、新镇乡、成富朝鲜族满族乡',
        },
        {
          宝清县:
            '宝清镇、七星泡镇、青原镇、夹信子镇、龙头镇、小城子镇、朝阳乡万金山乡、尖山子乡、七星河乡 ',
        },
        {
          饶河县:
            '饶河镇、小佳河镇、西丰镇、五林洞镇、西林子乡、四排赫哲族乡、大佳河乡、山里乡、大通河乡 ',
        },
      ],
      七台河市: [
        {
          桃山区:
            '桃南街道、桃北街道、桃西街道、兴岗街道、桃东街道、桃山街道、万宝河镇 ',
        },
        {
          新兴区:
            '兴华街道、新城街道、新立街道、新建街道、北山街道、河南街道、新合街道、缸窑沟街道、新安街道、越秀街道、红旗镇 ',
        },
        {
          茄子河区:
            '东风街道、向阳街道、富强街道、新富街道、龙湖街道、茄子河镇、宏伟镇、铁山乡、中心河乡',
        },
        {
          勃利县:
            '新起街道、新华街道、元明街道、铁西街道、城西街道、勃利镇、小五站镇、大四站镇、双河镇、倭肯镇、青山乡、长兴乡、永恒乡、抢垦乡、杏树朝鲜族乡、吉兴朝鲜族满族乡',
        },
      ],
      绥化市: [
        {
          北林区:
            '紫来街道、爱路街道、大有街道、吉泰街道、东兴办事处、北林办事处、宝山镇、绥胜满族镇、西长发镇、永安满族镇、太平川镇、秦家镇、双河镇、三河镇、四方台镇、津河镇、张维镇、东津镇、红旗满族乡、连岗乡、新华乡、兴福乡、东富乡、三井乡、五营乡、兴和朝鲜族乡',
        },
        {
          安达市:
            '铁西街道、新兴街道、安虹街道、安达镇、任民镇、万宝山镇、昌德镇、升平镇、羊草镇、老虎岗镇、中本镇、太平庄镇、吉兴岗镇、火石山乡、卧里屯乡、青肯泡乡、先源乡',
        },
        {
          肇东市:
            '朝阳区办事处、东升区办事处、西园区办事处、正阳区办事处、肇东镇、昌五镇、宋站镇、五站镇、尚家镇、姜家镇、里木店镇、四站镇、涝洲镇、五里明镇、黎明镇、太平乡、海城乡、向阳乡、洪河乡、跃进乡、西八里乡、德昌乡、宣化乡、安民乡、明久乡',
        },
        {
          海伦市:
            '海伦镇、海北镇、伦河镇、共合镇、海兴镇、祥富镇、东风镇、前进乡、向荣乡、东林乡、海南乡、共荣乡、乐业乡、福民乡、丰山乡、永富乡、百祥乡、联发乡、永和乡、爱民乡、扎音河乡、双录乡',
        },
        {
          望奎县:
            '望奎镇、通江镇、卫星镇、海丰镇、莲花镇、惠七满族镇、先锋镇、东郊乡、灵山满族乡、后三乡、火箭乡、灯塔乡、东升乡、恭六乡、厢白满族乡 ',
        },
        {
          兰西县:
            '兰西镇、榆林镇、临江镇、平山镇、康荣乡、燎原乡、北安乡、长江乡、兰河乡、红星乡、红光乡、长岗乡、远大乡、星火乡、奋斗乡',
        },
        {
          青冈县:
            '青冈镇、中和镇、祯祥镇、兴华镇、永丰镇、芦河镇、劳动乡、建设乡、新村乡、昌盛乡、柞岗乡、民政乡、德胜乡、迎春乡、连丰乡',
        },
        {
          庆安县:
            '庆安镇、民乐镇、大罗镇、平安镇、勤劳镇、久胜镇、建民乡、巨宝山乡、新胜乡、丰收乡、发展乡、同乐乡、致富乡、欢胜乡',
        },
        {
          明水县:
            '明水镇、兴仁镇、永兴镇、崇德镇、通达镇、双兴乡、永久乡、树人乡、光荣乡、繁荣乡通泉乡、育林乡 ',
        },
        {
          绥棱县:
            '绥棱镇、上集镇、四海店镇、双岔河镇、靠山乡、后头乡、长山乡、克音河乡、绥中乡、泥尔河乡、阁山乡',
        },
      ],
      黑河市: [
        {
          爱辉区:
            '花园街道、兴安街道、海兰街道、西兴街道、西岗子镇、爱辉镇、罕达汽镇、幸福乡、四嘉子满族乡、坤河达斡尔族满族乡、上马厂、张地营子乡、西峰山乡、新生鄂伦春族乡、二站乡',
        },
        {
          北安市:
            '兆麟街道、和平街道、北岗街道、庆华街道、铁西街道、铁南街道、通北镇、赵光镇、海星镇、石泉镇、二井镇、城郊乡、东胜乡、杨家乡、主星朝鲜族乡',
        },
        {
          五大连池市:
            '青山街道、龙镇、和平镇、五大连池镇、新发乡、兴隆乡、建设乡、太平乡、双泉乡、团结乡、兴安乡、朝阳乡',
        },
        {
          嫩江县:
            '嫩江镇、伊拉哈镇、双山镇、多宝山镇、海江镇、前进镇、长福镇、科洛镇、临江乡、联兴乡、白云乡、塔溪乡、霍龙门乡、长江乡',
        },
        {
          逊克县:
            '克街道、边疆镇、逊河镇、干岔子乡、松树沟乡、车陆乡、新鄂鄂伦春族乡、新兴鄂伦春族乡、克林乡、宝山乡',
        },
        {
          孙吴县:
            '城区街道、孙吴镇、辰清镇、西兴乡、沿江满族乡、腰屯乡、卧牛河乡群山乡、奋斗乡、红旗乡、正阳山乡',
        },
      ],
      大兴安岭地区: [
        {
          呼玛县:
            '呼玛镇、韩家园镇、三卡乡、金山乡、兴华乡、鸥浦乡、白银纳鄂伦春族乡、北疆乡',
        },
        {
          塔河县:
            '塔河镇、瓦拉干镇、盘古镇、十八站鄂伦春族乡、依西肯乡、开库康乡',
        },
        { 漠河县: '西林吉镇、图强镇、劲涛镇、兴安镇、北极乡' },
        {
          加格达奇区:
            '东山街道、卫东街道、红旗街道、长虹街道、曙光街道、光明街道、加北乡、白桦乡',
        },
        { 松岭区: '小扬气镇、劲松镇、古源镇' },
        {
          新林区: '新林镇、翠岗镇、塔源镇、大乌苏镇、塔尔根镇、碧洲镇、宏图镇',
        },
        { 呼中区: '呼中镇、碧水镇、呼源镇、宏伟镇' },
      ],
    },
  ],
  江苏省: [
    {
      南京市: [
        {
          玄武区:
            '梅园街道、新街口街道、玄武门街道、后宰门街道、锁金村街道、红山街道、孝陵卫街道、玄武湖街道',
        },
        {
          白下区:
            '五老村街道、建康路街道、洪武路街道、大光路街道、瑞金路街道、月牙湖街道、光华路街道、朝天宫街道、止马营街道 ',
        },
        { 秦淮区: '秦虹街道、夫子庙街道、双塘街道、红花街道、中华门街道' },
        {
          建邺区:
            '滨湖街道、沙洲街道、双闸街道、江心洲街道、南湖街道、兴隆街道、南苑街道',
        },
        {
          鼓楼区:
            '宁海路街道、华侨路街道、湖南路街道、中央门街道、挹江门街道、江东街道、莫愁街道',
        },
        {
          下关区:
            '阅江楼街道、热河南路街道、幕府山街道、建宁路街道、宝塔桥街道、小市街道',
        },
        {
          浦口区:
            '泰山街道、顶山街道、沿江街道、江浦街道、桥林镇、永宁镇、汤泉镇、星甸镇、石桥镇、乌江镇、盘城镇',
        },
        {
          栖霞区:
            '尧化街道、马群街道、迈皋桥街道、燕子矶街道、栖霞街道、龙潭街道、仙林街道、靖安街道、八卦洲街道',
        },
        {
          雨花台区:
            '宁南街道、赛虹桥街道、雨花新村街道、西善桥街道  板桥街道、铁心桥街道、梅山街道',
        },
        {
          江宁区:
            '东山街道、秣陵街道、汤山街道、淳化街道、禄口街道  江宁街道、谷里街道、湖熟镇、横溪镇',
        },
        {
          六合区:
            '卸甲甸街道、西厂门街道、山潘街道、葛塘街道、长芦街道、雄州街道、龙池街道、冶山镇、八百桥镇、横梁镇、东沟镇、龙袍镇、玉带镇、瓜埠镇、程桥镇、竹镇镇、马集镇、马鞍镇、新篁镇',
        },
        {
          溧水县:
            '永阳镇、白马镇、东屏镇、柘塘镇、石湫镇、洪蓝镇、晶桥镇、和凤镇',
        },
        {
          高淳县:
            '淳溪镇、阳江镇、砖墙镇、古柏镇、漆桥镇、固城镇、东坝镇、桠溪镇',
        },
      ],
      无锡市: [
        {
          崇安区:
            '崇安寺街道、通江街道、广瑞路街道、上马墩街道、江海街道、广益街道',
        },
        {
          南长区:
            '迎龙桥街道、南禅寺街道、清名桥街道、金星街道、金匮街道、扬名街道',
        },
        { 北塘区: '黄巷街道、山北街道、北大街街道、惠山街道' },
        {
          滨湖区:
            '河埒街道、旺庄街道、荣巷街道、蠡园街道、蠡湖街道、太湖街道、滨湖街道华庄街道、雪浪街道、南站街道、坊前街道、新安街道、硕放街道、江溪街道、梅村镇、马山镇、胡埭镇、鸿山镇',
        },
        {
          锡山区: '东亭街道、安镇镇、羊尖镇、鹅湖镇、东北塘镇、锡北镇、东港镇',
        },
        {
          惠山区:
            '堰桥街道、长安街道、钱桥街道、前洲镇、玉祁镇、洛社镇、阳山镇',
        },
        {
          江阴市:
            '澄江街道、璜土镇、利港镇、申港镇、夏港镇、月城镇、青阳镇、徐霞客镇南闸镇、云亭镇、华士镇、周庄镇、新桥镇、长泾镇、顾山镇、祝塘镇',
        },
        {
          宜兴市:
            '宜城街道、屺亭街道、新街街道、新庄街道、张渚镇、西渚镇、太华镇、徐舍镇、官林镇、杨巷镇、新建镇、和桥镇、高塍镇、万石镇、周铁镇、芳桥镇、丁蜀镇、湖父镇',
        },
      ],
      徐州市: [
        {
          鼓楼区:
            '黄楼街道、丰财街道、琵琶街道、牌楼街道、铜沛街道、环城街道、金山桥街道、东环街道、大黄山街道、大庙镇、大黄山镇 ',
        },
        {
          云龙区:
            '彭城街道、子房街道、黄山街道、骆驼山街道、大郭庄街道、翠屏山街道、潘塘街道',
        },
        {
          九里区:
            '庞庄街道、利国街道、桃园街道、三河尖街道、垞城街道、义安街道、张集街道、电厂街道、张双楼街道、九里街道、拾屯街道、苏山街道、火花街道',
        },
        {
          贾汪区:
            '老矿街道、夏桥街道、贾汪镇、青山泉镇、大吴镇、塔山镇、汴塘镇、紫庄镇、江庄镇',
        },
        {
          泉山区:
            '王陵街道、永安街道、湖滨街道、段庄街道、翟山街道、奎山街道、和平街道、泰山街道、金山街道、七里沟街道',
        },
        {
          新沂市:
            '新安镇、瓦窑镇、港头镇、唐店镇、合沟镇  草桥镇窑湾镇、棋盘镇、王庄镇、新店镇、邵店镇、北沟镇、时集镇、高流镇、阿湖镇、双塘镇 ',
        },
        {
          邳州市:
            '运河镇、邳城镇、官湖镇、四户镇、宿羊山镇八义集镇、土山镇、碾庄镇、港上镇  邹庄镇、占城镇、新河镇八路镇、炮车镇、铁富镇、岔河镇、戴圩镇、陈楼镇、邢楼镇、戴庄镇、车辐山镇、燕子埠镇、赵墩镇、议堂镇',
        },
        {
          丰县: '凤城镇、首羡镇、顺河镇、常店镇、欢口镇、师寨镇、华山镇、梁寨镇、范楼镇、孙楼镇、宋楼镇、大沙河镇、王沟镇、赵庄镇',
        },
        {
          沛县: '龙固镇、杨屯镇、大屯镇、沛城镇、胡寨镇、魏庙镇、五段镇、张庄镇、张寨镇、敬安镇、河口镇、栖山镇、鹿楼镇、朱寨镇安国镇',
        },
        {
          铜山县:
            '铜山镇、何桥镇、黄集镇、马坡镇、郑集镇、柳新镇、刘集镇、大彭镇、汉王镇、三堡镇、棠张镇、张集镇、房村镇、伊庄镇、单集镇、徐庄镇、大许镇、茅村镇、柳泉镇、利国镇',
        },
        {
          睢宁县:
            '睢城镇、王集镇、双沟镇、岚山镇、李集镇、桃园镇、官山镇、高作镇、沙集镇、凌城镇、邱集镇、古邳镇、姚集镇、魏集镇、梁集镇、庆安镇',
        },
      ],
      常州市: [
        {
          天宁区: '雕庄街道、青龙街道、茶山街道、红梅街道、兰陵街道、天宁街道',
        },
        { 戚墅堰区: '戚墅堰街道、丁堰街道、潞城街道' },
        {
          钟楼区:
            '五星街道、永红街道、北港街道、西林街道、南大街街道、荷花池街道、新闸街道',
        },
        {
          新北区:
            '河海街道、三井街道、龙虎塘街道、春江镇、孟河镇、新桥镇、薛家镇、罗溪镇、西夏墅镇',
        },
        {
          武进区:
            '南夏墅街道、湖塘镇、牛塘镇、洛阳镇、遥观镇、横林镇、横山桥镇、郑陆镇、雪堰镇、前黄镇、礼嘉镇、邹区镇、嘉泽镇、湟里镇、奔牛镇',
        },
        {
          溧阳市:
            '溧城镇、埭头镇、上黄镇、戴埠镇、天目湖镇、别桥镇、上兴镇、竹箦镇、南渡镇、社渚镇',
        },
        { 金坛市: '金城镇、儒林镇、尧塘镇、直溪镇、朱林镇、薛埠镇、指前镇' },
      ],
      苏州市: [
        {
          沧浪区:
            '双塔街道、南门街道、胥江街道、吴门桥街道、葑门街道、友新街道 ',
        },
        { 金阊区: '金阊区' },
        {
          平江区:
            '观前街道、平江路街道、苏锦街道、娄门街道、城北街道、桃花坞街道',
        },
        {
          虎丘区:
            '横塘街道、狮山街道、枫桥街道、镇湖街道、浒墅关镇、通安镇、东渚镇',
        },
        {
          吴中区:
            '长桥街道、越溪街道、郭巷街道、横泾街道、香山街道、苏苑街道、龙西街道、城南街道、甪直镇、木渎镇、胥口镇、东山镇、光福镇、西山镇、临湖镇',
        },
        {
          相城区:
            '元和街道、黄桥街道、太平街道、北桥街道、望亭镇、黄埭镇、渭塘镇、阳澄湖镇',
        },
        {
          常熟市:
            '虞山镇、梅李镇、海虞镇、新港镇、古里镇、沙家浜镇、支塘镇、董浜镇、尚湖镇、辛庄镇',
        },
        {
          张家港市:
            '杨舍镇、塘桥镇、金港镇、锦丰镇、乐余镇、凤凰镇、南丰镇、大新镇',
        },
        {
          昆山市:
            '玉山镇、巴城镇、周市镇、陆家镇、花桥镇、淀山湖镇、张浦镇  周庄镇、千灯镇、锦溪镇',
        },
        {
          吴江市:
            '松陵镇、同里镇、汾湖镇、平望镇、盛泽镇、横扇镇、七都镇、震泽镇、桃源镇',
        },
        { 太仓市: '城厢镇、沙溪镇、浏河镇、璜泾镇、双凤镇、浮桥镇、陆渡镇' },
      ],
      南通市: [
        {
          崇川区:
            '城东街道、和平桥街道、任港街道、新城桥街道、虹桥街道、学田街道、钟秀街道、文峰街道、中兴街道、观音山镇、狼山镇、新开镇、竹行镇、小海镇 ',
        },
        {
          港闸区:
            '永兴街道、唐闸镇街道、天生港镇街道、秦灶街道、陈桥乡、幸福乡',
        },
        {
          启东市:
            '汇龙镇、大兴镇、南阳镇、惠丰镇、民主镇、北新镇、久隆镇、王鲍镇、合作镇、志良镇、吕四港镇、天汾镇、兆民镇、海复镇、少直镇、向阳镇、东元镇、近海镇、寅阳镇、和合镇、惠萍镇、新安镇、东海镇、启隆乡',
        },
        {
          如皋市:
            '如城镇、柴湾镇、雪岸镇、东陈镇、丁堰镇、白蒲镇、林梓镇、下原镇、九华镇、郭园镇、石庄镇、长江镇、吴窑镇、江安镇、高明镇、常青镇、搬经镇、磨头镇、桃园镇、袁桥镇 ',
        },
        {
          通州市:
            '金沙镇、西亭镇、二甲镇、东社镇、三余镇、十总镇、骑岸镇、五甲镇、石港镇、四安镇 、刘桥镇、平潮镇、平东镇、五接镇、兴仁镇、兴东镇、张芝山镇、川港镇、先锋镇、姜灶镇',
        },
        {
          海门市:
            '门镇、三星镇、天补镇、三和镇、德胜镇、三厂镇、常乐镇、麒麟镇、悦来镇、万年镇、三阳镇 、四甲镇、货隆镇、余东镇、正余镇、包场镇、刘浩镇、东灶港镇、临江镇、王浩镇、树勋镇、海永乡',
        },
        {
          海安县:
            '海安镇、城东镇、曲塘镇、李堡镇、老坝港镇、角斜镇、西场镇、大公镇、孙庄镇、雅周镇  胡集镇、白甸镇、南莫镇、墩头镇',
        },
        {
          如东县:
            '栟茶镇、洋口镇、苴镇、长沙镇、大豫镇、掘港镇、兵房镇、丰利镇、曹埠镇、岔河镇、新店镇、河口镇、袁庄镇、马塘镇、双甸镇',
        },
      ],
      连云港市: [
        {
          新浦区:
            '浦东街道、浦西街道、新东街道、新南街道、路南街道、新海街道、南城镇、云台乡、花果山乡、宁海乡',
        },
        {
          连云区:
            '墟沟街道、连云街道、云山街道、板桥街道、连岛街道、中云街道、猴嘴街道、朝阳镇、宿城乡、高公岛乡、前三岛乡',
        },
        { 海州区: '海州街道、朐阳街道、洪门街道、幸福路街道、新坝镇、锦屏镇' },
        {
          赣榆县:
            '黑林镇、门河镇、欢墩镇、班庄镇、沙河镇、城西镇、塔山镇、城头镇、厉庄镇、金山镇、赣马镇、墩尚镇、罗阳镇、宋庄镇、石桥镇、柘汪镇、海头镇、青口镇',
        },
        {
          东海县:
            '牛山镇、白塔埠镇、浦南镇、黄川镇、石梁河镇、青湖镇、石榴镇、温泉镇、双店镇、桃林镇、洪庄镇、、安峰镇、房山镇、平明镇、驼峰乡、南辰乡、横沟乡、李埝乡、山左口乡、石湖乡、曲阳乡、张湾乡',
        },
        {
          灌云县:
            '伊山镇、板浦镇、杨集镇、燕尾港镇、同兴镇、四队镇、圩丰镇、龙苴镇、伊芦乡、图河乡、沂北乡、下车乡、白蚬乡、东王集乡、侍庄乡、小伊乡、穆圩乡、陡沟乡、南岗乡、鲁河乡',
        },
        {
          灌南县:
            '新安镇、堆沟港镇、长茂镇、北陈集镇、张店镇、三口镇、孟兴庄镇、汤沟镇、百禄镇、五队乡、田楼乡、李集乡、新集乡、花园乡',
        },
      ],
      淮安市: [
        {
          清河区:
            '北京路街道、长西街道、淮海街道、长东街道、西安路街道、水渡口街道、东湖街道、新港街道、广州街道、京沪路办事处、钵池乡、徐扬乡',
        },
        {
          清浦区:
            '清江街道、浦楼街道、闸口街道、清安街道、武墩镇、盐河镇、和平镇、城南乡、黄码乡',
        },
        {
          楚州区:
            '淮城镇、平桥镇、上河镇、马甸镇、朱桥镇、溪河镇、施河镇、车桥镇、泾口镇、流均镇、博里镇、仇桥镇、复兴镇、苏嘴镇、钦工镇、顺河镇、季桥镇、席桥镇、林集镇、南闸镇、范集镇、建淮乡、茭陵乡、宋集乡、城东乡  南马厂乡  三堡乡',
        },
        {
          淮阴区:
            '王营镇、赵集镇、吴城镇、南陈集镇、码头镇、王兴镇  棉花庄镇、丁集镇、五里镇、徐溜、 渔沟镇、吴集镇、西宋集镇、三树镇、韩桥乡、新渡乡、老张集乡、凌桥乡、袁集乡、刘老庄乡、古寨乡',
        },
        {
          涟水县:
            '涟城镇、高沟镇、唐集镇、保滩镇、大东镇、五港镇、梁岔镇、石湖镇、朱码镇、岔庙镇、东胡集镇、南集镇、义兴镇、成集镇、红窑镇、陈师镇、前进镇、徐集乡、黄营乡',
        },
        {
          洪泽县:
            '高良涧镇、蒋坝镇、仁和镇、岔河镇、西顺河镇、老子山镇、三河镇、朱坝镇、黄集镇、万集镇、东双沟镇、共和镇 ',
        },
        {
          盱眙县:
            '盱城镇、马坝镇、官滩镇、旧铺镇、桂五镇、管镇镇、河桥镇、鲍集镇黄花塘镇、明祖陵镇、铁佛镇、淮河镇、仇集镇、观音寺镇、维桥乡、穆店乡、王店乡、古桑乡、兴隆乡',
        },
        {
          金湖县:
            '黎城镇、金南镇、闵桥镇、塔集镇、银集镇、涂沟镇、前锋镇、吕良镇、陈桥镇、金北镇、戴楼镇',
        },
      ],
      盐城市: [
        {
          亭湖区:
            '毓龙街道、先锋街道、文峰街道、大洋街道、五星街道、黄海街道、新城街道、新洋街道、伍佑街道、南洋镇、新兴镇、步凤镇、便仓镇、盐东镇、黄尖镇',
        },
        {
          盐都区:
            '新都街道、盐渎街道、盐龙街道、潘黄街道、张庄街道、楼王镇、尚庄镇、秦南镇、学富镇、龙冈镇、郭猛镇、大冈镇、大纵湖镇',
        },
        {
          大丰区:
            '大中镇、新丰镇、草堰镇、白驹镇、刘庄镇、小海镇、西团镇、大桥镇、草庙镇、万盈镇、南阳镇、三龙镇',
        },
        {
          滨海县:
            '东坎镇、八滩镇、五汛镇、蔡桥镇、正红镇、通榆镇、界牌镇、八巨镇、滨淮镇、天场镇、陈涛镇、滨海港镇',
        },
        {
          射阳县:
            '合德镇、特庸镇、洋马镇、临海镇、千秋镇、四明镇、海河镇、海通镇、兴桥镇、新坍镇、长荡镇、盘湾镇、黄沙港镇',
        },
        {
          阜宁县:
            '阜城街道、新城街道、花园街道、吴滩街道、沟墩镇、陈良镇、三灶镇、郭墅镇、新沟镇、陈集镇、羊寨镇、板湖镇、东沟镇、益林镇、古河镇、罗桥镇、芦蒲镇',
        },
        {
          建湖县:
            '近湖街道、钟庄街道、塘河街道建阳镇、恒济镇、颜单镇、沿河镇、芦沟镇、庆丰镇、上冈镇、冈西镇、宝塔镇、高作镇、九龙口镇',
        },
        {
          响水县:
            '响水镇、黄圩镇、南河镇、大有镇、运河镇、双港镇、小尖镇、陈家港镇',
        },
        {
          东台市:
            '东台镇、弶港镇、五烈镇、时堰镇、梁垛镇、头灶镇、富安镇、溱东镇、安丰镇、新街镇、唐洋镇、许河镇、三仓镇、南沈灶镇',
        },
      ],
      扬州市: [
        {
          广陵区:
            '东关街道、汶河街道、文峰街道、曲江街道、湾头镇、头桥镇、李典镇、沙头镇、杭集镇、泰安镇、汤汪乡',
        },
        {
          邗江区:
            '梅岭街道、邗上街道、蒋王街道、汊河街道、、双桥街道、瘦西湖街道、新盛街道、甘泉街道、竹西街道、西湖镇、瓜洲镇、公道镇、方巷镇、槐泗镇、杨寿镇、杨庙镇、城北乡、平山乡、双桥乡',
        },
        {
          江都区:
            '仙女镇、邵伯镇、小纪镇、郭村镇、大桥镇、宜陵镇、武坚镇、樊川镇、真武镇、丁沟镇、丁伙镇、浦头镇、吴桥镇',
        },
        { 开发区: '文汇街道、扬子津街道、施桥镇、八里镇、朴席镇' },
        {
          宝应县:
            '安宜镇、范水镇、夏集镇、柳堡镇、射阳湖镇、广洋湖镇、鲁垛镇、小官庄镇、望直港镇、曹甸镇、西安丰镇、山阳镇、黄塍镇、泾河镇',
        },
        {
          仪征市:
            '真州镇、青山镇、马集镇、新城镇、新集镇、刘集镇、大仪镇、陈集镇、月塘镇',
        },
        {
          高邮市:
            '高邮街道、马棚街道、送桥镇、三垛镇、卸甲镇、界首镇、临泽镇、甘垛镇、龙虬镇、汤庄镇、车逻镇、周山镇、菱塘回族乡',
        },
      ],
      镇江市: [
        {
          京口区:
            '丹徒镇、谏壁街道、象山街道、大港镇、正东路街道、健康路街道、大市口街道、四牌楼街道、丁卯街道、大港街道',
        },
        {
          润州区:
            '宝塔路街道、和平路街道、中华路街道、京畿路街道、七里甸镇、蒋乔镇、官塘桥镇、民营经济开发区',
        },
        {
          丹徒区:
            '高桥镇、辛丰镇、谷阳镇、上党镇、宝堰镇、世业镇、江心洲生态农业园区、荣炳盐资源区、宜城街道、高资街道',
        },
        { 镇江新区: '丁岗镇、大路镇、姚桥镇、大港街道、丁卯街道' },
        {
          句容市:
            '崇明街道、华阳街道、黄梅镇、下蜀镇、宝华镇、边城镇、白兔镇、郭庄镇、后白镇、天王镇、茅山镇、市经济开发区管委会、市赤山湖风景区管委会、市茅山风景区管委会',
        },
        {
          丹阳市:
            '司徒镇、访仙镇皇塘镇、导墅镇、延陵镇、界牌镇、陵口镇、吕城镇、珥陵镇、丹北镇、云阳街道、丹阳经济开发区',
        },
        { 扬中市: '三茅街道、兴隆街道、新坝镇、油坊镇、八桥镇、西来桥镇' },
      ],
      泰州市: [
        {
          海陵区:
            '九龙镇、苏陈镇、罡杨镇、京泰路街道、城中街道、城东街道、城南街道、城西街道、城北街道、海陵工业园区',
        },
        {
          高港区:
            '永安洲镇、大泗镇、白马镇、胡庄镇、口岸街道办事处、刁铺街道办事处、许庄街道办事处',
        },
        {
          姜堰区:
            '罗塘街道、三水街道、溱潼镇、顾高镇、蒋垛镇、大伦镇、白米镇、娄庄镇、沈高镇、俞垛镇、兴泰镇、张甸镇、淤溪镇、梁徐镇、桥头镇、华港镇、经济开发区',
        },
        {
          兴化市:
            '经济开发区、戴窑镇、合陈镇、永丰镇、新垛镇、安丰镇、海南镇、钓鱼镇、大邹镇、沙沟镇、中堡镇、李中镇、西郊镇、临城镇、垛田镇、竹泓镇、沈沦镇、大垛镇、荻垛镇、陶庄镇、昌荣镇、茅山镇、周庄镇、陈堡镇、戴南镇、张郭镇、昭阳镇、大营镇、下圩镇、城东镇、老圩乡、周奋乡、缸顾乡、西鲍乡、林湖乡',
        },
        {
          泰兴市:
            '黄桥镇、分界镇、古溪镇、元竹镇、珊瑚镇、曲霞镇、张桥镇、河失镇、新街镇、姚王镇、宣堡镇、虹桥镇、滨江镇、济川街道、根思乡',
        },
        {
          靖江市:
            '靖城街道、新桥镇、东兴镇、斜桥镇、西来镇、季市镇、孤山镇、生祠镇、马桥镇',
        },
      ],
      宿迁市: [
        {
          宿城区:
            '幸福街道、项里街道、河滨街道、古城街道、王官集镇、蔡集镇、双庄镇、耿车镇、埠子镇、龙河镇、洋北镇、中扬镇、陈集镇、罗圩乡、屠园乡',
        },
        {
          宿豫区:
            '顺河街道、下相街道、豫新街道、仰化镇、大兴镇、丁嘴镇、来龙镇、陆集镇、关庙镇、侍岭镇、新庄镇、曹集乡、保安乡',
        },
        {
          沭阳县:
            '沭阳县经济技术开发区、沭城街道、南湖街道、梦溪街道、十字街道、七雄街道、章集街道、陇集镇、胡集镇、钱集镇、塘沟镇、马厂镇、沂涛镇、庙头镇、韩山镇、华冲镇、桑墟镇、悦来镇、刘集镇、李恒镇、扎下镇、颜集镇、潼阳镇、龙庙镇、高墟镇、耿圩镇、汤涧镇、新河镇、贤官镇、吴集镇、湖东镇、青伊湖镇、北丁集乡、周集乡、东小店乡、张圩乡、茆圩乡、西圩乡、万匹乡、官墩乡、青伊湖农场',
        },
        {
          泗阳县:
            '城厢街道、来安街道、史集街道、众兴镇、爱园镇、王集新城、新袁新城、裴圩镇、李口镇、临河镇、穿城镇、张家圩镇、高渡镇、卢集镇、庄圩乡、里仁乡、三庄乡、南刘集乡、八集乡、泗阳农场、泗阳棉花原种场',
        },
        {
          泗洪县:
            '青阳镇、双沟镇、上塘镇、魏营镇、临淮镇、半城镇、孙园镇、梅花镇、归仁镇、金锁镇、朱湖镇、界集镇、太平镇、龙集镇、四河乡、峰山乡、天岗湖乡、车门乡、瑶沟乡、石集乡、城头乡、陈圩乡、曹庙乡',
        },
        { 洋河新区: '郑楼镇、洋河镇、仓集镇' },
        { 湖滨新区: '晓店镇、井头乡、皂河镇、黄墩镇、祥和社区、嶂山林场' },
        { 苏宿工业园区: '苏宿工业园区' },
        { 经济开发区: '南蔡乡、三棵树乡、黄河街道、古楚街道' },
      ],
    },
  ],
  浙江省: [
    {
      杭州市: [
        {
          拱墅区:
            '米市巷街道、湖墅街道、小河街道、和睦街道、拱宸桥街道、大关街道、半山街道、康桥街道、上塘街道、祥符街道',
        },
        {
          上城区: '清波街道、湖滨街道、小营街道、望江街道、南星街道、紫阳街道',
        },
        {
          江干区:
            '凯旋街道、采荷街道、白杨街道、下沙街道、闸弄口街道、四季青街道、笕桥街道、彭埠街道、九堡街道、丁兰街道',
        },
        {
          西湖区:
            '西湖街道、北山街道、西溪街道、灵隐街道、翠苑街道、文新街道、古荡街道、转塘街道、留下街道、蒋村街道、三墩镇、双浦镇',
        },
        { 滨江区: '浦沿街道、西兴街道、长河街道' },
        {
          萧山区:
            '城厢街道、北干街道、蜀山街道、新塘街道、靖江街道、南阳街道、河庄街道、义蓬街道、新湾街道、临江街道、前进街道、新街街道、宁围街道、闻堰街道、临浦镇、义桥镇、戴村镇、河上镇、楼塔镇、浦阳镇、进化镇、瓜沥镇、衙前镇、党湾镇、益农镇、所前镇',
        },
        {
          余杭区:
            '临平街道、南苑街道、东湖街道、星桥街道、五常街道、乔司街道、崇贤街道、余杭街道、闲林街道、仓前街道、良渚街道、运河街道、仁和街道、中泰街道、塘栖镇、瓶窑镇、鸬鸟镇、黄湖镇、百丈镇、径山镇',
        },
        {
          富阳区:
            '富春街道、东洲街道、春江街道、鹿山街道、银湖街道、场口镇、常安镇、万市镇、洞桥镇、胥口镇、新登镇、渌渚镇、灵桥镇、大源镇、常绿镇、龙门镇、里山镇、永昌镇、环山乡、湖源乡、上官乡、渔山乡、春建乡、新桐乡',
        },
        {
          桐庐县:
            '桐君街道、城南街道、旧县街道、凤川街道、富春江镇、横村镇、分水镇、百江镇、瑶琳镇、江南镇、钟山乡、新合乡、合村乡、莪山畲族乡',
        },
        {
          淳安县:
            '千岛湖镇、临岐镇、威坪镇、姜家镇、汾口镇、大墅镇、梓桐镇、中洲镇、文昌镇、石林镇、枫树岭镇、屏门乡、浪川乡、安阳乡、王阜乡、里商乡、金峰乡、瑶山乡、宋村乡、鸠坑乡、左口乡、富文乡、界首乡',
        },
        {
          建德市:
            '洋溪街道、更楼街道、新安江街道、梅城镇、下涯镇、杨村桥镇、乾潭镇、三都镇、寿昌镇、大同镇、航头镇、李家镇、大洋镇、莲花镇、大慈岩镇、钦堂乡',
        },
        {
          临安市:
            '锦城街道、锦北街道、锦南街道、玲珑街道、青山湖街道、板桥镇、高虹镇、太湖源镇、於潜镇、天目山镇、太阳镇、潜川镇、昌化镇、龙岗镇、河桥镇、湍口镇、清凉峰镇、岛石镇',
        },
        {
          下城区:
            '武林街道、天水街道、朝晖街道、潮鸣街道、长庆街道、石桥街道、东新街道、文晖街道',
        },
      ],
      宁波市: [
        {
          海曙区:
            '灵塔街道、月湖街道、鼓楼街道、南门街道、西门街道、白云街道、段塘街道、望春街道',
        },
        {
          江东区:
            '百丈街道、东胜街道、明楼街道、白鹤街道、东柳街道、东郊街道、福明街道',
        },
        {
          江北区:
            '中马街道、白沙街道、孔浦街道、文教街道、甬江街道、庄桥街道、洪塘街道、慈城镇',
        },
        {
          北仑区:
            '柴桥街道、大碶街道、大榭街道、戚家山街道、小港街道、霞浦街道、新碶街道、春晓街道、梅山街道、白峰镇',
        },
        {
          镇海区: '蛟川街道、骆驼街道、庄市街道、招宝山街道、澥浦镇、九龙湖镇',
        },
        {
          鄞州区:
            '钟公庙街道、下应街道、石碶街道、潘火街道、首南街道、中河街道、梅墟街道、咸祥镇、邱隘镇、五乡镇、云龙镇、横溪镇、姜山镇、古林镇、横街镇、鄞江镇、章水镇、瞻岐镇、东吴镇、高桥镇、塘溪镇、洞桥镇、集士港镇、东钱湖镇、龙观乡',
        },
        {
          余姚市:
            '凤山街道、阳明街道、梨洲街道、兰江街道、朗霞街道、低塘街道、临山镇、泗门镇、马渚镇、牟山镇、丈亭镇、梁弄镇、陆埠镇、大隐镇、大岚镇、河姆渡镇、四明山镇、小曹娥镇、黄家埠镇、三七市镇、鹿亭乡',
        },
        {
          慈溪市:
            '浒山街道、古塘街道、白沙路街道、宗汉街道、坎墩街道、龙山镇、掌起镇、观海卫镇、附海镇、桥头镇、匡堰镇、逍林镇、新浦镇、胜山镇、横河镇、崇寿镇、庵东镇、天元镇、长河镇、周巷镇',
        },
        {
          奉化市:
            '锦屏街道、岳林街道、江口街道、西坞街道、萧王庙街道、溪口镇、尚田镇、莼湖镇、裘村镇、大堰镇、松岙镇',
        },
        {
          象山县:
            '丹东街道、丹西街道、爵溪街道、石浦镇、西周镇、贤庠镇、墙头镇、涂茨镇、大徐镇、泗洲头镇、定塘镇、新桥镇、鹤浦镇、黄避岙乡、茅洋乡、东陈乡、高塘岛乡、晓塘乡',
        },
        {
          宁海县:
            '跃龙街道、桃源街道、梅林街道、桥头胡街道、长街镇、力洋镇、一市镇、前童镇、桑洲镇、黄坛镇、强蛟镇、西店镇、深甽镇、岔路镇、大佳何镇、胡陈乡、茶院乡、越溪乡',
        },
      ],
      温州市: [
        {
          鹿城区:
            '五马街道、松台街道、滨江街道、南汇街道、七都街道、双屿街道、仰义街道、藤桥镇',
        },
        {
          龙湾区:
            '永中街道、永兴街道、海滨街道、蒲州街道、海城街道、状元街道、瑶溪街道、沙城街道、天河街道、星海街道',
        },
        {
          瓯海区:
            '景山街道、梧田街道、茶山街道、南白象街道、新桥街道、娄桥街道、三垟街道、瞿溪街道、潘桥街道、郭溪街道、丽岙街道、仙岩街道、泽雅镇',
        },
        {
          洞头区:
            '北岙街道、东屏街道、霓屿街道、元觉街道、灵昆街道、大门镇、鹿西乡',
        },
        {
          瑞安市:
            '安阳街道、玉海街道、锦湖街道、东山街道、上望街道、汀田街道、飞云街道、南滨街道、仙降街道、莘塍街道、塘下镇、马屿镇、陶山镇、湖岭镇、高楼镇',
        },
        {
          乐清市:
            '大荆镇、仙溪镇、雁荡镇、芙蓉镇、清江镇、虹桥镇、淡溪镇、柳市镇、北白象镇、天成街道、石帆街道、翁垟街道、乐成街道、白石街道、城南街道、城东街道、盐盆街道',
        },
        {
          永嘉县:
            '北城街道、南城街道、东城街道、江北街道、东瓯街道、三江街道、黄田街道、乌牛街道、桥头镇、桥下镇、沙头镇、碧莲镇、巽宅镇、岩头镇、枫林镇、岩坦镇、大箬岩镇、鹤盛镇',
        },
        {
          平阳县:
            '昆阳镇、鳌江镇、水头镇、萧江镇、万全镇、腾蛟镇、山门镇、顺溪镇、南雁镇、青街畲族乡',
        },
        {
          苍南县:
            '  灵溪镇、龙港镇、宜山镇、钱库镇、藻溪镇、桥墩镇、金乡镇、矾山镇、赤溪镇、马站镇、凤阳畲族乡、岱岭畲族乡',
        },
        {
          文成县:
            '大峃镇、龙川乡、樟台乡、金炉乡、里阳乡、珊溪镇、巨屿镇、仰山乡、桂山乡、峃口乡、双桂乡、金垟乡、平和乡、周山畲族乡、公阳乡、玉壶镇、周壤乡、东溪乡、上林乡、金星乡、朱雅乡、南田镇、百丈漈镇、二源乡、十源乡、黄寮乡、黄坦镇、富岙乡、云湖乡、西坑畲族镇、石垟乡、下垟乡、岭后乡',
        },
        {
          泰顺县:
            '罗阳镇、百丈镇、泗溪镇、雅阳镇、仕阳镇、三魁镇、筱村镇、彭溪镇、司前畲族镇、竹里畲族乡',
        },
      ],
      绍兴市: [
        {
          越城区:
            '塔山街道、府山街道、北海街道、蕺山街道、城南街道、迪荡街道、稽山街道、灵芝镇、皋埠镇、东湖镇、东浦镇、马山镇、斗门镇、陶堰镇、富盛镇、孙端镇',
        },
        {
          柯桥区:
            '柯桥街道、柯岩街道、湖塘街道、华舍街道、福全镇、兰亭镇、漓渚镇、夏履镇、杨讯桥镇、安昌镇、齐贤镇、马鞍镇、平水镇、王坛镇、稽东镇、钱清镇',
        },
        {
          上虞区:
            '百官街道、曹娥街道、东关街道、道墟镇、上浦镇、汤浦镇、章镇镇、下管镇、丰惠镇、永和镇、梁湖镇、驿亭镇、崧厦镇、沥海镇、小越镇、、谢塘镇、长塘镇、盖北镇；岭南乡、陈溪乡、丁宅乡',
        },
        {
          新昌县:
            '羽林街道、南明街道、七星街道、沙溪镇、儒岙镇、回山镇、澄潭镇、小将镇、镜岭镇、梅渚镇、大市聚镇；双彩乡、巧英乡、东茗乡、新林乡、城南乡',
        },
        {
          嵊州市:
            '三江街道、鹿山街道、剡湖街道、浦口街道；甘霖镇、仙岩镇、长乐镇、崇仁镇、石璜镇、三界镇、下王镇、黄泽镇、北漳镇、金庭镇、谷来镇；雅璜乡、通源乡、王院乡、竹溪乡、贵门乡、里南乡',
        },
        {
          诸暨市:
            '暨阳街道、浣东街道、陶朱街道；大唐镇、次坞镇、店口镇、阮市镇、江藻镇、枫桥镇、赵家镇、马剑镇、草塔镇、牌头镇、同山镇、安华镇、街亭镇、璜山镇、浬浦镇、直埠镇、五泄镇、岭北镇、陈宅镇、王家井镇、应店街镇、山下湖镇、东白湖镇、东和乡',
        },
      ],
      湖州市: [
        {
          吴兴区:
            '月河街道、朝阳街道、爱山街道、飞英街道、龙泉街道、凤凰街道、康山街道、仁皇山街道、环渚街道、滨湖街道、织里镇、八里店镇、妙西镇、杨家埠镇、埭溪镇、东林镇、道场乡',
        },
        {
          南浔区:
            '南浔经济开发区、国际旅游度假区、南浔镇、练市镇、双林镇、菱湖镇、和孚镇、善琏镇、旧馆镇、千金镇、石淙镇',
        },
        {
          德清县:
            '武康镇、乾元镇、新市镇、洛舍镇、新安镇、雷甸镇、钟管镇、禹越镇、莫干山镇、筏头乡、三合乡',
        },
        {
          长兴县:
            '雉城街道、画溪街道、太湖街道、煤山镇、夹浦镇、李家巷镇、洪桥镇、泗安镇、和平镇、小浦镇、林城镇、虹星桥镇、水口乡、槐坎乡、白岘乡、吕山乡',
        },
        {
          安吉县:
            '梅溪镇、天子湖镇、鄣吴镇、杭垓镇、孝丰镇、报福镇、章村镇、天荒坪镇、溪龙乡、上墅乡、山川乡、递铺街道、昌硕街道、灵峰街道、孝源街道',
        },
      ],
      嘉兴市: [
        {
          南湖区:
            '新嘉街道、解放街道、建设街道、新兴街道、南湖街道、东栅街道、城南街道、长水街道、七星镇、大桥镇、凤桥镇、余新镇、新丰镇',
        },
        {
          秀洲区:
            '新城街道、高照街道、嘉北街道、塘汇街道、王店镇、洪合镇、新塍镇、王江泾镇、油车港镇',
        },
        {
          嘉善县:
            '魏塘街道、罗星街道、惠民街道、西塘镇、姚庄镇、大云镇、陶庄镇、干窑镇、天凝镇',
        },
        {
          海盐县:
            '武原街道、秦山街道、元通街道、西塘桥街道、沈荡镇、百步镇、于城镇、澉浦镇、通元镇',
        },
        {
          海宁市:
            '硖石街道、海洲街道、海昌街道、马桥街道、许村镇、长安镇、周王庙镇、盐官镇、 斜桥镇、丁桥镇、袁花镇、黄湾镇',
        },
        {
          平湖市:
            '当湖街道、钟埭街道、曹桥街道、乍浦镇、新埭镇、新仓镇、独山港镇、广陈镇、林埭镇',
        },
        {
          桐乡市:
            '乌镇、濮院镇、屠甸镇、石门镇、河山镇、洲泉镇、大麻镇、崇福镇、高桥镇、梧桐街道、龙翔街道、凤鸣街道、',
        },
      ],
      金华市: [
        {
          婺城区:
            '城东街道、城中街道、城西街道、城北街道、江南街道、西关街道、三江街道、秋滨街道、新狮街道、罗店镇、蒋堂镇、汤溪镇、罗埠镇、雅畈镇、琅琊镇、洋埠镇、安地镇、白龙桥镇、苏孟乡、竹马乡、乾西乡、长山乡、莘畈乡、箬阳乡、沙畈乡、塔石乡、岭上乡',
        },
        {
          金东区:
            '多湖街道、东孝街道、孝顺镇、傅村镇、曹宅镇、澧浦镇、岭下镇、江东镇、塘雅镇、赤松镇、源东乡',
        },
        {
          兰溪市:
            '兰江街道、云山街道、上华街道、永昌街道、赤溪街道、女埠街道、游埠镇、诸葛镇、黄店镇、香溪镇、马涧镇、梅江镇、横溪镇、灵洞乡、水亭畲族乡、柏社乡',
        },
        {
          义乌市:
            '稠城街道、北苑街道、稠江街道、江东街道、后宅街道、城西街道、廿三里街道、上溪镇、义亭镇、佛堂镇、赤岸镇、苏溪镇、大陈镇',
        },
        {
          东阳市:
            '吴宁街道、南市街道、白云街道、江北街道、城东街道、六石街道、巍山镇、虎鹿镇、歌山镇、佐村镇、东阳江镇、湖溪镇、马宅镇、千祥镇、南马镇、画水镇、横店镇、三单乡',
        },
        {
          永康市:
            '芝英镇、石柱镇、前仓镇、舟山镇、古山镇、方岩镇、龙山镇、西溪镇、象珠镇、唐先镇、花街镇、东城街道、西城街道、江南街道',
        },
        {
          浦江县:
            '浦南街道、仙华街道、浦阳街道、黄宅镇、岩头镇、郑宅镇、檀溪镇、杭坪镇、白马镇、郑家坞镇、虞宅乡、大畈乡、中余乡、前吴乡、花桥乡',
        },
        {
          武义县:
            '白洋街道、壶山街道、熟溪街道、履坦镇、桐琴镇、王宅镇、桃溪镇、泉溪镇、新宅镇、茭道镇、柳城畲族镇、大田乡、白姆乡、俞源乡、坦洪乡、西联乡、三港乡、大溪口乡',
        },
        {
          磐安县:
            '安文镇、尚湖镇、方前镇、新渥镇、尖山镇、玉山镇、仁川镇、大盘镇、冷水镇、胡宅乡、窈川乡、双溪乡、深泽乡、双峰乡、盘峰乡、维新乡、高二乡、九和乡、万苍乡',
        },
      ],
      衢州市: [
        {
          柯城区:
            '航埠镇、石梁镇、黄家乡、七里乡、沟溪乡、九华乡、华墅乡、姜家山乡、万田乡、石室乡、信安街道、白云街道、府山街道、荷花街道、双港街道、花园街道、新新街道',
        },
        {
          衢江区:
            '高家镇、杜泽镇、上方镇、峡川镇、莲花镇、全旺镇、大洲镇、后溪镇、廿里镇、湖南镇、黄坛口乡、岭洋乡、灰坪乡、太真乡、双桥乡、周家乡、云溪乡、横路乡、举村乡、樟潭街道、浮石街道',
        },
        {
          龙游县:
            '湖镇、横山镇、塔石镇、小南海镇、溪口镇、詹家镇、模环乡、石佛乡、社阳乡、罗家乡、庙下乡、大街乡、沐尘畲族乡、东华街道、龙洲街道',
        },
        {
          江山市:
            '上余镇、四都镇、贺村镇、清湖镇、坛石镇、大桥镇、淤头镇、新塘边镇、凤林镇、峡口镇、廿八都镇、长台镇、石门镇、碗窑乡、大陈乡、保安乡、塘源口乡、张村乡、双溪口乡、虎山街道、双塔街道',
        },
        {
          常山县:
            '天马镇、辉埠镇、招贤镇、青石镇、芳村镇、白石镇、球川镇、何家乡、宋畈乡、东案乡、大桥头乡、新昌乡、新桥乡、同弓乡',
        },
        {
          开化县:
            '城关镇、华埠镇、马金镇、村头镇、池淮镇、桐村镇、杨林镇、苏庄镇、齐溪镇、林山乡、音坑乡、中村乡、长虹乡、张湾乡、何田乡、塘坞乡、大溪边乡、金村乡',
        },
      ],
      台州市: [
        {
          椒江区:
            '海门街道、白云街道、葭芷街道、洪家街道、下陈街道、前所街道、章安街道、三甲街道、大陈镇',
        },
        {
          黄岩区:
            '东城街道、南城街道、西城街道、北城街道、江口街道、新前街道、澄江街道、高桥街道、宁溪镇、北洋镇、头陀镇、院桥镇、沙埠镇、富山乡、上郑乡、屿头乡、上洋乡、茅畲乡、平田乡',
        },
        {
          路桥区:
            '新桥街道、路南街道、路北街道、螺洋街道、桐屿街道、峰江街道、横街镇、蓬街镇、金清镇、新桥镇',
        },
        {
          临海市:
            '古城街道、大洋街道、江南街道、大田街道、邵家渡街道、东塍镇、杜桥镇、白水洋镇、汛桥镇、桃渚镇、沿江镇、括苍镇、涌泉镇、小芝镇、上盘镇、尤溪镇、河头镇、永丰镇、汇溪镇',
        },
        {
          温岭市:
            '太平街道、城东街道、城西街道、城北街道、横峰街道、大溪镇、泽国镇、新河镇、城南镇、坞根镇、石桥头镇、温峤镇、箬横镇、松门镇、石塘镇、滨海镇',
        },
        {
          玉环县:
            '珠港镇、清港镇、楚门镇、干江镇、芦浦镇、沙门镇、龙溪乡、鸡山乡、海山乡',
        },
        {
          天台县:
            '白鹤镇、石梁镇、街头镇、平桥镇、坦头镇、三合镇、洪畴镇、三州乡、龙溪乡、雷峰乡、南屏乡、泳溪乡、赤城街道、始丰街道、福溪街道',
        },
        {
          仙居县:
            '横溪镇、白塔镇、田市镇、官路镇、下各镇、朱溪镇、埠头镇、安岭乡、溪港乡、湫山乡、皤滩乡、淡竹乡、步路乡、上张乡、广度乡、大战乡、双庙乡、福应街道、南峰街道、安洲街道',
        },
        {
          三门县:
            '海游镇、沙柳镇、珠岙镇、亭旁镇、六敖镇、横渡镇、健跳镇、里浦镇、花桥镇、小雄镇、高枧乡、蛇蟠乡、沿赤乡、泗淋乡',
        },
      ],
      丽水市: [
        {
          莲都区:
            '白云街道、万象街道、紫金街道、富岭街道、岩泉街道、水阁街道、碧湖镇、大港头镇、老竹畲族镇、雅溪镇、联城镇、太平乡、仙渡乡、黄村乡、双黄乡、高溪乡、丽新畲族乡、峰源乡',
        },
        {
          龙泉市:
            '龙渊街道、西街街道、剑池街道、八都镇、上洋镇、小梅镇、查田镇、屏南镇、安仁镇、锦溪镇、住龙镇、兰巨乡、达石乡、宝溪乡、龙南乡、道太乡、岩樟乡、城北乡、竹洋畲族乡',
        },
        {
          青田县:
            '鹤城镇、温溪镇、东源镇、船寮镇、北山镇、山口镇、海口镇、腊口镇、高湖镇、仁庄镇、章村乡、贵岙乡、石溪乡、小舟山乡、祯埠乡、祯旺乡、万山乡、黄洋乡、季宅乡、海溪乡、高市乡、巨浦乡、舒桥乡、万阜乡、汤洋乡、方山乡、吴坑乡、仁宫乡、章旦乡、阜山乡、岭根乡',
        },
        { 云和县: '崇头镇、石塘镇、紧水滩镇、赤石乡、雾溪畲族乡、安溪畲族乡' },
        {
          景宁畲族自治县:
            '鹤溪街道、红星街道、沙湾镇、渤海镇、东坑镇、英川镇、梧桐乡、大均乡、澄照乡、大漈乡、大地乡、家地乡、标溪乡、毛垟乡、秋炉乡、郑坑乡、九龙乡、葛山乡、鸬鹚乡、雁溪乡、梅岐乡、景南乡、外舍管理区',
        },
        {
          庆元县:
            '松源街道、濛洲街道、屏都街道、黄田镇、竹口镇、荷地镇、左溪镇、贤良镇、岭头乡、五大堡乡、淤上乡、安南乡、张村乡、隆宫乡、举水乡、江根乡、百山祖镇、龙溪乡、官塘乡、四山乡',
        },
        {
          缙云县:
            '壶镇镇、新建镇、舒洪镇、大洋镇、东方镇、东渡镇、大源镇、七里乡、前路乡、三溪乡、双溪口乡、溶江乡、胡源乡、方溪乡、石笕乡、五云街道、新碧街道、仙都街道',
        },
        {
          遂昌县:
            '妙高街道、云峰街道、北界镇、大柘镇、石练镇、金竹镇、黄沙腰镇、新路湾镇、王村口镇、焦滩乡、应村乡、湖山乡、濂竹乡、高坪乡、蔡源乡、龙洋乡、西畈乡、按口乡、柘岱口乡、三仁畲族乡',
        },
        {
          松阳县:
            '西屏街道、水南街道、望松街道、古市镇、玉岩镇、象溪镇、大东坝镇、新兴镇、赤寿乡、四都乡、樟溪乡、三都乡、斋坛乡、叶村乡、竹源乡、枫坪乡、裕溪乡、安民乡、板桥畲族乡',
        },
      ],
      舟山市: [
        {
          定海区:
            '解放街道、环南街道、昌国街道、城东街道、盐仓街道、临城街道、双桥镇、小沙镇、金塘镇、白泉镇、岑港镇、马岙镇、干缆镇、长白乡、册子乡、北蝉乡',
        },
        {
          普陀区:
            '沈家门街道、东港街道、朱家尖街道、展茅街道、六横镇、桃花镇、虾峙镇、东极镇、普陀山镇',
        },
        { 岱山县: '高亭镇、衢山镇、东沙镇、岱西镇、长涂镇、岱东镇、秀山乡' },
        { 嵊泗县: '菜园镇、嵊山镇、洋山镇、五龙乡、黄龙乡、枸杞乡、花鸟乡' },
      ],
    },
  ],
  安徽省: [
    {
      合肥市: [
        {
          瑶海区:
            '城东街道、胜利路街道、明光路街道、车站街道、三里街街道、铜陵路街道、七里站街道、大通路街道、和平路街道、红光街道、长淮街道、方庙街道、龙岗综合经济开发区、大兴镇',
        },
        {
          庐阳区:
            '逍遥津街道、三孝口街道、亳州路街道、双岗街道、杏花村街道、海棠街道、杏林街道、四里河街道、林店街道、大杨镇、三十岗乡',
        },
        {
          蜀山区:
            '稻香村街道、南七里站街道、三里庵街道、琥珀山庄街道、西园新村街道、五里墩街道、荷叶地街道、笔架山街道、井岗镇、南岗镇、小庙镇、蜀山经济开发区',
        },
        {
          包河区:
            '常青街道、芜湖路街道、包公街道、骆岗街道、望湖街道、淝河镇、大圩镇、义城街道、烟墩街道、包河工业区、滨湖世纪社区',
        },
        {
          长丰县:
            '水湖镇、双墩镇、岗集镇、下塘镇、吴山镇、杨庙镇、朱巷镇、庄墓镇、罗塘乡、义井乡、左店乡、杜集乡、造甲乡、陶楼乡、双凤开发区',
        },
        {
          肥东县:
            '店埠镇、陈集镇、撮镇镇、梁园镇、桥头集镇、长临河镇、石塘镇、古城镇、八斗镇、元疃镇、白龙镇、包公镇、众兴乡、张集乡、马湖乡、响导乡、杨店乡、牌坊回族满族乡',
        },
        {
          肥西县:
            '上派镇、三河镇、桃花镇、花岗镇、高刘镇、官亭镇、小庙镇、山南镇、丰乐镇、紫蓬镇、高店乡、铭传乡、柿树岗乡、严店乡',
        },
        {
          庐江县:
            '庐城镇、白湖镇、汤池镇、泥河镇、白山镇、盛桥镇、同大镇、金牛镇、石头镇、郭河镇、万山镇、罗河镇、乐桥镇、柯坦镇、冶父山镇、龙桥镇、矾山镇',
        },
        {
          巢湖市:
            '亚父街道、天河街道、凤凰山街道、卧牛山街道、中庙街道、半汤街道、槐林镇、黄麓镇、栏杆集镇、苏湾镇、夏阁镇、散兵镇、银屏镇、柘皋镇、坝镇、烔炀镇、中垾镇、庙岗乡',
        },
        { 高新技术产业开发区: '高新技术产业开发区' },
        {
          经济技术开发区:
            '新港工业园、锦绣社区、莲花社区、芙蓉社区、临湖社区、高刘镇',
        },
        { 新站综合开发试验区: '新站综合开发试验区' },
        { 巢湖经济开发区: '巢湖经济开发区' },
      ],
      芜湖市: [
        {
          鸠江区:
            '四褐山街道、裕溪口街道、湾里街道、官陡街道、清水街道、龙山街道、万春街道、沈巷镇、汤沟镇、二坝镇、白茆镇',
        },
        {
          镜湖区:
            '北京路街道、赭麓街道、镜湖街道、吉和街道、赭山街道、弋矶山街道、汀棠街道、天门山街道、北门街道、东门街道、荆山街道、方村街道',
        },
        {
          弋江区:
            '弋江桥街道、中山南路街道、利民路街道、马塘街道、澛港街道、火龙街道、白马街道、南瑞党工委',
        },
        { 三山区: '峨桥镇、三山街道、保定街道、龙湖街道、高安街道' },
        { 芜湖县: '湾沚镇、六郎镇、陶辛镇、红杨镇、花桥镇' },
        {
          繁昌县:
            '繁阳镇、荻港镇、孙村镇、新港镇、平铺镇、峨山镇、横山公共服务中心',
        },
        {
          南陵县:
            '籍山镇、许镇、弋江镇、三里镇、何湾镇、工山镇、烟墩镇、家发镇',
        },
        {
          无为县:
            '无城镇、陡沟镇、福渡镇、泥汊镇、姚沟镇、刘渡镇、襄安镇、十里墩乡、泉塘镇、蜀山镇、洪巷乡、牛埠镇、昆山乡、鹤毛乡、开城镇、赫店镇、严桥镇、红庙镇、石涧镇',
        },
      ],
      蚌埠市: [
        {
          蚌山区:
            '天桥街道、青年街道、纬二路街道、黄庄街道、宏业村街道、雪华乡、燕山乡',
        },
        {
          龙子湖区:
            '东风街道、延安街道、治淮街道、东升街道、解放街道、曹山街道、长淮卫镇、李楼乡',
        },
        {
          禹会区:
            '朝阳街道、秀水街道、迎淮街道、张公山街道、金域街道、大庆街道、锦绣街道、钓鱼台街道、喜迎门街道、长青乡、马城镇、涂山风景区',
        },
        { 淮上区: '小蚌埠镇、吴小街镇、曹老集镇、梅桥镇、沫河口镇、淮滨街道' },
        {
          怀远县:
            '城关镇、包集镇、龙亢镇、河溜镇、双桥集镇、魏庄镇、万福镇、唐集镇、淝南乡、淝河乡、褚集乡、陈集乡、古城乡、徐圩乡、兰桥乡、荆芡乡、找郢乡、龙亢农场、新城区、工业园区、怀远经济开发区、怀远龙亢经济开发区',
        },
        {
          五河县:
            '城关镇、新集镇、小溪镇、双忠庙镇、小圩镇、东刘集镇、头铺镇、大新镇、武桥镇、朱顶镇、浍南镇、申集镇、沱湖乡、临北回族乡',
        },
        {
          固镇县:
            '城关镇、王庄镇、新马桥镇、连城镇、刘集镇、任桥镇、湖沟镇、濠城镇、石湖乡、杨庙乡、仲兴乡',
        },
      ],
      淮南市: [
        {
          田家庵区:
            '安成镇、舜耕镇、曹庵镇、三和乡、史院乡、洞山街道、泉山街道、淮滨街道、新淮街道、公园街道、田东街道、国庆街道、龙泉街道、朝阳街道',
        },
        {
          潘集区:
            '田集街道、高皇镇、平圩镇、架河镇、泥河镇、潘集镇、芦集镇、夹沟镇、祁集镇、贺疃镇、古沟回族乡',
        },
        {
          谢家集区:
            '谢家集街道、蔡家岗街道、立新街道、谢三村街道、平山街道、望峰岗镇、李郢孜镇、唐山镇、杨公镇、孙庙乡、孤堆回族乡',
        },
        {
          八公山区:
            '妙山林场、毕家岗街道、新庄孜街道、土坝孜街道、山王镇、八公山镇',
        },
        { 大通区: '大通街道、上窑镇、洛河镇、九龙岗镇、孔店乡' },
        {
          凤台县:
            '城关镇、新集镇、顾桥镇、岳张集镇、朱马店镇、大兴集乡、丁集乡、桂集镇、凤凰镇、杨村镇、古店乡、尚塘乡、刘集乡、关店乡、钱庙乡',
        },
        { 山南新区: '山南新区' },
        { 社会发展综合实验区: '毛集镇、夏集镇、焦岗乡' },
        { 经济技术开发区: '经济技术开发区' },
      ],
      马鞍山市: [
        {
          花山区:
            '金家庄街道、塘西街道、慈湖街道、江东街道、沙塘街道、解放路街道、湖东路街道、桃源路街道、霍里街道、慈湖乡',
        },
        { 博望区: '博望镇、丹阳镇、新市镇' },
        {
          雨山区:
            '雨山街道、安民街道、平湖街道、采石街道、向山镇、银塘镇、佳山乡',
        },
        {
          当涂县:
            '姑孰镇、黄池镇、乌溪镇、石桥镇、塘南镇、护河镇、太白镇、大陇乡、年陡镇、江心乡、湖阳镇',
        },
        {
          含山县:
            '仙踪镇、林头镇、运漕镇、铜闸镇、陶厂镇、环峰镇、清溪镇、昭关镇',
        },
        {
          和县: '历阳镇、白桥镇、姥桥镇、功桥镇、西埠镇、香泉镇、乌江镇、善厚镇、石杨镇',
        },
      ],
      淮北市: [
        {
          烈山区:
            '杨庄街道、前岭街道、百善街道、临海童街道、任楼街道、烈山镇、宋疃镇、古饶镇',
        },
        { 杜集区: '高岳街道、矿山集街道、朔里镇、石台镇、段园镇' },
        {
          相山区:
            '相南街道、相山东街道、相山西街道、三堤口街道、东山街道、刘桥街道、任圩街道、渠沟镇',
        },
        {
          濉溪县:
            '濉溪镇、临涣镇、南坪镇、百善镇、铁佛镇、刘桥镇、孙疃镇、韩村镇、双堆集镇、五沟镇、四铺乡',
        },
      ],
      铜陵市: [
        {
          铜官山区:
            '井湖社区、五松社区、映湖社区、人民社区、梅塘社区、官塘社区、幸福社区、学苑社区、阳光社区、友好社区、螺蛳山社区、露采社区、鹞山社区、金口岭社区、朝阳社区、滨江社区、金山社区、横港社区',
        },
        {
          狮子山区:
            '西湖镇、狮子山社区、立新社区、铜霞社区、凤凰山社区、翠湖社区、东郊街道、狮子山经济开发区',
        },
        { 郊区: '灰河乡、铜山镇、大通镇、桥南街道、安庆矿区街道' },
        {
          铜陵县:
            '东联乡、西联乡、胥坝乡、老洲乡、五松镇、顺安镇、钟鸣镇、天门镇',
        },
      ],
      安庆市: [
        {
          迎江区:
            '新洲乡、长风乡、龙狮桥乡、宜城路街道、建设路街道、新河路街道、华中路街道、人民路街道、孝肃路街道',
        },
        {
          大观区:
            '玉琳路街道、德宽路街道、龙山路街道、集贤路街道、花亭路街道、石化路街道、菱湖街道、海口镇街道、山口乡、十里铺乡',
        },
        { 宜秀区: '杨桥镇、白泽湖乡、大龙山镇、五横乡、罗岭镇、菱北街道' },
        {
          怀宁县:
            '石牌镇、高河镇、平山镇、月山镇、腊树镇、江镇镇、洪铺镇、黄龙镇、三桥镇、茶岭镇、金拱镇、黄墩镇、小市镇、公岭镇、马庙镇、雷埠乡、清河乡、凉亭乡、秀山乡、石镜乡',
        },
        {
          枞阳县:
            '枞阳镇、汤沟镇、??山镇、老洲镇、陈瑶湖镇、周潭镇、横埠镇、项铺镇、钱桥镇、麒麟镇、义津镇、浮山镇、官埠桥镇、会宫镇、铁铜乡、凤仪乡、长沙乡、钱铺乡、金社乡、白梅乡、白湖乡、雨坛乡',
        },
        {
          潜山县:
            '梅城镇、王河镇、天柱山镇、黄泥镇、黄柏镇、黄铺镇、水吼镇、余井镇、源潭镇、槎水镇、官庄镇、痘姆乡、油坝乡、五庙乡、塔畈乡、龙潭乡',
        },
        {
          太湖县:
            '江塘乡、大石乡、城西乡、汤泉乡、天华镇、北中镇、新仓镇、寺前镇、百里镇、弥陀镇、牛镇、小池镇、刘畈乡、徐桥镇、晋熙镇',
        },
        {
          宿松县:
            '孚玉镇、复兴镇、汇口镇、许岭镇、下仓镇、长铺镇、破凉镇、二郎镇、凉亭镇、洲头乡、九姑乡、千岭乡、程岭乡、高岭乡、五里乡、佐坝乡、隘口乡、河塌乡、陈汉乡、柳坪乡、趾凤乡、北浴乡',
        },
        {
          望江县:
            '华阳镇、赛口镇、雅滩镇、长岭镇、太慈镇、漳湖镇、高士镇、杨湾镇、雷池乡、凉泉乡',
        },
        {
          岳西县:
            '古坊乡、店前镇、河图镇、来榜镇、莲云乡、毛尖山、青天乡、石关乡、头陀镇、巍岭乡、温泉镇、响肠镇、主簿镇、天堂镇、黄尾镇、五河镇、菖蒲镇、白帽镇、中关乡、田头乡、包家乡、冶溪镇',
        },
        {
          桐城市:
            '新渡镇、双港镇、青草镇、范岗镇、金神镇、嬉子湖镇、孔城镇、吕亭镇、大关镇、唐湾镇、鲟鱼镇、黄甲镇、龙眼街道、文昌街道、龙腾街道',
        },
        { 高新技术产业开发区: '高新技术产业开发区' },
      ],
      黄山市: [
        {
          屯溪区:
            '昱东街道、昱中街道、昱西街道、老街街道、屯光镇、阳湖镇、黎阳镇、新潭镇、奕棋镇',
        },
        { 徽州区: '岩寺镇、西溪南镇、潜口镇、呈坎镇、洽舍乡、富溪乡、杨村乡' },
        {
          黄山区:
            '新城街道、甘棠镇、汤口镇、谭家桥镇、太平湖镇、仙源镇、焦村镇、耿城镇、三口镇、新明乡、龙门乡、乌石乡、新华乡、新丰乡、永丰乡',
        },
        {
          歙县: '徽城镇、深渡镇、北岸镇、富堨镇、郑村镇、桂林镇、许村镇、溪头镇、杞梓里镇、霞坑镇、街口镇、王村镇、坑口乡、雄村乡、上丰乡、昌溪乡、武阳乡、三阳乡、金川乡、小川乡、新溪口乡、璜田乡、长陔乡、森村乡、绍濂乡、石门乡、狮石乡',
        },
        {
          休宁县:
            '海阳镇、齐云山镇、万安镇、五城镇、东临溪镇、蓝田镇、溪口镇、流口镇、汪村镇、商山乡、山斗乡、岭南乡、渭桥乡、板桥乡、陈霞乡、鹤城乡、源芳乡、榆村乡、龙田乡、璜尖乡、白际乡',
        },
        {
          黟县: '碧阳镇、西递镇、宏村镇、渔亭镇、洪星乡、美溪乡、柯村乡、宏潭乡',
        },
        {
          祁门县:
            '祁山镇、枭峰镇、小路口镇、金子牌镇、平里镇、历口镇、闪里镇、安凌镇、大坦乡、柏溪乡、塔坊乡、祁红乡、溶口乡、芦溪乡、渚口乡、古溪乡、新安乡、箬坑乡',
        },
        { 黄山风景区: '黄山风景区' },
        { 黄山经济开发区: '黄山经济开发区' },
        { 黄山高铁新区: '黄山高铁新区' },
      ],
      阜阳市: [
        {
          颍州区:
            '马寨乡、程集镇、王店镇、西湖镇、三合镇、袁集镇、九龙镇、三十里铺镇、三塔集镇、鼓楼街道、文峰街道、清河街道、颍西街道、西湖景区街道、颍州经济开发区、颍州工贸园',
        },
        {
          颍东区:
            '河东街道、新华街道、向阳街道、口孜镇、插花镇、袁寨镇、枣庄镇、老庙镇、正午镇、杨楼孜镇、新乌江镇、冉庙乡',
        },
        { 颍泉区: '中市街道、周棚街道、伍明镇、闻集镇、行流镇、宁老庄镇' },
        {
          临泉县:
            '城关镇、杨桥镇、鲖城镇、谭棚镇、老集镇、滑集镇、吕寨镇、单桥镇、长官镇、宋集镇、张新镇、艾亭镇、陈集镇、韦寨镇、迎仙镇、瓦店镇、姜寨镇、庙岔镇、黄岭镇、白庙镇、关庙镇、牛庄乡、高塘乡、范兴集乡、土陂乡、谢集乡、杨小街镇、陶老乡、田桥乡、张营乡、庞营乡、临泉县工业园区',
        },
        {
          太和县:
            '城关镇、旧县镇、税镇镇、皮条孙镇、原墙镇、倪邱镇、李兴镇、大新镇、肖口镇、关集镇、三塔镇、双浮镇、蔡庙镇、三堂镇、苗老集镇、赵庙镇、宫集镇、坟台镇、洪山镇、清浅镇、五星镇、高庙镇、桑营镇、大庙集镇、胡总乡、赵集乡、郭庙镇、马集乡、二郎乡、阮桥镇、双庙乡、太和经济开发区',
        },
        {
          阜南县:
            '鹿城镇、方集镇、中岗镇、柴集镇、新村镇、柳沟镇、朱寨镇、王化镇、赵集镇、田集镇、苗集镇、黄岗镇、焦陂镇、张寨镇、会龙镇、曹集镇、王堰镇、地城镇、洪河桥镇、王家坝镇、王店孜乡、许堂乡、公桥乡、龙王乡、于集乡、老观乡、郜台乡、段郢乡、阜南经济开发区',
        },
        {
          颍上县:
            '慎城镇、谢桥镇、南照镇、杨湖镇、江口镇、润河镇、新集镇、六十铺镇、耿棚镇、半岗镇、王岗镇、夏桥镇、江店孜镇、陈桥镇、黄桥镇、八里河镇、迪沟镇、西三十铺镇、红星镇、十八里铺镇、鲁口镇、建颍乡、五十铺乡、盛堂乡、关屯乡、垂岗乡、赛涧回族乡、刘集乡、黄坝乡、古城乡',
        },
        {
          界首市:
            '东城街道、西城街道、颍南街道、光武镇、泉阳镇、芦村镇、新马集镇、大黄镇、田营镇、陶庙镇、王集镇、砖集镇、顾集镇、戴桥镇、舒庄镇、邴集乡、靳寨乡、任寨乡',
        },
      ],
      宿州市: [
        {
          姑苏区:
            '观前街道、平江街道、苏锦街道、娄门街道、桃花坞街道、城北街道、双塔街道、沧浪街道、胥江街道、吴门桥街道、友新街道、石路街道、葑门街道、金阊街道、留园街道、虎丘街道、白洋湾街道',
        },
        {
          相城区:
            '元和街道、太平街道、黄桥街道、北桥街道、望亭镇、黄埭镇、渭塘镇、阳澄湖镇',
        },
        {
          吴中区:
            '长桥街道、越溪街道、郭巷街道、横泾街道、香山街道、苏苑街道、龙西街道、城南街道、角直镇、木渎镇、胥口镇、东山镇、光福镇、西山镇、临湖镇、吴中经济开发区、吴中太湖旅游区、太湖现代农业示范园',
        },
        {
          虎丘区:
            '狮山街道、枫桥街道、横塘街道、镇湖街道；浒墅关镇、通安镇、东渚镇、下设浒墅关经济开发区、苏州高新区出口加工区、科技城、苏州西部生态城、保税物流中心',
        },
        {
          吴江区:
            '滨湖街道、松陵镇、同里镇、平望镇、黎里镇、盛泽镇、七都镇、震泽镇、桃源镇',
        },
        {
          常熟市:
            '虞山镇、梅李镇、海虞镇、古里镇、沙家浜镇、支塘镇、董浜镇、尚湖镇、辛庄镇、碧溪新区、东南街道',
        },
        {
          昆山市:
            '昆山经济技术开发区、昆山综合保税区、昆山高新技术产业开发区、江苏省花桥经济开发区、江苏省昆山旅游度假区、玉山镇、巴城镇、周庄镇、张浦镇、千灯镇、陆家镇、锦溪镇、淀山湖镇、周庄镇',
        },
        {
          张家港市:
            '杨舍镇、塘桥镇、金港镇、锦丰镇、乐余镇、凤凰镇、南丰镇、大新镇、常阴沙现代农业示范园区',
        },
        {
          太仓市:
            '城厢镇、浏河镇、浮桥镇、沙溪镇、璜泾镇、双凤镇、港口开发区、经济开发区、科教新城',
        },
      ],
      滁州市: [
        {
          琅琊区:
            '东门社区、南门社区、西门社区、北门社区、琅琊社区、凤凰社区、古道社区、白云社区、清流社区、创业社区、琅琊经济技术开发区',
        },
        {
          南谯区:
            '龙蟠街道、大王街道、凤凰街道、乌衣镇、腰铺镇、沙河镇、珠龙镇、施集镇、大柳镇、章广镇、黄泥岗镇',
        },
        {
          天长市:
            '天长街道、铜城镇、汊涧镇、秦栏镇、大通镇、杨村镇、石梁镇、金集镇、仁和集镇、冶山镇、郑集镇、张铺镇、新街镇、永丰镇、万寿镇、城东新区、大圹圩农场',
        },
        {
          明光市:
            '明光街道、明西街道、明南街道、明东街道、张八岭镇、三界镇、管店镇、自来桥镇、涧溪镇、石坝镇、苏巷镇、桥头镇、女山湖镇、古沛镇、潘村镇、柳巷镇、泊岗乡',
        },
        {
          来安县:
            '新安镇、半塔镇、水口镇、汊河镇、大英镇、雷官镇、施官镇、舜山镇、三城乡、独山乡、杨郢乡、张山乡',
        },
        {
          全椒县:
            '襄河镇、古河镇、大墅镇、二郎口镇、武岗镇、马厂镇、石沛镇、西王镇、六镇镇、十字镇',
        },
        {
          定远县:
            '定城镇、炉桥镇、张桥镇、蒋集镇、朱湾镇、连江镇、仓镇、界牌集镇、西卅店镇、永康镇、池河镇、桑涧镇、三和集镇、藕塘镇、大桥镇、吴圩镇、七里塘乡、能仁乡、二龙乡、范岗乡、严桥乡、拂晓乡',
        },
        {
          凤阳县:
            '府城镇、临淮关镇、武店镇、刘府镇、西泉镇、大庙镇、殷涧镇、总铺镇、红心镇、板桥镇、大溪河镇、小溪河镇、官塘镇、枣巷镇、黄湾乡、凤阳县工业园区、凤阳硅工业园区、凤宁现代产业园',
        },
        { 滁州经济技术开发区: '滁州经济技术开发区' },
        { 苏滁现代产业园: '苏滁现代产业园' },
      ],
      六安市: [
        {
          金安区:
            '中市街道、东市街道、三里桥街道、望城街道、清水河街道、木厂镇、马头镇、东桥镇、张店镇、毛坦厂镇、东河口镇、双河镇、施桥镇、孙岗镇、三十铺镇、椿树镇、城北乡、翁墩乡、淠东乡、中店乡、横塘岗乡、先生店乡',
        },
        {
          裕安区:
            '西市街道、鼓楼街道、小华山街道、苏埠镇、韩摆渡镇、新安镇、顺河镇、独山镇、石婆店镇、城南镇、丁集镇、固镇镇、徐集镇、分路口镇、江家店镇、单王乡、青山乡、石板冲乡、西河口乡、平桥乡、罗集乡、狮子岗乡',
        },
        {
          霍邱县:
            '姚李镇、洪集镇、曹庙镇、众兴集镇、乌龙镇、长集镇、夏店镇、岔路镇、河口镇、户胡镇、孟集镇、彭塔乡、冯瓴乡、潘集镇、花园镇、周集镇、临水镇、范桥镇、王截流乡、冯井镇、石店镇、马店镇、邵岗乡、白莲乡、龙潭镇、高塘镇、新店镇、临淮岗乡、城西湖乡、三流乡、宋店乡、城关镇、霍邱经济开发区',
        },
        {
          霍山县:
            '衡山镇、佛子岭镇、下符桥镇、但家庙镇、与儿街镇、黑石渡镇、诸佛庵镇、落儿岭镇、磨子潭镇、大化坪镇、漫水河镇、上土市镇、单龙寺乡、东西溪乡、太平畈乡、太阳乡',
        },
        {
          金寨县:
            '梅山镇、青山镇、古碑镇、南溪镇、燕子河镇、响洪甸镇、斑竹园镇、汤家汇镇、双河镇、关庙乡、铁冲乡、全军乡、张畈乡、沙河乡、张冲乡、长岭乡、桃岭乡、花石乡',
        },
        {
          寿县: '寿春镇、双桥镇、涧沟镇、丰庄镇、正阳关镇、迎河镇、板桥镇、安丰塘镇、堰口镇、保义镇、隐贤镇、安丰镇、众兴镇、茶庵镇、三觉镇、炎刘镇、刘岗镇、双庙集镇、小甸镇、瓦埠镇、大顺镇、八公山乡、张李乡、窑口乡、陶店回族乡',
        },
        {
          舒城县:
            '城关镇、晓天镇、桃溪镇、万佛湖镇、千人桥镇、百神庙镇、杭埠镇、舒茶镇、南港镇、干汊河镇、张母桥镇、五显镇、山七镇、河棚镇、汤池镇、春秋乡、柏林乡、棠树乡、阙店乡、高峰乡、庐镇乡',
        },
        { 六安经济开发区: '六安经济开发区' },
        { 六安市承接产业转移示范园区: '六安市承接产业转移示范园区' },
        { 叶集改革发展试验区: '叶集改革发展试验区' },
      ],
      宣城市: [
        {
          宣州区:
            '双桥街道、鳌峰街道、西林街道、澄江街道、济川街道、飞彩街道、敬亭山街道、金坝街道、水阳镇、狸桥镇、杨柳镇、孙埠镇、洪林镇、沈村镇、向阳镇、溪口镇、周王镇、古泉镇、水东镇、新田镇、文昌镇、寒亭镇、黄渡乡、养贤乡、五星乡、朱桥乡',
        },
        {
          郎溪县:
            '建平镇、梅渚镇、涛城镇、十字镇、毕桥镇、新发镇、飞鲤镇、凌笪乡、姚村乡',
        },
        {
          广德县:
            '桃州镇、新杭镇、邱村镇、誓节镇、柏垫镇、卢村乡、东亭乡、杨滩镇、四合乡',
        },
        {
          宁国市:
            '·西津街道、河沥街道、南山街道、汪溪街道、竹峰街道、天湖街道、中溪镇、港口镇、云梯乡、南极乡、宁墩镇、万家乡、梅林镇、仙霞镇、霞西镇、青龙乡、甲路镇、方塘乡、胡乐镇',
        },
        {
          泾县: '泾川镇、茂林镇、榔桥镇、丁家桥镇、蔡村镇、琴溪镇、桃花潭镇、黄村镇、云岭镇、汀溪乡、昌桥乡',
        },
        {
          绩溪县:
            '华阳镇、临溪镇、长安镇、上庄镇、扬溪镇、伏岭镇、金沙镇、瀛洲镇、板桥头乡、家朋乡、荆州乡',
        },
        {
          旌德县:
            '旌阳镇、蔡家桥镇、三溪镇、庙首镇、白地镇、俞村镇、版书乡、云乐乡、兴隆乡、孙村乡',
        },
      ],
      池州市: [
        {
          贵池区:
            '池阳街道、秋浦街道、江口街道、里山街道、马衙街道、墩上街道、梅龙街道、秋江街道、杏花村街道、清风街道、清溪街道、牛头山镇、梅街镇、殷汇镇、涓桥镇、乌沙镇、唐田镇、梅村镇、牌楼镇、棠溪镇、池州站前区、池州经济开发区',
        },
        {
          东至县:
            '尧渡镇、东流镇、大渡口镇、胜利镇、张溪镇、洋湖镇、葛公镇、香隅镇、官港镇、昭潭镇、龙泉镇、泥溪镇、花园乡、木塔乡、青山乡、香隅化工园区、大渡口经济开发区、东流经济开发区、东至站前区、东至政务新区、东至城南新区、胜利工业园区、龙泉工业园区、梅城工业园区、东至经济开发区',
        },
        {
          石台县:
            '仁里镇、七都镇、仙寓镇、丁香镇、小河镇、横渡镇、大演乡、矶滩乡、石台县开发区',
        },
        {
          青阳县:
            '蓉城镇、杨田镇、庙前镇、朱备镇、木镇镇、新河镇、陵阳镇、丁桥镇、杜村乡、乔木乡、酉华乡、青阳县开发区',
        },
      ],
      亳州市: [
        {
          谯城区:
            '花戏楼街道、薛阁街道、汤陵街道、古井镇、华佗镇、十八里镇、芦庙镇、魏岗镇、牛集镇、五马镇、谯东镇、十九里镇、沙土镇、观堂镇、古城镇、大杨镇、城父镇、十河镇、双沟镇、淝河镇、龙扬镇、立德镇、颜集镇、张店乡、赵桥乡',
        },
        {
          涡阳县:
            '城关街道、城东街道、城西街道、涡北街道、涡阳工业园区、店集镇、涡南镇、楚店镇、高公镇、高炉镇、曹市镇、青疃镇、石弓镇、龙山镇、义门镇、新兴镇、临湖镇、丹城镇、马店集镇、花沟镇、西阳镇、陈大镇、牌坊镇、公吉寺镇、标里镇',
        },
        {
          蒙城县:
            '城关街道、漆园街道、庄周街道、蒙城经济开发区、小辛集乡、许疃镇、岳坊镇、王集乡、双涧镇、乐土镇、马集镇、板桥集镇、小涧镇、篱笆镇、立仓镇、楚村镇、坛城镇、白杨林场、三义镇、范集工业园区',
        },
        {
          利辛县:
            '城关镇、利辛工业园区、张村镇、汝集镇、王人镇、孙庙乡、江集镇、旧城镇、西潘楼镇、孙集镇、巩店镇、、王市镇、永兴镇、马店孜镇、大李集镇、胡集镇、展沟镇、程家集镇、中疃镇、望疃镇、刘家集乡、纪王场乡',
        },
      ],
    },
  ],
  福建省: [
    {
      福州市: [
        {
          鼓楼区:
            '鼓东街道、鼓西街道、温泉街道、东街街道、南街街道、安泰街道、华大街道、水部街道、五凤街道、洪山镇',
        },
        {
          台江区:
            '茶亭街道、洋中街道、后洲街道、新港街道、瀛洲街道、苍霞街道、义洲街道、上海街道、宁化街道、鳌峰街道',
        },
        {
          仓山区:
            '仓前街道、下渡街道、临江街道、三叉街街道、对湖街道、上渡街道、金山街道、东升街道、建新镇、盖山镇、仓山镇、城门镇、螺洲镇、红星农场',
        },
        {
          晋安区:
            '茶园街道、王庄街道、象园街道、新店镇、岳峰镇、鼓山镇、宦溪镇、寿山乡、日溪乡',
        },
        { 马尾区: '罗星街道、马尾镇、亭江镇、琅岐镇' },
        {
          平潭县:
            '潭城镇、苏澳镇、流水镇、澳前镇、北厝镇、平原镇、敖东镇、白青乡、屿头乡、大练乡、芦洋乡、中楼乡、东庠乡、岚城乡、南海乡',
        },
        {
          福清市:
            '玉屏街道、龙山街道、龙江街道、音西街道、宏路街道、石竹街道、阳下街道、海口镇、东张镇、龙田镇、高山镇、渔溪镇、城头镇、江镜镇、三山镇、江阴镇、港头镇、沙埔镇、东瀚镇、上迳镇、新厝镇、镜洋镇、一都镇、南岭镇、江镜华侨、东阁华侨',
        },
        {
          长乐市:
            '吴航街道、航城街道、营前街道、漳港街道、梅花镇、金峰镇、潭头镇、玉田镇、松下镇、江田镇、古槐镇、鹤上镇、首占镇、文武砂镇、湖南镇、文岭镇、罗联乡、猴屿乡',
        },
        {
          闽侯县:
            '甘蔗街道、白沙镇、尚干镇、祥谦镇、青口镇、南通镇、南屿镇、上街镇、荆溪镇、竹岐乡、洋里乡、鸿尾乡、大湖乡、小箬乡、廷坪乡、江洋农场',
        },
        {
          连江县:
            '凤城镇、晓澳镇、浦口镇、琯头镇、敖江镇、东岱镇、东湖镇、丹阳镇、马鼻镇、透堡镇、官坂镇、黄岐镇、筱埕镇、苔菉镇、长龙镇、坑园镇、潘渡乡、蓼沿乡、下宫乡、安凯乡、江南乡、马祖乡、小沧畲族乡',
        },
        {
          罗源县:
            '凤山镇、鉴江镇、松山镇、起步镇、中房镇、飞竹镇、白塔乡、西兰乡、洪洋乡、碧里乡、霍口畲族乡、罗源湾',
        },
        {
          闽清县:
            '梅城镇、坂东镇、池园镇、梅溪镇、白樟镇、白中镇、塔庄镇、东桥镇、雄江镇、金沙镇、省璜镇、云龙乡、上莲乡、三溪乡、下祝乡、桔林乡',
        },
        {
          永泰县:
            '樟城镇、嵩口镇、梧桐镇、葛岭镇、城峰镇、清凉镇、长庆镇、同安镇、大洋镇、塘前乡、富泉乡、岭路乡、赤锡乡、洑口乡、盖洋乡、东洋乡、霞拔乡、盘谷乡、红星乡、白云乡、丹云乡',
        },
      ],
      厦门市: [
        {
          思明区:
            '厦港街道、中华街道、滨海街道、鹭江街道、开元街道、梧村街道、筼筜街道、莲前街道、嘉莲街道、鼓浪屿街道',
        },
        {
          湖里区:
            '湖里街道、殿前街道、禾山街道、江头街道、金山街道、火炬高技术开发区、象屿保税区',
        },
        {
          集美区:
            '集美街道、侨英街道、杏林街道、杏滨街道、灌口镇、后溪镇、第二农场、省天马种猪场、天马华侨农场、坂头防护林场',
        },
        {
          海沧区:
            '海沧街道、新阳街道、东孚镇、第一农场、海沧农场、天竺山林场、新阳工业区、出口加工区',
        },
        {
          同安区:
            '大同街道、祥平街道、莲花镇、新民镇、洪塘镇、西柯镇、汀溪镇、五显镇、凤南农场、白沙仑农场、竹坝华侨农场',
        },
        { 翔安区: '大嶝街道、马巷镇、新圩镇、新店镇、内厝镇、大帽山农场' },
        { 厦门火炬高技术产业开发区: '厦门火炬高技术产业开发区' },
        { 厦门出口加工区: '厦门出口加工区' },
        { 厦门象屿保税区: '厦门象屿保税区' },
        { 厦门海沧保税港区: '厦门海沧保税港区' },
        { 厦门象屿保税物流园区: '厦门象屿保税物流园区' },
        { 厦门集美台商投资区: '厦门集美台商投资区' },
        { 厦门杏林台商投资区: '厦门杏林台商投资区' },
        { 厦门海沧台商投资区: '厦门海沧台商投资区' },
      ],
      漳州市: [
        {
          芗城区:
            '东铺头街道、西桥街道、新桥街道、巷口街道、南坑街道、通北街道、浦南镇、天宝镇、芝山镇、石亭镇、芗城区奶牛场、后房农场、天宝林场、五峰农场',
        },
        { 龙文区: '蓝田镇、步文镇、朝阳镇、郭坑镇' },
        {
          龙海市:
            '角美镇、九湖镇、程溪镇、颜厝镇、榜山镇、紫泥镇、石码镇、海澄镇、东园镇、白水镇、浮宫镇、港尾镇、东泗乡、隆教畲族乡',
        },
        {
          云霄县:
            '云陵镇、峛屿镇、东厦镇、火田镇、莆美镇、陈岱镇、马铺乡、下河乡、和平乡、云陵工业开发区、常山华侨经济开发区',
        },
        {
          漳浦县:
            '绥安镇、旧镇镇、佛昙镇、赤湖镇、杜浔镇、霞美镇、官浔镇、石榴镇、盘陀镇、长桥镇、前亭镇、马坪镇、深土镇、六鳌镇、沙西镇、古雷镇、大南坂镇、南浦乡、赤岭畲族乡、湖西畲族乡、赤土乡、漳浦盐场、万安农场、玳瑁山茶场',
        },
        {
          诏安县:
            '南诏镇、深桥镇、桥东镇、梅岭镇、四都镇、太平镇、霞葛镇、官陂镇、秀篆镇、西潭乡、白洋乡、建设乡、红星乡、金星乡、梅洲乡、西山农场、金星农场、红星农场、建设农场、梅洲农场、建设林场、岭下溪林场、诏安闽粤边界开发区、诏安工业园区',
        },
        {
          长泰县:
            '武安镇、岩溪镇、陈巷镇、枋洋镇、坂里乡、国营古农农场、长泰经济开发区、马洋溪生态旅游区、林墩工业区',
        },
        { 东山县: '西埔镇、杏陈镇、陈城镇、康美镇、樟塘镇、前楼镇' },
        {
          南靖县:
            '山城镇、靖城镇、龙山镇、金山镇、和溪镇、奎洋镇、南坑镇、船场镇、书洋镇、梅林镇',
        },
        {
          平和县:
            '小溪镇、山格镇、文峰镇、南胜镇、坂仔镇、安厚镇、大溪镇、霞寨镇、九峰镇、芦溪镇、五寨乡、国强乡、崎岭乡、长乐乡、秀峰乡、安厚农场',
        },
        {
          华安县:
            '华丰镇、仙都镇、新圩镇、沙建镇、丰山镇、高安镇、湖林乡、高车乡、马坑乡',
        },
        { 漳州招商局经济技术开发区: '漳州招商局经济技术开发区' },
        { 漳州台商投资区: '漳州台商投资区' },
        { 漳州高新技术开发区: '漳州高新技术开发区' },
        { 漳州东山开发区: '漳州东山开发区' },
      ],
      泉州市: [
        {
          鲤城区:
            '江南街道、浮桥街道、金龙街道、常泰街道、开元街道、鲤中街道、海滨街道、临江街道、江南高新电子园区',
        },
        {
          丰泽区:
            '东湖街道、丰泽街道、泉秀街道、清源街道、华大街道、城东街道、东海街道、北峰街道',
        },
        {
          泉港区: '：山腰街道、后龙镇、南埔镇、涂岭镇、前黄镇、峰尾镇、界山镇',
        },
        { 洛江区: '万安街道、双阳街道、河市镇、马甲镇、罗溪镇、虹山乡' },
        {
          晋江市:
            '安海镇、磁灶镇、陈埭镇、池店镇、内坑镇、东石镇、深沪镇、金井镇、龙湖镇、永和镇、英林镇、紫帽镇、西滨镇、青阳街道、梅岭街道、西园街道、罗山街道、新塘街道、灵源街道',
        },
        {
          石狮市:
            '湖滨街道、凤里街道、灵秀镇、宝盖镇、蚶江镇、祥芝镇、鸿山镇、锦尚镇、永宁镇',
        },
        {
          南安市:
            '溪美街道、柳城街道、美林街道、官桥镇、省新镇、仑苍镇、东田镇、英都镇、翔云镇、金淘镇、诗山镇、蓬华镇、码头镇、九都镇、乐峰镇、罗东镇、梅山镇、洪濑镇、洪梅镇、康美镇、丰州镇、霞美镇、水头镇、石井镇、眉山乡、向阳乡、南安经济开发区、雪峰华侨经济开发区',
        },
        {
          惠安县:
            '螺城镇、洛阳镇、崇武镇、东园镇、张坂镇、东岭镇、辋川镇、涂寨镇、螺阳镇、黄塘镇、山霞镇、净峰镇、东桥镇、紫山镇、小岞镇、百崎回族乡',
        },
        {
          安溪县:
            '凤城镇、湖头镇、蓬莱镇、官桥镇、剑斗镇、城厢镇、魁斗镇、金谷镇、龙门镇、西坪镇、虎邱镇、感德镇、芦田镇、湖上乡、尚卿乡、大坪乡、龙涓乡、长坑乡、蓝田乡、祥华乡、桃舟乡、参内乡、白濑乡、福田乡',
        },
        {
          永春县:
            '桃城镇、下洋镇、蓬壶镇、五里街镇、岵山镇、湖洋镇、一都镇、坑仔口镇、玉斗镇、锦斗镇、达埔镇、吾峰镇、石鼓镇、东平镇、东关镇、桂洋镇、苏坑镇、仙夹镇、横口乡、呈祥乡、介福乡、外山乡',
        },
        {
          德化县:
            '浔中镇、龙浔镇、三班镇、龙门滩镇、雷峰镇、南埕镇、水口镇、赤水镇、葛坑镇、上涌镇、盖德镇、杨梅乡、汤头乡、桂阳乡、国宝乡、美湖镇、大铭乡、春美乡',
        },
        { 金门县: '金城镇、金湖镇、金沙镇、金宁乡、烈屿乡、乌丘乡' },
      ],
      三明市: [
        { 梅列区: '列东街道、列西街道、徐碧街道、陈大镇、洋溪镇' },
        {
          三元区:
            '城关街道、白沙街道、富兴堡街道、荆西街道、莘口镇、岩前镇、城东乡、中村乡',
        },
        {
          明溪县:
            '雪峰镇、盖洋镇、胡坊镇、瀚仙镇、城关乡、沙溪乡、夏阳乡、枫溪乡、夏坊乡',
        },
        {
          清流县:
            '龙津镇、嵩口镇、嵩溪镇、灵地镇、长校镇、林畲乡、温郊乡、余朋乡、沙芜乡、赖坊乡、李家乡、里田乡、田源乡',
        },
        {
          宁化县:
            '翠江镇、泉上镇、湖村镇、石壁镇、曹坊镇、安远镇、淮土镇、城郊乡、城南乡、济村乡、方田乡、安乐乡、治平畲族乡、中沙乡、河龙乡、水茜乡',
        },
        {
          大田县:
            '均溪镇、上京镇、广平镇、桃源镇、太华镇、建设镇、石牌镇、奇韬镇、华兴乡、屏山乡、吴山乡、济阳乡、武陵乡、谢洋乡、文江乡、梅山乡、湖美乡、前坪乡',
        },
        {
          尤溪县:
            '城关镇、梅仙镇、西滨镇、洋中镇、新阳镇、管前镇、西城镇、尤溪口镇、坂面镇、联合乡、汤川乡、溪尾乡、中仙乡、台溪乡、八字桥乡',
        },
        {
          沙县: '凤岗街道、虬江街道、青州镇、夏茂镇、高砂镇、高桥镇、富口镇、大洛镇、南霞乡、南阳乡、郑湖乡、湖源乡、沙县金古经济技术开发区、三明高新技术产业开发区',
        },
        {
          将乐县:
            '古镛镇、万安镇、高唐镇、白莲镇、黄潭镇、水南镇、光明乡、漠源乡、南口乡、万全乡、安仁乡、大源乡、余坊乡',
        },
        {
          泰宁县:
            '杉城镇、朱口镇、新桥乡、上青乡、大田乡、梅口乡、下渠乡、开善乡、大龙乡',
        },
        {
          永安市:
            '燕东街道、燕西街道、燕南街道、燕北街道、西洋镇、贡川镇、安砂镇、小陶镇、大湖镇、曹远镇、洪田镇、槐南镇、上坪乡、罗坊乡、青水畲族乡',
        },
        {
          建宁县:
            '濉溪镇、里心镇、溪口镇、均口镇、伊家乡、黄坊乡、溪源乡、客坊乡、黄埠乡',
        },
      ],
      莆田市: [
        {
          城厢区:
            '龙桥街道、凤凰山街道、霞林街道、常太镇、华亭镇、灵川镇、东海镇',
        },
        { 荔城区: '镇海街道、拱辰街道、西天尾镇、新度镇、黄石镇、北高镇' },
        {
          涵江区:
            '涵东街道、涵西街道、三江口镇、白塘镇、国欢镇、梧塘镇、江口镇、秋芦镇、白沙镇、庄边镇、新县镇、大洋乡',
        },
        { 秀屿区: '笏石镇、东庄镇、东峤镇、埭头镇、平海镇、南日镇、月塘乡' },
        { 湄洲湾北岸开发区: '忠门镇、东埔镇、山亭镇' },
        {
          仙游县:
            '鲤城街道、榜头镇、枫亭镇、郊尾镇、度尾镇、鲤南镇、大济镇、龙华镇、赖店镇、盖尾镇、钟山镇、游洋镇、园庄镇、西苑乡、石苍乡、社硎乡、书峰乡、菜溪乡',
        },
        { 湄洲岛: '湄洲镇' },
      ],
      南平市: [
        {
          延平区:
            '梅山街道、黄墩街道、紫云街道、四鹤街道、水南街道、水东街道、来舟镇、樟湖镇、夏道镇、西芹镇、峡阳镇、大横镇、王台镇、太平镇、南山镇、塔前镇、茫荡镇、洋后镇、炉下镇、巨口乡、赤门乡',
        },
        {
          邵武市:
            '昭阳街道、通泰街道、水北街道、晒口街道、城郊镇、水北镇、下沙镇、卫闽镇、沿山镇、拿口镇、洪墩镇、大埠岗镇、和平镇、肖家坊镇、大竹镇、吴家塘镇、桂林乡、张厝乡、金坑乡',
        },
        {
          武夷山市:
            '崇安街道、武夷街道、新丰街道、星村镇、兴田镇、五夫镇、上梅乡、吴屯乡、岚谷乡、洋庄乡',
        },
        {
          建瓯市:
            '水源乡、川石乡、龙村乡、顺阳乡、东峰镇、东游镇、吉阳镇、南雅镇、徐墩镇、房道镇、小桥镇、迪口镇、玉山镇、小松镇、芝山街道、通济街道、瓯宁街道、建安街道',
        },
        {
          建阳区:
            '潭城街道、童游街道、麻沙镇、水吉镇、将口镇、莒口镇、黄坑镇、漳墩镇、徐市镇、小湖镇、崇雒乡、书坊乡、回龙乡',
        },
        {
          顺昌县:
            '双溪街道、建西镇、洋口镇、元坑镇、埔上镇、大历镇、大干镇、仁寿镇、洋墩乡、郑坊乡、岚下乡、高阳乡',
        },
        {
          浦城县:
            '南浦街道、河滨街道、富岭镇、石陂镇、临江镇、仙阳镇、水北街镇、永兴镇、忠信镇、莲塘镇、九牧镇、万安乡、古楼乡、山下乡、枫溪乡、濠村乡、管厝乡、盘亭乡、官路乡',
        },
        {
          光泽县:
            '杭川镇、寨里镇、止马镇、鸾凤乡、崇仁乡、李坊乡、华桥乡、司前乡',
        },
        {
          松溪县:
            '松源街道、郑墩镇、渭田镇、河东乡、旧县乡、溪东乡、花桥乡、祖墩乡、茶平乡',
        },
        {
          政和县:
            '熊山街道、东平镇、铁山镇、镇前镇、石屯镇、星溪乡、外屯乡、杨源乡、澄源乡、岭腰乡',
        },
      ],
      龙岩市: [
        {
          新罗区:
            '东城街道、南城街道、西城街道、中城街道、北城街道、西陂街道、曹溪街道、东肖街道、龙门街道、铁山街道、红坊镇、适中镇、雁石镇、白沙镇、万安镇、大池镇、小池镇、江山镇、岩山镇、苏坂镇',
        },
        {
          永定区:
            '坎市镇、下洋镇、湖雷镇、高陂镇、抚市镇、湖坑镇、培丰镇、龙潭镇、峰市镇、城郊乡、西溪乡、金砂乡、仙师乡、洪山乡、湖山乡、岐岭乡、古竹乡、堂堡乡、合溪乡、虎岗乡、大溪乡、陈东乡、高头乡',
        },
        {
          长汀县:
            '汀州镇、馆前镇、河田镇、大同镇、策武镇、古城镇、新桥镇、童坊镇、南山镇、濯田镇、四都镇、涂坊镇、三洲镇、铁长乡、庵杰乡、宣成乡、红山乡、羊牯乡',
        },
        {
          连城县:
            '莲峰镇、庙前镇、新泉镇、朋口镇、莒溪镇、文亨镇、姑田镇、北团镇、林坊乡、曲溪乡、宣和乡、隔川乡、罗坊乡、揭乐乡、塘前乡、四堡乡、赖源乡',
        },
        {
          上杭县:
            '临江镇、临城镇、中都镇、蓝溪镇、稔田镇、白砂镇、古田镇、才溪镇、南阳镇、湖洋镇、下都乡、庐丰畲族乡、太拔乡、溪口镇、茶地乡、泮境乡、蛟洋镇、步云乡、旧县镇、通贤乡、官庄畲族乡、珊瑚乡',
        },
        {
          武平县:
            '平川镇、中山镇、十方镇、岩前镇、中堡镇、桃溪镇、城厢乡、万安乡、东留乡、永平乡、民主乡、下坝乡、象洞乡、武东乡、中赤乡、大禾乡、湘店乡',
        },
        {
          漳平市:
            '菁城街道、桂林街道、新桥镇、双洋镇、永福镇、溪南镇、和平镇、拱桥镇、象湖镇、赤水镇、芦芝乡、西园镇、南洋镇、官田乡、吾祠乡、灵地乡',
        },
      ],
      宁德市: [
        {
          蕉城区:
            '蕉南街道、蕉北街道、城南镇、漳湾镇、七都镇、八都镇、九都镇、霍童镇、赤溪镇、洋中镇、飞鸾镇、三都镇、金涵畲族乡、洪口乡、石后乡、虎贝乡',
        },
        { 东侨经济技术开发区: '东侨经济技术开发区' },
        {
          福安市:
            '福建福安赛岐经济开发区、福安畲族经济开发区、福安白马港经济开发区、福安湾坞工业集中区、罗江街道、城南街道、城北街道、阳头街道、城阳镇、坂中畲族乡、赛岐镇、穆阳镇、穆云畲族乡、康厝畲族乡、溪潭镇、甘棠镇、下白石镇、湾坞镇、溪尾镇、松罗乡、溪柄镇、潭头镇、上白石镇、范坑乡、社口镇、晓阳镇',
        },
        {
          福鼎市:
            '桐山街道、桐城街道、山前街道、太姥山镇、贯岭镇、前岐镇、沙埕镇、店下镇、磻溪镇、白琳镇、点头镇、管阳镇、嵛山镇、硖门畲族乡、叠石乡、佳阳乡、龙安开发区',
        },
        {
          霞浦县:
            '松城街道、松港街道、长春镇、牙城镇、溪南镇、沙江镇、下浒镇、三沙镇、盐田畲族乡、水门畲族乡、崇儒畲族乡、柏洋乡、北壁乡、海岛乡',
        },
        {
          古田县:
            '城东街道、城西街道、平湖镇、大桥镇、黄田镇、鹤塘镇、杉洋镇、凤都镇、水口镇、大甲镇、吉巷乡、泮洋乡、凤埔乡、卓洋乡',
        },
        {
          屏南县:
            '古峰镇、双溪镇、黛溪镇、长桥镇、屏城乡、棠口乡、甘棠乡、熙岭乡、路下乡、寿山乡、岭下乡',
        },
        {
          寿宁县:
            '鳌阳镇、斜滩镇、南阳镇、武曲镇、大安乡、坑底乡、清源乡、竹管垄乡、犀溪乡、平溪乡、芹洋乡、托溪乡、凤阳乡、下党乡',
        },
        {
          柘荣县:
            '双城镇、富溪镇、城郊乡、乍洋乡、东源乡、黄柏乡、宅中乡、楮坪乡、英山乡',
        },
        {
          周宁县:
            '狮城镇、咸村镇、浦源镇、七步镇、李墩镇、纯池镇、泗桥乡、礼门乡、玛坑乡',
        },
      ],
    },
  ],
  山东省: [
    {
      济南市: [
        {
          市中区:
            '四里村街道、大观园街道、杆石桥街道、魏家庄街道、泺源街道、六里山街道、二七新村街道、七里山街道、舜玉街道、舜耕街道、王官庄街道、白马山街道、七贤街道、十六里河街道、兴隆街道、党家街道、陡沟街道',
        },
        {
          历下区:
            '泉城路街道、大明湖街道、东关街道、建筑新村街道、千佛山街道、趵突泉街道、解放路街道、文化东路街道、燕山路街道、甸柳新村街道、姚家街道、智远街道、龙洞街道',
        },
        {
          天桥区:
            '锦市街道、北坦街道、纬北路街道、天桥东街街道、官扎营街道、宝华街街道、堤口路街道、工人新村南村街道、工人新村北村街道、无影山街道、药山街道、北园街道、泺口街道、大桥镇、桑梓店镇',
        },
        {
          槐荫区:
            '营市街街道、南辛庄街道、西市场街道、中大街道、道德街街道、振兴街街道、五里沟街道、青年公园街道、匡山街道、张庄路街道、段北街道、美里湖街道、兴福街道、玉清湖街道、腊山街道、吴家堡街道',
        },
        {
          历城区:
            '山大路街道、洪家楼街道、东风街道、全福街道、孙村街道、巨野河街道、华山街道、荷花路街道、王舍人街道、鲍山街道、郭店街道、唐冶街道、港沟街道、遥墙街道、临港街道、仲宫镇、柳埠镇、董家镇、唐王镇、西营镇、彩石镇',
        },
        {
          长清区:
            '文昌街道、平安街道、崮云湖街道、五峰山街道、归德镇、孝里镇、双泉镇、马山镇、万德镇、张夏镇',
        },
        {
          平阴县:
            '锦水街道、榆山街道、安城乡、玫瑰镇、东阿镇、孔村镇、孝直镇、洪范池镇',
        },
        {
          济阳县:
            '孙耿镇、垛石镇、曲堤镇、仁风镇、崔寨镇、太平镇、新市镇、回河镇、济北街道、济阳街道',
        },
        {
          商河县:
            '玉皇庙镇、怀仁镇、贾庄镇、殷巷镇、郑路镇、龙桑寺镇、白桥镇、孙集乡、韩庙乡、沙河乡、张坊乡、许商街道',
        },
        {
          章丘市:
            '明水街道、双山街道、龙山街道、枣园街道、埠村街道、圣井街道、普集镇、相公庄镇、绣惠镇、刁镇、水寨镇、垛庄镇、文祖镇、高官寨镇、白云湖镇、宁家埠镇、曹范镇、官庄乡、黄河乡、辛寨乡',
        },
      ],
      青岛市: [
        {
          市南区:
            '八大峡街道、云南路街道、中山路街道、八大关街道、湛山街道、香港中路街道、八大湖街道、金门路街道、珠海路街道、江苏路街道',
        },
        {
          市北区:
            '冠县路街道、泰山路街道、胶州路街道、热河路街道、辽宁路街道、黄台路街道、华阳路街道、登州路街道、利津路街道、延安路街道、威海路街道、北仲路街道、宁夏路街道、敦化路街道、辽源路街道、合肥路街道',
        },
        {
          李沧区:
            '李村街道、虎山路街道、浮山路街道、永清路街道、振华路街道、永安路街道、兴华路街道、兴城路街道、楼山街道、湘潭路街道、九水路街道',
        },
        {
          城阳区:
            '城阳街道、惜福街道、夏庄街道、流亭街道、棘洪滩街道、上马街道、红岛经济区、',
        },
        { 崂山区: '中韩街道、沙子口街道、王哥庄街道、北宅街道' },
        {
          黄岛区:
            '黄岛街道、辛安街道、薛家岛街道、灵珠山街道、长江路街道、红石崖街道、珠山街道、珠海街道、隐珠街道、灵山卫街道、铁山街道、滨海街道、琅琊镇、泊里镇、大场镇、大村镇、六汪镇、王台镇、张家楼镇、海青镇、宝山镇、藏南镇',
        },
        {
          即墨市:
            '环秀街道、通济街道、北安街道、龙山街道、鳌山卫镇、温泉镇、龙泉镇、刘家庄镇、蓝村镇、店集镇、灵山镇、七级镇、王村镇、丰城镇、段泊岚镇、移风店镇、普东镇、大信镇、田横镇、金口镇、华山镇、南泉镇和山东省、墨经济开发区',
        },
        {
          胶州市:
            '阜安街道、中云街道、北关街道、南关街道、云溪街道、胶莱镇、马店镇、李哥庄镇、营海镇、铺集镇、张应镇、里岔镇、胶东镇、胶西镇、洋河镇、九龙镇、杜村镇、胶北镇',
        },
        {
          平度市:
            '凤台街道、白沙河街道、东阁街道、同和街道、李园街道、崔家集镇、南村镇、田庄镇、蓼兰镇、明村镇、大泽山镇、旧店镇',
        },
        {
          莱西市:
            '龙水街道、水集街道、望城街道、梅花山街道、沽河街道、姜山镇、李权庄镇、孙受镇、夏格庄镇、店埠镇、院上镇、武备镇、南墅镇、日庄镇、马连庄镇、河头店镇',
        },
      ],
      淄博市: [
        {
          张店区:
            '南定镇、傅家镇、马尚镇、房镇镇、中埠镇、沣水镇、车站街道、和平街道、公园街道、科苑街道、体育场街道、杏园街道',
        },
        {
          淄川区:
            '经济开发区、般阳路街道、将军路街道、松龄路街道、洪山镇、昆仑镇、双杨镇、罗村镇、寨里镇、龙泉镇、西河镇、太河镇、岭子镇',
        },
        {
          博山区:
            '城东街道、城西街道、域城镇、白塔镇、山头街道、八陡镇、石马镇、博山镇、源泉镇、池上镇',
        },
        {
          周村区:
            '丝绸路街道、大街街道、青年路街道、永安街道、城北路街道、北郊镇、南郊镇、王村镇、萌水镇、商家镇',
        },
        {
          临淄区:
            '闻韶街道、雪宫街道、辛店街道、稷下街道、齐陵街道、齐都镇、皇城镇、敬仲镇、朱台镇、金岭镇、金山镇、凤凰镇',
        },
        {
          桓台县:
            '索镇街道、少海街道、起凤镇、田庄镇、荆家镇、马桥镇、新城镇、唐山镇、果里镇',
        },
        {
          高青县:
            '田镇街道、芦湖街道、青城镇、高城镇、黑里寨镇、唐坊镇、常家镇、花沟镇、木李镇',
        },
        {
          沂源县:
            '历山街道、南麻街道、南鲁山镇、鲁村镇、东里镇、悦庄镇、西里镇、大张庄镇、中庄镇、张家坡镇、燕崖镇、石桥镇',
        },
      ],
      枣庄市: [
        {
          薛城区: '陶庄镇、邹坞镇、临城街道、常庄镇、周营镇、沙沟镇、巨山街道',
        },
        {
          市中区:
            '四里村街道、大观园街道、杆石桥街道、魏家庄街道、泺源街道、六里山街道、二七新村街道、七里山街道、舜玉街道、舜耕街道、王官庄街道、白马山街道、七贤街道、十六里河街道、兴隆街道、党家街道、陡沟街道',
        },
        {
          峄城区: '坛山街道、吴林街道、古邵镇、阴平镇、底阁镇、榴园镇、峨山镇',
        },
        {
          山亭区:
            '山城街道、徐庄镇、冯卯镇、水泉镇、北庄镇、城头镇、西集镇、店子镇、桑村镇、凫城乡',
        },
        { 台儿庄区: '运河街道、邳庄镇、张山子镇、泥沟镇、涧头集镇、马兰屯镇' },
        {
          滕州市:
            '荆河街道、龙泉街道、北辛街道、善南街道、东沙河镇、洪绪镇、南沙河镇、大坞镇、滨湖镇、级索镇、西岗镇、姜屯镇、鲍沟镇、张汪镇、官桥镇、柴胡店镇、羊庄镇、木石镇、界河镇、龙阳镇、东郭镇',
        },
      ],
      东营市: [
        {
          东营区:
            '文汇街道、黄河路街道、东城街道、辛店街道、胜利街道、胜园街道、牛庄镇、六户镇、史口镇、龙居镇',
        },
        { 河口区: '河口街道、义和镇、仙河镇、孤岛镇、新户乡、太平乡、六合乡' },
        { 垦利县: '垦利镇、胜坨镇、郝家镇、永安镇、黄河口镇、西宋乡、董集乡' },
        {
          利津县:
            '利津镇、北宋镇、盐窝镇、陈庄镇、汀罗镇、明集乡、北岭乡、虎滩乡、刁口乡',
        },
        {
          广饶县:
            '广饶镇、大王镇、稻庄镇、石村镇、丁庄镇、李鹊镇、花官乡、陈官乡、大码头乡、西刘桥乡、经济开发区、城区街道',
        },
      ],
      烟台市: [
        { 莱山区: '黄海街道、初家街道、滨海路街道、解甲庄镇、莱山镇' },
        {
          芝罘区:
            '向阳街道、东山街道、毓璜顶街道、通伸街道、凤凰台街道、奇山街道、白石街道、芝罘岛街道、黄务街道、只楚街道、世回尧街道、幸福街道',
        },
        {
          福山区:
            '清洋街道、福新街道、古现街道、大季家街道、高疃镇、张格庄镇、回里镇、门楼镇、八角街道',
        },
        {
          牟平区:
            '宁海街道、文化街道、养马岛街道、观水镇、武宁镇、大窑镇、姜格庄、龙泉镇、玉林店镇、水道镇、莒格庄镇、高陵镇、王格庄镇',
        },
        {
          栖霞市:
            '翠屏街道、庄园街道、亭口镇、唐家泊镇、庙后镇、蛇窝泊镇、官道镇、寺口镇、西城镇、观里镇、苏家店镇、杨础镇、松山镇、桃村镇、臧家庄镇',
        },
        {
          海阳市:
            '方圆街道、东村街道、凤城街道、郭城镇、盘石店镇、徐家店镇、发城镇、小纪镇、行村镇、留格庄镇、大阎家镇、辛安镇、里店镇、朱吴镇、二十里店镇',
        },
        {
          龙口市:
            '徐福镇、七甲镇、下丁家镇、北马镇、芦头镇、黄山馆镇、新嘉街道、东江镇、龙港街道、石良镇、东莱街道、兰高镇、诸由观镇',
        },
        {
          莱阳市:
            '城厢街道、古柳街道、龙旺庄街道、冯格庄街道、羊郡镇、姜疃镇、吕格庄镇、高格庄镇、山前店镇、柏林庄镇、河洛镇、照旺庄镇、大夼镇、沐浴店镇、谭格庄镇、万第镇、团旺镇、穴坊镇、经济开发区',
        },
        {
          莱州市:
            '城港路街道、文峰路街道、文昌街道、永安街道、三山岛街道、金城镇、平里店镇、朱桥镇、驿道镇、程郭镇、郭家店镇、柞村镇、夏邱镇、虎头崖镇、沙河镇、土山镇',
        },
        {
          蓬莱市:
            '北沟镇、大辛店镇、刘家沟镇、小门家镇、潮水镇、大柳行镇、村里集镇、登州街道、紫荆山街道、蓬莱阁街道、新港街道、南王街道',
        },
        {
          招远市:
            '温泉街道（招远经济开发区）、泉山街道、罗峰街道、梦芝街道、辛庄镇、蚕庄镇、张星镇、阜山镇、玲珑镇、金岭镇、大秦家镇、齐山镇、夏甸镇、毕郭镇',
        },
        {
          长岛县:
            '南长山镇、砣矶镇、北长山乡、黑山乡、大钦岛乡、小钦岛乡、南隍城乡、北隍城乡',
        },
      ],
      潍坊市: [
        {
          潍城区: '于河街道、望留街道、西关街道、城关街道、南关街道、北关街道',
        },
        {
          寒亭区:
            '寒亭街道、开元街道、固堤街道、央子街道、大家洼街道、高里镇、朱里镇',
        },
        {
          坊子区:
            '凤凰街道、坊安街道、坊城街道、九龙涧街道、黄旗堡镇、赵戈镇、太保庄镇',
        },
        {
          奎文区:
            '大虞街道、潍州路街道、广文街道、廿里堡街道、北苑街道、梨园街道、东关街道、新城街道、清池街道',
        },
        {
          青州市:
            '王府街道、益都街道、云门山街道、弥河镇、王坟镇、庙子镇、邵庄镇、高柳镇、何官镇、东夏镇、谭坊镇、黄楼',
        },
        {
          诸城市:
            '密州街道、龙都街道、舜王街道、贾悦镇、石桥子镇、林家村镇、桃林乡',
        },
        {
          寿光市:
            '圣城街道、文家街道、古城街道、洛城街道、孙家集街道、化龙镇、营里镇、台头镇、田柳镇、上口镇、侯镇、纪台镇、稻田镇、羊口镇',
        },
        {
          安丘市:
            '景芝镇、柘山镇、吾山镇、大盛镇、金冢子镇、石堆镇、新安街道、兴安街道、凌河镇、辉渠镇、石埠子镇、官庄镇',
        },
        {
          高密市:
            '朝阳街道、醴泉街道、密水街道、柏城镇、夏庄镇、姜庄镇、大牟家镇、阚家镇、井沟镇、柴沟镇',
        },
        {
          昌邑市:
            '奎聚街道、都昌街道、柳疃镇、龙池镇、卜庄镇、围子镇、饮马镇、北孟镇',
        },
        {
          临朐县:
            '城关街道、东城街道、五井镇、冶源镇、寺头镇、九山镇、辛寨镇、沂山镇、柳山镇、上林镇',
        },
        {
          昌乐县:
            '城关街道、宝城街道、朱刘街道、城南街道、五图街道、乔官镇、营邱镇、鄌郚镇、红河镇',
        },
      ],
      济宁市: [
        {
          市中区:
            '仙营街道、金城街道、南苑街道、观音阁街道、阜桥街道、古槐街道、越河街道、济阳街道',
        },
        {
          任城区:
            '三贾街道、许庄街道、柳行街道、李营镇、廿里铺镇、长沟镇、安居镇、唐口镇、接庄镇、南张镇、石桥镇、喻屯镇',
        },
        {
          曲阜市:
            '鲁城街道、书院街道、吴村镇、姚村镇、陵城镇、小雪镇、南辛镇、时庄镇、王庄乡、董庄乡、息陬乡、防山乡',
        },
        {
          兖州市:
            '鼓楼街道、永安街道、新兖镇、谷村镇、大安镇、小孟镇、兴隆庄镇、新驿镇、颜店镇、王因镇、漕河镇、黄屯镇',
        },
        {
          邹城市:
            '钢山街道、千泉街道、凫山街道、香城镇、城前镇、大束镇、北宿镇、中心店镇、唐村镇、太平镇、平阳寺镇、石墙镇、峄山镇、看庄镇、张庄镇、田黄镇、郭里镇',
        },
        {
          微山县:
            '夏镇街道、昭阳街道、韩庄镇、欢城镇、南阳镇、鲁桥镇、傅村镇、留庄镇、微山岛乡、两城乡、马坡乡、高楼乡、张楼乡、赵庙乡、西平乡',
        },
        {
          鱼台县:
            '谷亭镇、清河镇、鱼城镇、王鲁镇、张黄镇、王庙镇、李阁镇、唐马乡、老砦乡、罗屯乡',
        },
        {
          金乡县:
            '金乡镇、羊山镇、胡集镇、肖云镇、鸡黍镇、王丕镇、司马镇、鱼山镇、马庙镇、化雨乡、卜集乡、高河乡、兴隆乡',
        },
        {
          嘉样县:
            '嘉祥镇、纸坊镇、梁宝寺镇、卧龙山镇、疃里镇、马村镇、金屯镇、大张楼镇、老僧堂乡、黄垓乡、万张乡、马集乡、满硐乡、仲山乡、孟姑集乡',
        },
        {
          汶上县:
            '汶上镇、南站镇、南旺镇、次丘镇、寅寺镇、郭楼镇、康驿镇、苑庄镇、义桥乡、刘楼乡、郭仓乡、杨店乡、军屯乡、白石乡',
        },
        {
          泗水县:
            '泗河街道、济河街道、泉林镇、苗馆镇、星村镇、柘沟镇、杨柳镇、中册镇、金庄镇、泗张镇、高峪乡、圣水峪乡、大黄沟乡',
        },
        {
          粱山县:
            '梁山镇、小路口镇、韩岗镇、徐集镇、拳铺镇、杨营镇、韩垓镇、馆驿镇、小安山镇、马营乡、寿张集乡、黑虎庙乡、赵固堆乡、大路口乡',
        },
      ],
      泰安市: [
        {
          泰山区:
            '岱庙街道、财源街道、泰前街道、上高街道、徐家楼街道、省庄镇、邱家店镇、大津口乡',
        },
        {
          岱岳区:
            '粥店街道、天平街道、山口镇、黄前镇、祝阳镇、范镇、角峪镇、徂徕镇、房村镇、满庄镇、北集坡镇、夏张镇、道朗镇、大汶口镇、马庄镇、良庄镇、下港乡、化马湾乡',
        },
        {
          新泰市:
            '青云街道、新汶街道、东都镇、小协镇、翟镇、泉沟镇、羊流镇、果都镇、西张庄镇、天宝镇、楼德镇、禹村镇、宫里镇、谷里镇、石莱镇、放城镇、刘杜镇、汶南镇、龙廷镇、岳家庄乡',
        },
        {
          肥城市:
            '新城街道、石横镇、潮泉镇、孙伯镇、老城镇、王瓜店镇、桃园镇、王庄镇、湖屯镇、安临站镇、安驾庄镇、边院镇、汶阳镇、仪阳乡',
        },
        {
          宁阳县:
            '宁阳镇、泗店镇、东疏镇、堽城镇、蒋集镇、磁窑镇、华丰镇、葛石镇、伏山镇、鹤山乡、东庄乡、乡饮乡',
        },
        {
          东平县:
            '东平镇、老湖镇、斑鸠店镇、银山镇、州城镇、沙河站镇、彭集镇、接山乡、大羊乡、梯门乡、新湖乡、商老庄乡、戴庙乡、旧县乡',
        },
      ],
      威海市: [
        {
          环翠区:
            '环翠楼街道、鲸园街道、竹岛街道、怡园街道、田和街道、皇冠街道、凤林街道、西苑街道、张村镇、羊亭镇、温泉镇、桥头镇、崮山镇、孙家疃镇、泊于镇、草庙子镇、初村镇',
        },
        {
          文登市:
            '龙山街道、天福街道、环山街道、宋村镇、文登营镇、大水泊镇、张家产镇、高村镇、泽库镇、侯家镇、泽头镇、小观镇、葛家镇、米山镇、界石镇、汪疃镇、苘山镇',
        },
        {
          荣成市:
            '俚岛镇、成山镇、埠柳镇、港西镇、夏庄镇、崖西镇、荫子镇、滕家镇、大疃镇、上庄镇、虎山镇、人和镇',
        },
        {
          石岛管理区:
            '桃园街道、东山街道、斥山街道、王连街道、宁津街道、港湾街道',
        },
        { 荣成经济开发区: '崖头街道、寻山街道、崂山街道、城西街道' },
        {
          乳山市:
            '城区街道、夏村镇、乳山口镇、海阳所镇、白沙滩镇、大孤山镇、南黄镇、冯家镇、下初镇、午极镇、育黎镇、崖子镇、诸往镇、乳山寨镇、徐家镇',
        },
      ],
      日照市: [
        {
          东港区:
            '两城镇、陈疃镇、南湖镇、西湖镇、河山镇、三庄镇、涛雒镇、秦楼街道、奎山街道、石臼街道、日照街道、北京路街道',
        },
        {
          岚山区:
            '安东卫街道、后村镇、巨峰镇高兴镇、安东卫街道、虎山镇、岚山街道、碑廓镇、黄墩镇、、前三岛乡',
        },
        {
          五莲县:
            '于里镇、汪湖镇、洪凝镇、街头镇、许孟镇、潮河镇、叩官镇、中至镇、高泽镇、石场乡、户部乡、松柏乡',
        },
        {
          莒县: '城阳镇、中楼镇、龙山镇、安庄镇、寨里河乡、刘官庄镇、夏庄镇、小店镇、长岭镇、陵阳镇、店子集镇、峤山镇、桑园乡、招贤镇、东莞镇、库山乡、碁山镇、果庄乡、洛河镇、阎庄镇、浮来山镇',
        },
      ],
      滨州市: [
        {
          滨城区:
            '市中街道、市西街道、北镇街道、市东街道、彭李街道、滨北街道、里则街道、小营街道、梁才街道、杜店街道、沙河街道、旧镇、堡集镇、尚集乡、秦皇台乡',
        },
        {
          惠民县:
            '孙武镇、石庙镇、桑落墅镇、淄角镇、胡集镇、李庄镇、麻店镇、魏集镇、清河镇、姜楼镇、何坊乡、皂户李乡、辛店乡、大年陈乡',
        },
        {
          阳信县:
            '阳信镇、商店镇、温店镇、河流镇、翟王镇、流坡坞镇、劳店乡、水落坡乡、洋湖乡',
        },
        {
          无棣县:
            '无棣镇、水湾镇、小泊头镇、埕口镇、马山子镇、碣石山镇、信阳乡、车镇乡、佘家巷乡、柳堡乡、西小王乡',
        },
        {
          沾化县:
            '富国镇、下洼镇、古城镇、冯家镇、泊头镇、大高镇、黄升乡、下河乡、利国乡、滨海乡、海防街道',
        },
        {
          博兴县:
            '博兴镇、曹王镇、兴福镇、陈户镇、湖滨镇、店子镇、吕艺镇、纯化镇、庞家镇、乔庄镇',
        },
        {
          邹平县:
            '黛溪街道、黄山街道、高新街道、长山镇、魏桥镇、西董镇、好生镇、临池镇、焦桥镇、韩店镇、孙镇、九户镇、青阳镇、明集镇、台子镇、码头镇',
        },
      ],
      德州市: [
        {
          德城区:
            '天衢街道、运河街道、新湖街道、新华街道、东地街道、二屯镇、黄河涯镇、宋官屯镇、赵虎镇、抬头寺乡、袁桥乡',
        },
        {
          乐陵市:
            '市中街道、胡家街道、云红街道、郭家街道、杨安镇、朱集镇、黄夹镇、丁坞镇、花园镇、郑店镇、化楼镇、孔镇、西段乡、大孙乡、铁营乡、寨头堡乡',
        },
        {
          禹城市:
            '市中街道、房寺镇、辛寨镇、十里望回族乡、张庄镇、梁家镇、李屯乡、莒镇乡、伦镇、安仁镇、辛店镇',
        },
        {
          陵县: '陵城镇、郑家寨镇、麋镇、宋家镇、徽王庄镇、神头镇、滋镇、前孙镇、边临镇、义渡口乡、丁庄乡、于集乡',
        },
        {
          宁津县:
            '宁津镇、柴胡店镇、长官镇、杜集镇、时集镇、保店镇、大柳镇、大曹镇、相衙镇、张大庄乡、刘营伍乡',
        },
        {
          庆云县:
            '渤海路街道、庆云镇、常家镇、尚堂镇、崔口镇、严务乡、东辛店乡、中丁乡、徐园子乡',
        },
        {
          临邑县:
            '邢侗街道、恒源街道、临盘街道、临邑镇、临南镇、德平镇、林子镇、兴隆镇、孟寺镇、翟家乡、理合乡、宿安',
        },
        {
          齐河县:
            '晏城镇、表白寺镇、焦庙镇、赵官镇、祝阿镇、仁里集镇、潘店镇、胡官屯镇、宣章屯镇、华店乡、安头乡、马集乡、刘桥乡、大黄乡',
        },
        {
          平原县:
            '龙门街道、桃园街道、王风楼镇、前曹镇、恩城镇、王庙镇、王杲铺镇、张华镇、腰站镇、坊子乡、王打卦乡、三唐乡',
        },
        {
          夏津县:
            '银城街道、北城街道、苏留庄镇、新盛店镇、雷集镇、郑保屯镇、白马湖镇、东李官屯镇、宋楼镇、香赵庄镇、双庙镇、南城镇、渡口驿乡、田庄乡',
        },
        {
          武城县:
            '广运街道、武城镇、老城镇、滕庄镇、鲁权屯镇、郝王庄镇、杨庄乡、李家户乡、甲马营乡',
        },
      ],
      聊城市: [
        {
          东昌府区:
            '古楼街道、柳园街道、新区街道、湖西街道、道口铺街道、闫寺街道、凤凰街道、北城街道、侯营镇、沙镇镇、堂邑镇、梁水镇、斗虎屯镇、郑家镇、张炉集镇、于集镇、许营乡、朱老庄乡',
        },
        {
          临清市:
            '新华街道、青年街道、先锋街道、大辛庄街道、唐园镇、烟店镇、潘庄镇、尚店乡、八岔路镇、刘垓子镇、戴湾乡、魏湾镇、康庄镇、金郝庄乡、老赵庄镇、松林镇',
        },
        {
          阳谷县:
            '博济桥街道、侨润街道、狮子楼街道、张秋镇、寿张镇、李台镇、阎楼镇、石佛镇、定水镇、安乐镇、阿城镇、七级镇、高庙王乡、大布乡、郭店屯乡、十五里园镇、金斗营乡、西湖乡',
        },
        {
          莘县: '莘亭街道、振兴街道、雁塔街道、东鲁街道、张鲁回族镇、朝城镇、观城镇、古城镇、大张家镇、古云镇、十八里铺镇、燕店镇、董杜庄镇、王奉镇、樱桃园镇、河店镇、妹冢镇、魏庄乡、大王寨乡、俎店乡、张寨乡、徐庄乡、王庄集乡、柿子园乡',
        },
        {
          茌平县:
            '振兴街道、信发街道、博平镇、乐平镇、冯屯镇、菜屯镇、杜郎口镇、韩屯镇、洪官屯乡、肖庄乡、贾寨乡、韩集乡、广平乡、胡屯乡、温陈乡、杨官屯乡',
        },
        {
          东阿县:
            '铜城街道、新城街道、刘集镇、牛角店镇、大桥镇、高集镇、姜楼镇、顾官屯镇、姚寨镇、陈集乡、单庄乡',
        },
        {
          冠县: '冠城镇、东古城镇、北陶馆镇、柳林镇、清水镇、桑阿镇、贾镇、斜店乡、万善乡、店子乡、甘屯乡、兰沃乡、辛集乡、范寨乡、定远寨乡、烟庄乡、梁堂乡',
        },
        {
          高唐县:
            '汇鑫街道、人和街道、鱼邱湖街道、梁村镇、尹集镇、清平镇、固河镇、三十里铺镇、琉璃寺镇、姜店乡、杨屯乡、赵寨子乡',
        },
      ],
      临沂市: [
        {
          兰山区:
            '兰山街道、银雀山街道、金雀山街道、南坊街道、白沙埠镇、枣沟头镇、半程镇、义堂镇、马厂湖镇、李官镇、朱保镇',
        },
        {
          罗庄区:
            '罗庄街道、付庄街道、盛庄街道、册山街道、高都街道、罗西街道、双月湖街道、汤庄街道',
        },
        {
          河东区:
            '九曲街道、芝麻墩街道、梅埠街道、相公街道、太平街道、汤头街道、凤凰岭街道、重沟镇、汤河镇、八湖镇、郑旺镇、刘店子乡',
        },
        {
          沂南县:
            '界湖镇、岸堤镇、孙祖镇、双堠镇、青驼镇、张庄镇、砖埠镇、葛沟镇、杨家坡镇、大庄镇、辛集镇、蒲汪镇、湖头镇、苏村镇、铜井镇、依汶镇、马牧池乡',
        },
        {
          郯城县:
            '郯城镇、马头镇、重坊镇、李庄镇、褚墩镇、杨集镇、黄山镇、港上镇、高峰头镇、庙山镇、沙墩镇、胜利乡、新村乡、花园乡、红花乡、归昌乡、泉源乡',
        },
        {
          沂水县:
            '沂水镇、马站镇、高桥镇、许家湖镇、黄山铺镇、姚店子镇、诸葛镇、崔家峪镇、四十里堡镇、杨庄镇、夏蔚镇、沙沟镇、高庄镇、道托乡、圈里乡、龙家圈乡、泉庄乡、富官庄乡、院东头乡',
        },
        {
          苍山县:
            '卞庄镇、大仲村镇、兰陵镇、长城镇、磨山镇、神山镇、车辋镇、尚岩镇、向城镇、新兴镇、南桥镇、层山镇、庄坞镇、沂堂镇、贾庄乡、矿坑乡、下村乡、鲁城乡、三合乡、兴明乡、二庙乡',
        },
        {
          费县: '费城镇、上冶镇、薛庄镇、方城镇、汪沟镇、探沂镇、朱田镇、梁邱镇、新庄镇、马庄镇、新桥镇、刘庄镇、胡阳镇、石井镇、大田庄乡、南张庄乡、城北乡、芍药山乡',
        },
        {
          平邑县:
            '平邑镇、仲村镇、武台镇、保太镇、柏林镇、卞桥镇、地方镇、铜石镇、温水镇、流峪镇、郑城镇、白彦镇、临涧镇、丰阳镇、资邱乡、魏庄乡',
        },
        {
          莒南县:
            '十字路镇、团林镇、大店镇、坊前镇、坪上镇、相邸镇、板泉镇、洙边镇、文疃镇、壮岗镇、汀水镇、石莲子镇、岭泉镇、筵宾镇、涝坡镇、朱芦镇、道口乡、相沟乡',
        },
        {
          蒙阴县:
            '蒙阴镇、常路镇、岱崮镇、坦埠镇、垛庄镇、高都镇、野店镇、桃墟镇、界牌镇、联城乡、旧寨乡',
        },
        {
          临沭县:
            '临沭镇、蛟龙镇、大兴镇、石门镇、曹庄镇、南古镇、郑山镇、白旄镇、青云镇、玉山镇、店头镇、朱仓乡',
        },
      ],
      菏泽市: [
        {
          牡丹区:
            '东城街道、西城街道、南城街道、北城街道、牡丹街道、丹阳街道、岳程街道、佃户屯街道、何楼街道、万福街道、沙土镇、吴店镇、王浩屯镇、黄堽镇、都司镇、高庄镇、小留镇、李村镇、马岭岗镇、安兴镇、大黄集镇、吕陵镇、胡集乡、皇镇乡',
        },
        {
          曹县: '桃源集镇、韩集镇、邵庄镇、曹城镇、庄寨镇、常乐集乡、楼庄乡、魏湾镇、青岗集乡、普连集镇、古营集镇、王集镇、侯集回族镇、苏集镇、青堌集镇、孙老家镇、阎店楼镇、安蔡楼镇、梁堤头镇、朱洪庙乡、仵楼乡、大集乡、郑庄乡、倪集乡、砖庙镇',
        },
        {
          单县: '单城镇、郭村镇、黄岗镇、终兴镇、孙溜镇、高韦庄镇、徐寨镇、蔡堂镇、朱集镇、李新庄镇、浮岗镇、莱河镇、时楼镇、杨楼镇、张集镇、龙王庙镇、谢集乡、高老家镇、曹庄乡、李田楼乡',
        },
        {
          成武县:
            '成武镇、大田集镇、天官庙镇、汶上镇、南鲁镇、伯乐集镇、苟村镇、白浮图镇、孙寺镇、九女镇、党集乡、张楼乡',
        },
        {
          巨野县:
            '巨野镇、龙堌镇、大义镇、柳林镇、章缝镇、大谢集镇、独山镇、麒麟镇、核桃园镇、田庄镇、太平镇、万丰镇、陶庙镇、董官屯镇、田桥镇、营里镇',
        },
        {
          郓城县:
            '郓城镇、黄安镇、杨庄集镇、侯咽集镇、武安镇、郭屯镇、丁里长镇、玉皇庙镇、程屯镇、随官屯镇、张营镇、潘渡镇、双桥乡、唐庙乡、南赵楼乡、黄堆集乡、黄集乡、李集乡、张鲁集乡、水堡乡、郭陈坡乡',
        },
        {
          鄄城县:
            '鄄城镇、什集镇、红船镇、旧城镇、闫什镇、吉山镇、李进士堂镇、董口镇、临卜镇、彭楼镇、左营乡、大埝乡、引马乡、凤凰乡、富春乡、郑营乡',
        },
        {
          定陶县:
            '定陶镇、仿山乡、张湾镇、马集镇、南王店乡、冉堌镇、黄店镇、孟海镇、半堤乡、陈集镇、杜堂乡',
        },
        {
          东明县:
            '城关镇、东明集镇、刘楼镇、陆圈镇、马头镇、三春集镇、大屯镇、武胜桥乡、菜园集乡、小井乡、沙窝乡、长兴集乡、焦园乡',
        },
      ],
      莱芜市: [
        {
          莱城区:
            '凤城街道、张家洼街道、高庄街道、鹏泉街道、羊里镇、辛庄镇、方下镇、牛泉镇、苗山镇、雪野镇、大王庄镇、寨里镇、杨庄镇、茶叶口镇、和庄乡',
        },
        { 钢城区: '艾山街道、颜庄镇、黄庄镇、里辛镇、辛庄镇' },
      ],
    },
  ],
  河北省: [
    {
      石家庄市: [
        {
          长安区:
            '韦曲街道、郭杜街道、黄良街道、兴隆街道、王寺街道、王曲街道、斗门街道、五星街道、鸣犊街道、杜曲街道、细柳街道、马王街道、高桥街道、灵沼街道、大兆街道、太乙宫街道、魏寨街道、子午街道、炮里街道、引镇街道、王莽街道、东大街道、滦镇街道、杨庄街道、五台街道',
        },
        {
          桥西区:
            '东里街道、中山路街道、南长街道、维明街道、裕西街道、友谊街道、红旗街道、新石街道、苑东街道、西里街道、振头街道、留营街道',
        },
        {
          新华区:
            '革新街街道、新华路街道、宁安路街道、东焦街道、西苑街道、合作路街道、联盟路街道、石岗大街街道、五七路街道、天苑街道、北苑街道、大郭镇、赵陵铺镇、西三庄乡、杜北乡',
        },
        { 井陉矿区: '贾庄镇、凤山镇、横涧乡' },
        {
          裕华区:
            '建华南街道、裕东街道、裕强街道、裕兴街道、裕华街道、槐底街道、东苑街道、裕华路街道、建能街道、裕翔街道、方村镇',
        },
        {
          藁城区:
            '廉州镇、兴安镇、常安镇、贾市庄镇、南营镇、梅花镇、岗上镇、丘头镇、南董镇、九门回族自治乡、张家庄镇、增村镇、南孟镇、西关镇、石家庄经济技术开发区',
        },
        {
          鹿泉区:
            '获鹿镇、经济开发区、寺家庄镇、铜冶镇、上庄镇、李村镇、黄壁庄镇、宜安镇、石井乡、白鹿泉乡、大河镇、山尹村镇、上寨乡',
        },
        {
          栾城区:
            '栾城镇、冶河镇、楼底镇、窦妪镇、郄马镇、南高乡、柳林乡、西营乡、城区街道',
        },
        { 井陉县: '贾庄镇、凤山镇、横涧乡' },
        {
          正定县:
            '微水镇、上安镇、天长镇、秀林镇、南峪镇、威州镇、小作镇、南障城镇、苍岩山镇、测鱼镇、吴家窑乡、北正乡、于家乡、孙庄乡、南陉乡、辛庄乡、南王庄乡',
        },
        {
          行唐县:
            '龙州镇、南桥镇、上碑镇、口头镇、独羊岗乡、安香乡、只里乡、市同乡、翟营乡、城寨乡、上方乡、玉亭乡、北河乡、上阎庄乡、九口子乡',
        },
        {
          灵寿县:
            '灵寿镇、青同镇、塔上镇、陈庄镇、慈峪镇、岔头镇、三圣院乡、北洼乡、牛城乡、狗台乡、南寨乡、燕川乡、谭庄乡、寨头乡、南营乡',
        },
        { 高邑县: '高邑镇、大营镇、富村镇、中韩乡、万城乡' },
        { 深泽县: '深泽镇、铁杆镇、白庄乡、留村乡、赵八乡、桥头乡' },
        {
          赞皇县:
            '赞皇镇、院头镇、南清河乡、西龙门乡、南邢郭乡、张楞乡、土门乡、许亭乡、西阳泽乡、黄北坪乡、嶂石岩乡',
        },
        {
          无极县:
            '无极镇、七汲镇、张段固镇、北苏镇、郭庄镇、大陈镇、高头回族乡、郝庄乡、东侯坊乡、里城道乡、南流乡',
        },
        {
          平山县:
            '平山镇、东回舍镇、温塘镇、古月镇、南甸镇、岗南镇、下槐镇、孟家庄镇、蛟潭庄镇、小觉镇、西柏坡镇、下口镇、东王坡乡、两河乡、西大吾乡、上三汲乡、宅北乡、北冶乡、苏家庄乡、上观音堂乡、杨家桥乡、营里乡、合河口乡',
        },
        {
          元氏县:
            '槐阳镇、宋曹镇、南因镇、殷村镇、姬村镇、南佐镇、东张乡、苏阳乡、赵同乡、北褚镇、马村乡、北正乡、苏村乡、前仙乡、黑水河乡',
        },
        {
          赵县: '赵州镇、北王里镇、新寨店镇、韩村镇、南柏舍镇、范庄镇、沙河店镇、前大章乡、高村乡、王西章乡、谢庄乡',
        },
        {
          辛集市:
            '辛集镇、小辛庄乡、张古庄镇、中里厢乡、天宫营乡、位伯镇、旧城镇、前营乡、新垒头镇、和睦井乡、田家庄乡、新城镇、马庄乡、南智邱镇、王口镇',
        },
        {
          晋州市:
            '晋州镇、小樵镇、马于镇、桃园镇、总十庄镇、东卓宿镇、槐树镇、营里镇、周家庄乡、东里庄镇',
        },
        {
          新乐市:
            '长寿街道、承安镇、马头铺镇、邯邰镇、化皮镇、正莫镇、杜固镇、东王镇、南大岳镇、木村乡、协神乡、彭家庄回族乡',
        },
      ],
      唐山市: [
        {
          迁安县:
            '迁安镇、夏官营镇、杨各庄镇、建昌营镇、赵店子镇、野鸡坨镇、大崔庄镇、杨店子镇、蔡园镇、马兰庄镇、沙河驿镇、木厂口镇、扣庄乡、彭店子乡、闫家店乡、五重安乡、大五里乡、太平庄乡、上射雁庄乡、城区街道',
        },
        {
          遵化县:
            '遵化镇、堡子店镇、马兰峪镇、平安城镇、东新庄镇、新店子镇、党峪镇、地北头镇、东旧寨镇、铁厂镇、苏家洼镇、建明镇、石门镇、西留村乡、崔家庄乡、兴旺寨乡、西下营满族乡、汤泉满族乡、东陵满族乡、刘备寨乡、团瓢庄乡、娘娘庄乡、西三里乡、侯家寨乡、小厂乡',
        },
        {
          滦县: ':滦河街道、古城街道、滦州镇、响嘡镇、东安各庄镇、雷庄镇、茨榆坨镇、榛子镇、杨柳庄镇、油榨镇、古马镇、小马庄镇、九百户镇、王店子镇',
        },
        {
          滦南县:
            '倴城镇、宋道口镇、长凝镇、胡各庄镇、坨里镇、姚王庄镇、司各庄镇、安各庄镇、扒齿港镇、程庄镇、青坨营镇、柏各庄镇、南堡镇、方各庄镇、东黄坨镇、马城镇',
        },
        {
          乐亭县:
            '乐安街道、乐亭镇、汤家河镇、胡家坨镇、王滩镇、闫各庄镇、马头营镇、新寨镇、汀流河镇、姜各庄镇、毛庄镇、庞各庄乡、大相各庄乡、古河乡、中堡镇',
        },
        {
          迁西县:
            '兴城镇、三屯营镇、金厂峪镇、洒河桥镇、东荒峪镇、太平寨镇、罗家屯镇、新集镇、滦阳镇、白庙子乡、上营乡、汉儿庄乡、旧城乡、渔户寨乡、尹庄乡、东莲花院乡、新庄子乡',
        },
        {
          玉田县:
            '玉田镇、亮甲店镇、鸦鸿桥镇、窝洛沽镇、石臼窝镇、虹桥镇、散水头镇、林南仓镇、林西镇、杨家板桥镇、彩亭桥镇、孤树镇、大安镇、唐自头镇、郭家屯乡、林头屯乡、杨家套乡、潮洛窝乡、陈家铺乡、郭家桥乡',
        },
        { 唐海镇: '唐海镇' },
        {
          路南区:
            '稻地镇、女织寨乡、广场街道、小山街道、永红桥街道、惠民道街道、友谊街道、学院南路街道、梁家屯路街道、文北街道',
        },
        {
          路北区:
            '凤凰新城管委会、韩城镇、果园乡、乔屯街道、文化路街道、钓鱼台街道、东新村街道、缸窑街道、机场路街道、河北路街道、龙东街道、大里街道、光明街道、翔云道街道',
        },
        {
          开平区:
            '开平街道、马家沟街道、陡电街道、荆各庄矿区街道、二十二冶街道、税务庄街道、开平镇、栗园镇、郑庄子乡、双桥乡、洼里乡、越河乡',
        },
        {
          古冶区:
            '林西街道、唐家庄街道、古冶街道、赵各庄街道、京华街道、王辇庄乡、习家套乡、大庄坨乡、范各庄镇、卑家店镇',
        },
        {
          丰润区:
            '太平路街道、燕山路街道、浭阳街道、丰润镇、任各庄镇、左家坞镇、泉河头镇、王官营镇、火石营镇、新军屯镇、小张各庄镇、丰登坞镇、李钊庄镇、白官屯镇、石各庄镇、沙流河镇、七树庄镇、杨官林镇、姜家营乡、欢喜庄乡、银城铺镇、刘家营乡、常庄镇',
        },
        {
          丰南区:
            '丰南镇、稻地镇、小集镇、黄各庄镇、西葛镇、大新庄镇、钱营镇、唐坊镇、王兰庄镇、柳树瞿镇、黑沿子镇、大齐各庄镇、南孙庄乡、东田庄乡、尖字沽乡',
        },
        { 高新技术开发区: '唐山市高新技术开发区' },
        { 海港开发区: '海港开发区' },
        { 南堡开发区: '南堡开发区' },
        { 芦台经济技术开发区: '芦台经济技术开发区' },
        { 汉沽管理区: '汉丰镇、振兴街道、农业总公司' },
        { 曹妃甸工业区: '原曹妃甸工业区、曹妃甸生态城、唐海县、南堡开发区' },
      ],
      秦皇岛市: [
        {
          海港区:
            '文化路街道、海滨路街道、北环路街道、建设大街街道、河东街道、西港路街道、燕山大街街道、港城大街街道、东环路街道、白塔岭街道、东港镇、海港镇、腾飞路街道、珠江道街道、黄河道街道、石门寨镇、驻操营镇、杜庄镇、西港镇、海阳镇、北港镇、秦皇岛北部工业区、临港物流园区、圆明山文化旅游产业聚集区、太阳城管委会、金梦海湾管委会',
        },
        {
          山海关区:
            '南关街道、东街街道、西街街道、路南街道、第一关镇、石河镇、孟姜镇、渤海镇',
        },
        {
          北戴河区:
            '牛头崖镇、海滨镇、戴河镇、西山街道、东山街道办事处、北戴河经济技术开发区',
        },
        {
          青龙满族自治县:
            '青龙镇、祖山镇、木头凳镇、双山子镇、马圈子镇、肖营子镇、八道河镇、隔河头镇、娄杖子镇、土门子镇、大巫岚镇、凤凰山乡、龙王庙乡、三星口乡、干沟乡、大石岭乡、官场乡、茨榆山乡、平方子乡、安子岭乡、朱杖子乡、草碾乡、七道河乡、三拨子乡、凉水河乡',
        },
        {
          昌黎县:
            '路东街道、路南街道、路西街道、城郊区、昌黎镇、靖安镇、安山镇、龙家店镇、泥井镇、大蒲河街道、新集镇、刘台庄镇、茹荷镇、朱各庄镇、荒佃庄镇、团林街道、葛条港乡、马坨店乡、两山乡、十里铺乡',
        },
        {
          抚宁区:
            '抚宁镇、榆关镇、台营镇、大新寨镇、茶棚乡、深河乡、骊城街道、南戴河街道、留守营街道、坟坨管理区、下庄管理区、田各庄管理区',
        },
        {
          卢龙县:
            '卢龙镇、燕河营镇、双望镇、刘田各庄镇、石门镇、潘庄镇、木井镇、陈官屯乡、蛤泊乡、印庄乡、刘家营乡、下寨乡',
        },
      ],
      邯郸市: [
        {
          丛台区:
            '人民路街道、沁河街道、中华街道、和平街道、联纺西街道、丛台东街道、丛台西街道、联纺东街道、柳林桥街道、光明桥街道、苏曹、三陵、黄粱梦',
        },
        {
          邯山区:
            '火磨街道、陵园路街道、光明路街道、滏东街道、罗城头街道、渚河路街道、浴新南街道、农林路街道、贸东街道、贸西街道、马头镇、北张庄镇、马庄乡',
        },
        {
          复兴区:
            '胜利桥街道、庞村街道、铁路大院街道、石化街道、化林路街道、二六七二街道、百家村街道、彭家寨乡、户村镇、康庄乡',
        },
        {
          峰峰矿区:
            '临水镇、峰峰镇、新坡镇、大社镇、和村镇、义井镇、彭城镇、界城镇、大峪镇',
        },
        {
          邯郸县: '尚璧镇、河沙镇镇、南吕固乡、南堡乡、代召乡、兼庄乡、姚寨乡',
        },
        {
          武安市:
            '武安镇、康二城镇、午汲镇、磁山镇、伯延镇、淑村镇、大同镇、邑城镇、矿山镇、贺进镇、阳邑镇、徘徊镇、冶陶镇、上团城乡、北安庄乡、北安乐乡、西土山乡、西寺庄乡、活水乡、石洞乡、管陶乡、马家庄乡',
        },
        {
          临漳县:
            '临漳镇、南东坊镇、称勾镇、柳园镇、孙陶镇）、9乡（章里集乡、西羊羔乡、柏鹤乡、香菜营乡、狄邱乡、杜村乡、张村乡、砖寨营乡、习文乡',
        },
        {
          成安县:
            '成安镇、李家疃镇、商城镇、漳河店镇、辛义乡、柏寺营乡、北乡义乡、长巷乡、道东堡乡、城西工业区、商城工业区',
        },
        {
          大名县:
            '大名镇、杨桥镇、万堤镇、龙王庙镇、束馆镇、金滩镇、沙圪塔镇、王村乡、铺上乡、黄金堤乡、大街乡、旧治乡、西未庄乡、孙甘店乡、西付集乡、埝头乡、北峰乡、张集乡、红庙乡、营镇回族乡',
        },
        {
          涉县: '涉城镇、河南店镇、索堡镇、西戌镇、井店镇、更乐镇、固新镇、西达镇、偏城镇、神头乡、辽城乡、偏店乡、龙虎乡、木井乡、关防乡、合漳乡、鹿头乡',
        },
        {
          磁县: '磁州镇、高臾镇、西光禄镇、讲武城镇、岳城镇、观台镇、林坛镇、白土镇、黄沙镇、路村营乡、西固义乡、辛庄营乡、花官营乡、时村营乡、南城乡、台城乡、陶泉乡、都党乡、北贾壁乡',
        },
        {
          肥乡县:
            '肥乡镇、天台山镇、辛安镇、大西韩乡、毛演堡乡、元固乡、屯庄营乡、东漳堡乡、旧店乡',
        },
        {
          永年县:
            '临洺关镇、大北汪镇、广府镇、张西堡镇、南沿村镇、永合会镇、西苏乡、刘汉乡、界河店乡、刘营乡、曲陌乡、正西乡、讲武乡、东杨庄乡、小龙马乡、姚寨乡、小西堡乡、西河庄乡、西阳城乡、辛庄堡乡',
        },
        {
          邱县: '新马头镇、邱城镇、香城固镇、梁二庄镇、南辛店乡、古城营乡、陈村回族乡',
        },
        {
          鸡泽县: '鸡泽镇、小寨镇、双塔镇、浮图店乡、吴官营乡、风正乡、曹庄镇',
        },
        { 广平县: '南阳堡乡、南韩村乡、十里铺乡、胜营镇、平固店镇、广平镇' },
        {
          馆陶县:
            '柴堡镇、馆陶镇、房寨镇、魏僧寨镇、路桥乡、南徐村乡、寿山寺乡、王桥乡、陶山街道办',
        },
        {
          魏县: '魏城镇、东代固镇、德政镇、北皋镇、双井镇、牙里镇、车往镇、回隆镇、张二庄镇、泊口乡、棘针寨乡、沙口集乡、仕望集乡、野胡拐乡、北台头乡、前大磨乡、院堡乡、南双庙乡、大辛庄乡、边马乡、大马村乡',
        },
        {
          曲周县:
            '曲周镇、河南疃镇、第四疃镇、安寨镇、侯村镇、槐桥乡、南里岳乡、白寨乡、大河道乡、依庄乡',
        },
      ],
      邢台市: [
        { 桥东区: '建北街道、青园街道、广安街道、育才街道、跃进街道' },
        {
          桥西区:
            '东里街道、中山路街道、南长街道、维明街道、裕西街道、友谊街道、红旗街道、新石街道、苑东街道、西里街道、振头街道、留营街道',
        },
        { 邢台经济开发区: '邢台经济开发区' },
        {
          大曹庄管理区:
            '徐家河村、老王庄村、刘家场村、宋家庄村、泊里庄村、榆树庄村、盐厂前村、盐厂后村、张家庄村、贾家庄村、鱼滩头村、大曹庄乡',
        },
        {
          邢台县:
            '豫让桥街道、东汪镇、南石门镇、羊范镇、晏家屯镇、王快镇、皇寺镇、祝村镇、西黄村镇、将军墓镇、浆水镇、路罗镇、会宁镇、白岸乡、北小庄乡、太子井乡、城计头乡、冀家村乡、龙泉寺乡、宋家庄乡',
        },
        {
          临城县:
            '石城乡、黑城乡、鸭鸽营乡、赵庄乡、临城镇、东镇镇、西竖镇、郝庄镇',
        },
        {
          内丘县:
            '内丘镇、大孟村镇、金店镇、官庄镇、柳林镇、五郭店乡、南赛乡、獐么乡、侯家庄乡',
        },
        { 柏乡县: '柏乡镇、固城店镇、西汪镇、龙华乡、内步乡、王家庄乡' },
        {
          隆尧县:
            '隆尧镇、魏家庄镇、尹村镇、山口镇、莲子镇镇、固城镇、北楼乡、东良乡、双碑乡、牛家桥乡、千户营乡、大张庄乡',
        },
        {
          任县: '任城镇、邢家湾镇、辛店镇、天口镇、西固城乡、永福庄乡、骆庄乡、大屯乡',
        },
        {
          南和县:
            '和阳镇、贾宋镇、郝桥镇、东三召乡、阎里乡、河郭乡、史召乡、三思乡',
        },
        {
          宁晋县:
            '凤凰镇、河渠镇、北河庄镇、耿庄桥镇、东汪镇、贾家口镇、四芝兰镇、大陆村镇、苏家庄镇、换马店镇、侯口乡、纪昌庄乡、唐邱乡、北鱼乡、宁晋经济开发区、宁晋盐化工园区',
        },
        {
          巨鹿县:
            '巨鹿镇、官亭镇、小吕寨镇、西郭城镇、阎疃镇、王虎寨镇、张王疃乡、堤村乡、观寨乡、苏家营乡',
        },
        { 新河县: '新河镇、寻寨镇、白神首乡、荆家庄乡、西流乡、仁让里乡' },
        {
          广宗县:
            '广宗镇、大平台乡、件只乡、核桃园乡、冯家寨乡、东召乡、北塘疃乡、葫芦乡、经济开发区',
        },
        {
          平乡县: '丰州镇、河古庙镇、平乡镇、油召乡、节固乡、田付村乡、寻召乡',
        },
        {
          威县: '洺州镇、梨元屯镇、章台镇、贺营镇、侯贯镇、七级镇、张家营乡、方家营镇、常庄乡、第什营乡、枣园乡、高公庄乡、固献乡、赵村乡、常屯乡、贺钊乡',
        },
        {
          清河县:
            '葛仙庄镇、连庄镇、油坊镇、谢炉镇、王官庄镇、坝营镇、桥东办事处',
        },
        {
          临西县:
            '临西镇、河西镇、下堡寺镇、尖冢镇、老官寨镇、东枣园乡、吕寨乡、摇鞍镇乡、大刘庄乡',
        },
        {
          南宫市:
            '凤岗街道、南杜街道、北胡街道、西丁街道、苏村镇、大高村镇、垂杨镇、明化镇、段芦头镇、紫冢镇、大村乡、南便村乡、大屯乡、王道寨乡、薛吴村乡',
        },
        {
          沙河市:
            '褡裢街道、桥东街道、桥西街道、赞善街道、周庄街道、新城镇、白塔镇、十里亭镇、綦村镇、册井乡、刘石岗乡、柴关乡、蝉房乡、沙河城镇、留村镇',
        },
      ],
      保定市: [
        {
          竞秀区:
            '先锋街道、新市场街道、东风街道、建设南路街道、韩村北路街道、颉庄乡、富昌乡、韩村乡、南奇乡、江城乡',
        },
        {
          莲池区:
            '联盟街道、红星街道、裕华街道、永华街道、南关街道、和平里街道、五四路街道、东关街道、西关街道、中华路街道、杨庄乡、南大园乡、焦庄乡、五尧乡、东金庄乡、韩庄乡、百楼乡',
        },
        {
          满城区:
            '惠阳街道、满城镇、大册营镇、神星镇、南韩村镇、方顺桥镇、于家庄乡、贤台乡、要庄乡、白龙乡、石井乡、坨南乡、刘家台乡',
        },
        {
          清苑区:
            '清苑镇、冉庄镇、阳城镇、魏村镇、温仁镇、张登镇、大庄镇、臧村镇、白团乡、北店乡、石桥乡、李庄乡、北王力乡、东吕乡、何桥乡、孙村乡、阎庄乡、望亭乡',
        },
        {
          徐水区:
            '漕河镇、高林村镇、遂城镇、大王店镇、大因镇、崔庄镇、安肃镇、户木乡、瀑河乡、义联庄乡、东釜山乡、正村乡、留村乡、东史端乡',
        },
        {
          高碑店市:
            '和平街道、军城街道、东盛街道、北城街道、兴华路街道、方官镇、新城镇、泗庄镇、辛立庄镇、东马营镇、肖官营乡、梁家营乡、张六庄乡、辛桥乡',
        },
        {
          安国市:
            '祁州药市街道、祁州镇、伍仁桥镇、石佛镇、郑章镇、大五女镇、明官店乡、南娄底乡、西安国城乡、西佛落镇、北段村乡',
        },
        {
          涞水县:
            '涞水镇、永阳镇、义安镇、石亭镇、赵各庄镇、九龙镇、三坡镇、明义乡、王村乡、东文山乡、娄村满族乡、宋各庄乡、口乡、龙门乡、胡家庄乡',
        },
        {
          唐县: '仁厚镇、王京镇、高昌镇、北罗镇、白合镇、军城镇、川里镇、长古城乡、都亭乡、南店头乡、北店头乡、罗庄乡、雹水乡、大洋乡、迷城乡、齐家佐乡、羊角乡、石门乡、黄石口乡、倒马关乡',
        },
        {
          易县: '易州镇、高村镇、梁格庄镇、西陵镇、裴山镇、塘湖镇、狼牙山镇、良岗镇、紫荆关镇、桥头乡、白马乡、流井乡、高陌乡、大龙华乡、安格庄乡、凌云册满族回族乡、西山北乡、尉都乡、独乐乡、七峪乡、富岗乡、坡仓乡、牛岗乡、桥家河乡、甘河净乡、蔡家峪乡、南城司乡',
        },
        {
          涞源县:
            '涞源镇、银坊镇、走马驿镇、水堡镇、王安镇、杨家庄镇、白石山镇、南屯乡、北石佛乡、东团堡乡、上庄乡、留家庄乡、南马庄乡、金家井乡、烟煤洞乡、塔崖驿乡、乌龙沟乡',
        },
        {
          定兴县:
            '定兴镇、固城镇、贤寓镇、北河镇、天宫寺镇、小朱庄镇、东落堡乡、高里乡、张家庄乡、姚村乡、肖村乡、柳卓乡、杨村乡、北田乡、北南蔡乡、李郁庄乡、城区',
        },
        {
          顺平县:
            '蒲阳镇、高于铺镇、腰山镇 、蒲上镇、神南镇、白云乡 、河口乡、安阳乡、台鱼乡、大悲乡',
        },
        {
          望都县:
            '望都镇、固店镇、寺庄乡、赵庄乡、黑堡乡、高岭乡、中韩庄乡、贾村乡',
        },
        {
          高阳县:
            '高阳镇、西演镇、庞口镇、邢家南镇、蒲口乡、晋庄乡、龙化乡、庞家佐乡、小王果庄乡',
        },
        {
          安新县:
            '安新镇、大王镇、三台镇、端村镇、赵北口镇、同口镇、刘李庄镇、安州镇、老河头镇、圈头乡、寨里乡、芦庄乡',
        },
        {
          雄县: '雄州镇、昝岗镇、大营镇、龙湾镇、朱各庄乡镇、米家务镇、双堂乡、张岗乡、北沙口乡',
        },
        {
          容城县:
            '容城镇、南张镇、小里镇、大河镇、平王乡、八于乡、贾光乡、晾马台乡',
        },
        {
          曲阳县:
            '恒州镇、灵山镇、羊平镇、燕赵镇、文德镇、产德乡、路庄子乡、党城乡、孝墓乡、齐村乡、晓林乡、郎家庄乡、庄窠乡、北台乡、范家庄乡、邸村乡、东旺乡、下河乡',
        },
        {
          阜平县:
            '阜平镇、龙泉关镇、平阳镇、城南庄镇、天生桥镇、王林口乡、台峪乡、大台乡、史家寨乡、砂窝乡、吴王口乡、夏庄乡、北果元乡',
        },
        {
          博野县: '博野镇、程委镇、小店镇、东墟乡、北杨村乡、城东乡、南小王乡',
        },
        {
          蠡县: '蠡吾镇、留史镇、大百尺镇、辛兴镇、北郭丹镇、万安镇、桑元镇、南庄镇、北埝头乡、鲍墟乡、小陈乡、林堡乡、大曲堤乡',
        },
      ],
      张家口市: [
        { 宣化县: '宣化县' },
        {
          张北县:
            '张北镇、公会镇、大囫囵镇、小二台镇、台路沟乡、油篓沟乡、馒头营乡、二泉井乡、单晶河乡、海流图乡、两面井乡、郝家营乡、白庙滩乡、战海乡、三号乡、大西湾乡',
        },
        {
          康保县:
            '康保镇、张纪镇、土城子镇、邓油坊镇、李家地镇、照阳河镇、屯垦镇、阎油房乡、丹清河乡、哈必嘎乡、二号卜乡、芦家营乡、忠义乡、处长地乡、满德堂乡',
        },
        {
          沽源县:
            '平定堡镇、小厂镇、黄盖淖镇、九连城镇、高山堡乡、小河子乡、二道渠乡、闪电河乡、长梁乡、丰源店乡、西辛营乡、莲花滩乡、白土窑乡、大二号回族乡',
        },
        {
          尚义县:
            '南壕堑镇、大青沟镇、红土梁镇、八道沟镇、小蒜沟镇、三工地镇、满井镇、七甲乡、大营盘乡、甲石河乡、大苏计乡、下马圈乡、石井乡、套里庄乡',
        },
        {
          蔚县: '蔚州镇、代王城镇、西合营镇、吉家庄镇、白乐镇、暖泉镇、南留庄镇、北水泉镇、桃花镇、阳眷镇、宋家庄镇、下宫村乡、南杨庄乡、柏树乡、常宁乡、涌泉庄乡、杨庄窠乡、南岭庄乡、陈家洼乡、黄梅乡、白草村乡、草沟堡乡',
        },
        {
          阳原县:
            '西城镇、东井集镇、东城镇、化稍营镇、揣骨疃镇、要家庄乡、东堡乡、井儿沟乡、三马坊乡、大田洼乡、高墙乡、辛堡乡、马圈堡乡、浮图讲乡',
        },
        {
          怀安县:
            '柴沟堡镇、左卫镇、头百户镇、怀安城镇、第三堡乡、渡口堡乡、第六屯乡、西湾堡乡、西沙城乡、太平庄乡、王虎屯乡',
        },
        {
          万全县:
            '孔家庄镇、洗马林镇、郭磊庄镇、万全镇、膳房堡乡、北新屯乡、北沙城乡、宣平堡乡、旧堡乡、安家堡乡、高庙堡乡',
        },
        {
          怀来县:
            '沙城镇、北辛堡镇、新保安镇、东花园镇、官厅镇、桑园镇、存瑞镇、土木镇、大黄庄镇、西八里镇、小南辛堡镇、狼山乡、鸡鸣驿乡、东八里乡、瑞云观乡、孙庄子乡、王家楼回族乡。县政府驻沙城镇',
        },
        {
          涿鹿县:
            '大堡镇、涿鹿镇、五堡镇、武家沟镇、保岱镇、河东镇、张家堡镇、矾山镇、辉耀镇、温泉屯镇、大河南镇、蟒石口镇、东小庄镇、栾庄乡、黑山寺乡、卧佛寺乡、谢家堡乡',
        },
        {
          赤城县:
            '赤城镇、龙关镇、田家窑镇、雕鹗镇、后城镇、独石口镇、龙门所镇、白草镇、东卯镇、云州乡、马营乡、镇宁堡乡、样田乡、炮梁乡、大海陀乡、三道川乡、东万口乡、茨营子乡',
        },
        {
          崇礼县:
            '西湾子镇、高家营镇、四台嘴乡、红旗营乡、石窑子乡、驿马图乡、石嘴子乡、狮子沟乡、清三营乡、白旗乡',
        },
        { 桥东区: '桥东区' },
        { 桥西区: '桥西区' },
        {
          宣化区:
            '天泰寺街街道、南关街道、建国街街道、工业街街道、南大街街道、皇城街道、大北街街道、庞家堡镇、河子西乡、春光乡、侯家庙乡',
        },
        {
          下花园区:
            '花园乡、辛庄子乡、定方水乡、段家堡乡、城镇街道办事处、煤矿街道办事处',
        },
        { 察北管理区: '黄山、白塔、石门、乌兰、金沙、沙沟乡、宇宙营乡' },
        { 塞北管理区: '塞北管理区' },
        { 高新区: '高新区' },
      ],
      承德市: [
        {
          双桥区:
            '中华路街道、新华路街道、西大街街道、头道牌楼街道、石洞子沟街道、潘家沟街道、桥东街道、大石庙镇、冯营子镇、水泉沟镇、牛圈子沟镇、狮子沟镇、双峰寺镇、上板城镇',
        },
        {
          双滦区:
            '元宝山街道、钢城街道、双塔山镇、滦河镇、偏桥子镇、大庙镇、陈栅子乡、西地满族乡',
        },
        { 鹰手营子矿区: '鹰手营子镇、北马圈子镇、寿王坟镇、汪家庄镇' },
        {
          承德县:
            '下板城镇、甲山镇、六沟镇、三沟镇、头沟镇、高寺台镇、东小白旗乡、鞍匠乡、刘杖子乡、新杖子乡、孟家院乡、大营子乡、八家乡、上谷乡、满杖子乡、石灰窑乡、五道河乡、岔沟乡、岗子满族乡、磴上乡、两家满族乡、三家乡、仓子乡、下板城街道',
        },
        {
          兴隆县:
            '兴隆镇、孤山子镇、蓝旗营镇、半壁山镇、平安堡镇、北营房镇、六道河镇、挂兰峪镇、青松岭镇、雾灵山乡、大杖子乡、蘑菇峪乡、三道河乡、安子岭乡、大水泉乡、李家营乡、陡子峪乡、上石洞乡、八卦岭满族乡、南天门满族乡',
        },
        {
          平泉县:
            '平泉镇、黄土梁子镇、榆树林子镇、杨树岭镇、七沟镇、小寺沟镇、党坝镇、卧龙镇',
        },
        {
          滦平县:
            '滦平镇、长山峪镇、红旗镇、金沟屯镇、虎什哈镇、巴克什营镇、张百湾镇、平坊满族乡、安纯沟门满族乡、付营子乡、小营满族乡、西沟满族乡、邓厂满族乡、五道营子满族乡、马营子满族乡、付家店满族乡、火斗山乡、两间房乡、涝洼乡、大屯满族乡',
        },
        {
          隆化县:
            '隆化镇、韩麻营镇、中关镇、七家镇、汤头沟镇、张三营镇、唐三营镇、蓝旗镇、步古沟镇、郭家屯镇',
        },
        {
          丰宁满族自治县:
            '大阁镇、大滩镇、鱼儿山镇、土城镇、黄旗镇、凤山镇、波罗诺镇、黑山咀镇、天桥镇、万胜永乡、四岔口乡、苏家店乡、外沟门乡、草原乡、窟窿山乡、小坝子乡、五道营乡、选将营乡、西官营乡、王营乡、北头营乡、胡麻营乡、石人沟乡、汤河乡、杨木栅子乡、南关蒙古族乡',
        },
        {
          宽城满族自治县:
            '宽城镇、龙须门镇、峪耳崖镇、板城镇、汤道河镇、饽罗台镇、碾子峪镇、亮甲台镇、化皮溜子乡、塌山乡、孟子岭乡、独石沟乡、东大地乡、铧尖乡、东黄花川乡、苇子沟乡、大字沟门乡、大石柱子乡',
        },
        {
          围场满族蒙古族自治县:
            '围场镇、四合永镇、克勒沟镇、棋盘山镇、半截塔镇、朝阳地镇、朝阳湾镇、腰站乡、黄土坎乡、银窝沟乡、蓝旗卡伦乡、四道沟乡、新地乡、育太和乡、广发永乡、杨家湾乡、郭家湾乡、大唤起乡、哈里哈乡、新拨乡、宝元栈乡、张家湾乡、山湾子乡、姜家店乡、三义永乡、下伙房乡、燕格柏乡、城子乡、牌楼乡、御道口乡、老窝铺乡、大头山乡、南山嘴乡、石桌子乡、西龙头乡、龙头山乡、道坝子乡',
        },
      ],
      沧州市: [
        {
          运河区:
            '水月寺街道、市场街道、公园街道、南湖街道、西环街道、南环街道、以及南陈屯乡、小王庄镇',
        },
        {
          新华区:
            '革新街道、新华路街道、宁安街道、东焦街道、西苑街道、合作路街道、联盟街道、石岗街道、五七街道、天苑街道、北苑街道、大郭镇、赵陵铺镇、西三庄乡、杜北乡',
        },
        {
          泊头市:
            '泊镇、交河镇、富镇、齐桥镇、文庙镇、郝村镇、寺门村镇、洼里王镇八镇及王武乡、四营乡、营子乡、西辛店乡四乡、辖古楼、解放、河东3个街道办事处',
        },
        {
          任丘市:
            '新华路街道、西环路街道、永丰路街道、中华路街道、出岸镇、石门桥镇、吕公堡镇、长丰镇、鄚州镇、苟各庄镇、梁召镇、辛中驿镇、麻家坞镇、议论堡乡、青塔乡、北辛庄乡、七间房乡、北汉乡、于村乡、开发区管理委员会、任丘雁翎工业园区、华北石油管理局',
        },
        {
          黄骅市:
            '骅中街道、骅东街道、骅西街道、黄骅镇、南排河镇、旧城镇、吕桥镇、官庄乡、常郭乡、齐家务乡、羊二庄镇、羊三木回族乡',
        },
        {
          河间市:
            '黎民居乡、果子洼回族乡、北石槽乡、沙洼乡、龙华店乡 、尊祖庄乡、故仙乡、兴村乡、行别营乡、西九吉乡、诗经村乡、时村乡、郭家村乡、瀛州镇、米各庄镇、沙河桥镇、束城镇、留古寺镇、卧佛堂镇、景和镇',
        },
        {
          沧县: '旧州镇、兴济镇、杜生镇、崔尔庄镇、薛官屯乡、捷地回族乡、张官屯乡、李天木回族乡、风化店乡、姚官屯乡、杜林回族乡、汪家铺乡、刘家庙乡、仵龙堂乡、大官厅乡、高川乡、黄递铺乡、大褚村回族乡、纸房头乡',
        },
        {
          青县: '清州镇、木门店镇、流河镇、金牛镇、新兴镇、马厂镇、曹寺乡、盘古乡、陈嘴乡、上伍乡',
        },
        {
          东光县:
            '东光镇、连镇镇、找王镇、秦村镇、灯明寺镇、南霞口镇、大单镇、龙王李乡、于桥乡',
        },
        {
          海兴县:
            '苏基镇、辛集镇、高湾镇、赵毛陶乡、香坊乡、小山乡、张会亭乡、海兴县农场、青先农场、青锋农场',
        },
        {
          盐山县:
            '盐山镇、韩集镇、千童镇、庆云镇、圣佛镇、望树镇。6乡包括：边务乡、小营乡、小庄乡、常庄乡、孟店乡、杨集乡',
        },
        {
          肃宁县:
            '肃宁镇、梁家村镇、窝北镇、尚村镇、万里镇、师素镇、河北留善寺乡、付家佐乡、邵庄乡',
        },
        {
          南皮县:
            '南皮镇、冯家口镇、寨子镇、鲍官屯镇、王寺镇、乌马营镇、大浪淀乡、刘八里乡、潞灌乡',
        },
        {
          吴桥县:
            '桑园镇、铁城镇、于集镇、梁集镇、安陵镇、杨家寺乡、曹家洼乡、宋门乡、何庄乡、沟店铺乡',
        },
        {
          献县: '乐寿镇、淮镇镇、郭庄镇、河城街镇、韩村乡、陌南乡、陈庄乡、尚庄镇、商林乡、段村乡、张村乡、临河乡、小平王乡、十五级乡、垒头乡、南河头乡、西城乡、本斋回族乡',
        },
        {
          孟村回族自治县: '孟村镇、新县镇、辛店镇、高寨镇、宋庄子乡、牛进庄乡',
        },
      ],
      廊坊市: [
        {
          安次区:
            '银河南路街道、光明西道街道、落垡镇、码头镇、葛渔城镇、东沽港镇、杨税务乡、仇庄乡、调河头乡、北史家务乡',
        },
        {
          广阳区:
            '：银河北路街道、爱民东道街道、解放道街道、新开路街道、新源道街道、南尖塔镇、万庄镇、九州镇、北旺乡',
        },
        {
          廊坊经济技术开发区:
            '有辛庄、化营村、大长亭、大官地、桐柏、上庄头、下庄头、小长亭',
        },
        {
          霸州市:
            '编辑霸州镇、南孟镇、信安镇、堂二里镇、煎茶铺镇、胜芳镇、岔河集乡、康仙庄乡、东杨庄乡、王庄子乡、东段乡、杨芬港镇',
        },
        {
          三河市:
            '泃阳镇、李旗庄镇、杨庄镇、皇庄镇、新集镇、段甲岭镇、黄土庄镇、高楼镇、齐心庄镇、燕郊镇',
        },
        {
          永清县:
            '永清镇、韩村镇、后奕镇、别古庄镇、里澜城镇、管家务回族乡、曹家务乡、龙虎庄乡、刘街乡、三圣口乡',
        },
        {
          固安县:
            '固安镇、宫村镇、柳泉镇、牛驼镇、马庄镇、东湾乡、彭村乡、渠沟乡、礼让店乡',
        },
        {
          香河县:
            '淑阳镇、蒋辛屯镇、渠口镇、安头屯镇、安平镇、刘宋镇、五百户镇、钱旺乡、钳屯乡',
        },
        {
          大城县:
            '平舒镇、旺村镇、大尚屯镇、南赵扶镇、留各庄镇、权村镇、里坦镇、广安乡、北魏乡、臧屯乡',
        },
        {
          文安县:
            '文安镇、新镇镇、苏桥镇、大柳河镇、左各庄镇、滩里镇、史各庄镇、赵各庄镇、兴隆宫镇、大留镇镇、孙氏镇、德归镇、大围河回族满族乡',
        },
        { 大厂回族自治县: '大厂镇、夏垫镇、祁各庄镇、邵府乡、陈府乡' },
      ],
      衡水市: [
        {
          桃城区:
            '河西街道、河东街道、路北街道、中华街道、郑家河沿镇、赵圈镇、何家庄乡、大马森乡、邓庄乡、彭杜村乡',
        },
        { 滨湖新区: '义城街道、烟墩街道 滨湖世纪社区' },
        { 工业新区: '工业新区' },
        {
          冀州: '冀州镇、官道李镇、南午村镇、周村镇、码头李镇、西王镇、门庄乡、徐家庄乡、北漳淮乡、小寨乡',
        },
        {
          深州: '唐奉镇、高古庄镇、深州镇、辰时镇、榆科镇、魏桥镇、大堤镇、前磨头镇、王家井镇、护驾迟镇、大屯镇、兵曹乡、穆村乡、东安庄乡、北溪村乡、大冯营乡、乔屯乡、双井经济开发区',
        },
        {
          枣强县:
            '枣强镇、大营镇、马屯镇、恩察镇、肖张镇、加会镇、王常乡、张秀屯镇、王均乡、新屯镇、唐林乡',
        },
        {
          武邑县:
            '武邑镇、清凉店镇、桥头镇、审坡镇、赵桥镇、韩庄镇、紫塔乡、龙店乡、圈头乡、循环经济园区',
        },
        { 武强县: '武强镇、街关镇、周窝镇、豆村乡、北代乡、孙庄乡' },
        {
          饶阳县:
            '饶阳镇、大尹村镇、五公镇、大官亭镇、同岳乡、留楚乡、东里满乡',
        },
        {
          安平县:
            '安平镇、马店镇、南王庄镇、安平镇、马店镇、南王庄镇、何庄乡、油子乡、两洼乡、子文乡、黄城乡',
        },
        {
          故城县:
            '郑口镇、夏庄镇、青罕镇、故城镇、武官寨镇、饶阳店镇、军屯镇、建国镇、西半屯镇、辛庄乡、里老乡、房庄乡、三朗乡',
        },
        {
          阜城县:
            '阜城镇、码头镇、古城镇、霞口镇、崔家庙镇、漫河乡、建桥乡、蒋坊乡、王集乡、大白乡',
        },
        {
          景县: '景州镇、龙华镇、广川镇、王瞳镇、洚河流镇、安陵镇、杜桥镇、王谦寺镇、北留智镇、留智庙镇、刘集乡、连镇乡、梁集乡、温城乡、后留名府乡、青兰乡',
        },
      ],
    },
  ],
  湖北省: [
    {
      武汉市: [
        {
          江岸区:
            '上海街道、大智街道、一元街道、车站街道、四唯街道、永清街道、西马街道、球场街道、劳动街道、二七街道、新村街道、丹水池街道、台北街道 、花桥街道、谌家矶街道、后湖街道',
        },
        {
          江汉区:
            '民族街道、民权街道、花楼街道、满春街道、水塔街道、前进街道、民意街道、新华街道、万松街道、北湖街道、唐家墩街道、常青街道、汉兴街道',
        },
        {
          硚口区:
            '易家墩街道、韩家墩街道、宗关街道、汉水桥街道、宝丰街道、荣华街道、崇仁街道、汉中街道、汉正街街道、六角亭街道、长丰街道',
        },
        {
          汉阳区:
            '月湖街道、晴川街道、建桥街道、鹦鹉街道、洲头街道、翠微街道、五里墩街道、琴断口街道、江汉二桥街道、永丰街道、江堤街道、四新管委会、经济开发区',
        },
        {
          武昌区:
            '杨园街道、徐家棚街道、积玉桥街道、中华路街道、粮道街街道、黄鹤楼街道、紫阳街道、白沙洲街道、首义路街道、中南路街道、水果湖街道、珞珈山街道、石洞街道、南湖街道',
        },
        {
          青山区:
            '红钢城街、新沟桥街、红卫路街、冶金街、钢花村街、青山镇街、武东街、白玉山街、厂前街、工人村街、钢都管委会、北湖管委会',
        },
        {
          洪山区:
            '关山街道、珞南街道、狮子山街道、和平街道、洪山街道、张家湾街道、梨园街道、青菱街街道、卓刀泉街道、红旗街道、葛化街道、花山镇、左岭镇、青菱乡、九峰乡、建设乡、天兴乡',
        },
        {
          东西湖区:
            '长青街、慈惠街、马岭街、新沟镇街、径河街、金银湖街、将军路街、辛安渡办事处、东山办事处、柏泉办事处',
        },
        { 汉南区: '纱帽街道、邓南街道、东荆街道、湘口街道' },
        {
          蔡甸区:
            '蔡甸街道、奓山街道、永安街道、侏儒街道、大集街道、张湾街道、索河镇、玉贤镇、消泗乡、桐湖办事处、军山街、沌口街',
        },
        {
          江夏区:
            '纸坊街道、流芳街道、金口街道、郑店街道、乌龙泉街道、五里界街道、安山街道、山坡街道、湖泗街道、豹澥街道、舒安街道、金水农场',
        },
        {
          黄陂区:
            '前川街道、横店街道、罗汉寺街道、滠口街道、六指街道、天河街道、武湖街道、祁家湾街道、王家河街道、长轩岭街道、李家集街道、姚家集街道、蔡家榨街道、三里桥街道、蔡店街道、木兰乡、大潭原种场',
        },
        {
          新洲区:
            '凤凰镇、徐古镇、辛冲镇、邾城街、涨渡湖街、双柳街、阳逻街、仓埠街、李集街、汪集街、三店街道、潘塘街、旧街街、阳逻经济开发区、道观河风景旅游区',
        },
      ],
      黄石市: [
        {
          黄石港区:
            '黄石港街道、沈家营街道、红旗桥街道、胜阳港街道、花湖街道、江北管理区',
        },
        {
          西塞山区:
            '澄月街道办、陈家湾街道、八泉街道办、临江街道办、黄思湾街、河口镇',
        },
        { 下陆区: '新下陆街道、老下陆街道、东方山街道、团城山街道' },
        {
          铁山区:
            '矿山路社区、建设路社区、九龙洞社区、友爱路社区、胜利路社区、冶矿路社区、铜鼓地社区、向阳路社区、曹家林社区、木栏村、盛洪卿村、三岔路村、龙衢湾村、熊家境村',
        },
        {
          大冶市:
            '东岳路街道、金湖街道、罗家桥街道；金牛镇、保安镇、灵乡镇、金山店镇、还地桥镇、殷祖镇、刘仁八镇、陈贵镇、大箕铺镇、茗山乡',
        },
        {
          阳新县:
            '兴国镇、富池镇、黄颡口镇、湋源口镇、太子镇、大王镇、陶港镇、白沙镇、浮屠镇、三溪镇、王英镇、龙港镇、洋港镇、排市镇、木港镇、枫林镇、阳新工业园、黄石新港物流园、金海开发区、军垦农场管理区、综合农场管理区、半壁山农场管理区、荆头山农场管理区',
        },
      ],
      十堰市: [
        {
          茅箭区:
            '五堰街道、二堰街道、武当街道、大川镇、茅塔乡、鸳鸯乡、东城经济开发区、赛武当风景区',
        },
        {
          张湾区:
            '车城街道、汉江街道、红卫街道、花果街道、柏林镇、黄龙镇、西沟乡、方滩乡、西城经济开发区',
        },
        {
          十堰经济技术开发区:
            '白浪街道、光明居委会、马路居委会、中观居委会、白浪堂居委会、柯家垭村、马路村、小河村、白浪村、方块村',
        },
        {
          郧阳区:
            '城关镇、柳陂镇、茶店镇、谭家湾镇、青山镇、鲍峡镇、杨溪铺镇、安阳镇、青曲镇、白桑关镇、胡家营镇、南化塘镇、谭山镇、梅铺镇、刘洞镇、白浪镇、五峰乡、大柳乡、叶大乡、红岩背国营林场、经济开发区',
        },
        {
          郧西县:
            '城关镇、土门镇、上津镇、店子镇、夹河镇、羊尾镇、观音镇、马安镇、河夹镇、香口乡、关防乡、景阳乡、六郎乡、涧池乡、安家乡、湖北口回族乡、三官洞林区、槐树林特场',
        },
        {
          竹山县:
            '城关镇、溢水镇、官渡镇、麻家渡镇、宝丰镇、擂鼓镇、秦古镇得胜镇、上庸镇、田家坝镇、楼台乡、文峰乡、潘口乡、竹坪乡、深河乡、大庙乡、双台乡、柳林乡、农林四场',
        },
        {
          竹溪县:
            '城关镇、蒋家堰镇、中峰镇、水坪镇、县河镇、丰溪镇、泉溪镇、龙坝镇、新洲乡、兵营新镇、鄂坪乡、汇湾乡、天宝乡、桃源乡、向坝乡',
        },
        {
          房县: '城关镇、红塔镇、军店镇、化龙堰镇、门古寺镇、大木厂镇、青峰镇、土城镇、野人谷镇、白鹤镇、窑淮镇、尹吉甫镇、沙河乡、万峪河乡、九道乡、上龛乡、中坝乡、姚坪乡、回龙乡、五台山林场',
        },
        {
          丹江口市:
            '均州路街道、大坝街道、丹赵路街道、三官殿街道、六里坪镇、均县镇、官山镇、丁家营镇、浪河镇、盐池河镇、土关垭镇、习家店镇、蒿坪镇、石鼓镇、凉水河镇、龙山镇族乡、武当山旅游经济特区',
        },
      ],
      荆州市: [
        {
          荆州区:
            '纪南镇、川店镇、马山镇、八岭山镇、李埠镇、弥市镇、郢城镇、东城街道、西城街道、城南街道、太湖港农场、菱角湖农场、城南经济开发区',
        },
        {
          沙市区:
            '解放路街道、崇文路街道、中山路街道、胜利路街道、立新乡、关沮乡、联合乡、锣场乡',
        },
        {
          江陵县:
            '资市镇、滩桥镇、熊河镇、白马寺镇、沙岗镇、普济镇、郝穴镇、马家寨乡、秦市乡',
        },
        {
          松滋市:
            '新江口镇、沙道观镇、涴市镇、八宝镇、老城镇、陈店镇、南海镇、洈水镇、刘家场镇、街河市镇、王家桥镇、斯家场镇、万家乡、纸厂河镇、杨林市镇、卸甲坪土家族乡',
        },
        {
          公安县:
            '埠河镇、斗湖堤镇、杨家厂镇、麻豪口镇、夹竹园镇、闸口镇、藕池镇、黄山头镇、孟家溪镇、南平镇、章庄铺镇、狮子口镇、斑竹垱镇、毛家港镇、甘家厂乡、章田寺乡',
        },
        {
          监利县:
            '容城镇、朱河镇、新沟镇 、尺八镇、白螺镇 、上车湾镇 、汴河镇 、周老嘴镇 、龚场镇、分盐镇、毛市镇 、福田寺镇、黄歇口镇 、网市镇、汪桥镇、程集镇、三洲镇、桥市镇、红城乡、棋盘乡、柘木乡、大垸农场、荒湖农场、开发区管委会',
        },
        {
          洪湖市:
            '新堤街道、滨湖街道、乌林镇、螺山镇、龙口镇、燕窝镇、新滩镇、黄家口镇、峰口镇、府场镇、曹市镇、戴家场镇、沙口镇、瞿家湾镇、万全镇、汊河镇、老湾回族乡、大同湖管理区、大沙湖管理区、小港管理区',
        },
        { 荆州经济开发区: '荆州经济开发区' },
      ],
      宜昌市: [
        {
          远安县: '鸣凤镇、花林寺镇、旧县镇、洋坪镇、茅坪场镇、荷花镇、河口乡',
        },
        {
          兴山县:
            '古夫镇、高阳镇、峡口镇、南阳镇、黄粮镇、水月寺镇、高桥乡、榛子乡',
        },
        {
          秭归县:
            '茅坪镇、屈原镇、归州镇、水田坝乡、泄滩乡、沙镇溪镇、两河口镇、梅家河乡、磨坪乡、郭家坝镇、九畹溪镇、杨林桥镇',
        },
        {
          长阳土家族自治县:
            '龙舟坪镇、磨市镇、都镇湾镇、资丘镇、渔峡口镇、榔坪镇、贺家坪镇、高家堰镇、大堰乡、鸭子口乡、火烧坪乡',
        },
        {
          五峰土家族自治县:
            '五峰镇、长乐坪镇、渔阳关镇、仁和坪镇、湾潭镇、采花乡、傅家堰乡、牛庄乡、五峰民族工业园',
        },
        {
          宜都市:
            '陆城街道、红花套镇、高坝洲镇、聂家河镇、松木坪镇、枝城镇、姚家店镇、五眼泉镇、王家畈镇、潘家湾土家族乡',
        },
        {
          枝江市:
            '马家店街道、七星台镇、问安镇、董市镇、安福寺镇、白洋镇、百里洲镇、顾家店镇、仙女镇',
        },
        {
          夷陵区:
            '小溪塔街道、樟村坪镇、雾渡河镇、太平溪镇、乐天溪镇、分乡镇、龙泉镇、鸦鹊岭镇、下堡坪乡、黄花乡、邓村乡',
        },
        {
          西陵区:
            '西陵街道、学院街道、云集街道、西坝街道、葛洲坝街道、夜明珠街道、窑湾乡、宜昌开发区、长江三峡风景名胜区',
        },
        { 伍家岗区: ' 大公桥街道、万寿桥街道、宝塔河街道、伍家岗街道、伍家乡' },
        { 点军区: '联棚乡、土城乡、桥边镇、艾家镇、点军街道' },
        { 猇亭区: '古老背街道、云池街道、虎牙街道' },
      ],
      襄阳市: [
        {
          襄城区:
            '王府街道、昭明街道、檀溪街道、庞公街道、隆中街道、余家湖街道、卧龙镇、欧庙镇、尹集乡',
        },
        {
          樊城区:
            '柿铺街道、清河口街道、屏襄门街道、定中门街道、中原街道、王寨街道、汉江街道、米公街道、张湾镇、龙王镇、石桥镇、黄集镇、伙牌镇、古驿镇、朱集镇、程河镇、双沟镇、张家集镇、黄龙镇、峪山镇、东津镇',
        },
        {
          枣阳市:
            '南城街道、北城街道、环城街道、西城开发区、车河管理器、随阳管理区',
        },
        {
          南漳县:
            '城关镇、武安镇、九集镇、肖堰镇、东巩镇、巡检镇、板桥镇、薛坪镇、长坪镇、李庙镇',
        },
        {
          保康县:
            '城关镇、黄堡镇、后坪镇、龙坪镇、马良镇、店垭镇、歇马镇、马桥镇、寺坪镇、过渡湾镇、两峪乡',
        },
        {
          谷城县:
            '城关镇、石花镇、冷集镇、五山镇、紫金镇、盛康镇、南河镇、庙滩镇、茨河镇',
        },
        {
          宜城市:
            '鄢城街道、南营街道、小河镇、郑集镇、孔湾镇、刘猴镇、王集镇、板桥店镇、流水镇、宜城经济开发区、大雁工业园区',
        },
        {
          老河口市:
            '光化街道、酂阳街道、洪山咀镇、李楼镇、仙人渡镇、孟楼镇、张集镇、薛集镇、竹林桥镇、袁冲乡',
        },
      ],
      鄂州市: [
        { 梁子湖区: '太和镇、东沟镇、沼山镇、梁子镇、涂家垴镇' },
        { 华容区: '华容镇、段店镇、庙岭镇、蒲团乡、临江乡' },
        {
          鄂城区:
            '古楼街道、凤凰街道、樊口街道、西山街道、长港镇、杜山镇、泽林镇、碧石渡镇、汀祖镇、花湖镇、杨叶镇、燕矶镇、新庙镇、沙窝乡',
        },
      ],
      荆门市: [
        {
          钟祥市:
            '郢中街办、洋梓镇、长寿镇、丰乐镇、胡集镇、双河镇、磷矿镇、文集镇、冷水镇、石牌镇、旧口镇、柴湖镇、长滩镇、东桥镇、客店镇、张集镇、九里回族乡、官庄湖管理区、南湖原种场、罗汉寺种畜场、温峡水库、石门水库、黄坡水库、钟祥经济开发区',
        },
        {
          东宝区:
            '泉口街道、龙泉街道、漳河镇、马河镇、栗溪镇、子陵镇、石桥驿镇、牌楼镇、仙居乡',
        },
        { 掇刀区: '掇刀石街道、白庙街道、团林铺镇、麻城镇' },
        { 漳河新区: '漳河镇、双喜街道' },
        { 屈家岭管理区: '罗汉寺街道、长滩街道、何集街道、易家岭街道' },
        {
          京山县:
            '新市镇、永兴镇、曹武镇、罗店镇、宋河镇、三阳镇、坪坝镇、绿林镇、杨集镇、孙桥镇、石龙镇、永漋镇、雁门口镇、钱场镇、八里途开发区、县原种场',
        },
        {
          沙洋县:
            '沙洋镇、五里铺镇、纪山镇、后港镇、曾集镇、拾回桥镇、沈集镇、高阳镇、马良镇、官当镇、李市镇、毛李镇、十里铺镇',
        },
        { 荆门高新技术产业开发区: '荆门高新技术产业开发区' },
      ],
      黄冈市: [
        {
          黄州区:
            '赤壁街道、南湖街道、东湖街道、禹王街道、陶店乡、路口镇、堵城镇、陈策楼镇、火车站经济开发区',
        },
        {
          麻城市:
            '鼓楼街道、龙池桥街道、南湖街道、宋埠镇、白果镇、夫子河镇、岐亭镇、中馆驿镇、浮桥河镇、福田河镇、黄土岗镇、阎家河镇、三河口镇、木子店镇、张家畈镇、盐田河镇、乘马岗镇、顺河镇、龟山镇、开发区、狮子峰林场、五脑山林场',
        },
        {
          武穴市:
            '大法寺镇、大金镇、花桥镇、田镇街道、梅川镇、武穴街道、刊江街道、万丈湖街道、余川镇、石佛寺镇、四望镇、龙坪镇',
        },
        {
          团风县:
            '团风镇、方高坪镇、马曹庙镇、总路嘴镇、淋山河镇、回龙山镇、上巴河镇、但店镇、杜皮乡、贾庙乡',
        },
        {
          浠水县:
            '沙洋镇、五里铺镇、纪山镇、后港镇、曾集镇、拾回桥镇、沈集镇、高阳镇、马良镇、官当镇、李市镇、毛李镇、十里铺镇',
        },
        {
          罗田县:
            '凤山镇、匡河镇、九资河镇、河铺镇、骆驼坳镇、大河岸镇、胜利镇、三里畈镇、白庙河镇、大崎镇、平湖乡、白莲河乡',
        },
        {
          英山县:
            '温泉镇、南河镇、红山镇、石头嘴镇、雷家店镇、金家铺镇、草盘地镇、杨柳湾镇、孔家坊乡、陶家河乡、方家咀乡',
        },
        {
          蕲春县:
            '漕河镇、蕲州镇、横车镇、株林镇、狮子镇、张榜镇、大同镇、管窑镇、彭思镇、刘河镇、青石镇、檀林镇、赤东镇、向桥乡、八里湖街道',
        },
        {
          黄梅县:
            '黄梅镇、小池镇、孔垄镇、下新镇、大河镇、停前镇、五祖镇、濯港镇、蔡山镇、新开镇、独山镇、分路镇、杉木乡、柳林乡、苦竹乡、刘佐乡、挪步园管理处、龙感湖管理处',
        },
        {
          红安县:
            '城关镇、七里坪镇、二程镇、高桥镇、觅儿寺镇、上新集镇、八里湾镇、太平桥镇、永佳河镇、华家河镇、杏花乡、火连畈茶场',
        },
        {
          龙感湖管理区:
            '芦柴湖街道、洋湖街道、沙湖街道、春港街道、塞湖街道、青泥湖街道、严家闸街道、城关街道',
        },
      ],
      孝感市: [
        {
          孝南区:
            '书院街道、新华街道、车站街道、广场街道、西河镇、陡岗镇、毛陈镇、祝站镇、杨店镇、肖港镇、三汊镇、新铺镇、卧龙乡、朋兴乡',
        },
        {
          汉川市:
            '仙女山街道、汈东街道、新河镇、马口镇、杨林沟镇、沉湖镇、庙头镇、回龙镇、城隍镇、脉旺镇、垌冢镇、刘家隔镇、分水镇、田二河镇、新堰镇、麻河镇、马鞍乡、韩集乡、西江乡、南河乡、里潭乡、湾潭乡、中洲农场、华严农场、汈汊湖养殖场、三星垸农场',
        },
        {
          应城市:
            '四里棚街道、长江埠街道、杨河镇、城北街道、城中街道、郎君镇、天鹅镇、陈河镇、汤池镇、东马坊街道、田店镇、三合镇、黄滩镇、义和镇、杨岭镇、南垸良种场',
        },
        {
          安陆市:
            '府城街道、南城街道赵棚镇、李店镇、巡店镇、棠棣镇、王义贞镇、雷公镇、孛畈镇、烟店镇、洑水镇陈店乡、辛榨乡、木梓乡、接官乡',
        },
        {
          云梦县:
            '城关镇、义堂镇、曾店镇、吴铺镇、伍洛镇、下辛店镇、道桥镇、隔蒲潭镇、胡金店镇、倒店乡、沙河乡、清明河乡',
        },
        {
          孝昌县:
            '花园镇、周巷镇、卫店镇、丰山镇、王店镇、邹岗镇、小河镇、花西乡、白沙镇、季店乡、小悟乡、陡山乡、经济开发区',
        },
        {
          大悟县:
            '城关镇、阳平镇、芳畈镇、新城镇、夏店镇、刘集镇、河口镇、四姑镇、吕王镇、黄站镇、宣化店镇、丰店镇、大新镇、三里镇、东新乡、高店乡、彭店乡',
        },
      ],
      咸宁市: [
        {
          咸安区:
            '永安街道、浮山街道、温泉街道、汀泗桥镇、双溪桥镇、桂花镇、马桥镇、高桥镇、横沟桥镇、贺胜桥镇、官埠桥镇、向阳湖镇、大幕乡',
        },
        {
          通山县:
            '通羊镇、黄沙铺镇、九宫山镇、洪港镇、南林桥镇、厦铺镇、闯王镇、大畈镇、燕夏乡、杨芳林乡、大路乡、慈口乡、九宫山风景区',
        },
        {
          通城县:
            '隽水镇、石南镇、北港镇、五里镇、马港镇、关刀镇、麦市镇、塘湖镇、沙堆镇、大坪乡、四庄乡',
        },
        {
          嘉鱼县:
            '鱼岳镇、官桥镇、潘家湾镇、簰洲湾镇、陆溪镇、新街镇、渡普镇、高铁岭镇、国营头墩农场',
        },
        {
          崇阳县:
            '天城镇、石城镇、桂花泉镇、白霓镇、青山镇、金塘镇、路口镇、沙坪镇、铜钟乡、高枧乡、港口乡、肖岭乡',
        },
        {
          赤壁市:
            '赤马港街道、陆水湖街道、蒲圻街道、赵李桥镇、新店镇、周郎嘴回族镇、中伙铺镇、茶庵岭镇、官塘驿镇、柳山湖镇、神山镇、车埠镇、黄盖湖镇、余家桥乡',
        },
      ],
      随州市: [
        {
          曾都区:
            '东城街道、 西城街道、 南郊街道、 北郊街道、淅河镇、 万店镇、 何店镇、 洛阳镇、 府河镇、曾都区经济开发区',
        },
        {
          随县: '厉山镇、 洪山镇、 柳林镇、 三里岗镇 、均川镇、 澴潭镇 、安居镇 、万福镇 、新街镇 、高城镇 、殷店镇 、长岗镇、草店镇 、小林镇、 淮河镇 、万和镇 、尚市镇、 唐县镇 、吴山镇',
        },
        {
          广水市:
            '应山街道、十里街道、广水街道、杨寨镇 、陈巷镇、 长岭镇、 马坪镇 、余店镇、 蔡河镇、 郝店镇 、吴店镇、 关庙镇 、武胜关镇、城郊乡、 李店乡 、太平乡、 骆店乡 ',
        },
      ],
      恩施土家族苗族自治州: [
        {
          恩施市:
            '舞阳坝街道、小渡船街道、六角亭街道、崔家坝镇、龙凤镇、板桥镇、白杨坪镇、三岔乡、新塘乡、红土乡、沙地乡、屯堡乡、太阳河乡、白果乡、芭蕉侗族乡、盛家坝乡 ',
        },
        {
          利川市:
            '都亭街道、东城街道、谋道镇、汪营镇、团堡镇、柏杨坝镇、忠路镇、建南镇、毛坝镇、南坪乡、沙溪乡、文斗乡、元堡乡、凉雾乡、福宝山生态综合开发区 ',
        },
        {
          建始县:
            '业州镇、红岩镇、高坪镇、景阳镇、官店镇、花坪镇、长梁乡、茅田乡、龙坪乡、三里乡 ',
        },
        {
          巴东县:
            '信陵镇、东壤口镇、沿渡河镇、官渡口镇、茶店子镇、绿葱坡镇、大支坪镇、野三关镇、清太坪镇、水布垭镇、溪丘湾乡、金果坪乡 ',
        },
        {
          宣恩县:
            '珠山镇、李家河镇、椒园镇、沙道沟镇、高罗乡、长潭河侗族乡、晓关侗族乡、万寨乡、椿木营乡 ',
        },
        {
          咸丰县:
            '高乐山镇、忠堡镇、甲马池镇、朝阳寺镇、清坪镇、丁寨乡、尖山乡、活龙坪乡、小村乡、黄金洞乡 ',
        },
        {
          来凤县:
            '翔凤镇、绿水镇、漫水镇、百福司镇、大河镇、旧司镇、革勒车镇、三胡乡、 ',
        },
        {
          鹤峰县:
            '容美镇、铁炉镇、中营镇、太平镇、燕子镇、邬阳乡、五里乡、下坪乡 ',
        },
      ],
    },
  ],
  湖南省: [
    {
      长沙市: [
        {
          芙蓉区:
            '湘湖街道、定王台街道、韭菜园街道、文艺路街道、朝阳街道、五里牌街道、马王堆街道、荷花园街道、东屯渡街道、火星街道、东岸街道、马坡岭街道、东湖街道、长沙高新技术产业开发区 ',
        },
        {
          天心区:
            '坡子街街道、城南路街道、裕南街街道、金盆岭街道、赤岭路街道、新开铺街道、文源街道、青园街道、桂花坪街道、黑石铺街道、先锋街道、大托铺街道 ',
        },
        {
          岳麓区:
            '岳麓街道、桔子洲街道、望月湖街道、银盆岭街道、观沙岭街道、西湖街道、望城坡街道、望岳街道、咸嘉湖街道、梅溪湖街道、天顶街道、坪塘街道、洋湖街道、含浦街道、学士街道、莲花镇、雨敞坪镇、岳麓山风景名胜区麓山景区、桔子洲景区、大学科技园、岳麓科技产业园 ',
        },
        {
          开福区:
            '青竹湖镇、洪山街道、望麓园街道、清水塘街道、湘雅路街道、伍家岭街道、新河街道、东风路街道、通泰街街道、四方坪街道、芙蓉北路街道、月湖街道、浏阳河街道、捞刀河街道、沙坪街道、新港街道、秀峰街道、长沙金霞经济开发区、 ',
        },
        {
          雨花区:
            '圭塘街道、侯家塘街道、左家塘街道、砂子塘街道、东塘街道、高桥街道、雨花亭街道、井湾子街道、洞井街道、同升街道、黎托街道、东山街道 ',
        },
        {
          望城区:
            '丁字湾街道、书堂山街道、高塘岭街道、喻家坡街道、金山桥街道、黄金园街道、廖家坪街道、白沙洲街道、大泽湖街道、月亮岛街道、桥驿镇、茶亭镇、东城镇、铜官镇、乔口镇、靖港镇、格塘镇、乌山镇、白箬铺镇、雷锋镇、新康乡 ',
        },
        {
          浏阳市:
            '淮川街道、荷花街道、集里街道、关口街道、沿溪镇、官渡镇、高坪镇、三口镇、达浒镇、大围山镇、古港镇、永和镇、张坊镇、大瑶镇、文家市镇、金刚镇、中和镇、澄潭江镇、镇头镇、太平桥镇、普迹镇、枨冲镇、柏加镇、永安镇、洞阳镇、北盛镇、蕉溪乡、沙市镇、社港镇、龙伏镇、淳口镇、官桥镇、永安镇、洞阳镇、北盛镇、蕉溪乡、沙市镇、社港镇、葛家乡、小河乡、溪江乡、杨花乡、七宝山乡 ',
        },
        {
          长沙县:
            '星沙街道、湘龙街道、泉塘街道、暮云镇、跳马镇、黄兴镇、榔梨镇、江背镇、黄花镇、春华镇、果园镇、路口镇、金井镇、高桥镇、双江镇、开慧镇、福临镇、青山铺镇、安沙镇、北山镇、干杉乡、白沙乡 ',
        },
        {
          宁乡县:
            '玉潭街道、城郊街道、白马桥街道、历经铺街道、道林镇、花明楼镇、东湖塘镇、夏铎铺镇、双江口镇、煤炭坝镇、坝塘镇、偕乐桥镇、灰汤镇、双凫铺镇、老粮仓镇、流沙河镇、巷子口镇、龙田镇、横市镇、回龙铺镇、黄材镇、大成桥镇、青山桥镇、金洲镇、大屯营镇、朱良桥乡、菁华铺乡、南田坪乡、资福乡、枫木桥乡、喻家坳乡、沙田乡、沩山乡',
        },
      ],
      株洲市: [
        {
          天元区:
            '嵩山路街道、泰山路街道、栗雨街道、马家河镇、群丰镇、雷打石镇、三门镇 ',
        },
        {
          荷塘区:
            '月塘街道、茨菇塘街道、宋家桥街道、桂花街道、金山街道、仙庾镇、明照乡 ',
        },
        {
          芦淞区:
            '建宁街道、庆云街道、 枫溪街道、龙泉街道、建设街道、董家塅街道、贺家土街道、白关镇、 五里墩乡、姚家坝乡',
        },
        {
          石峰区:
            '田心街道、响石岭街道、清水塘街道、铜塘湾街道、井龙街道、龙头铺镇、云田乡 ',
        },
        { 云龙示范区: '云田镇、龙头铺镇、学林街道 ' },
        {
          醴陵市:
            '阳三街道、西山街道、黄泥坳街道、来龙门街道、南桥镇、富里镇、白兔潭镇、浦口镇、王坊镇、王仙镇、东富镇、泗汾镇、沈潭镇、船湾镇、大障镇、贺家桥镇、栗山坝镇、神福港镇、均楚镇、石亭镇、仙霞镇、黄獭嘴镇、东堡乡、孙家湾乡、清水江乡、嘉树乡、板杉乡、新阳乡、官庄乡、枫林市乡、醴陵经济技术开发区、长庆示范区 ',
        },
        {
          攸县: '联星街道、江桥街道、鸾山镇、黄丰桥镇、柏市镇、酒埠江镇、网岭镇、皇图岭镇、丫江桥镇、新市镇、大同桥镇、上云桥镇、菜花坪镇、渌田镇、石羊塘镇、桃水镇、莲塘坳镇、湖南坳乡、坪阳庙乡、槚山乡、鸭塘铺乡 ',
        },
        {
          株洲县:
            '渌口镇、朱亭镇、古岳峰镇、淦田镇、龙凤乡、龙潭乡、砖桥乡、平山乡、洲坪乡、南阳桥乡、仙井乡、堂市乡、王十万乡、太湖乡 ',
        },
        {
          茶陵县:
            '云阳街道、思聪街道、洣江街道、下东街道、界首镇、湖口镇、浣溪镇、马江镇、高陇镇、潞水镇、虎踞镇、平水镇、枣市镇、火田镇、严塘镇、腰陂镇、舲舫乡、八团乡、秩堂乡、桃坑乡 ',
        },
        {
          炎陵县:
            '霞阳镇、沔渡镇、十都镇、水口镇、三河镇、鹿原镇、垄溪乡、石洲乡、策源乡、下村乡、中村乡、龙渣瑶族乡、平乐乡、船形乡、东风乡、大院农场、九龙社会事务管理局 ',
        },
      ],
      湘潭市: [
        {
          雨湖区:
            '城正街街道、平政路街道、云塘街道、雨湖街道、中山路街道、窑湾街道、广场街道、羊牯塘街道、响塘街道、响水街道、鹤岭镇、楠竹山镇、姜畲镇、长城乡、先锋乡、昭潭乡、护潭乡',
        },
        {
          岳塘区:
            '宝塔街道、中洲路街道、五里堆街道、书院路街道、东坪街道、社建村街道、建设路街道、岳塘街道、下摄司街道、滴水街道、双马街道、板塘街道、易家湾镇、昭山乡、霞城乡、荷塘乡',
        },
        {
          湘乡市:
            '新湘路街道、望春门街道、昆仑桥街道、东山街道、龙洞镇、棋梓镇、潭市镇、白田镇、梅桥镇、泉塘镇、中沙镇、壶天镇、虞唐镇、翻江镇、栗山镇、月山镇、山枣镇、东郊乡、毛田镇、金石镇、育塅乡、金薮乡 ',
        },
        { 韶山市: '清溪镇、银田镇、如意镇、韶山乡、永义乡、杨林乡、大坪乡' },
        {
          湘潭县:
            '易俗河镇、梅林桥镇、谭家山镇、中路铺镇、茶恩寺镇、河口镇、射埠镇、花石镇、青山桥镇、石鼓镇、云湖桥镇、石潭镇、杨嘉桥镇、白石镇、乌石镇、分水乡、排头乡、龙口乡、锦石乡 ',
        },
      ],
      衡阳市: [
        {
          雁峰区:
            '先锋街道、雁峰街道、天马山街道、黄茶岭街道、白沙洲街道、金龙坪街道、岳屏镇、湘江乡',
        },
        {
          石鼓区:
            '人民路街道、青山街道、潇湘街道、五一街道、合江街道、黄沙湾街道、松木乡、角山乡 ',
        },
        {
          珠晖区:
            '广东路街道、东风路街道、冶金街道、苗圃街道、粤汉街道、新湘街道、衡州路街道、茶山坳镇、东阳渡镇、和平乡、酃湖乡',
        },
        {
          蒸湘区:
            '蒸湘街道、红湘街道、联合街道、华兴街道、长湖乡、雨母山乡、呆鹰岭镇 ',
        },
        { 南岳区: '祝融街道、南岳镇、岳林乡、龙凤乡、拜殿乡 ' },
        {
          衡阳县:
            '西渡镇、岘山镇、三湖镇、井头镇、关市镇、台源镇、曲兰镇、杉桥镇、金兰镇、金溪镇、洪市镇、界牌镇、集兵镇、渣江镇、演陂镇、库宗桥镇、石市镇、大安乡、长安乡、角山乡、栏垅乡、岣嵝乡、溪江乡、樟木乡 ',
        },
        {
          衡南县:
            '云集镇、向阳镇、廖田镇、茶市镇、冠市镇、江口镇、宝盖镇、花桥镇、铁丝塘镇、泉溪镇、洪山镇、三塘镇、谭子山镇、鸡笼镇、泉湖镇、柞市镇、茅市镇、硫市镇、栗江镇、近尾洲镇、车江镇、咸塘镇、相市乡、洲市乡、松江乡、以及川口街道、岐山街道 ',
        },
        {
          衡山县:
            '开云镇、萱洲镇、长江镇、店门镇、白果镇、东湖镇、新桥镇、马迹镇、贺家乡、永和乡、福田铺乡、岭坡乡、望峰乡、长青乡、贯塘乡、江东乡、沙泉乡 ',
        },
        {
          衡东县:
            '城关镇、石湾镇、新塘镇、大浦镇、吴集镇、甘溪镇、杨林镇、草市镇、杨桥镇、霞流镇、荣桓镇、白莲镇、高湖镇、蓬源镇、莫井乡、踏庄乡、高塘乡、南湾乡、大桥镇、三樟乡、栗木乡、珍珠乡、石滩乡、德圳乡',
        },
        {
          祁东县:
            '洪桥镇、白鹤铺镇、金桥镇、鸟江镇、粮市镇、河洲镇、归阳镇、过水坪镇、双桥镇、灵官镇、风石堰镇、白地市镇、黄土铺镇、石亭子镇、官家嘴镇、步云桥镇、砖塘镇、蒋家桥镇、太和堂镇、马杜桥乡、凤歧坪乡、城连圩乡、四明山乡 ',
        },
        {
          耒阳市:
            '蔡子池街道、灶市街街道、水东江街道、五里牌街道、三顺街道、黄市镇、小水镇、公平圩镇、泗门洲镇、三都镇、南阳镇、竹市镇、夏塘镇、龙塘镇、哲桥镇、永济镇、遥田镇、新市镇、洲陂乡、亮源乡、马水乡、太平圩乡、导子乡、东湖圩乡、上架乡、沙明乡、大义乡、磨形乡、南京镇仁义乡、余庆乡、长坪乡、大和圩乡、坛下乡、大市乡、淝田镇',
        },
        {
          常宁市:
            '宜阳街道、培元街道、泉峰街道、水口山街道、柏坊镇、松柏镇、烟洲镇、荫田镇、白沙镇、西岭镇、盐湖镇、三角塘镇、洋泉镇、庙前镇、罗桥镇、板桥镇、胜桥镇、官岭镇、新河镇、宜潭乡、蓬塘乡、兰江乡、大堡乡、江河乡、弥泉乡、塔山瑶族乡 ',
        },
      ],
      邵阳市: [
        {
          双清区:
            '兴隆街道 、龙须塘街道、汽车站街道、小江湖街道、东风路街道、桥头街道、高崇山镇、渡头桥镇、城东乡、石桥乡、火车站乡、云水乡 ',
        },
        {
          大祥区:
            '中心路街道、红旗路街道、城北路街道、城西路街道、翠园街道、百春园街道、城南街道、学院路街道、火车南站街道、雨溪镇、罗市镇、檀江乡、蔡锷乡、板桥乡 ',
        },
        {
          北塔区:
            '新滩镇街道、状元洲街道、田江乡、茶元头乡、陈家桥乡、江北民营经济开发试验区 ',
        },
        {
          邵东县:
            '大禾塘街道、两市塘街道、宋家塘街道、廉桥镇、黑田铺镇、牛马司镇、范家山镇、九龙岭镇、仙槎桥镇、火厂坪镇、佘田桥镇、灵官殿镇、团山镇、砂石镇、流光岭镇、流泽镇、魏家桥镇、野鸡坪镇、杨桥镇、水东江镇、黄陂桥乡、双凤乡、周官桥乡、简家陇乡、堡面前乡、石株桥乡、界岭乡、斫曹乡、皇帝岭林场 ',
        },
        {
          新邵县:
            '酿溪镇、严塘镇、雀塘镇、陈家坊镇、潭溪镇、寸石镇、坪上镇、龙溪铺镇、巨口铺镇、新田铺镇、小塘镇、潭府乡、大新乡、迎光乡、太芝庙乡 ',
        },
        {
          邵阳县:
            '塘渡口镇、白仓镇、金称市镇、塘田市镇、黄亭市镇、长阳铺镇、岩口铺镇、九公桥镇、下花桥镇、谷洲镇、郦家坪镇、五峰铺镇、黄塘乡、小溪市乡、霞塘云乡、长乐乡、蔡桥乡、河伯乡、黄荆乡、诸甲亭乡、罗城乡、金江乡 ',
        },
        {
          洞口县:
            '洞口镇、江口镇、毓兰镇、高沙镇、竹市镇、石江镇、黄桥镇、山门镇、醪田镇、花园镇、花古乡、古楼乡、长塘瑶族乡、罗溪瑶族乡、月溪乡、渣坪乡、岩山乡、杨林乡、水东乡、石柱乡、桐山乡、大屋瑶族乡、邵阳市茶铺茶场 ',
        },
        {
          隆回县:
            '桃洪镇、小沙江镇、金石桥镇、司门前镇、高平镇、六都寨镇、荷香桥镇、横板桥镇、周旺镇、滩头镇、鸭田镇、西洋江镇、雨山镇、三阁司镇、北山镇、岩口镇、南岳庙镇、麻塘山乡、虎形山瑶族乡、大水田乡、羊古坳乡、罗洪乡、七江镇、荷田乡、石门乡、山界回族乡',
        },
        {
          绥宁县:
            '长铺镇、武阳镇、李熙桥镇、红岩镇、唐家坊镇、金屋塘镇、东山侗族乡、鹅公岭侗族苗族乡、朝仪侗族乡、在市苗族乡、乐安铺苗族侗族乡、黄桑坪苗族乡、关峡苗族乡、长铺子苗族乡、党坪苗族乡、联民苗族瑶族乡、麻塘苗族乡、枫木团苗族侗族乡、河口苗族乡、竹舟江苗族乡、白玉乡、黄土矿乡、瓦屋塘乡、水口乡、梅坪乡 ',
        },
        {
          新宁县:
            '金石镇、一渡水镇、水庙镇、白沙镇、回龙镇、崀山镇、高桥镇、黄龙镇、马头桥镇、万塘乡、飞仙桥乡、丰田乡、安山乡、巡田乡、麻林瑶族乡、黄金瑶族乡、 清江桥乡、靖位乡 ',
        },
        {
          城步苗族自治县:
            '儒林镇、茅坪镇、西岩镇、丹口镇、五团镇、威溪乡、长安营乡、白毛坪乡、兰蓉乡、汀坪乡、南山镇、蒋坊乡、土桥农场管理区 ',
        },
        {
          武冈市:
            '辕门口街道、迎春亭街道、水西门街道、法相岩街道、邓元泰镇、湾头桥镇、文坪镇、荆竹铺镇、稠树塘镇、邓家铺镇、龙溪镇、司马冲镇、龙田乡、头堂乡、马坪乡、晏田乡、秦桥乡、水浸坪乡、双牌乡 ',
        },
      ],
      岳阳市: [
        {
          岳阳楼区:
            '岳阳楼街道、三眼桥街道、吕仙亭街道、金鹗山街道、五里牌街道、望岳路街道、枫桥湖街道、城陵矶街道、洛王街道、东茅岭街道、洞庭街道、奇家岭街道、站前路街道、王家河街道、梅溪镇、郭镇',
        },
        {
          君山区:
            '广兴洲镇、许市镇、钱粮湖镇、良心堡镇、采桑湖镇、柳林洲镇、西城街道、芦苇总场、水产养殖场 ',
        },
        {
          云溪区:
            '长岭街道、云溪乡、云溪镇、路口镇、文桥镇、陆城镇、道仁矶镇、永济乡 ',
        },
        {
          汨罗市:
            '城关镇、汨罗镇、新市镇、古培镇、白水镇、川山坪镇、高家坊镇、弼时镇、李家塅镇、沙溪镇、黄柏镇、长乐镇、三江镇、大荆镇、桃林寺镇、屈子祠镇、范家园镇、城郊乡、红花乡、黄市乡、玉池乡、天井乡、古仑乡、智峰乡、八景乡、火天乡、新塘乡、白塘乡、磊石乡、原种场、范家园茶场、磊石渔场、桃林林场、玉池林场、白水苗圃 ',
        },
        {
          临湘市:
            '长安街道、桃矿街道、忠防镇、白云镇、聂市镇、源潭镇、江南镇、羊楼司镇、桃林镇、长塘镇、白羊田镇、詹桥镇、儒溪镇、定湖镇、黄盖镇、五里牌乡、乘风乡、坦渡乡、城南乡、横铺乡 ',
        },
        {
          岳阳县:
            '城关镇、鹿角镇、麻塘镇、黄沙街镇、新墙镇、柏祥镇、筻口镇、公田镇、毛田镇、月田镇、张谷英镇、新开镇、中洲乡、长湖乡、步仙乡、甘田乡、杨林乡、云山乡、相思乡、饶村乡、东洞庭湖管委会 ',
        },
        {
          平江县:
            '三市镇、长寿镇、汉昌镇、嘉义镇、龙门镇、安定镇、伍市镇、向家镇、瓮江镇、虹桥镇、南江镇、浯口镇、梅仙镇、岑川镇、童市镇、福寿山镇、上塔市镇、石牛寨镇、三阳乡、三墩乡、大洲乡、木金乡、咏生乡、余坪乡、板江乡、南桥乡、黄金洞乡',
        },
        {
          湘阴县:
            '文星镇、城西镇、新泉镇、岭北镇、湘滨镇、杨林寨乡、南湖洲镇、石塘乡、东塘镇、六塘乡、三塘镇、白泥湖乡、袁家铺镇、长康镇、玉华乡、界头铺镇、静河乡、樟树镇、青潭乡 ',
        },
        {
          华容县:
            '城关镇、洪山头镇、三封寺镇、治河渡镇、北景港镇、鲇鱼须镇、宋家嘴镇、万庾镇、操军镇、梅田湖镇、插旗镇、注滋口镇、东山镇、胜峰乡、新河乡、护城乡、新建乡、南山乡、终南乡、幸福乡、团洲乡 ',
        },
      ],
      常德市: [
        {
          武陵区:
            '东江街道、启明街道、永安街道、府坪街道、丹阳街道、穿紫河街道、白马湖街道、芷兰街道、南坪街道、芙蓉街道、长庚街道、河洑镇、丹洲乡、芦荻山乡 ',
        },
        {
          鼎城区:
            '玉霞街道、红云街道、郭家铺街道、武陵镇、蒿子港镇、中河口镇、十美堂镇、镇德桥镇、牛鼻滩镇、灌溪镇、蔡家岗镇、斗姆湖镇、黄土店镇、双桥坪镇、雷公庙镇、石板滩镇、大龙站镇、尧天坪镇、黄珠洲乡、黑山嘴乡、大龙站乡、长岭岗乡、丁家港乡、草坪乡、唐家铺乡、沧山乡、钱家坪乡、港二口乡、尧天坪乡、长茅岭乡、 回族维吾尔族民族乡',
        },
        {
          安乡县:
            '深柳镇、大鲸港镇、黄山头镇、三岔河镇、官垱镇、下渔口镇、陈家嘴镇、焦圻镇、安障乡、安昌乡、安宏乡、安生乡、安全乡、安福乡、安凝乡、安丰乡、安裕乡、安康乡、安德乡 ',
        },
        {
          汉寿县:
            '蒋家嘴镇、岩汪湖镇、坡头镇、酉港镇、洲口镇、罐头嘴镇、沧港镇、朱家铺镇、太子庙镇、毓德铺镇、崔家桥镇、军山铺镇、百禄桥镇、洋淘湖镇、龙阳镇、周文庙乡、鸭子港乡、文蔚乡、新兴乡、聂家桥乡、毛家滩回族维吾尔族乡、丰家铺乡、东岳庙乡、岩嘴乡、株木山乡、三和乡、龙潭桥乡、月明潭乡、大南湖乡、高新区 ',
        },
        {
          澧县: '澧阳街道、澧西街道、澧浦街道、澧澹街道、张公庙镇、澧南镇、小渡口镇、梦溪镇、复兴厂镇、盐井镇、雷公塔镇、大堰垱镇、王家厂镇、金罗镇、码头铺镇、方石坪镇、甘溪滩镇、火连坡镇、澧东乡、涔南乡、大坪乡、道河乡、九垸乡、官垸乡、永丰乡、如东乡、双龙乡、宜万乡、车溪乡、中武乡、闸口乡、洞市乡、杨家坊乡、太青乡 ',
        },
        {
          临澧县:
            '安福镇、合口镇、新安镇、佘市桥镇、太浮镇、四新岗镇、停弦渡镇、修梅镇、杉板乡、文家乡、陈二乡、柏枝乡、烽火乡、望城乡、杨板乡、官亭乡、九里乡',
        },
        {
          桃源县:
            '漳江镇、陬市镇、盘塘镇、黄石镇、漆河镇、理公港镇、观音寺镇、龙潭镇、三阳港镇、剪市镇、茶庵铺镇、西安镇、沙坪镇、桃花源镇、架桥镇、马鬃岭镇、凌津滩镇、青林回族维吾尔族乡、车湖垸乡、枫树维吾尔族回族乡、木塘垸乡、双溪口乡、郝坪乡、九溪乡、黄甲铺乡、钟家铺乡、牛车河乡、佘家坪乡、太平桥乡、浯溪河乡、深水港乡、泥窝潭乡、兴隆街乡、太平铺乡、牯牛山乡、杨溪桥乡、寺坪乡、郑家驿乡、芦花潭乡 ',
        },
        {
          石门县:
            '楚江镇、蒙泉镇、夹山镇、易家渡镇、新关镇、皂市镇、维新镇、太平镇、磨市镇、壶瓶山镇、南北镇、二都乡、罗坪乡、雁池乡、新铺乡、白云乡、三圣乡、子良乡、所街乡、龙凤园艺场、大同山林场、秀坪园艺场、东山峰农场、洛浦寺林场 ',
        },
        {
          津市市:
            '三洲驿街道、 汪家桥街道 、襄阳街街道、 金鱼岭街道、 新洲镇、 渡口镇 、保河堤镇 、白衣镇、灵泉镇、李家铺乡 ',
        },
      ],
      张家界市: [
        {
          永定区:
            '永定街道、崇文街道、大庸桥街道、南庄坪街道、官黎坪街道、西溪坪街道、永定街道、崇文街道、大庸桥街道、南庄坪街道、官黎坪街道、西溪坪街道、枫香岗乡、沙堤乡、合作桥乡、罗水乡、桥头乡、三家馆乡、罗塔坪乡、青安坪乡、双溪桥乡、谢家垭乡、三岔乡、四都坪乡',
        },
        {
          武陵源区:
            '军地坪街道、天子山镇、索溪峪土家族乡、协合乡、中湖乡、张家界国家森林公园管理处 ',
        },
        {
          慈利县:
            '零阳镇、岩泊渡镇、溪口镇、东岳观镇、通津铺镇、杉木桥镇、象市镇、江垭镇苗市镇、零溪镇、高桥镇、龙潭河镇、广福桥镇、南山坪乡、宜冲桥乡、洞溪乡、金坪乡、景龙桥乡、二坊坪乡、国太桥乡、庄塌乡、杨柳铺乡、朝阳乡、三官寺土家族乡、高峰土家族乡、许家坊土家族乡、金岩土家族乡、赵家岗土家族乡、甘堰土家族乡、阳和土家族乡 ',
        },
        {
          桑植县:
            '澧源镇、瑞塔铺镇、官地坪镇、凉水口镇、龙潭坪镇、五道水镇、陈家河镇、廖家村镇、利福塔镇、空壳树乡、汨湖乡、竹叶坪乡、人潮溪乡、西莲乡、白石乡、长潭坪乡、樵子湾乡、谷罗山乡、沙塔坪乡、苦竹坪乡、四方溪乡、芭茅溪乡、细沙坪乡、八大公山乡、蹇家坡乡、岩屋口乡、河口乡、上河溪乡、两河口乡、打鼓泉乡、上洞街乡、天星山林场 、走马坪白族乡、刘家坪白族乡、芙蓉桥白族乡、麦地坪白族乡、马合口白族乡、淋溪河白族乡、洪家关白族乡',
        },
      ],
      益阳市: [
        {
          资阳区:
            '长春镇、新桥河镇、迎风桥镇、沙头镇、茈湖口镇、张家塞乡、长春经济开发区',
        },
        {
          赫山区:
            '桃花仑街道、金银山街道、赫山街道、会龙山街道、八字哨镇、岳家桥镇、兰溪镇、欧江岔镇、衡龙桥镇、龙光桥镇、沧水铺镇、泥江口镇、泉交河镇、新市渡镇、牌口乡、笔架山乡、龙岭工业园 ',
        },
        {
          大通湖区:
            '军地坪街道、天子山镇、索溪峪土家族乡、协合乡、中湖乡、张家界国家森林公园管理处 ',
        },
        {
          沅江市:
            '庆云山街道、琼湖街道、南大膳镇、黄茅洲镇、四季红镇、阳罗洲镇、草尾镇、泗湖山镇、共华镇、南嘴镇、新湾镇、三眼塘镇、茶盘洲镇、万子湖乡、南洞庭芦苇场、漉湖芦苇场 ',
        },
        {
          安化县:
            '东坪镇、清塘铺镇、梅城镇、仙溪镇、大福镇、长塘镇、羊角塘镇、冷市镇、小淹镇、江南镇、柘溪镇、马路口镇、奎溪镇、烟溪镇、渠江镇、平口镇、乐安镇、滔溪镇、高明乡、龙塘乡、田庄乡、南金乡、古楼乡 ',
        },
        {
          桃江县:
            '桃花江镇、修山镇、三堂街镇、鸬鹚渡镇、大栗港镇、武潭镇、马迹塘镇、石牛江镇、牛田镇、松木塘镇、灰山港镇、浮丘山乡、高桥乡、沾溪乡、鲊埠回族乡',
        },
        {
          南县: '明山头镇、青树嘴镇、厂窖镇、武圣宫镇、河坝镇、金盆镇、北洲子镇、南洲镇、华阁镇、茅草街镇、三仙湖镇、麻河口镇、浪拔湖镇、乌嘴乡、中鱼口乡 ',
        },
      ],
      娄底市: [
        {
          娄星区:
            '乐坪街道、花山街道、黄泥塘街道、长青街道、大科街道、涟滨街道、大埠桥街道、杉山镇、万宝镇、茶园镇、百亩乡、小碧乡、双江乡、石井乡',
        },
        {
          冷水江市:
            '冷水江街道、锡矿山街道、沙塘湾街道、布溪街道、禾青镇、岩口镇、渣渡镇、铎山镇、毛易镇、三尖镇、金竹山镇、潘桥乡、梓龙乡、矿山乡、中连乡、同兴乡 ',
        },
        {
          涟源市:
            '蓝田街道、六亩塘镇、石马山镇、安平镇、湄江镇、伏口镇、桥头河镇、七星街镇、杨市镇、枫坪镇、斗笠山镇、水洞底镇、白马镇、茅塘镇、荷塘镇、金石镇、三甲乡、龙塘乡、古塘乡、渡头塘镇 ',
        },
        {
          双峰县:
            '永丰镇、荷叶镇、井字镇、梓门桥镇、杏子铺镇、走马街镇、蛇形山镇、洪山殿镇、甘棠镇、三塘铺镇、青树坪镇、花门镇、锁石镇、石牛乡、沙塘乡、印塘乡 ',
        },
        {
          新化县:
            '新化经济开发区、上梅镇、石冲口镇、科头乡、维山乡、洋溪镇、槎溪镇、水车镇、文田镇、奉家镇、游家镇、炉观镇、西河镇、孟公镇、天门乡、琅塘镇、金凤乡、荣华乡、桑梓镇、曹家镇、吉庆镇、坐石乡、温塘镇、田坪镇、白溪镇、油溪乡、圳上镇、大熊山林场、古台山林场 ',
        },
      ],
      郴州市: [
        {
          苏仙区:
            '苏仙岭街道、南塔街道、白鹿洞街道、王仙岭街道、卜里坪街道、观山洞街道、桥口镇、白露塘镇、良田镇、栖凤渡镇、坳上镇、许家洞镇、五里牌镇、塘溪镇、五盖山镇、马头岭乡',
        },
        {
          北湖区:
            '人民路街道、北湖街道、燕泉街道、下湄桥街道、骆仙街道、郴江街道、增福街道、涌泉街道、石盖塘镇、华塘镇、鲁塘镇、保和镇、大塘瑶族乡、月峰瑶族乡、芙蓉乡、永春乡、市经济技术开发区',
        },
        {
          资兴市:
            '唐洞街道、东江街道、滁口镇、三都镇、蓼江镇、七里镇、兴宁镇、程水镇、州门司镇、青腰镇、黄草镇、东坪乡、团结瑶族乡、连坪瑶族乡、兰市乡、波水乡、清江乡、龙溪乡、白廊乡',
        },
        {
          安仁县:
            '永乐江镇、安平镇、龙海镇、关王镇、灵官镇、羊脑乡、龙市乡、豪山乡、洋际乡、竹山乡、渡口乡、华王乡、牌楼乡、平背乡、坪上乡、承坪乡、新洲乡　',
        },
        {
          桂阳县:
            '龙潭街道、鹿峰街道、黄沙坪街道；正和镇、太和镇、洋市镇、和平镇、流峰镇、塘市镇、春陵江镇、仁义镇、莲塘镇、浩塘镇、荷叶镇、樟市镇、方元镇、雷坪镇、敖泉镇、欧阳海镇、四里镇、桥市乡、泗洲乡、光明乡、白水乡、杨柳瑶族乡、华山瑶族乡',
        },
        {
          永兴县:
            '便江镇、马田镇、悦来镇、高亭镇、复和镇、油市镇、湘阴渡镇、塘门口镇、黄泥镇、金龟镇、樟树镇、柏林镇、太和镇、鲤鱼塘镇、三塘乡、油麻乡、洋塘乡、香梅乡、龙形市乡、七甲乡、大布江乡',
        },
        {
          宜章县:
            '玉溪镇、白石渡镇、麻田镇、梅田镇、迎春镇、黄沙镇、一六镇、白沙圩乡、瑶岗仙镇、杨梅山镇、莽山瑶族乡、岩泉镇、栗源镇	、五岭乡、浆水乡、长村乡、天塘乡、笆篱乡、关溪乡、赤石乡、里田乡、平和乡',
        },
        {
          嘉禾县:
            '珠泉镇、车头镇、塘村镇、袁家镇、龙潭镇、行廊镇、肖家镇、石桥镇、普满乡、田心乡、坦坪乡、广发乡、盘江乡',
        },
        {
          临武县:
            '舜峰镇武水镇、南强镇、金江镇、香花镇、楚江镇、麦市镇、汾市镇、水东镇、花塘乡、同益乡、土地乡、双溪乡、武源乡、万水乡、镇南乡、大冲乡、西山瑶族乡',
        },
        {
          汝城县:
            '永丰乡、集益乡、濠头乡、井坡乡、田庄乡、南洞乡、延寿乡、岭秀乡、盈洞乡、卢阳镇、泉水镇、大坪镇、三江口镇、热水镇、土桥镇、暖水镇、小垣镇、马桥镇、文明镇、汝城县经济开发区、汝城温泉旅游度假区',
        },
        {
          桂东县:
            '沤江镇、清泉镇、寨前镇、大塘镇、普乐镇、沙田镇、四都镇、桥头乡、寒口乡、增口乡、流源乡、贝溪乡、新坊乡、东洛乡、青山乡',
        },
      ],
      永州市: [
        {
          零陵区:
            '徐家井街道、朝阳街道、南津渡街道、七里店街道；水口山镇、珠山镇、黄田铺镇、富家桥镇、菱角塘镇、邮亭圩镇、接履桥镇、石岩头镇；大庆坪乡、梳子铺乡、石山脚乡、凼底乡',
        },
        {
          冷水滩区:
            '：梅湾街道、菱角山街道、肖家园街道、杨家桥街道、梧桐街道、凤凰街道、珊瑚街道、曲河街道、花桥街镇、普利桥镇、牛角坝镇、高溪市镇、黄阳司镇、上岭桥镇、竹山桥镇、伊塘镇、岚角山镇、蔡市镇、仁湾镇、杨村甸乡',
        },
        {
          祁阳县:
            '观音滩镇、茅竹镇、三口塘镇、大忠桥镇、肖家村镇、八宝镇、白水镇、进宝塘镇、黄泥塘镇、潘市镇、梅溪镇、羊角塘镇、下马渡镇、七里桥镇、大村甸镇、黎家坪镇、文富市镇、文明铺镇、龚家坪镇、金洞镇、龙山街道、浯溪街道、长虹街道、内下乡、小金洞乡、晒北滩乡、凤凰乡、白果乡、石鼓源乡、上司源乡、万宝山乡',
        },
        { 东安县: '大庙口镇、紫溪市镇、芦洪市镇' },
        {
          双牌县:
            '泷泊镇、江村镇、茶林镇、五里牌镇、理家坪乡、上梧江瑶族乡、塘底乡、麻江乡、何家洞乡、永江乡、尚仁里乡、平福头乡、五星岭乡、打鼓坪乡',
        },
        {
          道县: '濂溪街道、上关街道、东门街道、营江街道、西洲街道、万家庄街道、梅花镇、寿雁镇、仙子脚镇、清塘镇、祥霖铺镇、蚣坝镇、四马桥镇、白马渡镇、新车镇、白芒铺镇、柑子园镇、桥头镇、乐福堂乡、审章塘瑶族乡、井塘瑶族乡、洪塘营瑶族乡、月岩林场、大坪铺农场',
        },
        { 江永县: '潇浦镇、上江圩镇、允山镇' },
        {
          宁远县:
            '天堂镇、水市镇、湾井镇、冷水镇、太平镇、禾亭镇、仁和镇、中和镇、柏家坪镇、清水桥镇、鲤溪镇、保安镇、九嶷山瑶族乡、荒塘瑶族乡、棉花坪瑶族乡、桐木漯瑶族乡、九疑山林场、白云山林场、雾云山林场、洋塘林场',
        },
        {
          蓝山县:
            '塔峰镇、竹管寺镇、毛俊镇、楠市镇、所城镇、新圩镇、祠堂圩乡、土市乡、太平圩乡、汇源瑶族乡、犁头瑶族乡、浆洞瑶族乡、紫良瑶族乡、大桥瑶族乡、荆竹瑶族乡、蓝山荆竹林场、蓝山浆洞林场、蓝山南岭林场、蓝山原种场、蓝山黄毛岭茶场',
        },
        {
          新田县:
            '龙泉镇金陵镇、骥村镇、枧头镇、石羊镇、新圩镇、新隆镇、莲花乡、茂家乡、门楼下瑶族乡、冷水井乡、毛里乡、十字乡、金盆圩乡、三井乡、陶岭乡、高山乡、知市坪乡、大坪塘乡',
        },
        {
          江华瑶族自治县:
            '沱江镇、桥头铺镇、东田镇、大路铺镇、白芒营镇、涛圩镇、河路口镇、小圩镇、大圩镇、水口镇、码市镇、界牌乡、桥市乡、大石桥乡、清塘壮族乡、两岔河乡、务江乡、花江乡、湘江乡、贝江乡、未竹口乡、大锡乡、江华林业采育场',
        },
        {
          金洞管理区:
            '金洞镇、小金洞乡、上司源乡、晒北滩瑶族乡、白果市乡、凤凰乡、石鼓源乡、万宝山乡',
        },
        { 回龙圩管理区: '回龙圩管理区' },
      ],
      怀化市: [
        {
          鹤城区:
            '坨院街道、迎丰街道、红星街道、城中街道、城北街道、河西街道、城南街道、芦坪乡、凉亭坳乡、贺家田乡、黄金坳镇、盈口乡、杨村乡、石门乡、黄岩乡',
        },
        {
          洪江管理区:
            '河滨路街道、沅江路街道、新街街道、高坡街街道、横岩乡、常青乡、桂花园乡',
        },
        {
          中方县:
            '中方镇、牌楼坳镇、泸阳镇、花桥镇、铜湾镇、桐木镇、铁坡镇、炉亭坳乡、下坪乡、聂家村乡、龙场乡、铜鼎乡、新建乡、袁家乡、蒋家乡、新路河乡、丁家乡、锦溪乡、活水乡、石宝乡、蒿吉坪瑶族乡、接龙乡',
        },
        {
          沅陵县:
            '沅陵镇、五强溪镇、官庄镇、凉水井镇、七甲坪镇、麻溪铺镇、筲箕湾镇、明溪口镇、太常乡、盘古乡、二酉苗族乡、荔溪乡、马底驿乡、楠木铺乡、杜家坪乡、北溶乡、深溪口乡、肖家桥乡、大合坪乡、火场土家族乡、清浪乡、陈家滩乡、借母溪乡',
        },
        {
          辰溪县:
            '辰阳镇、孝坪镇、田湾镇、火马冲镇、黄溪口镇、潭湾镇、安坪镇、寺前镇、船溪乡、城郊乡、板桥乡、长田湾乡、小龙门乡、后塘瑶族乡、苏木溪瑶族乡、罗子山瑶族乡、上蒲溪瑶族乡、仙人湾瑶族乡、龙头庵乡、锦滨乡、石碧乡、桥头乡、大水田乡、桥头溪乡石马湾乡、龙泉岩乡、修溪乡、伍家湾乡、柿溪乡、谭家场乡',
        },
        {
          溆浦县:
            '龙潭镇、卢峰镇、低庄镇、观音阁镇、黄茅园镇、江口镇、均坪镇、双井镇、桥江镇、水东镇、两丫坪镇、谭家湾镇、祖市殿镇、葛竹坪镇',
        },
        {
          会同县:
            '林城镇、坪村镇、堡子镇、团河镇、若水镇、朗江镇、广坪镇、马鞍镇、沙溪乡、金子岩侗族苗族乡、王家坪乡、高椅乡、黄茅乡、肖家乡、金龙乡、宝田侗族苗族乡、漠滨侗族苗族乡、青朗侗族苗族乡、炮团侗族苗族乡、地灵乡、岩头乡、洒溪乡、',
        },
        {
          麻阳苗族自治县:
            '高村镇、锦和镇、江口墟镇、岩门镇、兰里镇、吕家坪镇、郭公坪乡、长潭乡、拖冲乡、尧市乡、文昌阁乡、大桥江乡、舒家村乡、隆家堡乡、谭家寨乡、石羊哨乡、板栗树乡、谷达坡乡、兰村乡、栗坪乡、绿溪口乡、和坪溪乡、黄桑乡',
        },
        {
          新晃侗族自治县:
            '新晃镇、波洲镇、兴隆镇、鱼市镇、凉伞镇、扶罗镇、中寨镇、步头降苗族乡、洞坪乡、大湾罗乡、方家屯乡、晏家乡、林冲乡、天堂乡、黄雷乡、凳寨乡、茶坪乡、新寨乡、贡溪乡、李树乡、禾滩乡、碧朗乡、米贝苗族乡',
        },
        {
          芷江侗族自治县:
            '芷江镇、罗旧镇、新店坪镇、碧涌镇、公坪镇、牛牯坪乡、艾头坪乡、岩桥乡、水宽乡、木叶溪乡、五郎溪乡、麻缨塘乡、竹坪铺乡、土桥乡、上坪乡、大树坳乡、大洪山乡、杨公庙乡、梨溪口乡、洞下场乡、罗岩乡、板山乡、大龙乡、禾梨坳乡、冷水溪乡、楠木坪乡、晓坪乡、萝卜田乡',
        },
        {
          靖州苗族侗族自治县:
            '渠阳镇、甘棠镇、大堡子镇、坳上镇、新厂镇、平茶镇、太阳坪乡、三锹乡、文溪乡、寨牙乡、横江桥乡、铺口乡、藕团乡',
        },
        {
          通道侗族自治县:
            '双江镇、县溪镇、播阳镇、临口镇、牙屯堡镇、菁芜洲镇、溪口镇、陇城镇、江口乡、戈冲苗族乡、大高坪苗族乡、独坡乡、杉木桥乡、木脚乡、下乡乡、马龙乡、传素瑶族乡、黄土乡、坪坦乡、甘溪乡',
        },
        {
          洪江市:
            '黔城镇、安江镇、托口镇、江市镇、沅河镇、 双溪镇、 雪峰镇、塘湾镇、硖州乡、龙田乡、岔头乡、茅渡乡、铁山乡、群峰乡、湾溪乡、洗马乡、大崇乡、熟坪乡、沙湾乡、太平乡、土溪乡、岩垅乡、红岩乡、龙船塘瑶族乡、深渡苗族乡',
        },
      ],
      湘西土家族苗族自治州: [
        {
          龙山县:
            '民安镇、石羔镇、茨岩塘镇、红岩溪镇、洗车河镇、隆头镇、苗儿滩镇、里耶镇、召市镇、桂塘镇、华塘、三元乡、石牌乡、桶车乡、兴隆街乡、洗洛乡、新城乡、白羊乡、湾塘乡、水田坝乡、大安乡、乌鸦乡、猛必乡、比溪乡、塔泥乡、农车乡、西湖乡、茅坪乡、水沙坪乡、他砂乡、靛房乡、坡脚乡、凤溪乡、猛西乡、洛塔乡、干溪乡、八面乡、长潭乡、贾市乡、岩冲乡、内溪乡、瓦房乡、辽叶乡、火岩乡、老兴乡、咱果乡、贾坝乡',
        },
        {
          吉首市:
            '乾州街道、吉凤街道、湘西经济开发区、双塘镇、马颈坳镇、社塘坡乡、矮寨镇、河溪镇、丹青镇、寨阳乡、已略乡、太坪乡、白岩乡、排绸乡、排吼乡',
        },
        {
          泸溪县:
            '白沙镇、 浦市镇、达岚镇 、合水镇、兴隆场镇、潭溪镇、洗溪镇、武溪镇、上堡乡、石榴坪乡、解放岩乡、小章乡、白羊溪乡、梁家潭乡、八什坪乡',
        },
        {
          凤凰县:
            '白沙镇 、浦市镇、达岚镇 、合水镇、兴隆场镇、潭溪镇、洗溪镇、武溪镇、上堡乡、石榴坪乡、解放岩乡、小章乡、白羊溪乡、梁家潭乡、八什坪乡',
        },
        {
          花垣县:
            '花垣镇、边城镇、龙潭镇、民乐镇、团结镇、吉卫镇、麻栗场镇、雅酉镇、长乐乡、两河乡、排碧乡、董马库乡、猫儿乡、补抽乡、道二乡、排吾乡、排料乡、雅桥乡',
        },
        {
          保靖县:
            '迁陵镇、复兴镇、普戎镇、毛沟镇、野竹坪镇、清水坪镇、比耳镇、水田河镇、葫芦镇、碗米坡镇、大妥乡、阳朝乡、涂乍乡、水银乡、清水乡、夯沙乡',
        },
        {
          古丈县:
            '古阳镇、默戎镇、罗依溪镇、红石林镇、岩头寨镇、断龙山乡、高峰乡、河蓬乡、坪坝乡、山枣乡、双溪乡、高望界乡',
        },
        {
          永顺县:
            '灵溪镇、首车镇、泽家镇、芙蓉镇（原王村镇）、永茂镇、石堤镇、塔卧镇、万坪镇 （原龙寨镇）、青坪镇、砂坝镇、松柏镇、长官镇、两岔乡、勺哈乡、西歧乡、对山乡、大坝乡、列夕乡、回龙乡、小溪乡、朗溪乡、润雅乡、车坪乡、毛坝乡、万民乡、盐井乡、抚志乡、吊井乡、颗砂乡、高坪乡',
        },
      ],
    },
  ],

  海南省: [
    {
      海口市: [
        {
          秀英区:
            '秀英街道、海秀街道、长流镇、西秀镇、海秀镇、石山镇、永兴镇、东山镇',
        },
        {
          龙华区:
            '城西镇、龙桥镇、新坡镇、遵谭镇、龙泉镇、中山街道、滨海街道、金贸街道、大同街道、海垦街道、金宇街道',
        },
        {
          琼山区:
            '府城街道、滨江街道、凤翔街道、国兴街道、龙塘镇、云龙镇、红旗镇、旧州镇、三门坡镇、甲子镇、大坡镇国营红明农场、国营东昌农场,省岭脚热带作物场,区属新民林场、中税热作场、长昌煤矿',
        },
        {
          美兰区:
            '灵山镇、演丰镇、三江镇、大致坡镇、白龙街道、蓝天街道、和平南街道、海府路街道、博爱街道、白沙街道、海甸街道、人民路街道、新埠街道',
        },
      ],
      三亚市: [
        { 国营农场: '国营农场' },
        {
          河东区:
            '九曲街道、芝麻墩街道、梅家埠街道、相公街道、太平街道、汤头街道、凤凰岭街道、朝阳街道、重沟镇、八湖镇、郑旺镇',
        },
        {
          海棠湾镇:
            '永宁社区、林旺社区、藤海社区、藤桥村、龙楼 村、营头村、龙海村、椰林村、东溪村、海丰村、升昌村、青田村、江林村、龙江村、庄大村、林新村、凤塘村、洪李村、北山村、三灶村、湾坡村、铁炉村',
        },
        { 崖城镇: '崖城镇' },
        { 吉阳镇: '吉阳镇' },
        { 天涯镇: '天涯镇' },
        { 凤凰镇: '凤凰镇' },
        {
          育才镇:
            '那受村、那会村、马亮村、龙密村、马脚村、明善村、雅林村、雅亮村、青法村、抱安村',
        },
        {
          河西区:
            '大营门街道、下瓦房街道、桃园街道、挂甲寺街道、马场街道、越秀路街道、友谊路街道、天塔街道、尖山街道、陈塘庄街道、柳林街道、东海街道、梅江街道',
        },
      ],
      三沙市: [
        {
          西沙群岛:
            '金银岛、珊瑚岛、 甘泉岛、 全富岛、鸭公岛 、银屿、银屿仔、 咸舍峙、石屿、 晋卿岛、 琛航岛、 广金岛、盘石屿、中建岛、永兴岛、石岛、南岛、中岛、北岛、赵述岛、东岛、 高尖石、筐仔沙洲、东新沙洲、西新沙洲、南沙洲、中沙洲、北沙洲、西沙洲',
        },
        {
          中沙群岛:
            '西门暗沙、本固暗沙、美滨暗沙、鲁班暗沙、中北暗沙、比微暗沙、隐矶滩、武勇暗沙、济猛暗沙、海鸠暗沙、安定连礁、美溪暗沙、布德暗沙、波洑暗沙、排波暗沙、过淀暗沙、排洪滩、涛静暗沙、控湃暗沙、华夏暗沙、石塘连礁、指掌暗沙、南扉暗沙、漫步暗沙、乐西暗沙、屏南暗沙、黄岩岛、宪法暗沙、一统暗沙、神狐暗沙、中南暗沙、北岩、南岩',
        },
        {
          南沙群岛:
            '双子群礁、北子岛、南子岛、贡士礁、北外沙洲、奈罗礁、东南暗沙、东北暗沙、北子暗沙、中业群礁、中业岛、渚碧礁、乐斯暗沙、永登暗沙、道明群礁、双黄沙洲、南钥岛、杨信沙洲、库归礁、郑和群礁、太平岛、敦谦沙洲、舶兰礁、安达礁、鸿庥岛、南薰礁、九章群礁、西门礁、东门礁、安乐礁、长线礁、主权礁、牛轭礁、染青东礁、染青沙洲、龙虾礁、扁参礁、漳溪礁、屈原礁、琼礁、赤瓜礁、鬼喊礁、华礁、吉阳礁、景宏岛、南门礁、康乐礁、小现礁、大现礁、尹庆群礁、中礁、西礁、东礁、华阳礁',
        },
      ],
      儋州市: [
        {
          儋州市:
            '那大镇、和庆镇、南丰镇、大成镇、雅星镇、兰洋镇、光村镇、木棠镇、海头镇、峨蔓镇、三都镇、王五镇、白马井镇、中和镇、排浦镇、东成镇、新州镇、国营西培农场、国营西华农场、国营西庆农场、国营西流农场、国营西联农场、国营蓝洋农场、国营新盈农场、国营八一农场、国营龙山农场、国营红岭农场',
        },
      ],
      五指山市: [
        {
          通什镇:
            '河北东社区、河北西社区、河南东社区、河南西社区、番茅村、什保村、福关村、红雅村、福利村、牙日村、应示村、番慢村、福安村、番香村、牙畜村、报龙村、太平村、什会村、番寨村、南定村',
        },
        { 南圣镇: '南圣村、红合村、什兰村、牙南村、同甲村、毛祥村' },
        {
          毛阳镇:
            '牙合村、毛旦村、毛辉村、什稿村、毛阳村、牙力村、毛栈村、牙胡村、空联村、什益村、毛兴村、毛贵村、毛路村',
        },
        { 番阳镇: '番阳村、孔首村、毛组村、布伦村、加艾村' },
        {
          畅好乡:
            '草办村、畅好村、番贺村、番通村、什哈村、什奋村、毛召村、什冲村、保国村、番好村',
        },
        { 毛道乡: '毛道村、毛枝村、毛卓村、红运村' },
        { 水满乡: '方龙村、新村村、水满村、毛脑村、牙排村' },
      ],
      文昌市: [
        {
          文昌市:
            '文城镇、重兴镇、蓬莱镇、会文镇、东路镇、潭牛镇、东阁镇、文教镇、东郊镇、龙楼镇、昌洒镇、翁田镇、抱罗镇、冯坡镇、锦山镇、铺前镇、公坡镇',
        },
      ],
      琼海市: [
        {
          嘉积镇:
            '美景社区、加祥社区、新民社区、纪纲社区、朝标社区、东区社区、加囊社区、登仙岭社区、龙利坡社区、蔗园社区、先锋社区、山叶社区、文坡社区、红星社区、勇敢村、不偏村、龙池村、南堀村、南中村、棉寨村、桥头村、雅洞村、益群村、军屯村、逢龙村、坡头村、万石村、官塘村、椰子寨村、温泉村、下寨垌村、参古村、上甬村、龙寿村、礼都村、山辉村、黄崖村、田头村、乌石村、大礼村、里邦村、龙阁村、泮水村、大坡村、俸田村、新朝村、东山村',
        },
        {
          万泉镇:
            '文曲社区、西河村、丹村村、文台村、夏坡村、龙头村、博山村、文南村、南轩村、大雅村、沐皇村、加城村、光跃村、罗凌村、新市村、加文村、枫树坡村',
        },
        { 石壁镇: '下朗村、石壁村、南星村、南通村、赤坡村、岸田村、水口仔村' },
        {
          中原镇:
            '中原村、大克村、锦武村、仙寨村、迈汤村、逢来村、山仙村、三更村、水口村、黄思村、黄竹村、排塘村、书斋村、沙坡村、新华村、联光村、乌皮村、乐群村、排沟村、长仙村、星池村、仙村村',
        },
        {
          博鳌镇:
            '东海村、东屿村、朝烈村、珠联村、田甬村、博鳌村、古调村、指母村、海燕村、中南村、仰大村、乐城村、莫村村、培兰村、沙美村、北山村、北岸村',
        },
        {
          阳江镇:
            '阳江村、岭下村、题榜村、益良村、上科村、江南村、东兴村、红色村、龙山村、老区村、桥园村、群联村、文市村、利试考村、棉坡村',
        },
        {
          龙江镇:
            '蓝山村、南正村、龙江村、滨滩村、中洞村、博文村、蒙养村、深造村、南面村',
        },
        {
          潭门镇:
            '日新村、潭门村、旧县村、林桐村、草塘村、墨香村、金鸡村、北甬村、凤头村、苏区村、社昌村、多亩村、西村村、福田村',
        },
        {
          塔洋镇:
            '先亮村、珍寨村、福寨村、联丰村、加贤村、红庄村、群良村、联先村、红花村、裕山村、里文村、千秋村、诗书村、孟里村、鱼良村',
        },
        {
          长坡镇:
            '文子村、椰林村、欧村村、青葛村、长坡村、社学村、福头村、孟文村、牛角村、良玖村、东塘村、多玉村、渔业村、伍园村、礼昌村、福石岭村、陈村村、黄号村、烟塘村、大头坡村、长山园村',
        },
        {
          大路镇:
            '大路村、礼合村、美容村、安竹村、新村村、岭脚村、青天村、湖仔村、马寨村、江湖村、蔗园坡村、石桥村、青廊村、堆头村、云满村',
        },
        { 会山镇: '大火村、沐塘村、三洲村、溪仔村、牛路岭电站农场' },
      ],
      万宁市: [
        {
          万宁市:
            '万城镇、龙滚镇、山根镇、和乐镇、后安镇、大茂镇、东澳镇、礼纪镇、长丰镇、北大镇、南桥镇、三更罗镇、国营东兴农场、国营东和农场、国营东岭农场、国营南林农场、国营新中农场、兴隆华侨农场',
        },
      ],
      东方市: [
        {
          八所镇:
            '解放社区、东海社区、琼西社区、友谊社区、滨海社区、福民社区、永安社区、新街居委会、墩头居委会、港门居委会、新北居委会、皇宁村、蒲草村、居龙村、八所村、大占坡村、福耀村、报坡村、田庄村、文通村、斯文村、玉章村、北黎村、老官村、唐马园村、那等村、新农村、益兴村、平岭村、昌义村、剪半园村、罗带村、大坡田村、下红兴村、上红兴村、青山村、老欧村、上名山村、下名山村、月村村、福久村、那悦村、高排村、十所村、小岭村',
        },
        {
          东河镇:
            '东方村、冲南村、土新村、土蛮村、旧村村、东新村、东风村、西方村、中方村、佳西村、亚要村、玉龙村、万丁村、佳头村、广坝村、南浪村、俄贤村、金炳村、苗村村',
        },
        {
          大田镇:
            '抱板村、居便村、长安村、戈枕村、牙炮村、老马村、月大村、马龙村、俄乐村、玉道村、新宁坡村、二甲村、那都村、短草村、万达村、冲报村、南尧村、俄龙村、罗旺村、大田村、乐妹村、报白村、保丁村、报英村',
        },
        {
          感城镇:
            '感城村、扶宅村、陀头村、陀烈村、生旺村、民兴村、不磨村、尧文村、宝西村、宝东村、入学村、加富村、凤亭村、感北村、感南村',
        },
        {
          板桥镇:
            '桥北村、桥南村、好瑞村、本廉村、文质村、老方村、元兴村、利章村、白穴村、抱利村、下园村、板桥村、南港村、中沙村、田头村、田中村、后壁村、加力村、三间村、高田村、新园村',
        },
        {
          三家镇:
            '三家村、老乡村、玉雄村、官田村、小酸梅村、乐安村、旺老村、红草村、代鸠村、居候村、岭村村、窑上村、酸梅村、水东村',
        },
        {
          四更镇:
            '四更村、土地村、付马村、赤坎村、四北村、来南村、沙村村、日新村、四中村、旦园村、四而村、英显村、大新村、旦场村、下荣村、长山村、居多村、四南村、上荣村、四必村',
        },
        {
          新龙镇:
            '新村村、下通天村、那斗村、龙北村、道达村、龙佑村、龙卧村、部道村、上通天村',
        },
        {
          天安乡:
            '安都村、长田村、赤好村、陈龙村、抱由村、天村村、布套村、芭蕉村、公爱村、光益村、陀牙村、温村村、益公村、陀类村、王沟村',
        },
        {
          江边乡:
            '江边营村、新明村、土眉村、俄查村、布温村、老村村、白查村、冲俄村、那文村、江边村',
        },
      ],
    },
  ],
  四川省: [
    {
      成都市: [
        {
          锦江区:
            '督院街街道、盐市口街道、春熙路街道、书院街街道、合江亭街道、水井坊街道、牛市口街道、龙舟路街道、双桂路街道、莲新街道、沙河街道、东光街道、狮子山街道、柳江街道、成龙路街道、三圣街道',
        },
        {
          青羊区:
            '太升路街道、草市街街道、西御河街道、汪家拐街道、少城街道、新华西路街道、草堂路街道、府南街道、光华街道、东坡街道、金沙街道、黄田坝街道、苏坡街道、文家街道',
        },
        {
          金牛区:
            '西安路街道、西华街道、人民北路街道、荷花池街道、驷马桥街道、茶店子街道、抚琴街道、九里堤街道、五块石街道、黄忠街道、营门口街道、金泉街道、沙河源街道、天回镇街道、凤凰山街道',
        },
        {
          武侯区:
            '浆洗街街道、望江路街道、玉林街道、跳伞塔街道、火车南站街道、双楠街道、晋阳街道、红牌楼街道、簇桥街道、机投桥街道、金花桥街道、簇锦街道、华兴街道、芳草街街道、肖家河街道、石羊街道、桂溪街道',
        },
        {
          成华区:
            '猛追湾街道、双桥子街道、二仙桥街道、府青路街道、建设路街道、白莲池街道、万年场街道、桃蹊路街道、双水碾街道、跳蹬河街道、圣灯街道、保和街道、青龙街道、龙潭街道',
        },
        { 成都高新技术产业开发区: '成都高新技术产业开发区' },
        {
          龙泉驿区:
            '龙泉街道、大面街道、十陵街道、同安街道、洛带镇、西河镇、洪安镇、柏合镇、茶店镇、黄土镇、山泉镇、万兴乡',
        },
        {
          温江区:
            '柳城街道、涌泉街道、公平街道、天府街道、永宁镇、永盛镇、寿安镇、和盛镇、万春镇、金马镇',
        },
        {
          新都区:
            '新都街道、大丰街道、三河街道、新繁、新民镇、清流镇、龙桥镇、马家镇、泰兴镇、木兰镇、军屯镇、石板滩镇、斑竹园镇',
        },
        {
          青白江区:
            '红阳街道、大弯街道,弥牟镇、大同镇、城厢镇、祥福镇、姚渡镇、清泉镇、龙王镇、福洪镇,人和乡',
        },
        {
          双流县:
            '东升街道、西航港街道、协和街道 、黄甲街道、九江街道、公兴街道、黄龙溪镇、永安镇、黄水镇、金桥镇、胜利镇、彭镇',
        },
        {
          郫县: '郫筒街道、合作街道、犀浦镇、红光镇、安靖镇、团结镇、唐昌镇、三道堰镇、新民场镇、花园镇、安德镇、唐元镇、德源镇、古城镇、友爱镇',
        },
        {
          蒲江县:
            '鹤山镇、大塘镇、寿安镇、朝阳湖镇、西来镇、大兴镇、甘溪镇、成佳镇、复兴乡、光明乡、白云乡、长秋乡',
        },
        {
          金堂县:
            '赵镇、三星镇、清江镇、官仓镇、栖贤乡、淮口镇、白果镇、福兴镇、赵家镇、五凤镇、高板镇、三溪镇、平桥乡、竹篙镇、隆盛镇、转龙镇、广兴镇、又新镇、土桥镇、云合镇、金龙镇',
        },
        {
          大邑县:
            '晋原镇、王泗镇、新场镇、悦来镇、安仁镇、江镇、花水湾镇、西岭镇、斜源镇、董场镇、韩场镇、三岔镇、上安镇、苏家镇、青霞镇、沙渠镇、蔡场镇、雾山乡、金星乡、鹤鸣乡',
        },
        {
          新津县:
            '五津街道、花源街道、新平街道、永商街道、邓双街道、花桥街道、普兴镇、金华镇、兴义镇、方兴镇、安西镇、文井乡',
        },
        {
          都江堰市:
            '灌口街道、幸福街道、银杏街道、永丰街道、奎光塔街道、蒲阳镇、聚源镇、崇义镇、天马镇、石羊镇、柳街镇、玉堂镇、中兴镇、青城山镇、龙池镇、胥家镇、安龙镇、大观镇、紫坪铺镇、向峨乡',
        },
        {
          彭州市:
            '天彭街道、龙门山镇、新兴镇、丽春镇、九尺镇、濛阳镇、濛阳街道、通济镇、丹景山镇、隆丰镇、敖平镇、磁峰镇、桂花镇、军乐镇、三界镇、小鱼洞镇、红岩镇、升平镇、白鹿镇、葛仙山镇、致和镇、致和街道',
        },
        {
          邛崃市:
            '临邛镇、固驿镇、平乐镇、羊安镇、火井镇、夹关镇、水口镇、桑园镇、牟礼镇、冉义镇、回龙镇、高埂镇、前进镇、高何镇、临济镇、卧龙镇、天台山镇、宝林镇、道佐乡、油榨乡、茶园乡、南宝乡、大同乡、孔明乡',
        },
        {
          崇州市:
            '崇阳区、羊马镇、街子镇、怀远镇、元通镇、桤泉镇、大划镇、三江镇、王场镇',
        },
        {
          余江县:
            '沙北街道办事处、城关镇、孟庙镇、商桥镇、裴城镇、新店镇、龙城镇、李集镇、黑龙潭乡',
        },
        {
          贵溪市:
            '天桥街街道、翟庄办事处、召陵镇、邓襄镇、万金镇、老窝镇、姬石镇、后谢乡、青年村乡',
        },
      ],
      绵阳市: [
        {
          涪城区:
            '城厢街道、城北街道、工区街道、南山街道、朝阳街道、高新区街道、经开区城南街道、园艺街道、创业园街道、丰谷镇、关帝镇、塘汛镇、青义镇、龙门镇、石塘镇、吴家镇、杨家镇、金峰镇、玉皇镇、新皂镇、河边镇、磨家镇、永兴镇、城郊乡、石洞乡',
        },
        {
          游仙区:
            '经济开发区、经济试验区、涪江街道、富乐街道、游仙镇、石马镇、小枧沟镇、新桥镇、石马镇、忠兴镇、柏林镇、魏城镇、石板镇、刘家镇、玉河镇、徐家镇、东林乡、云风乡、街子乡、建华乡 、凤凰乡、太平乡、朝真乡、观太乡、东宣乡、梓棉乡、白蝉乡',
        },
        {
          梓潼县:
            '文昌镇、长卿镇、许州镇、黎雅镇、白云镇、卧龙镇、观义镇、玛瑙镇、石牛镇、自强镇、仁和镇、东石乡、三泉乡、宏仁乡、小垭乡、演武乡、仙峰乡、双板乡、豢龙乡、双峰乡、交泰乡、金龙场乡、石台乡、仙鹅乡、马鸣乡、马迎乡、二洞乡、建兴乡、宝石乡、定远乡、大新乡、文兴乡',
        },
        {
          三台县:
            '潼川镇、芦溪镇、景福镇、富顺镇、刘营镇、西平镇、安居镇、塔山镇、乐安镇、金石镇、观桥镇、新生镇、古井镇、中太镇、中新镇、石安镇、秋林镇、建设镇、三元镇、万安镇、郪江镇、龙树镇、鲁班镇、紫河镇、建平镇、新鲁镇、立新镇、灵兴镇、八洞镇、凯河镇、花园镇、东塔镇、光辉镇、百顷镇、前锋镇、柳池镇、永明镇、新德镇、北坝镇、永新镇、黎曙镇等41个镇和乐加乡、幸福乡、老马乡、金鼓乡、里程乡、菊河乡、建中乡、断石乡、下新乡、争胜乡、双胜乡、双乐乡、忠孝乡、高堰乡、进都乡、宝泉乡、曙光乡、云同乡、上新乡、广利乡、协和乡、玉林乡',
        },
        {
          盐亭县:
            '云溪镇、富驿镇、玉龙镇、黄甸镇、金孔镇、两河镇、柏梓镇、八角镇、黑坪镇、高灯镇、金鸡镇、安家镇、林农镇、巨龙镇、三星乡、龙泉乡、折弓乡、麻秧乡、冯河乡、石牛庙乡、宗海乡、两岔河乡、林山乡、新农乡、三元乡、五龙乡、茶亭乡、金安乡、洗泽乡、毛公乡、剑河乡、来龙乡、永泰乡、黄溪乡、榉溪乡、双碑乡、大兴回族乡',
        },
        {
          安县: '桑枣镇、花荄镇、黄土镇、塔水镇、秀水镇、河清镇、界牌镇、永河镇、雎水镇、清泉镇、宝林镇、沸水镇、晓坝镇、乐兴镇、千佛镇、兴仁乡、高川乡、迎新乡',
        },
        {
          北川羌族自治县:
            '曲山镇、擂鼓镇、通口镇、永昌镇、安昌镇、永安镇、禹里镇、桂溪镇、香泉乡、陈家坝乡、贯岭乡、漩坪乡、白坭乡、小坝乡、片口乡、开坪乡、坝底乡、白什乡、青片乡、都坝乡、桃龙藏族乡、墩上乡、马槽乡',
        },
        {
          平武县:
            '龙安镇、古城镇、南坝镇、响岩镇、平通镇、豆叩镇、大印镇、水晶镇、大桥镇、高村乡、坝子乡、水观乡、水田羌族乡、平南羌族乡、徐塘羌族乡、锁江羌族乡、阔达藏族乡、木皮藏族乡、木座藏族乡、白马藏族乡、土城藏族乡、旧堡羌族乡、黄羊关藏族乡、虎牙藏族乡、泗耳藏族乡',
        },
        {
          江油市:
            '长钢街道、武都街道、含增街道、华平街道、中坝镇、太平镇、三合镇、含增镇、青莲镇、彰明镇、龙凤镇、武都镇、大康镇、新安镇、战旗镇、双河镇、永胜镇、小溪坝镇、河口镇、重华镇、厚坝镇、二郎庙镇、马角镇、雁门镇、九岭镇、八一乡、方水乡、西屏镇、香水镇、大堰镇、东兴乡、义新乡、贯山镇、新兴乡、新春乡、东安乡、铜星乡、文胜镇、重兴乡、云集乡、石元乡、敬元乡、六合乡、枫顺乡',
        },
      ],
      自贡市: [
        {
          自流井区:
            '五星街街道、东兴寺街道、新街街道、郭家坳街道、丹桂街道、学苑街道、仲权镇、舒坪镇、荣边镇、红旗乡、高峰乡、农团乡、漆树乡',
        },
        {
          贡井区:
            '筱溪街道、贡井街道、长土镇、艾叶镇、建设镇、桥头镇、五宝镇、龙潭镇、莲花镇、成佳镇、白庙镇、章佳乡、牛尾乡',
        },
        {
          大安区:
            '大安街道、龙井街道、马冲口街道、凉高山街街道、大山铺镇、团结镇、三多寨镇、何市镇、新店镇、新民镇、回龙镇、牛佛镇、庙坝镇、永嘉乡、和平乡、凤凰乡',
        },
        {
          沿滩区:
            '沿滩镇、王井镇、邓关镇、瓦市镇、仙市镇、卫坪镇、兴隆镇、永安镇、黄市镇、联络镇、富全镇、九洪乡、刘山乡',
        },
        {
          富顺县:
            '富世镇、东湖镇、琵琶镇、狮市镇、骑龙镇、互助镇、代寺镇、中石镇、童寺镇、古佛镇、永年镇、彭庙镇、兜山镇、板桥镇、福善镇、李桥镇、赵化镇、安溪镇、万寿镇、飞龙镇、怀德镇、长滩镇、龙万乡、宝庆乡、富和乡、石道乡',
        },
        {
          荣县: '旭阳镇、长山镇、鼎新镇、河口镇、古文镇、度佳镇、过水镇、正紫镇、保华镇、双古镇、新桥镇、望佳镇、铁厂镇、留佳镇、乐德镇、双石镇、东佳镇、来牟镇、观山镇、高山镇、东兴镇、于佳乡、复兴乡、墨林乡、雷音乡、古佳乡、金花乡',
        },
      ],
      攀枝花市: [
        {
          东区: '炳草岗街道、大渡口街道、长寿路街道、向阳村街道、弄弄坪街道、枣子坪街道、瓜子坪街道、密地街道、南山街道、银江镇',
        },
        {
          西区: '清香坪街道、玉泉街道、河门口街道、陶家渡街道、摩梭河街道、大宝鼎街道、格里坪镇',
        },
        {
          仁和区:
            '大河中路街道、仁和镇、平地镇、大田镇、福田镇、同德镇、金江镇、布德镇、前进镇、大龙潭彝族乡、啊喇彝族乡、总发乡、太平乡、务本乡、中坝乡',
        },
        {
          米易县:
            '攀莲镇、丙谷镇、得石镇、撒莲镇、垭口镇、白马镇、普威镇、草场乡、湾丘彝族乡、白坡彝族乡、麻陇彝族乡、新山傈僳族乡',
        },
        {
          盐边县:
            '桐子林镇、红格镇、渔门镇、永兴镇、益民乡、新九乡、和爱彝族乡、红果彝族乡、鳡鱼彝族乡、共和乡、国胜乡、红宝苗族彝族乡、惠民乡、箐河傈僳族乡、温泉彝族乡、格萨拉彝族乡',
        },
      ],
      泸州市: [
        {
          江阳区:
            '南城街道、北城街道、大山坪街道、邻玉街道、蓝田街道、茜草街道、华阳街道、张坝景区办事处、泰安镇、黄舣镇、弥陀镇、况场镇、通滩镇、江北镇、方山镇、丹林镇、分水岭镇、石寨镇',
        },
        {
          龙马潭区:
            '小市街道、高坝厂区街道、红星街道、莲花池街道、罗汉镇、鱼塘镇、石洞镇、胡市镇、特兴镇、安宁镇、双加镇、金龙乡、长安乡',
        },
        {
          纳溪区:
            '安富街道、永宁街道、东升街道、大渡口镇、护国镇、打古镇、上马镇、合面镇、棉花坡镇、丰乐镇、白节镇、天仙镇、新乐镇、渠坝镇、龙车镇',
        },
        {
          泸县: '玉蟾街道、嘉明镇、喻寺镇、得胜镇、牛滩镇、兆雅镇、玄滩镇、太伏镇、云龙镇、石桥镇、毗卢镇、奇峰镇、潮河镇、云锦镇、立石镇、百和镇、天兴镇、方洞镇、海潮镇',
        },
        {
          合江县:
            '合江镇、望龙镇、白沙镇、佛荫镇、先市镇、尧坝镇、九支镇、五通镇、凤鸣镇、榕山镇、白鹿镇、甘雨镇、福宝镇、先滩镇、自怀镇、大桥镇、车辋镇、密溪乡、白米乡、焦滩乡、参宝乡、二里乡、实录乡、虎头乡、榕右乡、南滩乡、石龙乡',
        },
        {
          叙永县:
            '叙永镇、江门镇、马岭镇、天池镇、水尾镇、两河镇、落卜镇、后山镇、分水镇、摩尼镇、赤水镇、向林乡、大石乡、兴隆乡、龙凤乡、震东乡、黄坭乡、营山乡、麻城乡、观兴乡、合乐苗族乡、白腊苗族乡、枧槽苗族乡、水潦彝族乡、石坝彝族乡',
        },
        {
          古蔺县:
            '古蔺镇、龙山镇、永乐镇、太平镇、二郎镇、大村镇、石宝镇、丹桂镇、水口镇、观文镇、双沙镇、德跃镇、护家乡、鱼化乡、石屏乡、东新乡、土城乡、金星乡、白泥乡、椒园乡、马嘶苗族乡、马蹄乡、箭竹苗族乡、大寨苗族乡、桂花乡、黄荆乡',
        },
      ],
      德阳市: [
        {
          旌阳区:
            '旌阳街道、工农街道、城北街道、城南街道、旌东街道、八角井街道、天虹街道、黄河街道、黄许镇、孝泉镇、柏隆镇、德新镇、扬嘉镇、天元镇、孝感镇、新中镇、双东镇、和新镇、东湖乡',
        },
        {
          什邡市:
            '方亭街道办事处、皂角街道办事处、红白镇、蓥华镇、冰川镇、洛水镇，马祖镇、师古镇、湔氐镇、马井镇、南泉镇、隐峰镇、双盛镇、禾丰镇、回澜镇、元石镇',
        },
        {
          广汉市:
            '雒城镇、三水镇、连山镇、高坪镇、南兴镇、向阳镇、小汉镇、金轮镇、新丰镇、兴隆镇、和兴镇、松林镇、金鱼镇、新平镇、南丰镇、西高镇、北外乡、西外乡',
        },
        {
          绵竹市:
            '剑南镇、东北镇、西南镇、兴隆镇、九龙镇、遵道镇、汉旺镇、拱星镇、土门镇、广济镇、金花镇、玉泉镇、板桥镇、新市镇、孝德镇、富新镇、齐天镇、什地镇、绵远镇、天池乡、清平乡',
        },
        {
          罗江县:
            '罗江镇、略坪镇、金山镇、鄢家镇、蟠龙镇、新盛镇、慧觉镇、御营镇、文星镇、白马关镇',
        },
        {
          中江县:
            '凯江镇、东北镇、杰兴镇、通济镇、回龙镇、永太镇、黄鹿镇、南华镇、南山镇、集凤镇、富兴镇、兴隆镇、辑庆镇、永安镇、悦来镇、双龙镇、继光镇、仓山镇、冯店镇、积金镇、会龙镇、联合镇、太安镇、万福镇、广福镇、永兴镇、普兴镇、玉兴镇、龙台镇、青市乡、瓦店乡、古店乡、清河乡、合兴乡、石泉乡、柏树乡、高店乡、太平乡、白果乡、民主乡、石笋乡、永丰乡、元兴乡、石龙乡、通山乡',
        },
      ],
      广元市: [
        {
          利州区:
            '东坝街道、嘉陵街道、河西街道、雪峰街道、南河街道、回龙河街道、上西街道、杨家岩街道、袁家坝街道、下西街道、荣山镇、大石镇、宝轮镇、赤化镇、三堆镇、工农镇、盘龙镇、白朝乡、金洞乡、龙潭乡',
        },
        {
          昭化区:
            '元坝镇、昭化镇、卫子镇、王家镇、磨滩镇、柏林沟镇、太公镇、虎跳镇、红岩镇、、晋贤乡、文村乡、清水乡、张家乡、香溪乡、青牛乡、陈江乡、丁家乡、黄龙乡、石井铺乡、白果乡、梅树乡、明觉乡、射箭乡、朝阳乡、大朝乡、沙坝乡、柳桥乡、紫云乡、拣银岩社区街道',
        },
        {
          朝天区:
            '朝天镇、大滩镇、羊木镇、曾家镇、中子镇、沙河镇、陈家乡、小安乡、鱼洞乡、东溪河乡、花石乡、蒲家乡、西北乡、宣河乡、转斗乡、青林乡、平溪乡、两河口乡、李家乡、汪家乡、麻柳乡、临溪乡、文安乡、马家坝乡、柏杨乡',
        },
        {
          旺苍县:
            '东河镇、嘉川镇、木门镇、白水镇、尚武镇、张华镇、黄洋镇、普济镇、三江镇、金溪镇、五权镇、高阳镇、双汇镇、英萃镇、国华镇、龙凤乡、大河乡、九龙乡、万家乡、燕子乡、水磨乡、鼓城乡、檬子乡、福庆乡、枣林乡、麻英乡、柳溪乡、农建乡、化龙乡、大两乡、万山乡、正源乡、天星乡、盐河乡、大德乡、静乐寺社区街道、陈家岭社区街道、磨岩社区街道',
        },
        {
          青川县:
            '乔庄镇、青溪镇、房石镇、关庄镇、凉水镇、竹园镇、木鱼镇、沙州镇、姚渡镇、黄坪乡、瓦砾乡、孔溪乡、茶坝乡、大坝乡、桥楼乡、三锅乡、蒿溪回族乡、乐安寺乡、前进乡、曲河乡、马公乡、石坝乡、红光乡、苏河乡、茅坝乡、大院回族乡、楼子乡、金子山乡、马鹿乡、七佛乡、建峰乡、白家乡、板桥乡、骑马乡、观音店乡、营盘乡',
        },
        {
          剑阁县:
            '普安镇、龙源镇、城北镇、盐店镇、柳沟镇、武连镇、东宝镇、开封镇、元山镇、演圣镇、王河镇、公兴镇、金仙镇、香沉镇、白龙镇、鹤龄镇、杨村镇、羊岭镇、江口镇、木马镇、剑门关镇、汉阳镇、下寺镇、江石乡、田家乡、闻溪乡、姚家乡、北庙乡、西庙乡、义兴乡、毛坝乡、凉山乡、垂泉乡、秀钟乡、正兴乡、马灯乡、高池乡、碗泉乡、迎水乡、国光乡、柘坝乡、公店乡、吼狮乡、长岭乡、涂山乡、圈龙乡、碑垭乡、广坪乡、禾丰乡、店子乡、摇铃乡、樵店乡、锦屏乡、柏垭乡、高观乡、张王乡、上寺乡',
        },
        {
          苍溪县:
            '陵江镇、云峰镇、东青镇、白桥镇、八庙镇、五龙镇、永宁镇、鸳溪镇、三川镇、龙王镇、元坝镇、唤马镇、歧坪镇、白驿镇、漓江镇、文昌镇、岳东镇、石马镇、运山镇、东溪镇、高坡镇、龙山镇、中土镇、亭子镇、禅林乡、白鹤乡、浙水乡、雍河乡、新观乡、石门乡、月山乡、白山乡、彭店乡、桥溪乡、龙洞乡、黄猫乡、石灶乡、河地乡、双河乡',
        },
      ],
      遂宁市: [
        {
          船山区:
            '南津路街道、凯旋路街道、高升街街道、镇江寺街道、育才路街道、介福路街道、嘉禾街道、广德寺街道、富源路街道、南强街道、龙坪街道、灵泉寺街道、慈音街道、九莲街道、龙凤镇、仁里镇、复桥镇、永兴镇、河沙镇、新桥镇、桂花镇、西宁乡、老池乡、保升乡、唐家乡、北固乡',
        },
        {
          安居区:
            '柔刚街道、凤凰街道、安居镇、东禅镇、分水镇、石洞镇、拦江镇、保石镇、白马镇、中兴镇、横山镇、会龙镇、三家镇、玉丰镇、西眉镇、磨溪镇、聚贤镇、常理镇、观音镇、莲花乡、步云乡、大安乡、马家乡',
        },
        {
          蓬溪县:
            '赤城镇、新会镇、文井镇、明月镇、常乐镇、天福镇、红江镇、宝梵镇、大石镇、吉祥镇、鸣凤镇、任隆镇、三凤镇、高坪镇、蓬南镇、群利镇、下东乡、新星乡、罗戈乡、板桥乡、槐花乡、吉星乡、黄泥乡、荷叶乡、金龙乡、农兴乡、新胜乡、回水乡、群力乡、高升乡、金桥乡',
        },
        {
          射洪县:
            '子昂街道、平安街道、紫云街道、太和镇、金华镇、仁和镇、青岗镇、明星镇、洋溪镇、香山镇、大榆镇、广兴镇、潼射镇、涪西镇、陈古镇、凤来镇、金家镇、天仙镇、太乙镇、曹碑镇、沱牌镇、复兴镇、官升镇、伏河乡、青堤乡、双溪乡、文升乡、瞿河乡、万林乡、太兴乡、东岳乡、金鹤乡、玉太乡',
        },
        {
          大英县:
            '蓬莱镇、隆盛镇、回马镇、天保镇、河边镇、卓筒井镇、玉峰镇、象山镇、通仙乡、金元乡、智水乡',
        },
      ],
      内江市: [
        {
          市中区:
            '城东街道、城南街道、城西街道、玉溪街道、牌楼街道、乐贤街道、白马镇、史家镇、凌家镇、朝阳镇、永安镇、全安镇、靖民镇、沱江乡、凤鸣乡、伏龙乡、龚家镇',
        },
        {
          东兴区:
            '东兴街道、西林街道、新江街道、胜利街道、田家镇、郭北镇、高梁镇、白合镇、顺河镇、高桥镇、双才镇、小河口镇、杨家镇、椑木镇、石子镇、平坦镇、椑南镇、永兴镇、太安乡、苏家乡、富溪乡、同福乡、永福乡、新店乡、双桥乡、中山乡、大治乡、柳桥乡、三烈乡',
        },
        { 内江经济开发区: '壕子口街道、四合镇、交通镇' },
        {
          资中县:
            '水南镇、重龙镇、归德镇、甘露镇、鱼溪镇、铁佛镇、球溪镇、龙结镇、罗泉镇、发轮镇、宋家镇、银山镇、太平镇、双河镇、公民镇、龙江镇、双龙镇、新桥镇、骝马镇、高楼镇、孟塘镇、陈家镇、配龙镇、狮子镇、走马镇、马鞍镇、金李井镇、兴隆街镇、顺河场镇、苏家湾镇、明心寺镇、龙山乡、板栗垭乡',
        },
        {
          威远县:
            '严陵镇、连界镇、向义镇、高石镇、东联镇、界牌镇、靖和镇、庆卫镇、山王镇、碗厂镇、龙会镇、越溪镇、镇西镇、两河镇、小河镇、新场镇、新店镇、观英滩镇、黄荆沟镇、铺子湾镇',
        },
        {
          隆昌县:
            '古湖街道、金鹅街道、响石镇、圣灯镇、黄家镇、龙市镇、界市镇、石碾镇、胡家镇、渔箭镇、山川镇、双凤镇、迎祥镇、周兴镇、云顶镇、李市镇、石燕桥镇、普润镇、桂花井镇',
        },
      ],
      乐山市: [
        {
          五通桥区:
            '竹根镇、牛华镇、金粟镇、金山镇、桥沟镇、石麟镇、冠英镇、杨柳镇、辉山镇、蔡金镇、西坝镇、新云乡',
        },
        {
          市中区:
            '张公桥街、泊水街、上河街、肖坝街、通江街、柏杨街、大佛街,苏稽镇、安谷镇、土主镇、水口镇、牟子镇、茅桥镇、青平镇、白马镇、棉竹镇、车子镇、临江镇、九峰镇、罗汉镇、全福镇、童家镇、杨湾乡、悦来乡、剑峰乡、凌云乡、平兴乡、普仁乡、石龙乡、九龙乡、迎阳乡、关庙乡',
        },
        {
          沙湾区:
            '沙湾镇、嘉农镇、太平镇、踏水镇、福禄镇、牛石镇、龚嘴镇、葫芦镇、碧山乡、谭坝乡、轸溪乡、铜茨乡、范店乡',
        },
        { 金口河区: '永和镇、金河镇、吉星乡、永胜乡、和平彝族乡、共安彝族乡' },
        {
          峨眉山市:
            '绥山镇、高桥镇、罗目镇、九里镇、龙池镇、乐都镇、符溪镇、峨山镇、双福镇、桂花桥镇、大为镇、胜利镇、龙门乡、川主乡、沙溪乡、新平乡、普兴乡、黄湾乡',
        },
        {
          犍为县:
            '玉津镇、清溪镇、罗城镇、芭沟镇、石溪镇、新民镇、孝姑镇、龙孔镇、定文镇、敖家镇、金石井镇、泉水镇、双溪乡、九井乡、同兴乡、榨鼓乡、铁炉乡、大兴乡、南阳乡、纪家乡、新盛乡、寿保乡、舞雩乡、下渡乡、玉屏乡、岷东乡、塘坝乡、马庙乡、公平乡、伏龙乡',
        },
        {
          井研县:
            '研城镇、马踏镇、竹园镇、研经镇、周坡镇、千佛镇、王村镇、三江镇、东林镇、磨池镇等10个镇,集益乡、纯复乡、三教乡、高滩乡、宝五乡、四合乡、黄钵乡、胜泉乡、门坎乡、石牛乡、高凤乡、金峰乡、分全乡、镇阳乡、天云乡、乌抛乡、大佛乡',
        },
        {
          夹江县:
            '漹城镇、木城镇、甘江镇、黄土镇、界牌镇、中兴镇、三洞镇、吴场镇、华头镇、甘霖镇、新场镇、马村乡、土门乡、迎江乡、歇马乡、麻柳乡、南安乡、青州乡、梧凤乡、龙沱乡、顺河乡、永青乡',
        },
        {
          沐川县:
            '沐溪镇、永福镇、大楠镇、箭板镇、舟坝镇、黄丹镇、利店镇、建和乡、幸福乡、新凡乡、富和乡、炭库乡、底堡乡、杨村乡、高笋乡、茨竹乡、海云乡、武圣乡、凤村乡',
        },
        {
          峨边彝族自治县:
            '沙坪镇、大堡镇、毛坪镇、五渡镇、新林镇、黑竹沟镇、红花乡、宜坪乡、杨村乡、白杨乡、觉莫乡、万坪乡、杨河乡、共和乡、新场乡、平等乡、哈曲乡、金岩乡、勒乌乡',
        },
        {
          马边彝族自治县:
            '民建镇、荣丁镇、劳动乡、建设乡、石梁乡、荍坝乡、民主乡、老河坝乡、下溪乡、雪口山乡、镇江庙乡、大竹堡乡、苏坝乡、烟烽乡、袁家溪乡、沙腔乡、三河口乡、梅子坝乡、高卓营乡、永红乡',
        },
      ],
      资阳市: [
        {
          雁江区:
            '雁江镇、松涛镇、宝台镇、临江镇、保和镇、老君镇、中和镇、丹山镇、小院镇、堪嘉镇、伍隍镇、石岭镇、东峰镇、南津镇、忠义镇、丰裕镇、碑记镇、迎接镇、祥符镇、新场乡、回龙乡、清水乡',
        },
        {
          安岳县:
            '岳阳镇、龙台镇、通贤镇、石羊镇、兴隆镇、鸳大镇、姚市镇、林凤镇、毛家镇、李家镇、永清镇、周礼镇、驯龙镇、镇子镇、两板桥镇、护龙镇、元坝镇、天林镇、文化镇、华严镇、石桥铺镇、永顺镇、龙居乡、思贤乡、来凤乡、高升乡、合义乡、白塔寺乡、双龙街乡、八庙乡、长河乡、乾龙乡、忠义乡、护建乡、清流乡、协和乡、太平乡、千佛乡、南熏乡、朝阳乡、城北乡、人和乡、天马乡、悦来乡、岳新乡、偏岩乡、云峰乡、东胜乡、顶新乡、横庙乡、高屋乡、努力乡、天宝乡、渔龙乡、九龙乡、岳源乡、宝华乡、拱桥乡、大埝乡、城西乡、石鼓乡、团结乡、坪河乡、瑞云乡、和平乡、建华乡、龙桥乡、自治乡、共和乡、白水乡、新民乡、太平乡',
        },
        {
          乐至县:
            '天池镇、石佛镇、回澜镇、石湍镇、童家镇、宝林镇、大佛镇、良安镇、金顺镇、中和场镇、劳动镇、中天镇、佛星镇、蟠龙镇、东山镇、通旅镇、高寺镇、龙溪乡、全胜乡、孔雀乡、龙门乡、双河场乡、放生乡、盛池乡、凉水乡',
        },
        {
          简阳市:
            '简城街道、射洪坝街道、十里坝街道、杨柳街道、石桥镇、新市镇、东溪镇、石盘镇、养马镇、平泉镇、禾丰镇、云龙镇、三星镇、贾家镇、青龙镇、三岔镇、镇金镇、石钟镇、施家镇、三合镇、平武镇、涌泉镇、金马镇、踏水镇、江源镇、芦葭镇、草池镇、太平桥镇、石板凳镇、五合乡、福田乡、宏缘乡、周家乡、平窝乡、武庙乡、高明乡、玉成乡、丹景乡、望水乡、清风乡、永宁乡、五星乡、飞龙乡、灵仙乡、五指乡、新民乡、新星乡、同合乡、老龙乡、壮溪乡、海螺乡、坛罐乡、雷家乡、安乐乡、普安乡、平息乡、老君井乡、董家埂乡',
        },
      ],
      宜宾市: [
        {
          翠屏区:
            '北城街道、东城街道、南城街道、西城街道、南岸街道、西郊街道、安阜街道、白沙湾街道、赵场街道、象鼻街道、南广镇、李庄镇、菜坝镇、金坪镇、高店镇、沙坪街道、牟坪镇、李端镇、邱场镇、宋家镇、明威乡、凉姜乡、思坡乡、宗场镇',
        },
        {
          南溪区:
            '南溪街道、罗龙街道、刘家镇、江南镇、大观镇、汪家镇、黄沙镇、仙临镇、长兴镇、裴石乡、马家乡、大坪乡、石鼓乡、林丰乡、留宾乡',
        },
        {
          宜宾县:
            '柏溪镇、横江镇、喜捷镇、观音镇、永兴镇、白花镇、孔滩镇、柳嘉镇、泥溪镇、蕨溪镇、商州镇、高场镇、安边镇、双龙镇、李场镇、合什镇、古罗镇、复龙镇、古柏县、王场县、双谊县、隆兴县、泥南县、龙池县、普安县、凤仪县',
        },
        {
          江安县:
            '：江安镇、红桥镇、阳春镇、井口镇、怡乐镇、留耕镇、底蓬镇、五矿镇、桐梓镇、迎安镇、铁清镇、水清镇、大井镇、夕佳山镇、四面山镇、大妙乡、蟠龙乡、仁和乡',
        },
        {
          长宁县:
            '梅硐镇、双河镇、龙头镇、硐底镇、花滩镇、竹海镇、老翁镇、开佛镇、古河镇、下长镇、长宁镇、富兴乡、铜鼓乡、三元乡、井江乡、铜锣乡、桃坪乡、梅白乡',
        },
        {
          高县: '庆符镇、文江镇、沙河镇、嘉乐镇、大窝镇、罗场镇、蕉村镇、可久镇、来复镇、月江镇、胜天镇、复兴镇、趱滩乡、羊田乡、落润乡、潆溪乡、庆岭乡、四烈乡、双河乡',
        },
        {
          筠连县:
            '筠连镇、腾达镇、巡司镇、蒿坝镇、双腾镇、沐爱镇、维新镇、镇舟镇、大雪山镇、塘坝乡、龙镇乡、孔雀乡、武德乡、乐义乡、高坎乡、团林苗族乡、联合苗族乡、高坪苗族乡',
        },
        {
          珙县: '巡场镇、珙泉镇、孝儿镇、底洞镇、上罗镇、洛表镇、洛亥镇、王家镇、下罗镇、沐滩镇、曹营镇、仁义乡、恒丰乡、石碑乡、玉和苗族乡、罗渡苗族乡、观斗苗族乡',
        },
        {
          兴文县:
            '古宋镇、僰王山镇、共乐镇、莲花镇、太平镇、石海镇、九丝城镇、周家镇、玉屏镇、大河苗族乡、大坝苗族乡、玉秀苗族乡、麒麟苗族乡、仙峰苗族乡',
        },
        {
          屏山县:
            '屏山镇、新市镇、中都镇、龙华镇、大乘镇、福延镇、富荣镇、新安镇、锦屏镇、楼东乡、鸭池乡、龙溪乡、太平乡、夏溪乡、屏边彝族乡、清平彝族乡',
        },
      ],
      南充市: [
        {
          顺庆区:
            '中城街道、北城街道、西城街道、东南街道、新建街道、舞凤街道、华凤街道、和平路街道、潆溪街道、荆溪街道、西山街道、共兴镇、金台镇、芦溪镇、李家镇、双桥镇、搬罾镇、新复乡、同仁乡、大林镇、梵殿乡、顺和乡、灯台乡、辉景镇、龙桂乡、永丰镇、桂花乡、凤山乡、渔溪乡',
        },
        {
          高坪区:
            '白塔街道、清溪街道、青松街道、青莲街道、龙门街道、小龙街道、都京街道、阙家镇、胜观镇、石圭镇、永安镇、青居镇、擦耳镇、东观镇、会龙镇、江陵镇、老君镇、螺溪镇、长乐镇、斑竹乡、万家乡、溪头乡、鄢家乡、喻家乡、御史乡、走马乡、凤凰乡、黄溪乡、佛门乡、马家乡、南江乡、隆兴乡',
        },
        {
          嘉陵区:
            '火花街道、文峰街道、都尉街道、凤垭街道、安平镇、龙蟠镇、世阳镇、金凤镇、龙泉镇、一立镇、七宝寺镇、双桂镇、曲水镇、龙岭镇、里坝镇、集凤镇、大通镇、吉安镇、金宝镇、西兴街道、李渡镇、三会镇、安福镇、盐溪乡、桃园乡、大同乡、花园镇、华兴乡、双店乡、石楼乡、河西镇、积善乡、桥龙乡、新庙乡、大观乡、天星乡、礼乐乡、木老乡、新场乡、土门乡、太和乡、大兴乡、移山乡、临江乡、白家乡',
        },
        {
          阆中市:
            '保宁街道、七里街道、沙溪街道、江南街道、双龙镇、彭城镇、裕华镇、柏垭镇、飞凤镇、思依镇、洪山镇、宝马镇、石龙镇、河溪镇、妙高镇、水观镇、龙泉镇、文成镇、老观镇、金垭镇、玉台镇、石滩镇、千佛镇、望垭镇、二龙镇、垭口乡、治平乡、天宫乡、天林乡、枣碧乡、北门乡、木兰乡、河楼乡、桥楼乡、清泉乡、朱镇乡、五马乡、金城乡、宝台乡 、鹤峰乡、福星乡、金子乡、解元乡、凉水乡、方山乡、西山乡、峰占乡、三庙乡、东兴乡、博树回族自治乡',
        },
        {
          南部县:
            '滨江街道、蜀北街道、南隆镇、河东镇、老鸦镇、永定镇、碑院镇、谢河镇、盘龙镇、石河镇、铁佛塘镇、东坝镇、 河坝镇、王家镇、富利镇、楠木镇、长坪镇、定水镇、大王镇、黄金镇、建兴镇、三官镇、流马镇、大桥镇、伏虎镇、双佛镇、花罐镇、升钟镇、升水镇、大坪镇、神坝镇、万年镇、大河镇、火峰乡、碾盘乡、群龙乡、平桥乡、窑场乡、大堰乡、马王乡、龙庙乡、梅家乡、大富乡、碧龙乡、中心乡、三清乡、五灵乡、兴盛乡、太华乡、寒坡乡、肖家乡、四龙乡、碾垭乡、石泉乡、永庆乡、宏观乡、雄狮乡、千秋乡、玉镇乡、柳驿乡、小元乡、永红乡、柳树乡、保城乡、双峰乡、皂角乡、丘垭乡、太霞乡、西河乡、店垭乡、桐坪乡、光中乡、铁鞭乡',
        },
        {
          西充县:
            '晋城镇、多扶镇、双凤镇、仁和镇、仙林镇、古楼镇、义兴镇、关文镇、凤鸣镇、青狮镇、鸣龙镇、高院镇、槐树镇、紫岩乡、中岭乡、扶君乡、太平镇、大全镇、常林乡、占山乡、莲池乡、宏桥乡、金泉乡、华光乡、金源乡、岱林乡、李桥乡、西碾乡、复安乡、观凤乡、青龙乡、双洛乡、义和乡、罐垭乡、中南乡、双江乡、凤和乡、东岱乡、同德乡、祥龙乡、车龙乡、东太乡、永清乡、金山乡',
        },
        {
          仪陇县:
            '新政镇、金城镇、度门镇、五福镇、土门镇、回春镇、观紫镇、张公镇、三蛟镇、大仪镇、先锋镇、复兴镇、赛金镇、双胜镇、二道镇、永乐镇、保平镇、文星镇、大寅镇、日兴镇、马鞍镇、杨桥镇、周河镇、丁字桥镇、柳垭镇、义路镇、立山镇、三河镇、瓦子镇、柴井乡、光华乡、中坝乡、双盘乡、凤仪乡、铜鼓乡、老木乡、檬垭乡、武棚乡、永光乡、炬光乡、九龙乡、芭蕉乡、灯塔乡、福临乡、碧泉乡、大风乡、双庆乡、来仪乡、乐兴乡、石佛乡、义门乡、合作乡、大罗乡、思德乡、秋垭乡、龙桥乡、板桥乡',
        },
        {
          营山县:
            '朗池镇、城南镇、渌井镇、东升镇、骆市镇、黄渡镇、小桥镇、灵鹫镇、老林镇、木垭镇、双流镇、绿水镇、三兴镇、蓼叶镇、回龙镇、新店镇、消水镇、星火镇、西桥镇、四喜乡、带河乡、龙伏乡、明德乡、双林乡、双溪乡、济川乡、茶盘乡、六合乡、悦中乡、柏林乡、合兴乡、孔雀乡、高码乡、安固乡、福源乡、增产乡、清水乡、丰产乡、青山乡、法堂乡、大庙乡、安化乡、通天乡、太蓬乡、三元乡、普岭乡、玲珑乡、木顶乡、涌泉乡、清源乡、七涧乡、柏坪乡、凉风乡',
        },
        {
          蓬安县:
            '相如镇、锦屏镇、巨龙镇、徐家镇、金溪镇、河舒镇、兴旺镇、罗家镇、利溪镇、正源镇、龙云镇、龙蚕镇、杨家镇、福德镇、银汉镇、睦坝乡、鲜店乡、石孔乡、金甲乡、茶亭乡、诸家乡、平头乡、高庙乡、济渡乡、骑龙乡、群乐乡、三坝乡、新园乡、开元乡、碧溪乡、凤石乡、南燕乡、天成乡、新河乡、海田乡、石梁乡、两路乡、长梁乡、柳滩乡',
        },
      ],
      达州市: [
        {
          通川区:
            '东城街道、西城街道、朝阳街道、西外镇、北外镇、罗江镇、蒲家镇、复兴镇、双龙镇、魏兴镇、碑庙镇、江陵镇、东岳镇、磐石镇、北山镇、金石镇、梓桐镇、新村乡、安云乡、青宁乡、龙滩乡、檬双乡',
        },
        {
          达川区:
            '翠屏街道、三里坪街道、石桥镇、石梯镇、河市镇、管村镇、亭子镇、麻柳镇、景市镇、赵家镇、大树镇、堡子镇、石板镇、南岳镇、檀木镇、福善镇、万家镇、金垭镇、百节镇、渡市镇、马家镇、双庙镇、桥湾镇、赵固镇、金檀镇、平滩镇、大风乡、江阳乡、东兴乡、花红乡、大滩乡、安仁乡、葫芦乡、黄庭乡、黄都乡、木子乡、碑高乡、幺塘乡、斌郎乡、罐子乡、陈家乡、申家乡、木头乡、草兴乡、龙会乡、九岭乡、大堰乡、五四乡、银铁乡、沿河乡、香隆乡、道让乡、洛车乡、永进乡、米城乡、虎让乡',
        },
        {
          宣汉县:
            '东乡镇、君塘镇、清溪镇、普光镇、天生镇、柏树镇、芭蕉镇、南坝镇、五宝镇、峰城镇、土黄镇、华景镇、樊哙镇、新华镇、黄金镇、胡家镇、毛坝镇、双河镇、大成镇、明月乡、红岭乡、柳池乡、三河乡、老君乡、黄石乡、七里乡、庙安乡、天宝乡、东林乡、下八乡、凉风乡、上峡乡、塔河乡、茶河乡、天台乡、观山乡、南坪乡、凤林乡、桃花乡、白马乡、漆碑乡、石铁乡、厂溪乡、红峰乡、凤鸣乡、花池乡、庆云乡、马渡乡、隘口乡、三墩土家族乡、漆树土家族乡、龙泉土家族乡、渡口土家族乡',
        },
        {
          开江县:
            '新宁镇、普安镇、任市镇、回龙镇、天师镇、永兴镇、讲治镇、甘棠镇、广福镇、长岭镇、骑龙乡、长田乡、新太乡、灵岩乡、梅家乡、沙坝场乡、新街乡、靖安乡、宝石乡、拔妙乡',
        },
        {
          大竹县:
            '文星镇、石子镇、观音镇、清水镇、欧家镇、团坝镇、双拱镇、城西乡、竹北乡、东柳乡、朝阳乡、人和乡、中华乡、黄家乡、柏家乡、李家乡、月华乡、二郎乡、蒲包乡、新生乡、永胜乡、安吉乡、白坝乡、双溪乡、八渡乡、中和乡、杨通乡、天城乡、四合乡、童家乡、张家乡、神合乡、金鸡乡、黄滩乡、牌坊乡、姚市乡、莲印乡、川主乡、高明乡',
        },
        {
          渠县: '渠江镇、天星镇、临巴镇、土溪镇、三汇镇、文崇镇、涌兴镇、贵福镇、岩峰镇、静边镇、清溪场镇、宝城镇、有庆镇、鲜渡镇、琅琊镇、渠南乡、渠北乡、青龙乡、板桥乡、锡溪乡、龙潭乡、河东乡、李馥乡、青神乡、流溪乡、东安乡、汇东乡、汇南乡、汇北乡、丰乐乡、报恩乡、安北乡、任家乡、平安乡、千佛乡、柏水乡、大义乡、义和乡、水口乡、三板乡、巨光乡、蔡和乡、鹤林乡、白兔乡、青丝乡、万寿乡、射洪乡、望江乡、和乐乡、龙凤乡、新市乡、宋家乡、定远乡、嘉禾乡、李渡乡、望溪乡、双土乡、拱市乡、中滩乡、屏西乡、卷硐乡、胡家乡',
        },
        {
          万源市:
            '太平镇、青花镇、旧院镇、罗文镇、河口镇、草坝镇、竹峪镇、大竹镇、黄钟镇、官渡镇、白沙镇、沙滩镇、茶垭乡、长石乡、白羊乡、铁矿乡、固军乡、井溪乡、堰塘乡、蜂桶乡、花楼乡、长坝乡、曾家乡、大沙乡、秦河乡、庙垭乡、鹰背乡、石窝乡、玉带乡、新店乡、魏家乡、柳黄乡、溪口乡、永宁乡、虹桥乡、康乐乡、白果乡、钟亭乡、庙子乡、紫溪乡、庙坡乡、梨树乡、皮窝乡、丝罗乡、罐坝乡、石人乡、赵塘乡、中坪乡、八台乡、花萼乡、曹家乡、石塘乡',
        },
      ],
      雅安市: [
        {
          雨城区:
            '东城街道、西城街道、河北街道、青江街道、北郊镇、草坝镇、多营镇、合江镇、大兴镇、对岩镇、沙坪镇、中里镇、上里镇、严桥镇、晏场镇、碧峰峡镇、南郊乡、八步乡、观化乡、孔坪乡、凤鸣乡、望鱼乡',
        },
        {
          名山区:
            '蒙阳镇、百丈镇、车岭镇、永兴镇、马岭镇、新店镇、蒙顶山镇、黑竹镇、红星镇、城东乡、前进乡、中峰乡、联江乡、廖场乡、万古乡、红岩乡、双河乡、建山乡、解放乡、茅河乡',
        },
        {
          荥经县:
            '严道镇、花滩镇、大田坝乡、六合乡、烈太乡、安靖乡、民建彝族乡、烈士乡、荥河乡、新建乡、泗坪乡、新庙乡、三合乡、天凤乡、宝峰彝族乡、新添乡、附城乡、五宪乡、烟竹乡、青龙乡、龙苍沟乡',
        },
        {
          汉源县:
            '富林镇、九襄镇、乌斯河镇、宜东镇、富庄镇、清溪镇、大树镇、皇木镇、大田乡、唐家乡、富春乡、河西乡、大岭乡、前域乡、后域乡、大堰乡、两河乡、富乡乡、梨园乡、三交乡、双溪乡、西溪乡、建黎乡、市荣社区、富泉社区、万工社区、安乐乡、万里乡、马烈乡、白岩乡、青富乡、桂贤乡、河南乡、晒经乡、料林乡、小堡藏族彝族乡、片马彝族乡、坭美彝族乡、永利彝族乡、顺河乡',
        },
        {
          石棉县:
            '棉城街道、新棉镇、安顺彝族乡、先锋乡、蟹螺乡、永和乡、回隆乡、擦罗乡、栗子坪乡、美罗乡、迎政乡、宰羊乡、丰乐乡、新民乡、挖角乡、田湾乡、草科乡',
        },
        {
          天全县:
            '城厢镇、始阳镇、小河乡、思经乡、鱼泉乡、紫石乡、两路乡、大坪乡、乐英乡、多功乡、仁义乡、老场乡、新华乡、新场乡、兴业乡',
        },
        {
          芦山县:
            '芦阳镇、飞仙关镇、双石镇、太平镇、大川镇、思延乡、清仁乡、龙门乡、宝盛乡',
        },
        {
          宝兴县:
            '穆坪镇、灵关镇、陇东镇、蜂桶寨乡、硗碛乡、永富乡、明礼乡、五龙乡、大溪乡',
        },
      ],
      阿坝藏族羌族自治州: [
        {
          汶川县:
            '威州镇、绵虒镇、映秀镇、卧龙镇、水磨镇、漩口镇、龙溪乡、克枯乡、雁门乡、草坡乡、银杏乡、耿达乡、三江乡',
        },
        {
          理县: '杂谷脑镇、米亚罗镇、古尔沟镇、薛城镇、夹壁乡、朴头乡、甘堡乡、蒲溪乡、上孟乡、下孟乡、木卡乡、通化乡、桃坪乡',
        },
        {
          茂县: '凤仪镇、南新镇、回龙乡、叠溪镇、东兴乡、渭门乡、永和乡、沟口乡、光明乡、富顺乡、土门乡、黑虎乡、飞虹乡、三龙乡、白溪乡、洼底乡、石大关乡、太平乡、松坪沟乡、 曲谷乡、雅都乡',
        },
        {
          松藩县:
            '进安镇、川主寺镇、进安回族乡、十里回族乡、青云乡、安宏乡、大寨乡、牟尼乡、镇江关乡、镇坪乡、岷江乡、大姓乡、白羊乡、红土乡、红扎乡、小姓乡、燕云乡、山巴乡、水晶乡、小河乡、施家堡乡、黄龙乡、上八寨乡、下八寨乡、草原乡',
        },
        {
          九寨沟县:
            '永乐镇、漳扎镇、永丰乡、永和乡、安乐乡、白河乡、双河乡、保华乡、罗依乡、勿角乡、马家乡、郭元乡、草地乡、陵江乡、黑河乡、玉瓦乡、大录乡',
        },
        {
          金川县:
            '金川镇、观音桥镇、沙耳乡、庆宁乡、咯尔乡、勒乌乡、万林乡、河东乡、河西乡、集沐乡、撒瓦脚乡、卡拉脚乡、俄热乡、太阳河乡、二嘎里乡、阿科里乡、安宁乡、卡撒乡、 曾达乡、独松乡、马尔邦乡、马奈乡、毛日乡',
        },
        {
          小金县:
            '美兴镇、日隆镇、老营乡、崇德乡、新桥乡、美沃乡、沙龙乡、宅垄乡、新格乡、达维乡、日尔乡、结斯乡、沃日乡、木坡乡、两河乡、抚边乡、八角乡、双柏乡、窝底乡、汗牛乡、潘安乡',
        },
        {
          黑水县:
            '芦花镇、卡龙镇、沙石多乡、红岩乡、麻窝乡、双溜索乡、瓦钵梁子乡、色尔古乡、石碉楼乡、龙坝乡、洛多乡、木苏乡、维古乡、知木林乡、扎窝乡、晴朗乡、慈坝乡',
        },
        {
          马尔康县:
            '马尔康镇、卓克基镇、松岗镇、梭磨乡、白湾乡、党坝乡、木尔宗乡、脚木足乡、沙尔宗乡、龙尔甲乡、大藏乡、康山乡、草登乡、日部乡',
        },
        {
          壤塘县:
            '壤柯镇、蒲西乡、宗科乡、石里乡、吾伊乡、岗木达乡、上杜柯乡、茸木达乡、南木达乡、尕多乡、中壤塘乡、上壤塘乡',
        },
        {
          阿坝县:
            '阿坝镇、哇尔玛乡、麦昆乡、河支乡、龙藏乡、求吉玛乡、甲尔多乡、各莫乡、德格乡、四洼乡、安斗乡、柯河乡、垮沙乡、安羌乡、查理乡、茸安乡、洛尔达乡、麦尔玛乡、贾洛乡',
        },
        {
          若尔盖县:
            '达扎寺镇、班佑乡、阿西乡、唐克乡、辖曼乡、红星乡、麦溪乡、嫩哇乡、冻列乡 崇尔乡、热尔乡、占哇乡、降扎乡、巴西乡、阿西茸乡、求吉乡、包座乡',
        },
        {
          红原县:
            '邛溪镇、刷经寺镇、安曲乡、龙日乡、江茸乡、查尔玛乡、瓦切乡、阿木乡、壤口乡、麦洼乡、色地乡',
        },
      ],
      甘孜藏族自治州: [
        {
          康定市:
            '炉城镇、姑咱镇、新都桥镇、金汤镇、沙德镇、塔公镇、雅拉乡、时济乡、前溪乡、舍联乡、麦崩乡、三合乡、捧塔乡、吉居乡、瓦泽乡、呷巴乡、普沙绒乡、甲根坝乡、朋布西乡、孔玉乡、贡嘎山乡',
        },
        {
          泸定县:
            '泸桥镇、冷碛镇、兴隆镇、岚安乡、烹坝乡、田坝乡、杵坭乡、加郡乡、德威乡、新兴乡、得妥乡',
        },
        {
          丹巴县:
            '章谷镇、格宗乡、水子乡、边尔乡、梭坡乡、丹东乡、巴底乡、岳扎乡、巴旺乡、太平桥乡、聂呷乡、半扇门乡、中路乡、东谷乡、革什扎乡',
        },
        {
          九龙县:
            '呷尔镇、乃渠乡、汤古乡、三岩龙乡、八窝龙乡、上团乡、斜卡乡、烟袋乡、魁多乡、乌拉溪乡、洪坝乡、子耳彝族乡、三垭彝族乡、俄尔彝族乡、朵洛彝族乡、踏卡彝族乡、小金彝族乡、湾坝彝族乡',
        },
        {
          雅江县:
            '河口镇、呷拉乡、八角楼乡、普巴绒乡、祝桑乡、米龙乡、八衣绒乡、波斯河乡、恶古乡、牙衣河乡、西俄洛乡、麻郎错乡、德差乡、红龙乡、柯拉乡、瓦多乡、木绒乡',
        },
        {
          道孚县:
            '鲜水镇、八美镇、葛卡乡、格西乡、麻孜乡、孔色乡、瓦日乡、木茹乡、甲斯孔乡、色卡乡、龙灯乡、沙冲乡、协德乡、亚卓乡、红顶乡、仲尼乡、扎拖乡、下拖乡、甲宗乡、维它乡、银恩乡、七美乡',
        },
        {
          炉霍县:
            '仁达乡、斯木乡、宜木乡、新都镇、雅德乡、泥巴乡、卡娘乡、洛秋乡、旦都乡、朱倭乡、充古乡、更知乡、上罗科马乡、下罗科马乡、宗塔乡、宗麦乡',
        },
        {
          甘孜县:
            '甘孜镇、呷拉乡、色西底乡、卡攻乡、仁果乡、拖坝乡、斯俄乡、南多乡、生康乡、贡隆乡、庭卡乡、下雄乡、四通达乡、夺多乡、扎科乡、来马乡、昔色乡、泥柯乡、茶扎乡、大德乡、卡龙乡、查龙乡',
        },
        {
          新龙县:
            '茹龙镇、沙堆乡、乐安乡、大盖乡、绕鲁乡、色威乡、甲拉西乡、拉日马乡、博美乡、尤拉西乡、子拖西乡、和平乡、洛古乡、雄龙西乡、麻日乡、通霄乡、友谊乡、皮擦乡、银多乡',
        },
        {
          德格县:
            '更庆镇、达马乡、普马乡、岳巴乡、八邦乡、龚垭乡、白垭乡、俄南乡、竹庆乡、俄支乡、玉隆乡、错阿乡、窝公乡、温拖乡、年古乡、浪多乡、阿须乡、打滚乡、亚丁乡、所巴乡、中扎柯乡、上然姑乡、汪布顶乡、柯洛洞乡、卡松渡乡、马尼干戈乡',
        },
        {
          白玉县:
            '建设镇、金沙乡、绒盖乡、章都乡、麻绒乡、河坡乡、热加乡、登龙乡、赠科乡、阿察乡、麻邛乡、辽西乡、纳塔乡、安孜乡、盖玉乡、沙马乡、山岩乡',
        },
        {
          石渠县:
            '色须镇、尼呷镇、洛须镇、真达乡、奔达乡、正科乡、麻呷乡、德荣马乡、、长沙贡马乡、呷衣乡、格孟乡、蒙宜乡、新荣乡、宜牛乡、虾扎乡、起坞乡、阿日扎乡、长须贡马乡、长沙干马乡、长须干马乡、温波乡、瓦须乡',
        },
        {
          色达县:
            '色柯镇、翁达镇、洛若乡、霍西乡、年龙乡、大则乡、塔子乡、大章乡、亚龙乡、塔子乡、泥朵乡、然充乡、康勒乡、旭日乡、歌乐沱乡、甲学乡、杨各乡',
        },
        {
          理塘县:
            '高城镇、呷柯乡、曲登乡、禾尼乡、村戈乡、奔戈乡、莫坝乡、麦洼乡、拉波乡、格林乡、德巫乡、觉吾乡、君坝乡、绒坝乡、藏坝乡、上木拉乡、章纳乡、喇嘛垭乡、甲洼乡、濯桑乡、哈依乡、亚火乡、中木拉乡、下木拉乡',
        },
        {
          巴塘县:
            '夏邛镇、拉哇乡、竹巴龙乡、党巴乡、措拉乡、茶洛乡、列衣乡、德达乡、莫多乡、甲英乡、波戈溪乡、松多乡、中心绒乡、地巫乡、苏哇龙乡、昌波乡、中咱乡、亚日贡乡、波密乡',
        },
        {
          乡城县:
            '香巴拉镇、尼斯乡、沙贡乡、水洼乡、青德乡、青麦乡、然乌乡、洞松乡、热打乡、定波乡、正斗乡、白依乡',
        },
        {
          稻城县:
            '金珠镇、桑堆乡、省母乡、傍河乡、色拉乡、巨龙乡、邓波乡、木拉乡、赤土乡、香格里拉乡、蒙自乡、各卡乡、吉呷乡、俄雅同乡',
        },
        {
          得荣县:
            '松麦镇、斯闸乡、奔都乡、八日乡、日龙乡、徐龙乡、曲雅贡乡、古学乡、子庚乡、茨巫乡、白松乡、贡波乡',
        },
      ],
      凉山彝族自治州: [
        {
          西昌市:
            '北城街道、西城街道、东城街道、长安街道、新村街道、长宁街道、马道镇、礼州镇、安宁镇、川兴镇、黄联关镇、佑君镇、太和镇、安哈镇、西郊乡、高梘乡、小庙乡、月华乡、兴胜乡、琅环乡、西乡乡、樟木箐乡、大兴乡、海南乡、经久乡、西溪乡、黄水乡、中坝乡、阿七乡。彝族乡：四合乡、民胜乡、响水乡、开元乡、大箐乡、洛古波乡、荞地乡、磨盘乡、巴汝乡、银厂乡、白马乡、马鞍山乡。回族乡：裕隆回族乡、高草回族乡',
        },
        {
          盐源县:
            '盐井镇、卫城镇、梅雨镇、白乌镇、树河镇、黄草镇、平川镇、泸沽湖镇、双河乡、干海乡、下海乡、棉垭乡、甘塘乡、马鹿乡、藤桥乡、田湾乡、德石乡、大河乡、盐塘乡、巫木乡、大草乡、博大乡、金河乡、右所乡、巴折乡、阿萨乡、长柏乡、桃子乡、盖租乡、前所乡、沃底乡、大坡蒙古族乡、洼里乡、梅子坪乡',
        },
        {
          德昌县:
            '德州镇、永郎镇、乐跃镇、麻栗镇、阿月乡、前山乡、巴洞乡、宽裕乡、茨达乡、王所乡、热河乡、大山乡、六所乡、铁炉乡、马安乡、大弯乡、锦川乡、小高乡、老碾乡、大陆槽乡、金沙乡、南山乡',
        },
        {
          会理县:
            '北街街道、南街街道、北关街道、城关镇、鹿厂镇、黎溪镇、通安镇、太平镇、益门镇、绿水镇、新发镇、云甸镇、老街乡、果元乡、南阁乡、内东乡、外北乡、彰冠乡、爱民乡、爱国乡、凤营乡、白鸡乡、矮郎乡、小黑箐乡、河口乡、中厂乡、关河乡、鱼鲊乡、黎洪乡、金雨乡、树堡乡、江竹乡、新安傣族乡、普隆乡、竹箐乡、杨家坝乡、江普乡、木古乡、富乐乡、海潮乡、芭蕉乡、横山乡、马宗乡、法坪乡、槽元乡、黄柏乡、仓田乡、白果湾乡、下村乡、龙泉乡、六华乡、三地乡、六民乡',
        },
        {
          会东县:
            '：鲹鱼河镇、铅锌镇、乌东德镇、姜州镇、堵格镇、淌塘镇、铁柳镇、松坪镇、新街镇、嘎吉镇、满银沟镇、大崇镇、鲁吉镇、江西街乡、野租乡、拉马乡、老君滩乡、小坝乡、野牛坪乡、溜姑乡',
        },
        {
          宁南县:
            '披砂镇、松新镇、竹寿镇、华弹镇、葫芦口镇、白鹤滩镇、景星乡、俱乐乡、新村乡、幸福乡、海子乡、六铁乡、新建乡、稻谷乡、新华乡、松林乡、石梨乡、杉树乡、梁子乡、西瑶乡、红星乡、大同乡、骑骡沟乡、倮格乡、跑马乡',
        },
        {
          普格县:
            '普基镇、荞窝镇、螺髻山镇、永安乡、向阳乡、文坪乡、黎安乡、花山乡、东山乡、大坪乡、辉隆乡、洛乌沟乡、雨水乡、甘天地乡、洛乌乡、孟甘乡、特兹乡、吉乐乡、特口乡、耶底乡、夹铁乡、瓦洛乡、哈力洛乡、莱子乡、祝联乡、刘家坪乡、月吾乡、特补乡、五道箐乡、特尔果乡、大槽乡、马洪乡、洛甘乡、红莫依达乡',
        },
        {
          布拖县:
            '特木里镇、龙潭镇、拖觉镇、木尔乡、九都乡、拉达乡、乌科乡、沙洛乡、洛古乡、补尔乡、觉撒乡、美撒乡、拉果乡、乌依乡、浪珠乡、包谷坪乡、合井乡、罗家坪乡、牛角湾乡、补洛乡、火烈乡、乐安乡、四棵乡、地洛乡、峨里坪乡、瓦都乡、采哈乡、委只洛乡、基只乡、联补乡',
        },
        {
          金阳县:
            '天地坝镇、派来镇、芦稿镇、对坪镇、桃坪乡、热水河乡、马依足乡、红峰乡、尔觉西乡、热柯觉乡、甲依乡、木府乡、寨子乡、则祖乡、基觉乡、小银木乡、春江乡、红联乡、青松乡、放马坪乡、梗堡乡、山江乡、洛觉乡、向岭乡、谷德乡、高峰乡、老寨子乡、德溪乡、南瓦乡、依莫合乡、土沟乡、丙底乡、依达乡、丝窝乡',
        },
        {
          昭觉县:
            '新城镇、谷曲乡、波洛乡、龙恩乡、达洛乡、四开乡、地莫乡、博洛乡、解放乡、普诗乡、竹核乡、庆恒乡、补约乡、库依乡、比尔乡、则普乡、金曲乡、日哈乡、且莫乡、哈甘乡、塘且乡、城北乡、树坪乡、美甘乡、柳且乡、大坝乡、库莫乡 、三岗乡、尼地乡、碗厂乡、革吾乡、色底乡、永洛乡、龙沟乡、三岔河乡、支尔莫乡、特布洛乡、拉一木乡、央摩租乡、宜牧地乡、马增依乌乡、甘多洛古乡、特口甲谷乡、洒拉地坡乡、齿可波西乡、阿并洛古乡、久特洛古乡',
        },
        {
          喜德县:
            '光明镇、冕山镇、洛哈镇、两河口镇、米市镇、红莫镇、尼波镇。17乡：贺波洛乡、拉克乡、且拖乡、巴久乡、李子乡、东河乡、乐武乡、沙马拉达乡、热柯依达乡、依洛乡、鲁基乡、北山乡、西河乡、则约乡、博洛拉达乡、额尼乡、洛莫乡',
        },
        {
          冕宁县:
            '城厢镇、泸沽镇、复兴镇、沙坝镇、漫水湾镇、大桥镇、惠安乡、回坪乡、回龙乡、哈哈乡、森荣乡、宏模乡、林里乡、石龙乡、后山乡、先锋乡、河边乡、河里乡、泽远乡、铁厂乡、曹古乡、彝海乡、拖乌乡、冶勒乡、锦屏乡、南河乡、青纳乡、和爱乡、棉沙乡、马头乡、窝堡乡、新兴乡、健美乡、里庄乡、麦地沟乡、金林乡、联合乡、腊窝乡',
        },
        {
          越西县:
            '越城镇、中所镇、新民镇、乃托镇、普雄镇、新乡乡、马拖乡、大瑞乡、南箐乡、丁山乡、大花乡、河东乡、西山乡、板桥乡、瓦岩乡、大屯乡、保安藏族乡、白果乡、梅花乡、拉普乡、铁西乡、尔觉乡、四甘普乡、贡莫乡、拉白乡、乐青地乡、德吉乡、依洛地坝乡、尔赛乡、古二乡、竹阿觉乡、保石乡、五里箐乡、书古乡、瓦普莫乡、申果乡、瓦曲觉乡、申普乡、瓦里觉乡、拉吉乡',
        },
        {
          甘洛县:
            '新市坝镇、田坝镇、海棠镇、吉米镇、斯觉镇、普昌镇、玉田镇、前进乡、胜利乡、新茶乡、两河乡、里克乡、尼尔觉乡、拉莫乡、波波乡、阿嘎乡、阿尔乡、石海乡、团结乡、嘎日乡、则拉乡、坪坝乡、蓼坪乡、阿兹觉乡、乌史大桥乡、黑马乡、沙岱乡、苏雄乡',
        },
        {
          美姑县:
            '巴普镇、觉洛乡、巴古乡、农作乡、瓦古乡、尔其乡、拖木乡、炳途乡、瓦西乡、采红乡、苏洛乡、竹库乡、典补乡、龙门乡、洒库乡、九口乡、柳洪乡、龙窝乡、子威乡、尔合乡、哈洛乡、尼哈乡、乐约乡、树窝乡、合姑洛乡、候古莫乡、牛牛坝乡、依果觉乡、峨曲古乡、佐戈依达乡、拉木阿觉乡、洛莫依达乡、井叶特西乡、依洛拉达乡、候播乃拖乡、洛俄依甘乡	',
        },
        {
          雷波县:
            '锦城镇、汶水镇、黄琅镇、西宁镇、金沙镇、海湾乡、帕哈乡、杉树堡乡、箐口乡、永盛乡、顺河乡、回龙场乡、溪洛米乡、渡口乡、马湖乡、中田乡、谷米乡、柑子乡、双河口乡、罗山溪乡、桂花乡、沙沱乡、烂坝子乡、八寨乡、松树乡、拉咪乡、千万贯乡、五官乡、曲依乡、卡哈洛乡、元宝山乡、大岩洞乡、岩脚乡、莫红乡、坪头乡、克觉乡、上田坝乡、簸箕梁子乡、大坪子乡、小沟乡、雷池乡、咪姑乡、一车乡、巴姑乡、斯古溪乡、山棱岗乡、长河乡、谷堆乡',
        },
        {
          木里藏族自治县:
            '瓦厂区、博瓦区、茶布朗区、桃坝乡、博科乡、宁朗乡、依吉乡、俄亚纳西族乡、水洛乡牦牛坪乡、屋脚蒙古族乡、乔瓦镇项脚蒙古族乡、李子坪乡列瓦乡、芽租乡、下麦地乡、西秋乡、克尔乡、白碉苗族乡、三桷垭乡、倮波乡、卡拉乡、沙湾乡、东孜乡、固增苗族乡、麦日乡、东朗乡、唐央乡、博窝乡、麦地龙乡',
        },
      ],
      广安市: [
        {
          广安区:
            '浓洄街道、北辰街道、广福街道、万盛街道、中桥街道、浓洄镇、协兴镇、悦来镇、花桥镇、恒升镇、石笋镇、枣山镇、官盛镇、浓溪镇、兴平镇、井河镇、龙台镇、肖溪镇、白市镇、大安镇、广门乡、化龙乡、大龙乡、彭家乡、蒲莲乡、东岳乡、郑山乡、崇望乡、消河乡、大有乡、广罗乡、方坪乡、龙安乡、杨坪乡、白马乡、苏溪乡、穿石乡',
        },
        {
          前锋区:
            '大佛寺街道、奎阁街道、代市镇、观塘镇、护安镇、观阁镇、广兴镇、桂兴镇、龙滩镇、虎城镇、光辉乡、小井乡、新桥乡',
        },
        {
          岳池县:
            '九龙镇、苟角镇、罗渡镇、中和镇、顾县镇、石垭镇、酉溪镇、坪滩镇、花园镇、白庙镇、龙孔镇、镇裕镇、同兴镇、兴隆镇、秦溪镇、天平镇、乔家镇、裕民镇、新场镇、赛龙镇、普安镇、临溪镇、镇龙乡、排楼乡、东板乡、双鄢乡、长田乡、鱼峰乡、大石乡、粽粑乡、西板乡、大佛乡、伏龙乡、恐龙乡、黄龙乡、朝阳乡、花板乡、齐福乡、北城乡、嘉陵乡、石鼓乡、平安乡、团结乡',
        },
        {
          武胜县:
            '沿口镇、飞龙镇、烈面镇、中心镇、万善镇、三溪镇、金牛镇、赛马镇、胜利镇、乐善镇、礼安镇、龙女镇、华封镇、街子镇、清平镇、万隆镇、宝箴塞镇、鼓匠乡、白坪乡、石盘乡、真静乡、鸣钟乡、高石乡、八一乡、永胜乡、双星乡、猛山乡、龙庭乡、旧县乡、新学乡、金光乡',
        },
        {
          邻水县:
            '鼎屏镇、九龙镇、丰禾镇、柑子镇、城北镇、城南镇、合流镇、坛同镇、高滩镇、御临镇、袁市镇、石永镇、兴仁镇、王家镇、八耳镇、观音桥镇、牟家镇、龙安镇、太和乡、新镇乡、冷家乡、长安乡、两河乡、关河乡、龙桥乡、黎家乡、椿木乡、梁板乡、三古乡、风垭乡、西天乡、同石乡、复盛乡、子中乡、护邻乡、凉山乡、长滩乡、甘坝乡、四海乡、九峰乡、古路乡、荆坪乡、柳塘乡、石滓乡、华蓥乡',
        },
        {
          华蓥市:
            '双河街道、华龙街道、古桥街道、阳和镇、高兴镇、溪口镇、庆华镇、天池镇、禄市镇、永兴镇、明月镇、观音溪镇、红岩乡',
        },
        { 广安经济技术开发区: '新桥工业园区、奎阁工业园区' },
        { 枣山物流商贸园区: '枣山镇、广门乡' },
        { 协兴生态文化旅游园区: '协兴镇、浓溪镇' },
      ],
      巴中市: [
        {
          巴州区:
            '东城街道、西城街道、回风街道、江北街道、玉堂街道、宕梁街道、兴文街道、大茅坪镇、清江镇、水宁寺镇、曾口镇、梁永镇、鼎山镇、大罗镇、化成镇、三江镇、枣林镇、光辉乡、花溪乡、大和乡、白庙乡、关渡乡、金碑乡、羊凤乡、凤溪乡、龙背乡、平梁乡、凌云乡、寺岭乡、梓橦庙乡',
        },
        {
          恩阳区:
            '登科街道、柳林镇、渔溪镇、青木镇、花丛镇、玉山镇、茶坝镇、三汇镇、上八庙镇、下八庙镇、三河场镇、观音井镇、明扬镇、石城乡、兴隆场乡、关公乡、三星乡、舞凤乡、双胜乡、群乐乡、万安乡、尹家乡、九镇乡、玉井乡、义兴乡',
        },
        {
          平昌县:
            '同州街道、江口镇、响滩镇、驷马镇、得胜镇、白衣镇、元山镇、岳家镇、云台镇、兰草镇、西兴镇、镇龙镇、笔山镇、邱家镇、涵水镇、坦溪镇、佛楼镇、望京镇、土兴镇、青凤镇、灵山镇、南风乡、大寨乡、六门乡、龙岗乡、元石乡、青云乡、板庙乡、泥龙乡、岩口乡、界牌乡、喜神乡、澌岸乡',
        },
        {
          南江县:
            '南江镇、沙河镇、乐坝镇、长赤镇、正直镇、大河镇、光雾山镇、东榆镇、下两镇、赶场镇、杨坝镇、赤溪乡、燕山乡、八庙乡、高塔乡、团结乡、红光乡、傅家乡、红四乡、天池乡、侯家乡、双桂乡、凤仪乡、朱公乡、黑潭乡、和平乡、双流乡、元潭乡、高桥乡、平岗乡、仁和乡、石滩乡、关门乡、兴马乡、北极乡、关路乡、关田乡、红岩乡、桥亭乡、贵民乡、沙坝乡、柳湾乡、汇滩乡、上两乡、关坝乡、寨坡乡、坪河乡、流坝乡',
        },
        {
          通江县:
            '诺江镇、铁佛镇、洪口镇、麻石镇、瓦室镇、涪阳镇、铁溪镇、诺水河镇、民胜镇、火炬镇、广纳镇、永安镇、至诚镇、沙溪镇、杨柏乡、大兴乡、东山乡、三溪乡、双泉乡、文峰乡、春在乡、三合乡、云昙乡、唱歌乡、芝苞乡、董溪乡、澌波乡、松溪乡、九层乡、胜利乡、板凳乡、文胜乡、兴隆乡、毛裕乡、泥溪乡、烟溪乡、沙坪乡、朱元乡、长坪乡、空山乡、青浴乡、铁厂乡、新场乡、陈河乡、草池乡、回林乡、龙凤场乡、两河口乡、板桥口乡、曲滨乡',
        },
      ],
      眉山市: [
        {
          东坡区:
            '苏祠街道、大石桥街道、通惠街道、松江镇、多悦镇、象耳镇、白马镇、太和镇、悦兴镇、富牛镇、思蒙镇、崇仁镇、尚义镇、万胜镇、崇礼镇、秦家镇、永寿镇、修文镇、复盛乡、土地乡、金花乡、复兴乡、柳圣乡、广济乡、三苏乡、盘鳌乡',
        },
        {
          彭山区:
            '凤鸣镇、江口镇、观音镇、江口镇、青龙镇、公义镇、灵石镇、谢家镇、彭溪镇、武阳乡、保胜乡、义和乡、锦江乡',
        },
        {
          仁寿县:
            '文林镇、富加镇、汪洋镇、龙正镇、钟祥镇、视高镇、禾加镇、文宫镇、龙马镇、北斗镇、彰加镇、高家镇、满井镇、禄加镇、清水镇、始建镇、慈航镇、中农镇、宝飞镇、方家镇、杨柳镇、黑龙滩镇、大化镇、宝马乡、天峨乡、元通乡、曲江乡、兴盛乡、里仁乡、中岗乡、向家乡、龙桥乡、兆嘉乡、景贤乡、虞丞乡、双堡乡、新店乡、凤陵乡、识经乡、促进乡、城堰乡、鸭池乡、谢安乡、河口乡、板桥乡、涂家乡、洪峰乡、四公乡、松峰乡、古佛乡、板燕乡、观寺乡、鳌陵乡、珠嘉乡、曹家乡、合兴乡、石嘴乡、藕塘乡、玉龙乡、青岗乡、农旺乡',
        },
        {
          洪雅县:
            '洪川镇、止戈镇、三宝镇、余坪镇、槽渔滩镇、中保镇、东岳镇、花溪镇、柳江镇、高庙镇、瓦屋山镇、中山乡、将军乡、汉王乡、桃源乡',
        },
        { 丹棱县: '丹棱镇、杨场镇、仁美镇、双桥镇、张场镇、顺龙乡、石桥乡' },
        {
          青神县:
            '青城镇、瑞峰镇、汉阳镇、黑龙镇、西龙镇、河坝子镇、南城镇、白果乡、高台乡、罗波乡',
        },
      ],
    },
  ],
  贵州省: [
    {
      贵阳市: [
        {
          观山湖区:
            '金源社区、金岭社区、金麦社区、碧海社区、世纪城社区、会展社区、金华园社区、新世界社区、逸景社区、金华镇、朱昌镇、百花湖乡',
        },
        {
          南明区:
            '湘雅社区、新华社区、西湖社区、水口寺社区、大南社区、中南社区、市府社区、河滨社区、遵义社区、兴关社区、沙冲社区、沙南社区、太慈社区、花果园社区、油榨社区、中曹司社区、二戈寨社区、见龙社区、龙洞社区、后巢乡、云关乡、小碧布依族苗族乡、永乐乡',
        },
        {
          云岩区:
            '黔灵镇、中华社区、中环社区、中东社区、东山社区、延中社区、市西社区、威清社区、普陀社区、北京路社区、栖霞社区、黔东社区、宅吉社区、省府社区、金狮社区、中天社区、贵乌社区、头桥社区、金龙社区、圣泉社区、三桥社区、金关社区、蔡关社区、荷塘社区、普天社区、金鸭社区、金惠社区',
        },
        {
          花溪区:
            '青岩镇、石板镇、麦坪镇、燕楼镇、孟关苗族布依族乡、党武乡、湖潮苗族布依族乡、久安乡、高坡苗族乡、黔陶布依族苗族乡、马铃布依族苗族乡、贵筑社区、溪北社区、清溪社区、兴隆社区、瑞华社区、清浦社区、黔江社区、平桥社区、航天社区、航空社区、金欣社区、黄河社区、三江社区、小孟社区、金竹社区、花孟社区',
        },
        {
          乌当区:
            '东风镇、水田镇、羊昌镇、下坝镇、百宜镇、新场镇、新堡布依族乡、偏坡布依族乡、顺新社区、新天社区、创新社区、振新社区、高新社区、新阳社区',
        },
        {
          白云区:
            ':艳山红镇、麦架镇、沙文镇、都拉布依族乡、牛场布依族乡、红云社区、大山洞社区、艳山红社区、铝兴社区、白沙关社区、都新社区',
        },
        {
          开阳县:
            '城关镇、双流镇、金中镇、冯三镇、楠木渡镇、龙岗镇、南龙乡、永温乡、宅吉乡、花梨乡、龙水乡、米坪乡、禾丰乡、南江乡、高寨乡、毛云乡',
        },
        {
          息烽县:
            '永靖镇、温泉镇、九庄镇、小寨坝镇、青山苗族乡、西山镇、养龙司镇、石硐镇、鹿窝乡、流长乡、新华社区',
        },
        {
          修文县:
            '龙场镇、扎佐镇、久长镇、六广镇、六屯镇、洒坪镇、六桶镇、谷堡乡、小箐乡、大石布依族乡、龙岗社区',
        },
        {
          清镇市:
            '红枫湖镇、站街镇、卫城镇、新店镇、麦格苗族布依族乡、暗流镇、王庄布依族苗族乡、流长苗族乡、犁倭镇、新岭社区、红塔社区、巢凤社区、百花社区、红新社区',
        },
      ],
      六盘水市: [
        {
          钟山区:
            '黄土坡街道、荷城街道、凤凰街道、德坞街道、月照街道、大湾镇、汪家寨镇、大河镇、双戛彝族乡',
        },
        {
          六枝特区:
            '平寨镇、郎岱镇、岩脚镇、木岗镇、大用镇、新华乡、新窑乡、新场乡、堕却乡、龙场乡、洒志彝族布依族苗族乡、梭戛苗族彝族回族乡、落别布依族彝族乡、折溪彝族乡、中寨苗族彝族布依族乡、牛场苗族彝族乡、箐口彝族仡佬族布依族乡、毛口布依族苗族乡、陇脚布依族乡',
        },
        {
          盘县: ' 翰林街道、亦资街道、两河街道、红果镇、城关镇、火铺镇、洒基镇、水塘镇、马依镇、板桥镇、民主镇、盘江镇、老厂镇、响水镇、乐民镇、保田镇、平关镇、柏果镇、西冲镇、断江镇、刘官镇、大山镇、石桥镇、滑石镇、珠东镇、新民镇、英武乡、忠义乡、普田回族乡、马场彝族苗族乡、鸡场坪彝族乡、旧营白族彝族苗族乡、羊场布依族白族苗族乡、保基苗族彝族乡、四格彝族乡、淤泥彝族乡、普古彝族苗族乡、坪地彝族乡、松河彝族乡',
        },
        {
          水城县:
            '尖山街道、双水街道、老鹰山街道、董地街道、保华镇、阿戛镇、陡箐镇、米箩镇、鸡场镇、发耳镇、都格镇、勺米镇、玉舍镇、木果镇、化乐镇、比德镇、蟠龙镇、杨梅彝族苗族回族乡、新街彝族苗族布依族乡、南开苗族彝族乡、青林苗族彝族乡、龙场苗族白族彝族乡、营盘苗族彝族白族乡、野钟苗族彝族布依族乡、果布戛彝族苗族布依族乡、猴场苗族布依族乡、金盆苗族彝族乡、坪寨彝族乡、花戛苗族布依族彝族乡、顺场苗族彝族布依族乡',
        },
      ],
      遵义市: [
        {
          红花岗区:
            '老城街道、万里路街道、中华路街道、南门关街道、延安路街道、舟水桥街道、中山路街道、北京路街道、忠庄镇、长征镇、南关镇、海龙镇、深溪镇、巷口镇、金鼎山镇',
        },
        {
          汇川区:
            '上海路街道、洗马路街道、大连路街道、高桥镇、董公寺镇、团泽镇、高坪镇、板桥镇、泗渡镇',
        },
        {
          遵义县:
            '南白镇、龙坑镇、三岔镇、苟江镇、三合镇、乌江镇、新舟镇、乐山镇、龙坪镇、喇叭镇、团溪镇、铁厂镇、西坪镇、尚嵇镇、茅栗镇、新民镇、鸭溪镇、石板镇、枫香镇、泮水镇、马蹄镇、沙湾镇、松林镇、毛石镇、山盆镇、芝麻镇、平正仡佬族乡、洪关苗族乡',
        },
        {
          桐梓县:
            '娄山关镇、楚米镇、新站镇、松坎镇、高桥镇、水坝塘镇、官仓镇、花秋镇、羊磴镇、九坝镇、大河镇、夜郎镇、木瓜镇、坡渡镇、燎原镇、狮溪镇、茅石镇、尧龙山镇、风水乡、容光乡、小水乡、黄连乡、芭蕉乡、马鬃苗族乡',
        },
        {
          绥阳县:
            '洋川镇、郑场镇、旺草镇、蒲场镇、风华镇、茅垭镇、枧坝镇、宽阔镇、黄杨镇、青杠塘镇、太白镇、温泉镇、坪乐乡、大路槽乡、小关乡',
        },
        {
          正安县:
            '安场镇、凤仪镇、庙塘镇、格林镇、中观镇、新州镇、芙蓉江镇、和溪镇、流渡镇、瑞溪镇、小雅镇、班竹镇、土坪镇、桴焉乡、乐俭乡、杨兴乡、碧峰乡、市坪苗族仡佬族乡、谢坝仡佬族苗族乡',
        },
        {
          凤冈县:
            '龙泉镇、进化镇、琊川镇、蜂岩镇、永和镇、花坪镇、绥阳镇、土溪镇、永安镇、何坝乡、天桥乡、王寨乡、石径乡、新建乡',
        },
        {
          湄潭县:
            '湄江镇、永兴镇、复兴镇、马山镇、鱼泉镇、黄家坝镇、高台镇、茅坪镇、兴隆镇、西河镇、洗马镇、新南镇、石莲镇、抄乐镇、天城镇',
        },
        {
          余庆县:
            '白泥镇、小腮镇、龙溪镇、构皮滩镇、大乌江镇、敖溪镇、龙家镇、松烟镇、关兴镇、花山苗族乡',
        },
        {
          习水县:
            '东皇镇、土城镇、同民镇、醒民镇、隆兴镇、习酒镇、回龙镇、桑木镇、永安镇、良村镇、温水镇、仙源镇、官店镇、寨坝镇、民化乡、二郎乡、二里乡、三岔河乡、大坡乡、双龙乡、桃林乡、坭坝乡、程寨乡',
        },
        {
          道真仡佬族苗族自治县:
            '玉溪镇、三江镇、隆兴镇、旧城镇、三桥镇、洛龙镇、忠信镇、阳溪镇、平模镇、大矸镇、棕坪乡、桃源乡、河口乡、上坝土家族乡',
        },
        {
          务川仡佬族苗族自治县:
            '都濡镇、丰乐镇、黄都镇、涪洋镇、镇南镇、砚山镇、浞水镇、茅天镇、柏村镇、大坪镇、蕉坝乡、红丝乡、泥高乡、分水乡、石朝乡',
        },
        {
          赤水市:
            '：市中街道、文华街道、金华街道、天台镇、复兴镇、大同镇、旺隆镇、葫市镇、元厚镇、官渡镇、长期镇、长沙镇、丙安乡、两河口乡、宝源乡、石堡乡、白云乡',
        },
        {
          仁怀市:
            '中枢街道、盐津街道、苍龙街道；茅台镇、鲁班镇、二合镇、合马镇、长岗镇、坛厂镇、茅坝镇、五马镇、九仓镇、喜头镇、三合镇、大坝镇；高大坪乡、火石岗乡、龙井乡、沙滩乡、学孔乡、后山苗族布依族自治乡',
        },
      ],
      安顺市: [
        {
          西秀区:
            '南街街道、东街街道、西街街道、北街街道、东关街道、华西街道、宋旗镇、幺铺镇、宁谷镇、龙宫镇、双堡镇、大西桥镇、七眼桥镇、蔡官镇、轿子山镇、旧州镇、新场布依族苗族乡、岩腊苗族布依族乡、鸡场布依族苗族乡、杨武布依族苗族乡、东屯乡、黄腊布依族苗族乡、刘官乡',
        },
        {
          平坝区:
            '齐伯镇、马场镇、天龙镇、白云镇、夏云镇、高峰镇、乐平镇、十字回族苗族乡、羊昌布依族苗族乡、鼓楼街道、安平街道',
        },
        {
          普定县:
            '城关镇、马官镇、化处镇、马场镇、白岩镇、坪上乡、龙场乡、鸡场坡乡、补郎苗族乡、猴场苗族仡佬族乡、猫洞苗族仡佬族乡',
        },
        {
          镇宁布依族苗族自治县:
            '江龙镇、城关镇、丁旗镇、黄果树镇、沙子乡、募役乡、六马乡、良田乡、简嘎乡、革利乡、打帮乡、扁担山乡、本寨乡、朵卜陇乡、马厂乡、大山乡',
        },
        {
          关岭布依族苗族自治县:
            '关索街道、顶云街道、永宁镇、花江镇、坡贡镇、上关镇、岗乌镇、断桥镇、新铺镇、沙营镇、八德乡、普利乡、板贵乡',
        },
        {
          紫云苗族布依族自治县:
            '松山镇、水塘镇、猴场镇、猫营镇、板当镇、白石岩乡、宗地乡、大营乡、四大寨乡、坝羊乡、火花乡、达帮乡',
        },
        { 安顺经济开发区: '宋旗镇、么铺镇、西航街道' },
        { 黄果树风景名胜区: '黄果树镇、白水镇' },
      ],
      毕节市: [
        {
          七星关区:
            '市西街道、市东街道、观音桥街道、三板桥街道、大新桥街道、麻园街道、洪山街道、碧阳街道、德溪街道、碧海街道、对坡镇、小吉场镇、杨家湾镇、大银镇、田坝桥镇、龙场营镇、撒拉溪镇、林口镇、水箐镇、青场镇、清水铺镇、田坝镇、朱昌镇、亮岩镇、燕子口镇、鸭池镇、层台镇、何官屯镇、长春堡镇、海子街镇、生机镇、普宜镇、放珠镇、大河乡、野角乡、田坎彝族乡、团结彝族苗族乡、阴底彝族苗族白族乡、千溪彝族苗族白族乡、阿市苗族彝族乡、大屯彝族乡',
        },
        {
          大方县:
            '慕俄格古城街道、顺德街道、红旗街道、双山镇、猫场镇、马场镇、羊场镇、黄泥塘镇、六龙镇、达溪镇、瓢井镇、长石镇、对江镇、东关乡、竹园彝族苗族乡、响水白族彝族仡佬族乡、文阁乡、绿塘乡、鼎新彝族苗族乡、牛场苗族彝族乡、小屯乡、理化苗族彝族乡、凤山彝族蒙古族乡、安乐彝族仡佬族乡、核桃彝族白族乡、八堡彝族苗族乡、兴隆苗族乡、果瓦乡、大山苗族彝族乡、雨冲乡、黄泥彝族苗族满族乡、大水彝族苗族布依族乡、沙厂彝族乡、普底彝族苗族白族乡、百纳彝族乡、三元彝族苗族白族乡、星宿苗族彝族仡佬族乡',
        },
        {
          黔西县:
            '城关镇、金碧镇、雨朵镇、大关镇、谷里镇、素朴镇、中坪镇、重新镇、林泉镇、五里布依族苗族乡、沙井苗族彝族仡佬族乡、羊场乡、绿化白族彝族乡、新仁苗族乡、钟山布依族彝族乡、铁石苗族彝族乡、协和彝族苗族乡、太来彝族苗族乡、甘棠乡、永燊彝族苗族乡、花溪彝族苗族乡、中建苗族彝族乡、定新彝族苗族乡、洪水乡、金坡苗族彝族满族乡、仁和彝族苗族乡、红林彝族苗族乡、锦星乡',
        },
        {
          金沙县:
            '鼓场街道、岩孔街道、五龙街道、西洛街道、柳塘镇、沙土镇、岚头镇、安底镇、禹谟镇、清池镇、源村镇、平坝镇、后山乡、长坝乡、木孔乡、茶园乡、化觉乡、高坪乡、桂花乡、石场苗族彝族乡、太平彝族苗族乡、马路彝族苗族乡、安洛苗族彝族满族乡、新化苗族彝族满族乡、大田彝族苗族布依族乡',
        },
        {
          织金县:
            '城关镇、桂果镇、牛场镇、猫场镇、化起镇、龙场镇、八步镇、以那镇、三塘镇、阿弓镇、珠藏镇、三甲白族苗族乡、自强苗族乡、大平苗族彝族乡、官寨苗族乡、茶店布依族苗族彝族乡、金龙苗族彝族布依族乡、后寨苗族乡、鸡场苗族彝族布依族乡、中寨乡、绮陌乡、普翁乡、实兴乡、马场乡、上坪寨乡、营合乡、纳雍乡、板桥乡、白泥乡、少普乡、熊家场乡、黑土乡',
        },
        {
          纳雍县:
            '居仁街道办事处、文昌街道办事处、雍熙街道办事处、鬃岭镇、阳长镇、维新镇、龙场镇、乐治镇、百兴镇、张家湾镇、勺窝乡、新房彝族苗族乡、厍东关彝族苗族白族乡、董地苗族彝族乡、寨乐乡、化作苗族彝族乡、老凹坝乡、沙包乡、水东乡、曙光乡、姑开苗族彝族乡、羊场苗族彝族乡、锅圈岩苗族彝族乡、昆寨苗族彝族白族乡、左鶂嘎彝族苗族乡、猪场苗族彝族乡',
        },
        {
          威宁彝族回族苗族自治县:
            '五里岗街道、六桥街道、海边街道、陕桥街道；19个镇：草海镇、幺站镇、金钟镇、炉山镇、龙场镇、黑石头镇、哲觉镇、观风海镇、牛棚镇、迤那镇、中水镇、龙街镇、雪山镇、羊街镇、小海镇、盐仓镇、东风镇、二塘镇、猴场镇；15个乡：金斗乡、岔河乡、麻乍乡、海拉乡、哈喇河乡、秀水乡、斗古乡、玉龙乡、黑土河乡、石门乡、云贵乡、兔街乡、双龙乡、板底乡、大街乡、新发布依族乡',
        },
        {
          赫章县:
            '城关镇、白果镇、妈姑镇、财神镇、六曲河镇、野马川镇、水塘堡彝族苗族乡、达依乡、兴发苗族彝族回族乡、松林坡白族彝族苗族乡、雉街彝族苗族乡、珠市彝族乡、罗州乡、双坪彝族苗族乡、铁匠苗族乡、辅处彝族苗族乡、可乐彝族苗族乡、德卓乡、河镇彝族苗族乡、安乐溪乡、结构彝族苗族乡、朱明乡、古基乡、哲庄乡、古达苗族彝族乡、威奢乡、平山乡',
        },
        { 双山新区: '双山镇、响水乡、文阁乡、竹园乡、梨树镇、岔河镇' },
      ],
      铜仁市: [
        {
          碧江区:
            '市中街道、环北街道、河西街道、谢桥街道、灯塔街道、川硐镇、坝黄镇、云场坪镇、漾头镇、桐木坪侗族乡、滑石侗族苗族土家族乡、和平土家族侗族乡、瓦屋侗族乡、六龙山侗族土家族乡',
        },
        {
          万山区:
            '万山镇、谢桥街道、茶店街道、高楼坪侗族乡、黄道侗族乡、敖寨乡、下溪乡',
        },
        {
          江口县:
            '双江镇、闵孝镇、太平土家族苗族乡、坝盘土家族侗族苗族乡、德旺土家族苗族乡、民和侗族土家族苗族乡、官和侗族土家族苗族乡、桃映土家族苗族乡、怒溪土家族苗族乡',
        },
        {
          石阡县:
            '汤山镇、龙塘镇、本庄镇、白沙镇、花桥镇、五德镇、中坝镇、河坝场乡、国荣乡、聚凤仡佬族侗族乡、龙井侗族仡佬族乡、大沙坝仡佬族侗族乡、枫香侗族仡佬族乡、青阳苗族仡佬族侗族乡、石固仡佬族侗族乡、坪地场仡佬族侗族乡、甘溪仡佬族侗族乡、坪山仡佬族侗族乡',
        },
        {
          思南县:
            '塘头镇、许家坝镇、大坝场镇、文家店镇、鹦鹉溪镇、合朋溪镇、张家寨镇、孙家坝镇、青杠坡镇、瓮溪镇、凉水井镇、邵家桥镇、长坝镇、板桥镇、大河坝镇、香坝镇、亭子坝镇、思林土家族苗族乡、胡家湾苗族土家族乡、宽坪土家族苗族乡、枫芸土家族苗族乡、三道水土家族苗族乡、天桥土家族苗族乡、兴隆土家族苗族乡、杨家坳苗族土家族乡',
        },
        {
          德江县:
            '青龙街道、玉水街道、煎茶镇、潮砥镇、枫香溪镇、稳坪镇、高山镇、泉口镇、长堡镇、共和镇、平原镇、堰塘土家族乡、龙泉土家族乡、钱家土家族乡、沙溪土家族乡、楠杆土家族乡、复兴土家族苗族乡、合兴土家族乡、桶井土家族乡、荆角土家族乡、长丰土家族乡',
        },
        { 玉屏侗族自治县: '平溪镇、大龙镇、朱家场镇、田坪镇、新店乡、亚鱼乡' },
        {
          松桃苗族自治县:
            '蓼皋镇、盘石镇、盘信镇、大坪场镇、普觉镇、寨英镇、孟溪镇、乌罗镇、甘龙镇、长兴堡镇、迓驾镇、大兴镇、牛郎镇、九江乡、世昌乡、正大乡、长坪乡、太平营乡、平头乡、大路乡、妙隘乡、冷水溪乡、石梁乡、瓦溪乡、永安乡、木树乡、黄板乡、沙坝河乡',
        },
        {
          沿河土家族自治县:
            '和平镇、沙子镇、谯家镇、淇滩镇、夹石镇、官舟镇、土地坳镇、思渠镇、客田镇、洪渡镇、大漆乡、黑水乡、黑獭乡、中界乡、晓景乡、甘溪乡、板场乡、泉坝乡、中寨乡、黄土乡、新景乡、塘坝乡、后坪乡',
        },
        {
          印江土家族苗族自治县:
            '峨岭镇、板溪镇、沙子坡镇、天堂镇、木黄镇、合水镇、朗溪镇、缠溪镇、洋溪镇、新寨乡、中坝乡、杉树乡、刀坝乡、新业乡、永义乡、罗场乡、杨柳乡',
        },
      ],
      黔西南布依族苗族自治州: [
        {
          兴义市:
            '黄草街道、兴泰街道、桔山街道、丰都街道、坪东街道、木贾街道、下五屯街道、万峰林街道、郑屯镇、鲁屯镇 、万屯镇、顶效镇、马岭镇 、清水河镇、威舍镇、乌沙镇 、白碗窑镇、三江口镇、鲁布格镇 、捧鲊镇、七舍镇、敬南镇 、泥凼镇、仓更镇 、巴结镇、雄武乡、洛万乡、 沧江乡 、则戎乡、猪场坪乡',
        },
        {
          兴仁县:
            '东湖街道、城北街道、城南街道、真武山街道、屯脚镇、巴铃镇、回龙镇、雨樟镇、潘家庄镇、下山镇、新龙场镇、百德镇、李关乡、民建乡、鲁础营乡、大山乡、田湾乡、新马场乡',
        },
        {
          安龙县:
            '栖凤街道、招堤街道、钱相街道、普坪镇、笃山镇、海子镇、洒雨镇、龙山镇、万峰湖镇',
        },
        {
          贞丰县:
            '：珉谷镇、龙场镇、者相镇、北盘江镇、白层镇、鲁贡镇、连环乡、挽漾乡、小屯乡、长田乡、平街乡、鲁容乡、沙坪乡',
        },
        {
          普安县:
            '盘水镇、龙吟镇、罐子窑镇、江西坡镇、三板桥镇、地瓜镇、青山镇、楼下镇、白沙乡、高棉乡、窝沿乡、罗汉乡、新店乡、雪浦乡',
        },
        {
          晴隆县:
            '莲城镇、沙子镇、碧痕镇、大厂镇、鸡场镇、花贡镇、中营镇、光照镇、长流乡、大田乡、马场乡、紫马乡、安谷乡、三宝彝族乡',
        },
        {
          册亨县:
            '者楼镇、坡妹镇、冗渡镇、丫他镇、巧马镇、秧坝镇、坝赖镇、岩架镇、八渡镇、庆坪乡、达央乡、威旁乡、弼佑乡、百口乡',
        },
        {
          望谟县:
            '复兴镇、乐元镇、打易镇、乐旺镇、桑郎镇、纳夜镇、新屯镇、石屯镇、坎边乡、岜饶乡、郊纳乡、打尖乡、麻山乡、昂武乡、蔗香乡、大观乡、油迈瑶族乡',
        },
      ],
      黔东南苗族侗族自治州: [
        {
          凯里市:
            '凯里经济开发区、炉碧经济开发区、大十字街道、城西街道、西门街道、鸭塘街道、湾溪街道、开怀街道、洗马河街道、三棵树镇、舟溪镇、旁海镇、湾水镇、炉山镇、万潮镇、龙场镇、下司镇、碧波镇、凯棠乡、大风洞乡',
        },
        {
          黄平县:
            '新州镇、旧州镇、重安镇、谷陇镇、平溪镇、崇仁乡、黄飘乡、重兴乡、翁坪乡、上塘乡、一碗水乡、苗陇乡、浪洞乡、纸房乡',
        },
        {
          施秉县:
            '城关镇、双井镇、牛大场镇、杨柳塘镇、白垛乡、甘溪乡、马号乡、马溪乡',
        },
        {
          三穗县:
            '八弓镇、台烈镇、瓦寨镇、桐林镇、雪洞镇、滚马乡、长吉乡、款场乡、良上乡',
        },
        {
          镇远县:
            '舞阳镇、蕉溪镇、青溪镇、羊坪镇、羊场镇、都坪镇、江古镇、金堡镇、涌溪乡、报京乡、大地乡、尚寨土家族乡',
        },
        {
          岑巩县:
            '思旸镇、水尾镇、天马镇、龙田镇、凯本镇、大有镇、注溪乡、天星乡、羊桥土家族乡、平庄乡、客楼乡、新兴经济开发区',
        },
        {
          天柱县:
            '凤城镇、邦洞镇、坪地镇、兰田镇、瓮洞镇、高酿镇、石洞镇、远口镇、坌处镇、白市镇、社学乡、渡马乡、注溪乡、地湖乡、竹林乡、江东乡',
        },
        {
          锦屏县:
            '：三江镇、茅坪镇、敦寨镇、启蒙镇、平秋镇、铜鼓镇、平略镇、大同乡、新化乡、隆里乡、钟灵乡、偶里乡、固本乡、河口乡、彦洞乡',
        },
        {
          剑河县:
            '柳川镇、岑松镇、南加镇、南明镇、革东镇、久仰乡、太拥乡、南哨乡、南寨乡、磻溪乡、敏洞乡、观么乡',
        },
        {
          台江县:
            '台拱镇、施洞镇、老屯乡、台盘乡、革一乡、排羊乡、方召乡、南宫乡',
        },
        {
          黎平县:
            '德凤街道、高屯街道、中潮镇、敖市镇、洪州镇、尚重镇、水口镇、岩洞镇、九潮镇、孟彦镇、肇兴镇、龙额镇、双江镇、坝寨乡、德顺乡、大稼乡、罗里乡、口江乡、平寨乡、德化乡、永从乡、茅贡乡、地坪乡、雷洞瑶族水族乡、顺化瑶族乡',
        },
        {
          榕江县:
            '古州镇、忠诚镇、寨蒿镇、平永镇、乐里镇、朗洞镇、栽麻乡、崇义乡、平江乡、平阳乡、两汪乡、八开乡、计划乡、仁里水族乡、三江水族乡、定威水族乡、兴华水族乡、水尾水族乡、塔石瑶族水族乡',
        },
        {
          从江县:
            '丙妹镇、贯洞镇、西山镇、下江镇、停洞镇、洛香镇、宰便镇、往洞镇、东朗乡、斗里乡、高增乡、加勉乡、庆云乡、往洞乡、光辉乡、谷坪乡、加榜乡、加鸠乡、雍里乡、翠里瑶族壮族乡、刚边壮族乡、秀塘壮族乡',
        },
        {
          雷山县:
            '丹江镇、西江镇、永乐镇、郎德镇、望丰乡、大塘乡、桃江乡、达地水族乡、方祥乡',
        },
        {
          麻江县:
            '杏山镇、下司镇、谷硐镇、宣威镇、碧波乡、景阳乡、龙山乡、坝芒乡、贤昌乡',
        },
        {
          丹寨县:
            '丹寨金钟经济开发区、排调镇、龙泉镇、兴仁镇、扬武乡、雅灰乡、南皋乡、长青乡',
        },
      ],
      黔南布依族苗族自治州: [
        {
          都匀市:
            '文峰街道、广惠街道、小围寨街道、沙包堡街道、绿茵湖街道、墨冲镇、平浪镇、毛尖镇、匀东镇、归兰水族乡',
        },
        {
          福泉市:
            '金山街道、马场坪街道、城厢镇、牛场镇、高坪镇、道坪镇、龙昌镇、黄丝镇、陆坪镇、凤山镇、地松镇、岔河乡、谷汪乡、仙桥乡、高石乡、兴隆乡、藜山乡',
        },
        {
          荔波县:
            '玉屏街道、朝阳镇、佳荣镇、甲良镇、茂兰镇、小七孔镇、瑶山瑶族乡、黎明关水族乡',
        },
        {
          贵定县:
            '城关镇、德新镇、新巴镇、盘江镇、沿山镇、旧治镇、昌明镇、云雾镇、新铺乡、落北河乡、马场河乡、定东乡、定南乡、巩固乡、都六乡、岩下乡、猴场堡乡、抱管乡、铁厂乡、窑上乡',
        },
        {
          瓮安县:
            '瓮水街道、雍阳街道、银盏镇、江界河镇、猴场镇、珠藏镇、中坪镇、平定营镇、永和镇、玉山镇、天文镇、建中镇、岚关乡',
        },
        {
          独山县:
            '百泉镇、麻尾镇、影山镇、基长镇、玉水镇、上司镇、下司镇、麻万镇',
        },
        {
          平塘县:
            '：四寨镇、者密镇、平湖镇、摆茹镇、牙舟镇、通州镇、克度镇、塘边镇、大塘镇、掌布镇、卡蒲乡、苗二河乡、白龙乡、甘寨乡、卡罗乡、谷硐乡、西凉乡、鼠场乡、新塘乡、兔场乡、鼠场乡',
        },
        {
          罗甸县:
            '龙坪镇、沫阳镇、边阳镇、逢亭镇、罗悃镇、红水河镇、茂井镇、木引镇、凤亭乡',
        },
        {
          长顺县:
            '长寨镇、广顺镇、威远镇、代化镇、摆所镇、白云山镇、鼓扬镇、马路乡、睦化乡、营盘乡、种获乡、新寨乡、摆塘乡、敦操乡、交麻乡、中坝乡、凯佐乡',
        },
        {
          龙里县:
            '：龙山镇、三元镇、醒狮镇、谷脚镇、羊场镇、洗马镇、草原乡、麻芝乡、水场乡、湾寨乡、摆省乡、巴江乡、谷龙乡、哪旁乡',
        },
        {
          惠水县:
            '涟江街道、濛江街道；下辖镇为摆金镇、 雅水镇、 断杉镇、 芦山镇、 王佑镇、 岗度镇 、羡塘镇 、好花红镇',
        },
        {
          三都水族自治县:
            '三合街道、中和镇、周覃镇、普安镇、大河镇、都江镇、九阡镇',
        },
      ],
    },
  ],
  云南省: [
    {
      昆明市: [
        {
          五华区:
            '华山街道、护国街道、大观街道、龙翔街道、丰宁街道、莲华街道、红云街道、黑林铺街道、普吉街道、西翥街道',
        },
        {
          盘龙区:
            '拓东街道、鼓楼街道、东华街道、联盟街道、金辰街道、青云街道、龙泉街道、茨坝街道、松华街道、双龙街道、滇源街道、阿子营街道',
        },
        {
          官渡区:
            '关上街道、太和街道、吴井街道、金马街道、小板桥街道、官渡街道、大板桥街道、阿拉街道、矣六街道、六甲街道、空港经济区',
        },
        {
          西山区:
            '马街街道、金碧街道、永昌街道、前卫街道、福海街道、棕树营街道、西苑街道、碧鸡街道、海口街道、团结街道',
        },
        {
          呈贡区:
            '龙城街道、洛羊街道、斗南街道、吴家营街道、洛龙街道、乌龙街道、雨花街道、大渔街道、马金铺街道、七甸街道',
        },
        {
          东川区:
            '铜都街道、汤丹镇、因民镇、拖布卡镇、乌龙镇、阿旺镇、红土地镇、舍块乡',
        },
        {
          安宁市:
            '连然街道、温泉街道、太平新区街道、青龙街道、草铺街道、禄脿街道、八街街道、县街街道、金方街道',
        },
        { 晋宁县: '晋城镇、二街镇、上蒜镇、六街镇、双河彝族乡、夕阳彝族乡' },
        {
          富民县:
            '永定镇、大营镇、赤鹫乡、东村乡、款庄乡、散旦乡、罗免彝族苗族乡',
        },
        { 嵩明县: '嵩阳镇、杨林镇、小街镇、牛栏江镇、滇源镇、阿子营乡' },
        {
          宜良县:
            '：匡远镇、北古城镇、狗街镇、汤池镇、竹山乡、马街乡、耿家营彝族苗族乡、九乡彝族回族乡',
        },
        { 石林彝族自治县: '鹿阜街道、长湖镇、圭山镇、西街口镇、大可乡' },
        {
          寻甸回族彝族自治县:
            '：仁德街道、羊街镇、倘甸镇、柯渡镇、功山镇、七星镇、河口镇、甸沙乡、金源乡、先锋镇、六哨乡、鸡街镇、凤合镇、联合乡',
        },
        {
          禄劝彝族苗族自治县:
            '屏山镇、撒营盘镇、转龙镇、茂山乡、翠华乡、团街乡、云龙乡、中屏乡、皎平渡乡、汤郎乡、马鹿塘乡、乌东德乡、九龙乡、则黑乡、乌蒙乡、雪山乡',
        },
      ],
      曲靖市: [
        {
          麒麟区:
            '南宁街道、建宁街道、寥廓街道、白石江街道、益宁街道、太和街道、潇湘街道、文华街道、三宝街道、珠街街道、沿江街道、茨营镇、东山镇、越州镇',
        },
        {
          沾益县:
            '西平镇、白水镇、盘江镇、大坡乡、菱角乡、德泽乡、炎方乡、播乐乡',
        },
        {
          宣威市:
            '宛水街道、西宁街道、双龙街道、虹桥街道、来宾街道、板桥街道、凤凰街道、丰华街道、倘塘镇、田坝镇、羊场镇、格宜镇、龙场镇、海岱镇、落水镇、务德镇、龙潭镇、宝山镇、东山镇、热水镇、得禄乡、普立乡、西泽乡、杨柳乡、双河乡、乐丰乡、文兴乡、阿都乡',
        },
        {
          罗平县:
            '罗雄街道、腊山街道、九龙街道、板桥镇、富乐镇、马镇街、阿岗镇、老厂乡、钟山乡、大水井乡、鲁布革民族乡、旧屋基民族乡、长底民族乡',
        },
        {
          富源县:
            '中安镇、后所镇、墨红镇、大河镇、营上镇、竹园镇、富村镇、黄泥河镇、十八连山镇、古敢水族乡、老厂乡',
        },
        {
          师宗县:
            '丹凤镇、雄壁镇、彩云镇、竹基镇、葵山镇、高良壮族苗族瑶族乡、五龙壮族乡、龙庆彝族壮族乡',
        },
        {
          陆良县:
            '中枢街道办事处、同乐街道办事处、板桥镇、三岔河镇、马街镇、召夸镇、大莫古镇、芳华镇、小百户镇、活水乡、龙海乡',
        },
        {
          会泽县:
            '金钟镇、大井镇、娜姑镇、迤车镇、乐业镇、者海镇、矿山镇、待补镇、纸厂乡、马路乡、火红乡、新街乡、雨碌乡、大海乡、鲁纳乡、老厂乡、上村乡、五星乡、驾车乡、大桥乡、田坝乡',
        },
        {
          马龙县:
            '通泉镇、王家庄镇、马过河镇、旧县镇、马鸣乡、张安屯乡、月望乡、纳章乡、大庄乡',
        },
      ],
      玉溪市: [
        {
          红塔区:
            '玉兴路街道、凤凰路街道、玉带路街道、北城街道、春和街道、李棋街道、大营街街道、研和街道、高仓街道、小石桥彝族乡、洛河彝族乡',
        },
        {
          江川县: '大街镇、江城镇、前卫镇、九溪镇、路居镇、安化彝族乡、雄关乡',
        },
        { 澄江县: '凤麓街道、龙街街道、阳宗镇、右所镇、海口镇、九村镇' },
        {
          通海县:
            '秀山街道、九街街道、河西镇、四街镇、杨广镇、纳古镇、兴蒙蒙古族乡、高大彝族傣族乡、里山彝族乡',
        },
        { 华宁县: '宁州街道、盘溪镇、青龙镇、通红甸彝族苗族乡' },
        { 易门县: '龙泉镇、六街镇、绿汁镇、浦贝乡、十街乡、铜厂乡、小街乡' },
        {
          峨山彝族自治县:
            '双江街道、小街街道、甸中镇、化念镇、塔甸镇、岔河乡、大龙潭乡、富良棚乡',
        },
        {
          新平彝族傣族自治县:
            '桂山街道、古城街道、扬武镇、漠沙镇、戛洒镇、水塘镇；平甸乡、新化乡、建兴乡、老厂乡、者竜乡和平掌乡',
        },
        {
          元江哈尼族彝族傣族自治县:
            '澧江街道、红河街道、甘庄街道、因远镇、曼来镇、羊街乡、那诺乡、洼垤乡、咪哩乡、龙潭乡',
        },
      ],
      保山市: [
        {
          隆阳区:
            '永昌街道、兰城街道、板桥镇、河图镇、汉庄镇、蒲缥镇、瓦窑镇、潞江镇、金鸡乡、辛街乡、西邑乡、丙麻乡、瓦渡乡、水寨乡、瓦马彝族白族乡、瓦房彝族苗族乡、杨柳白族彝族乡、芒宽彝族傣族乡',
        },
        {
          腾冲市:
            '腾越镇、芒棒镇、和顺镇、固东镇、滇滩镇、猴桥镇、界头镇、曲石镇、明光镇、中和镇、荷花镇、马站乡、北海乡、清水乡、五合乡、新华乡、蒲川乡、团田乡',
        },
        {
          施甸县:
            '甸阳镇、由旺镇、姚关镇、太平镇、仁和镇、万兴乡、摆榔彝族布朗族乡、酒房乡、旧城乡、木老元布朗族彝族乡、老麦乡、何元乡、水长乡',
        },
        {
          龙陵县:
            '龙山镇、镇安镇、勐糯镇、龙江乡、腊勐乡、碧寨乡、龙新乡、象达乡、平达乡、木城彝族傈僳族乡',
        },
        {
          昌宁县:
            '田园镇、漭水镇、柯街镇、卡斯镇、勐统镇、温泉乡、大田坝乡、鸡飞乡、翁堵乡、湾甸傣族乡、更戛乡、珠街彝族乡、苟街彝族苗族乡',
        },
      ],
      昭通市: [
        {
          昭阳区:
            '太平街道、龙泉街道、凤凰街道旧圃镇、永丰镇、北闸镇、苏家院乡、乐居乡、洒渔乡、盘河乡、靖安乡、苏甲乡、炎山乡、大寨子乡、大山包乡、田坝乡、守望回族乡、小龙洞回族彝族乡、青岗岭回族彝族乡、布嘎回族乡',
        },
        { 水富县: '云富街道、向家坝镇、太平镇、两碗镇' },
        {
          永善县:
            '溪洛渡镇、桧溪镇、黄华镇、茂林镇、大兴镇、莲峰镇、团结乡、细沙乡、青胜乡、务基乡、马楠苗族彝族乡、水竹乡、墨翰乡、伍寨彝族苗族乡、码口乡',
        },
        {
          大关县:
            '玉碗镇、上高桥镇、下高桥镇、木杆镇、黄葛镇、寿山镇、悦乐镇、煤翰镇、天星镇、翠华镇、黄金坝镇',
        },
        {
          鲁甸县:
            '文屏镇、小寨乡、大水井乡、火德红乡、龙头山乡、翠屏乡、乐红乡、龙树乡、水磨乡、铁厂乡、新街乡、梭山乡、桃源回族乡、茨院回族乡',
        },
        {
          盐津县:
            '盐井镇、普洱镇、豆沙镇、中和镇、兴隆乡、落雁乡、滩头乡、牛寨乡、庙坝乡、柿子乡',
        },
        { 绥江县: '中城镇、南岸镇、新滩镇、会仪镇、板栗乡' },
        {
          彝良县:
            '角奎镇、洛泽河镇、牛街镇；新场乡、毛坪乡、发达乡、荞山乡、海子乡、钟鸣乡、两河乡、龙安乡、小草坝乡、龙海乡；龙街苗族彝族乡、奎香苗族彝族乡、树林彝族苗族乡、柳溪苗族乡、洛旺苗族乡',
        },
        {
          威信县:
            '扎西镇、旧城镇、双河苗族彝族乡、高田乡、罗布乡、林凤乡、长安乡、庙沟乡、三桃乡、水田乡',
        },
        {
          巧家县:
            '白鹤滩镇、金塘镇、蒙姑镇　药山镇、大寨镇、茂租镇、东坪镇、崇溪镇、老店镇、包谷垴乡、马树镇、小河镇、新店镇、红山乡、炉房乡、中寨乡',
        },
        {
          镇雄县:
            '乌峰镇、罗坎镇、雨河镇、大湾镇、牛场镇、五德镇、芒部镇、以勒镇、赤水源镇、母享镇、泼机镇、黑树镇、碗厂镇、盐源镇、木卓镇、以古镇、坪上镇、塘房镇、场坝镇、中屯镇、坡头镇、花朗乡、花山乡、杉树乡、鱼洞乡、尖山乡、果珠彝族乡、林口彝族苗族乡',
        },
      ],
      丽江市: [
        {
          古城区:
            '大研街道、祥和街道、西安街道、束河街道、金安镇、七河乡大东乡、金山白族乡、金江白族乡',
        },
        {
          玉龙纳西族自治县:
            '黄山镇、石鼓镇、巨甸镇、石头白族乡、黎明傈僳族乡、九河白族乡、白沙乡、拉市乡、太安乡、龙蟠乡、鲁甸乡、塔城乡、大具乡、宝山乡、奉科乡、鸣音乡',
        },
        {
          永胜县:
            '永北镇、仁和镇、期纳镇、程海镇、三川镇、羊坪彝族乡、六德僳僳族彝族乡、东山僳僳族彝族乡、东风僳僳族乡、涛源乡、片角乡、光华僳僳族彝族乡、松坪僳僳族彝族乡、大安彝族纳西族乡、顺州乡',
        },
        {
          华坪县:
            '中心镇、荣将镇、兴泉镇、石龙坝镇、新庄僳僳族傣族乡、通达僳僳族乡、永兴僳僳族乡、船房僳僳族傣族乡',
        },
        {
          宁蒗县:
            '大兴镇、拉伯乡、永宁乡、翠玉乡、红桥乡、红旗乡、宁利乡、金棉乡、战河乡、西布河乡、永宁坪乡、跑马坪乡、蝉战河乡、新营盘乡、烂泥箐乡',
        },
      ],
      普洱市: [
        {
          思茅区:
            '：思茅镇、南屏镇、倚象镇、思茅港镇、龙潭彝族傣族乡、云仙彝族乡、六顺乡',
        },
        {
          宁洱哈尼族彝族自治县:
            '：宁洱镇、磨黑镇、同心镇、德化镇、勐先镇、梅子镇、普义乡、黎明乡、德安乡',
        },
        {
          景东彝族自治县:
            '锦屏镇、文井镇、漫湾镇、大朝山东镇、花山乡、大街乡、太忠乡、龙街乡、文龙乡、安定乡、林街乡、曼等乡、景福乡',
        },
        {
          镇沅彝族哈尼族拉祜族自治县:
            '：恩乐镇、按板镇、勐大镇、者东镇、田坝乡、古城乡、振太乡、九甲乡、和平乡',
        },
        {
          景谷傣族彝族自治县:
            '威远镇、永平镇、民乐镇、正兴镇、景谷镇、凤山镇、勐班乡、益智乡、半坡乡、碧安乡',
        },
        {
          墨江哈尼族自治县:
            '联珠镇、通关镇、鱼塘彝族乡、龙潭乡、文武乡、景星乡、新抚乡、团田乡、泗南江乡、雅邑乡、新安乡、孟弄彝族乡、龙坝乡、那哈乡、坝溜乡',
        },
        {
          澜沧拉祜族自治县:
            '勐朗镇、上允镇、糯扎渡镇、谦六彝族乡、东河乡、大山乡、南岭乡、酒井哈尼族乡、惠民哈尼族乡、东回乡、拉巴乡、竹塘乡、富邦乡、安康佤族乡、文东佤族乡、富东乡、雪林佤族乡、木戛乡、发展河哈尼族乡、糯福乡',
        },
        {
          西盟佤族自治县:
            '勐梭镇、勐卡镇、中课镇、新厂镇、翁嘎科镇、力所拉祜族乡、岳宋乡',
        },
        {
          江城哈尼族彝族自治县:
            '勐烈镇、整董镇、康平镇、宝藏镇、曲水镇、国庆乡、嘉禾乡',
        },
        {
          孟连傣族拉祜族佤族自治县:
            '公信乡、富岩乡、景信乡、芒信镇、勐马镇、娜允镇',
        },
      ],
      临沧市: [
        {
          临翔区:
            '凤翔街道、忙畔街道、博尚镇、蚂蚁堆乡、章驮乡、南美拉祜族乡、圈内乡、马台乡、邦东乡、平村彝族傣族乡',
        },
        {
          凤庆县:
            '凤山镇、鲁史镇、小湾镇、营盘镇、三岔河镇、勐佑镇、雪山镇、洛党镇、诗礼乡、新华彝族苗族乡、大寺乡、腰街彝族乡、郭大寨彝族白族乡',
        },
        {
          永德县:
            '德党镇、小勐统镇、永康镇、勐板乡、亚练乡、乌木龙乡、大雪山乡、班卡乡、崇岗乡、大山乡',
        },
        { 镇康县: '凤尾镇、南伞镇、勐捧镇、忙丙乡、木场乡、军赛乡、勐堆乡' },
        {
          云县: '爱华镇、漫湾镇、大朝山西镇、涌宝镇、茂兰镇、幸福镇、大寨镇、忙怀彝族布朗族乡、晓街乡、茶房乡、栗树彝族傣族乡、后箐彝族乡',
        },
        {
          沧源佤族自治县:
            '勐董镇、岩帅镇、勐省镇、芒卡镇、单甲乡、糯良乡、勐来乡、勐角傣族彝族拉祜族乡、班洪乡、班老乡、勐省农场',
        },
        {
          耿马傣族佤族自治县:
            '耿马镇、勐永镇、勐撒镇、孟定镇、大兴乡芒洪乡、四排山乡、贺派乡、勐简乡、耿马华侨农场管理区、勐撒农场、孟定农场',
        },
        {
          双江拉祜族佤族布朗族傣族自治县:
            '勐勐镇、勐库镇、沙河乡、大文乡、忙糯乡、邦丙乡',
        },
      ],
      德宏傣族景颇族自治州: [
        {
          芒市: '勐焕街道、芒市镇、遮放镇、勐戛镇、芒海镇、风平镇、江东乡、西山乡、中山乡、五岔路乡、三台山德昂族乡',
        },
        {
          瑞丽市:
            '姐告边境贸易区、畹町经济开发区、勐卯镇、畹町镇、弄岛镇、姐相乡、勐秀乡、户育乡',
        },
        {
          陇川县:
            '章凤镇、陇把镇、景罕镇、城子镇、户撒阿昌族乡、护国乡、清平乡、王子树乡、勐约乡、陇川农场',
        },
        {
          梁河县:
            '遮岛镇、芒东镇、勐养镇、平山乡、小厂乡、大厂乡、九保阿昌族乡、曩宋阿昌族乡、河西乡',
        },
        {
          盈江县:
            '平原镇、太平镇、旧城镇、弄璋镇、昔马镇、那邦镇、卡场镇、盏西镇、铜壁关乡、油松岭乡、新城乡、芒章乡、支那乡、勐弄乡、苏典乡',
        },
      ],
      怒江僳僳族自治州: [
        {
          泸水县:
            '六库镇、鲁掌镇、片马镇、洛本卓乡、古登乡、称杆乡、大兴地乡、老窝乡、上江乡',
        },
        {
          福贡县:
            '上帕镇、子里甲乡、架科底乡、鹿马登乡、石月亮乡、马吉乡、匹河怒族乡',
        },
        {
          兰坪白族普米族自治县:
            '金顶镇、啦井镇、营盘镇、通甸镇、兔峨乡、河西乡、石登乡、中排乡',
        },
        {
          贡山独龙族怒族自治县: '茨开镇、丙中洛乡、捧打乡、普拉底乡、独龙江乡',
        },
      ],
      迪庆藏族自治州: [
        {
          香格里拉市:
            '建塘镇、小中甸镇、虎跳峡镇、金江镇、上江乡、三坝纳西族乡、洛吉乡、尼西乡、格咱乡、东旺乡、五境乡',
        },
        {
          维西傈僳族自治县:
            '保和镇、叶枝镇、塔城镇、永春乡、攀天阁乡、白济讯乡、康普乡、巴迪乡、中路乡、维登乡',
        },
        {
          德钦县:
            '升平镇、奔子栏镇、佛山乡、云岭乡、燕门乡、霞若傈僳族乡、拖顶傈僳族乡、羊拉乡',
        },
      ],
      大理白族自治州: [
        {
          大理市:
            '下关镇、大理镇、凤仪镇、喜洲镇、海东镇、挖色镇、湾桥镇、银桥镇、双廊镇、上关镇、太邑彝族乡',
        },
        {
          祥云县:
            '祥城镇、沙龙镇、云南驿镇、下庄镇、刘厂镇、禾甸镇、米甸镇、鹿鸣乡、东山彝族乡',
        },
        {
          宾川县:
            '金牛镇、乔甸镇、宾居镇、州城镇、力角镇、大营镇、炼洞镇、平川镇、古底镇、钟英镇、拉乌镇',
        },
        {
          弥渡县:
            '弥城镇、新街镇、红岩镇、寅街镇、苴力镇、密祉乡、德苴乡、牛街彝族乡',
        },
        { 永平县: '博南镇、龙门乡、杉阳镇、厂街乡、水泄乡、北斗乡、龙街镇' },
        {
          云龙县:
            '诺邓镇、漕涧镇、旧州镇、白石镇、宝丰乡、关坪乡、团结乡、检槽乡、长新乡、表村乡、民建乡',
        },
        {
          洱源县:
            '茈碧湖镇、邓川镇、右所镇、三营镇、凤羽镇、乔后镇、牛街乡、炼铁乡、西山乡',
        },
        {
          剑川县:
            '金华镇、甸南镇、沙溪镇、马登镇、老君山镇、羊岑乡、弥沙乡、象图乡、红旗林业局',
        },
        {
          鹤庆县:
            '辛屯镇、草海镇、云鹤镇、金墩乡、松桂镇、西邑镇、黄坪镇、六合彝族乡、龙开口镇',
        },
        {
          漾濞彝族自治县:
            '苍山西镇、漾江镇、平坡镇、富恒乡、太平乡、顺濞乡、瓦厂乡、龙潭乡、鸡街乡',
        },
        {
          南涧彝族自治县:
            '南涧镇、宝华镇、公郎镇、小湾东镇、拥翠乡、乐秋乡、碧溪乡、无量山镇',
        },
        {
          巍山彝族回族自治县:
            '南诏镇、庙街镇、大仓镇、永建镇、巍宝山乡、紫金乡、马鞍山乡、五印乡、牛街乡、青华乡',
        },
      ],
      楚雄彝族自治州: [
        {
          楚雄市:
            '鹿城镇、东瓜镇、吕合镇、紫溪镇、东华镇、子午镇、苍岭镇、三街镇、八角镇、中山镇、新村镇、西舍路镇、大过口乡、大地基乡、树苴乡',
        },
        {
          双柏县:
            '：妥甸镇、大庄镇、法脿镇、鄂嘉镇、大麦地镇、安龙堡乡、爱尼山乡、独田乡',
        },
        { 牟定县: '共和镇、新桥镇、江坡镇、凤屯镇、蟠猫乡、戌街乡、安乐乡' },
        {
          南华县:
            '龙川镇、沙桥镇、五街镇、红土坡镇、兔街镇、马街镇、雨露乡、一街乡、罗武庄乡、五顶山乡',
        },
        {
          姚安县:
            '栋川镇、光禄镇、前场镇、弥兴镇、太平镇、适中乡、左门乡、官屯乡、大河口乡',
        },
        {
          大姚县:
            '金碧镇、石羊镇、六苴镇、龙街镇、赵家店镇、新街镇、昙华乡、桂花镇、湾碧傣族傈僳族乡、铁锁乡、三台乡、三岔河镇',
        },
        {
          永仁县: '永定镇、宜就镇、中和镇、莲池乡、维的乡、猛虎乡、永兴傣族乡',
        },
        {
          元谋县:
            '元马镇、黄瓜园镇、羊街镇、老城乡、平田乡、新华乡、物茂乡、江边乡、姜驿乡、凉山乡',
        },
        {
          武定县:
            '狮山镇、高桥镇、猫街镇、插甸乡、田心乡、发窝乡、白路乡、万德乡、己衣乡、环州乡、东坡傣族乡',
        },
        {
          禄丰县:
            '金山镇、仁兴镇、碧城镇、勤丰镇、一平浪镇、广通镇、黑井镇、土官镇、罗川镇、和平镇、恐龙山镇、中村乡、高峰乡、妥安乡',
        },
      ],
      红河哈尼族彝族自治州: [
        {
          蒙自市:
            '文澜镇、草坝镇、雨过铺镇、新安所镇、芷村镇、鸣鹫苗族镇、冷泉镇、水田乡、西北勒乡、期路白苗族乡、老寨苗族乡',
        },
        {
          个旧市:
            '城区街道、锡城镇、沙甸镇、鸡街镇、大屯镇、老厂镇、卡房镇、蔓耗镇、贾沙乡、保和乡',
        },
        {
          开远市:
            '乐百道街道、灵泉街道、小龙潭镇、中和营镇、大庄回族乡、羊街乡、碑格乡',
        },
        {
          弥勒市:
            '弥阳镇、新哨镇、竹园镇、朋普镇、虹溪镇、巡检司镇、西一镇、西二镇、西三镇、东山镇、五山乡、江边乡',
        },
        {
          建水县:
            '临安镇、曲江镇、南庄镇、西庄镇、青龙乡、岔科乡、面甸乡、坡头乡、官厅乡、普雄乡、盘江乡、甸尾乡',
        },
        {
          石屏县:
            '异龙镇、宝秀镇、坝心镇、龙朋镇、龙武镇、哨冲镇、牛街镇、新城乡、大桥乡',
        },
        {
          绿春县:
            '大兴镇、牛孔乡、大水沟乡、大黑山乡、戈奎乡、三猛乡、骑马坝乡、平河乡、半坡乡',
        },
        {
          泸西县:
            '中枢镇、金马镇、旧城镇、午街铺镇、白水镇、向阳乡、三塘乡、永宁乡',
        },
        {
          元阳县:
            '南沙镇、新街镇、牛角寨乡、沙拉托乡、嘎娘乡、上新城乡、小新街乡、逢春岭乡、大坪乡、攀枝花乡、黄草岭乡、黄茅岭乡、俄扎乡、马街乡',
        },
        {
          红河县:
            '迤萨镇、甲寅乡、宝华乡、洛恩乡、石头寨乡、阿扎河乡、乐育乡、浪堤乡、大羊街乡、车古乡、架车乡、垤玛乡、三村乡',
        },
        {
          金平苗族瑶族傣族自治县:
            '金河镇、金水河镇、勐拉乡、者米拉祜族乡、铜厂乡、营盘乡、老勐乡、老集寨乡、阿得博乡、沙依坡乡、大寨乡、勐桥乡、马鞍底乡',
        },
        {
          河口瑶族自治县: '河口镇、南溪镇、桥头乡、瑶山乡、老范寨乡、莲花滩乡',
        },
        {
          屏边苗族自治县:
            '玉屏镇、新现乡、和平乡、白河乡、白云乡、新华乡、湾塘乡',
        },
      ],
      文山壮族苗族自治州: [
        {
          文山市:
            '开化街道、卧龙街道、新平街道、古木镇、平坝镇、马塘镇、德厚镇、小街镇、追栗街镇、薄竹镇、新街乡、喜古乡、东山彝族乡、柳井彝族乡、坝心彝族乡、秉烈彝族乡、红甸回族乡',
        },
        {
          砚山县:
            '江那镇、平远镇、稼依镇、阿猛镇、阿舍彝族乡、维末彝族乡、盘龙彝族乡、八嘎乡、者腊乡、蚌峨乡、干河彝族乡',
        },
        {
          西畴县:
            '西洒镇、兴街镇、蚌谷乡、莲花塘乡、新马街乡、柏林乡、法斗乡、董马乡、鸡街乡',
        },
        {
          麻栗坡县:
            '麻栗镇、大坪镇、天保镇、董干镇、猛硐乡、下金厂乡、八布乡、六河乡、杨万乡、铁厂乡、马街乡、天保农场、八布农场',
        },
        {
          马关县:
            '马白镇、坡脚镇、八寨镇、仁和镇、木厂镇、夹寒箐镇、小坝子镇、都龙镇、金厂镇、南捞乡、大栗树乡、 篾厂乡、古林箐乡',
        },
        {
          丘北县:
            '锦屏镇、双龙营镇、曰者镇、腻脚彝族乡、新店（原冲头）彝族乡、舍得彝族乡、树皮彝族乡、八道哨彝族乡、官寨乡、平寨乡、天星乡、温浏乡',
        },
        {
          广南县:
            '莲城镇、坝美镇、八宝镇、南屏镇、珠街镇、那洒镇、珠琳镇、黑支果乡、曙光乡、篆角乡、五珠乡、者兔乡、者太乡、底圩乡、旧莫乡、董堡乡、杨柳井乡、板蚌乡',
        },
        {
          富宁县:
            '新华镇、归朝镇、剥隘镇、里达镇、田蓬镇、木央镇、板仑乡、谷拉乡、者桑乡、那能乡、洞波瑶族乡、阿用乡、花甲乡',
        },
      ],
      西双版纳傣族自治州: [
        {
          景洪市:
            '允景洪街道、嘎洒镇、勐龙镇、勐罕镇、勐养镇、普文镇、景哈哈尼族乡、景讷乡、大渡岗乡、勐旺乡、基诺山基诺族乡',
        },
        {
          勐海县:
            '勐海镇、打洛镇、勐遮镇、勐混镇、勐满镇、勐阿镇、勐宋乡、勐往乡、格朗和哈尼族乡、布朗山布朗族乡、西定哈尼族布朗族乡',
        },
        {
          勐腊县:
            '勐腊镇、勐捧镇、勐满镇、勐仑镇、尚勇镇、勐伴镇、关累镇、易武乡、象明彝族乡、瑶区瑶族乡',
        },
      ],
    },
  ],
  陕西省: [
    {
      西安市: [
        {
          新城区:
            '新城区、长乐中路街道、中山门街道、韩森寨街道、解放门街道、自强路街道、太华路街道、长乐西路街道、胡家庙街道',
        },
        {
          碑林区:
            '南院门街道、柏树林街道、长乐坊街道、东关南街街道、太乙路街道、文艺路街道、长安路街道、张家村街道',
        },
        {
          莲湖区:
            '青年路街道、北院门街道、北关街道、红庙坡街道、环城西路街道、西关街道、土门街道、桃园路街道、枣园街道',
        },
        {
          灞桥区:
            '纺织城街道、十里铺街道、红旗街道、席王街道、洪庆街道、狄寨街道、灞桥街道、新筑街道、新合街道',
        },
        {
          未央区:
            '张家堡街道、大明宫街道、辛家庙街道、徐家湾街道、谭家街道、草滩街道、未央湖街道、汉城街道、未央宫街道、三桥街道、六村堡街道',
        },
        {
          雁塔区:
            '大雁塔街道、小寨路街道、长延堡街道、电子城街道、电子城街道、鱼化寨街道、丈八沟街道、曲江街道',
        },
        {
          阎良区:
            '凤凰路街道、新华路街道、振兴街道、新兴街道、北屯街道、武屯镇、关山镇',
        },
        {
          临潼区:
            '骊山街道、秦陵街道、新丰街道、代王街道、斜口街道、行者街道、零口街道、相桥街道、雨金街道、马额街道、西泉街道、栎阳街道、新市街道、徐杨街道、何寨街道、交口街道、油槐街道、北田街道、铁炉街道、任留街道、穆寨街道、小金街道、仁宗街道。区政府驻骊山街道',
        },
        {
          长安区:
            '韦曲街道、郭杜街道、滦镇街道、引镇街道、黄良街办、王寺街道、马王街道、太乙宫街道、东大街道、子午街道、斗门街道、杜曲街道、大兆街道、兴隆街道、细柳街道、高桥乡、灵沼乡、五星乡、五台乡、王莽乡、杨庄乡、炮里乡、魏寨乡、鸣犊镇、王曲镇',
        },
        {
          蓝田县:
            '蓝关镇、洩湖镇、华胥镇、前卫镇、汤峪镇、焦岱镇、玉山镇、三里镇、普化镇、葛牌镇、辋川镇、蓝桥镇、玉川镇、灞源镇、孟村镇、安村镇、史家寨镇、小寨镇、三官庙镇、金山镇、九间房镇、厚镇',
        },
        {
          周至县:
            '二曲镇、哑柏镇、终南镇、楼观镇、尚村镇、马召镇、广济镇、集贤镇、厚畛子镇、侯家村乡、四屯乡、辛家寨乡、翠峰乡、竹峪乡、青化乡、富仁乡、司竹乡、九峰镇、陈河乡、骆峪乡、板房子乡',
        },
        {
          户县: '甘亭镇、余下镇、祖庵镇、秦渡镇、大王镇、草堂镇、蒋村镇、庞光镇、涝店镇、甘河镇、石井镇、五竹镇、玉蝉镇、天桥镇、渭丰镇、苍游镇',
        },
        { 高陵区: '鹿苑街道、泾渭街道、崇皇街道、通远镇、耿镇、张卜镇' },
      ],
      宝鸡市: [
        {
          渭滨区:
            '金陵街道、经二路街道、清姜街道、姜谭街道、桥南街道、马营镇、石鼓镇、神农镇、高家镇、八鱼镇',
        },
        {
          金台区:
            '卧龙寺街道、中山东路街道、西关街道、中山西路街道、群众路街道、东风路街道、十里铺街道；陈仓镇、蟠龙镇、金河镇、硖石镇',
        },
        {
          陈仓区:
            '虢镇、阳平镇、千河镇、磻溪镇、天王镇、幕仪镇、周原镇、贾村镇、桥镇、县功镇、新街镇、坪头镇、香泉镇、赤沙镇、拓石镇、凤阁岭镇、钓渭镇、胡店镇、县功镇、新街镇、坪头镇、香泉镇、赤沙镇、拓石镇、凤阁岭镇、胡店镇',
        },
        {
          宝鸡国家高新技术产业开发区:
            '马营镇、八鱼镇、千河镇、磻溪镇、天王镇、钓渭镇',
        },
        { 宝鸡蟠龙新区: '蟠龙镇' },
        { 宝鸡陆港新区: '阳平镇' },
        { 蔡家坡经济技术开发区: '蔡家坡镇' },
        {
          岐山县:
            '凤鸣镇、蔡家坡镇、益店镇、蒲村镇、祝家庄镇、青化镇、枣林镇、雍川镇、故郡镇、京当镇',
        },
        { 扶风县: '天度镇、午井镇、绛帐镇、段家镇、杏林镇、召公镇、法门镇' },
        {
          眉县: '首善镇、横渠镇、槐芽镇、汤峪镇、常兴镇、金渠镇、营头镇、齐镇',
        },
        {
          凤翔县:
            '城关镇、虢王镇、彪角镇、长青镇、横水镇、柳林镇、陈村镇、郭店镇、糜杆桥镇、南指挥镇、田家庄镇、姚家沟镇、尹家务乡、唐村乡、董家河乡、范家寨乡、汉封乡',
        },
        {
          陇县: '城关镇、东南镇、东风镇、八渡镇、温水镇、天城镇、曹家湾镇、火烧寨镇、李家河镇、固关镇、河北镇、新集川镇、关山管委会',
        },
        {
          千阳县:
            '城关镇、崔家头镇、南寨镇、张家塬镇、水沟镇、草碧镇、柿沟镇、高崖镇',
        },
        { 麟游县: '九成宫镇、崔木镇、招贤镇、两亭镇、天堂镇、丈八镇、酒房镇' },
        {
          凤县: '双石铺镇、凤州镇、黄牛铺镇、红花铺、河口镇、唐藏镇、平木镇、坪坎镇、留凤关镇',
        },
        {
          太白县:
            '咀头镇、靖口镇、太白河镇、桃川镇、鹦鸽镇、黄柏塬镇、王家堎镇',
        },
      ],
      咸阳市: [
        {
          秦都区:
            '人民路街道、西兰路街道、吴家堡街道、渭阳西路街道、陈杨寨街道、古渡街道、沣东街道、钓台街道、马泉街道、渭滨街道、双照街道、马庄镇',
        },
        {
          渭城区:
            '中山街道、文汇路街道、新兴街道、渭阳街道、渭城街道、窑店街道、正阳街道、周陵街道、底张街道、北杜镇',
        },
        {
          兴平市:
            '东城街道、西城街道、店张街道、马嵬街道、西吴街道、赵村镇、桑镇镇、南市镇、庄头镇、南位镇、汤坊镇、丰仪镇、阜寨镇',
        },
        {
          三原县:
            '城关镇、高渠发展服务中心、渠岸镇、安乐镇、陂西镇、独李镇、大程镇、西阳镇、鲁桥镇、徐木发展服务中心、陵前镇、马额发展服务中心、新兴镇、嵯峨镇',
        },
        {
          泾阳县:
            '泾干镇、永乐镇、云阳镇、桥底镇、王桥镇、口镇镇、三渠镇、太平镇、高庄镇、中张镇、崇文镇、兴隆镇、安吴镇',
        },
        {
          乾县: '城关镇、薛录镇、梁村镇、临平镇、姜村镇、王村镇、马连镇、阳峪镇、峰阳镇、注泔镇、灵源镇、阳洪镇、周城镇、大杨镇、新阳镇、梁山镇',
        },
        {
          礼泉县:
            '城关镇、史德镇、骏马镇、西张堡镇、阡东镇、烽火镇、烟霞镇、赵镇、建陵镇、叱干镇、南坊镇、石潭镇、药王洞社区、新时社区、昭陵社区、社区服务中心',
        },
        {
          永寿县:
            '监军镇、店头镇、常宁镇、仪井镇、甘井镇、马坊镇、窦家镇、御驾宫镇、渠子镇、永太镇、永平镇',
        },
        {
          彬县: '城关镇、北极镇、新民镇、龙高镇、小章镇、永乐镇、义门镇、水口镇、香庙镇、炭店镇、韩家镇、底店镇、太峪镇',
        },
        {
          长武县:
            '昭仁镇、罗峪乡、冉店乡、亭口乡、路家乡、巨家乡、枣元乡、丁家乡、洪家乡、马寨乡、地掌乡、彭公乡、相公乡、芋园乡',
        },
        {
          旬邑县:
            '城关镇、土桥镇、职田镇、张洪镇、太村镇、郑家镇、湫坡头镇、底庙镇、丈八寺镇、马栏镇、清塬镇',
        },
        {
          淳化县:
            '城关镇、官庄镇、马家镇、方里镇、润镇镇、胡家庙镇、十里塬镇、卜家镇、车坞镇、石桥镇、铁王镇、固贤镇',
        },
        {
          武功县:
            '普集镇、苏坊镇、武功镇、游风镇、贞元镇、长宁镇、小村镇、大庄镇',
        },
      ],
      渭南市: [
        {
          临渭区:
            '杜桥街道、人民街道、解放街道、向阳街道、站南街道、双王街道、良田街道、崇业路街道、桥南镇、阳郭镇、故市镇、下吉镇、三张镇、交斜镇、辛市镇、崇宁镇、孝义镇、吝店镇、官底镇、官路镇、丰原镇、阎村镇、龙背镇、官道镇',
        },
        {
          华阴市:
            '岳庙街道、太华街道、玉泉街道、桃下镇、敷水镇、孟塬镇、华西镇、五方乡、碨峪乡、北社乡',
        },
        {
          华县: '华州镇、杏林镇、赤水镇、高塘镇、大明镇、瓜坡镇、莲花寺镇、柳枝镇、下庙镇、金堆镇',
        },
        { 潼关县: '城关镇、秦东镇、太要镇、桐峪镇、代字营镇、安乐乡' },
        {
          大荔县:
            '高明镇、冯村镇、下寨镇、范家镇、城关镇、许庄镇、朝邑镇、安仁镇、两宜镇、羌白镇、官池镇、双泉镇、韦林镇、赵渡乡、苏村乡、户家乡、埝桥乡、张家乡、沙底乡、伯士乡、步昌乡、西寨乡、石槽乡、平民乡、八鱼乡、段家乡',
        },
        {
          蒲城县:
            '城关镇、永丰镇、罕井镇、党睦镇、孙镇、高阳镇、东陈镇、龙阳镇、苏坊镇、兴镇、荆姚镇、陈庄镇、坡头镇、洛滨镇、大孔乡、翔村乡、上王乡、贾曲乡、三合乡、东阳乡、椿林乡、原任乡、龙池乡、平路庙乡',
        },
        {
          澄城县:
            '城关镇、王庄镇、韦庄镇、寺前镇、冯塬镇、交道镇、尧头镇、赵庄镇、安里乡、善化乡、庄头乡、刘家洼乡、罗家洼乡、雷家洼乡',
        },
        {
          白水县:
            '城关镇、冯雷镇、尧禾镇、杜康镇、西固镇、林皋镇、史官镇、雷牙乡、云台乡、北塬乡',
        },
        {
          合阳县:
            '城关镇、王村镇、路井镇、黑池镇、坊镇镇、百良镇、皇甫庄镇、甘井镇、洽川镇、新池镇、和家庄镇、同家庄镇、防虏寨乡、知堡乡、杨家庄乡、马家庄乡',
        },
        {
          富平县:
            '城关镇、庄里镇、张桥镇、美原镇、流曲镇、淡村镇、王寮镇、留古镇、老庙镇、薛镇、到贤镇、曹村镇、宫里镇、梅家坪镇、刘集镇、小惠镇、齐村镇',
        },
      ],
      铜川市: [
        {
          王益区:
            '黄堡镇、王益乡、王家河乡、红旗街街道、桃园街道、七一路街道办事处、青年路街道',
        },
        {
          印台区:
            '城关街道、三里洞街道、王石凹街道、印台镇、陈炉镇、红土镇、广阳镇、阿庄镇、高楼河镇、金锁关镇',
        },
        {
          耀州区:
            '永安路街道、天宝路街道、锦阳路街道、瑶曲镇、庙湾镇、关庄镇、董家河镇、孙塬镇、小丘镇、照金镇、石柱乡、演池乡',
        },
        {
          宜君县:
            '城关镇、太安镇、五里镇、棋盘镇、彭镇、哭泉乡、尧生乡、西村乡、雷塬乡、云梦乡',
        },
      ],
      延安市: [
        {
          宝塔区:
            '姚店镇、蟠龙镇、南泥湾镇、临镇、青化砭镇、李渠镇、甘谷驿镇、枣园镇、柳林镇、河庄坪镇、桥儿沟镇、川口乡、梁村乡、冯庄乡、麻洞川乡、万花乡、宝塔山街道、南市街道、凤凰山街道、元龙寺中心社区、官庄中心社区、贯屯中心社区、松树林中心社区',
        },
        {
          延长县:
            '七里村镇、黑家堡镇、郑庄镇、张家滩镇、交口镇、罗子山镇、雷赤镇、郭旗乡、安沟乡、社管委',
        },
        {
          延川县:
            '延川镇、永坪镇、延水关镇、文安驿镇、杨家圪台镇、贾家坪镇、禹居镇、关庄镇、高家屯乡、冯家坪乡、贺家湾乡、黑龙关乡、南河乡、马家河乡、眼岔寺乡、稍道河乡、土岗乡',
        },
        {
          子长县:
            '瓦窑堡镇、杨家园子镇、玉家湾镇、安定镇、马家砭镇、南沟岔镇、涧峪岔镇、李家岔镇、热寺湾乡、史家畔乡、栾家坪乡、余家坪乡、寺湾乡',
        },
        {
          安塞县:
            '真武洞镇、砖窑湾镇、沿河湾镇、招安镇、化子坪镇、坪桥镇、建华镇、高桥镇、镰刀湾乡',
        },
        {
          志丹县:
            '保安镇、旦八镇、杏河镇、金丁镇、顺宁镇、永宁镇、吴堡乡、双河乡、张渠乡、周河乡、义正乡',
        },
        {
          吴起县:
            '吴起镇、铁边城镇、周湾镇、白豹镇、长官庙镇、长城镇、吴仓堡乡、庙沟乡、五谷城乡',
        },
        { 甘泉县: '城关镇、下寺湾镇、道镇镇、桥镇乡、石门乡、劳山乡' },
        {
          富县: '城区街道、富城镇、羊泉镇、张村驿镇、张家湾镇、直罗镇、茶坊镇、牛武镇、交道镇、钳二乡、吉子现乡、南道德乡、寺仙乡、北道德乡',
        },
        {
          洛川县:
            '凤栖镇、旧县镇、交口河镇、老庙镇、土基镇、菩堤乡、黄章乡、洪福梁乡、永乡乡、后子头乡、京兆乡、杨舒乡、槐柏乡、石泉乡、武石乡、秦关乡、百益乡、石头乡、朱牛乡',
        },
        {
          宜川县:
            '丹州镇、秋林镇、云岩镇、阁楼镇、集义镇、英旺乡、交里乡、牛家细乡、新市河乡、高柏乡、壶口乡、寿峰乡',
        },
        {
          黄龙县:
            '石堡镇、白马滩镇、瓦子街镇、三岔乡、界头庙乡、范家卓子乡、红石崖乡、柏峪乡、圪台乡、崾崄乡',
        },
        {
          黄陵县:
            '桥山镇、隆坊镇、田庄镇、店头镇、阿党镇、太贤乡、龙首乡、侯庄乡、康崖底乡、仓村乡、双龙镇、腰坪乡',
        },
      ],
      榆林市: [
        {
          榆阳区:
            '鼓楼街道、新明楼街道、上郡路街道、青山路街道、崇文路街道、航宇路街道、驼峰路街道、榆阳镇、鱼河镇、上盐湾镇、镇川镇、清泉镇、鱼河峁镇、大河塔镇、麻黄梁镇、牛家梁镇、金鸡难镇、马合镇、巴拉素镇、古塔镇、青云镇、孟家湾乡、小壕兔乡、岔河则乡、小纪汗乡、补浪河乡、红石桥乡、芹河乡',
        },
        {
          府谷县:
            '府谷镇、黄甫镇、麻镇镇、哈镇镇、庙沟门镇、新民镇、孤山镇、清水镇、大昌汗镇、古城镇、三道沟镇、老高川镇、武家庄镇、木瓜镇、田家寨镇',
        },
        {
          神木县:
            '神木镇、高家堡镇、店塔镇、孙家岔镇、大柳塔镇、花石崖镇、中鸡镇、贺家川镇、尔林兔镇、万镇镇、大保当镇、马镇镇、栏杆堡镇、沙峁镇、锦界镇',
        },
        {
          定边县:
            '白泥井镇、堆子梁镇、安边镇、砖井镇、红柳沟镇、杨井镇、白湾子镇、新安边镇、姬塬镇、贺圈镇、周台子乡、盐场堡乡、郝滩乡、石洞沟乡、黄湾乡、纪畔乡、油房庄乡、学庄乡、武峁子乡、冯地坑乡、王盘山乡、樊学乡、张崾崄乡、白马崾崄乡',
        },
        {
          靖边县:
            '张畔镇、东坑镇、青阳岔镇、宁条梁镇、周河镇、红墩界镇、杨桥畔镇、王渠则镇、中山界镇、天赐湾镇、杨米涧镇、龙洲乡、海则滩乡、黄蒿界乡、席麻湾乡、小河乡、镇靖乡',
        },
        {
          横山县:
            '横山镇、石湾镇、高镇镇、武镇镇、党岔镇、响水镇、波罗镇、殿市镇、塔湾镇、赵石畔镇、魏家楼镇、韩岔镇、白界乡、雷龙湾乡',
        },
        {
          佳县: '佳芦镇、坑镇、店镇、乌镇、金明寺镇、通镇、王家砭镇、方塌镇、刘国具乡、峪口乡、木头峪乡、螅镇乡、大佛寺乡、康家港乡、上高寨乡、刘家山乡、兴隆寺乡、官庄乡、朱官寨乡、朱家坬乡',
        },
        {
          子洲县:
            '双湖峪镇、何家集镇、老君殿镇、裴家湾镇、苗家坪镇、三川口镇、马蹄沟镇、电市镇、周家碱镇、砖庙镇、淮宁湾镇、马岔镇、驼耳巷乡',
        },
        { 吴堡县: '宋家川镇、辛家沟镇、郭家沟镇、寇家塬镇、张家山镇、岔上镇' },
        {
          绥德县:
            '名州镇、薛家峁镇、崔家湾镇、定仙墕镇、枣林坪镇、义合镇、吉镇镇、薛家河镇、四十铺镇、石家湾镇、田庄镇、辛店乡、白家碱乡、河底乡、中角乡、马家川乡、赵家砭乡、韭园沟乡、满堂川乡、张家砭乡',
        },
        {
          清涧县:
            '宽洲镇、石咀驿镇、折家坪镇、玉家河镇、高杰村镇、李家塔镇、店则沟镇、解家沟镇、郝家也乡、乐堂堡乡、下廿里铺乡、双庙河乡、老舍窠乡、二郎山乡、石盘乡',
        },
        {
          米脂县:
            '银洲镇、桃镇镇、龙镇镇、杨家沟镇、杜家石沟镇、沙家店镇、印斗镇、郭兴庄镇、桥河岔乡、十里铺乡',
        },
      ],
      安康市: [
        {
          汉滨区:
            '老城街道、新城街道、江北街道、建民街道、关庙镇、张滩镇、瀛湖镇、五里镇、大同镇、恒口镇、吉河镇、流水镇、大竹园镇、洪山镇、石转镇、茨沟镇、大河镇、沈坝镇、双龙镇、叶坪镇、中原镇、早阳镇、共进镇、石梯镇、关家镇、县河镇、田坝镇、晏坝镇、双溪镇、谭坝镇、坝河镇、新坝镇、牛蹄镇、紫荆镇',
        },
        {
          汉阴县:
            '城关镇、涧池镇、蒲溪镇、平梁镇、双乳镇、铁佛寺镇、龙垭镇、漩涡镇、汉阳镇、酒店镇、双河口镇、上七镇、观音河镇、双坪镇',
        },
        {
          石泉县:
            '城关镇、饶峰镇、两河镇、迎丰镇、池河镇、后柳镇、喜河镇、熨斗镇、曾溪镇、云雾山镇、中池镇',
        },
        {
          宁陕县:
            '城关镇、四亩地镇、江口回族镇、广货街镇、龙王镇、筒车湾镇、金川镇、皇冠镇、丰富镇、梅子镇、新场镇、太山庙镇',
        },
        {
          紫阳县:
            '城关镇、蒿坪镇、汉王镇、焕古镇、向阳镇、洞河镇、洄水镇、斑桃镇、双桥镇、高桥镇、红椿镇、高滩镇、毛坝镇、瓦庙镇、麻柳镇、双安镇、界岭镇、东木镇、广城镇、绕溪镇、联合镇',
        },
        {
          岚皋县:
            '城关镇、佐龙镇、花里镇、滔河镇、官元镇、石门镇、民主镇、大道河镇、蔺河镇、溢河镇、孟石岭镇、四季镇、横溪镇、堰门镇、铁炉镇',
        },
        {
          平利县:
            '城关镇、兴隆镇、老县镇、大贵镇、三阳镇、洛河镇、广佛镇、八仙镇、长安镇、西河镇、正阳镇',
        },
        {
          镇坪县:
            '城关镇、曾家镇、牛头店镇、钟宝镇、洪石镇、上竹镇、小曙河镇、曙坪镇、华坪镇',
        },
        {
          旬阳县:
            '城关镇、棕溪镇、关口镇、蜀河镇、双河镇、小河镇、赵湾镇、麻坪镇、甘溪镇、白柳镇、吕河镇、神河镇、赤岩镇、段家河镇、构元镇、仙河镇、红军镇、仁河口镇、桐木镇、石门镇、金寨镇、铜钱关镇',
        },
        {
          白河县:
            '城关镇、中厂镇、构扒镇、卡子镇、茅坪镇、宋家镇、西营镇、仓上镇、冷水镇、双丰镇、小双镇、麻虎镇',
        },
      ],
      汉中市: [
        {
          汉台区:
            '北关街道、东大街街道、汉中路街道、中山街街道、东关街道、鑫源街道、龙江街道、七里街道、铺镇镇、武乡镇、河东店镇、宗营镇、老君镇、汉王镇、徐望镇',
        },
        {
          南郑县:
            '汉山街道、圣水街道、大河坎街道、协税镇、梁山镇、阳春镇、高台镇、新集镇、濂水镇、黄官镇、青树镇、红庙镇、牟家坝镇、法镇镇、湘水镇、小南海镇、碑坝镇、黎坪镇、胡家营镇、两河镇、福成镇',
        },
        {
          城固县:
            '博望街道、莲花街道、文柳街道、南乐街道、老庄镇、桔园镇、原公镇、天明镇、二里镇、五堵镇、双溪镇、小河镇',
        },
        {
          洋县: '洋州街道、戚氏街道、龙亭镇、谢村镇、马畅镇、溢水镇、磨子桥镇、黄家营镇、黄安镇、黄金峡镇、槐树关镇、金水镇、华阳镇、茅坪镇、关帝镇、桑溪镇、八里关镇',
        },
        {
          西乡县:
            '北街道、城南街道、杨河镇、柳树镇、沙河镇、私渡镇、桑园镇、白龙塘镇、峡口镇、堰口镇、茶镇镇、高川镇、两河口镇、大河镇、骆家坝镇、白勉峡镇、子午镇',
        },
        {
          勉县: '勉阳街道、武侯镇、周家山镇、同沟寺镇、新街子镇、老道寺镇、褒城镇、金泉镇、定军山镇、温泉镇、元墩镇、阜川镇、新铺镇、茶店镇、镇川镇、漆树坝镇、长沟河镇、张家河镇',
        },
        {
          宁强县:
            '汉源街道、高寨子街道、大安镇、代家坝镇、阳平关镇、燕子砭镇、广坪镇、青木川镇、毛坝河镇、铁锁关镇、胡家坝镇、巴山镇、舒家坝镇、太阳岭镇、苍社镇、巨亭镇、安乐河镇、禅家岩镇、二郎坝镇',
        },
        {
          略阳县:
            '兴州街道、横现河街道、两河口镇、金家河镇、徐家坪镇、白水江镇、硖口驿镇、何家岩镇、乐素河镇、郭镇镇、马蹄湾镇、西淮坝镇、五龙洞镇、仙台坝镇、观音寺镇、黑河镇、白雀寺镇',
        },
        {
          镇巴县:
            '泾洋街道、渔度镇、盐场镇、观音镇、巴庙镇、兴隆镇、长岭镇、三元镇、简池镇、碾子镇、小洋镇、青水镇、永乐镇、杨家河镇、赤南镇、巴山镇、大池镇、平安镇、仁村镇、黎坝镇',
        },
        {
          留坝县:
            '紫柏街道、马道镇、武关驿镇、留侯镇、江口镇、青桥驿镇、火烧店镇、玉皇庙镇',
        },
        {
          佛坪县:
            '袁家庄街道、陈家坝镇、西岔河镇、大河坝镇、长角坝镇、石墩河镇、岳坝镇',
        },
      ],
      商洛市: [
        {
          商州区:
            '城关街道、大赵峪街道、陈塬街道、刘湾街道、孝义镇、夜村镇、白杨店镇、张村镇、沙河子镇、杨峪河镇、金陵寺镇、黑山镇、杨斜镇、麻街镇、黑龙口镇、牧护关镇、大荆镇、腰市镇、板桥镇、北宽坪镇、上官坊乡、砚池河乡、麻池河乡、阎村乡、三岔河乡、红门河乡、西荆乡、李庙乡、蒲峪乡、龙王庙乡',
        },
        {
          洛南县:
            '洛源镇、保安镇、卫东镇、永丰镇、四皓镇、城关镇、景村镇、古城镇、柏峪寺镇、三要镇、灵口镇、石门镇、麻坪镇、石坡镇、巡检镇、陈耳镇、寺耳镇',
        },
        {
          丹凤县:
            ':龙驹寨镇、庾岭镇、蔡川镇、峦庄镇、铁峪铺镇、武关镇、竹林关镇、土门镇、寺坪镇、商镇镇、棣花镇、月日乡、双槽乡、资峪乡、桃坪乡、花瓶乡、北赵川乡、东岭乡、毛里岗乡、花园乡、留仙坪乡',
        },
        {
          商南县:
            '城关镇、富水镇、湘河镇、白浪镇、赵川镇、梁家湾镇、太吉河镇、过风楼镇、试马镇、清油河镇、十里坪镇、青山乡、魏家台乡、白鲁础乡、水沟乡、党马乡',
        },
        {
          山阳县:
            '城关街道 、十里铺街道办事处、高坝店镇、 双坪镇、两岭镇、天竺山镇、中村镇、银花镇、王闫镇、天桥镇、西照川镇、石佛寺镇、漫川关镇、法官镇、延坪镇、南宽坪镇、户家塬镇、杨地镇、牛耳镇、小河口镇、色河铺镇、板岩镇、元子街镇',
        },
        {
          镇安县:
            '永乐街道、回龙镇、铁厂镇、大坪镇、米粮镇、茅坪回族镇、西口回族镇、高峰镇、青铜关镇、柴坪镇、达仁镇、木王镇、云盖寺镇、庙沟镇、月河镇',
        },
        {
          柞水县:
            '乾佑镇、营盘镇、蔡玉窖镇、杏坪镇、下梁镇、红岩寺镇、凤凰镇、曹坪镇、石瓮镇、小岭镇；柴庄乡、瓦房口乡、丰北河乡、两河乡、九间房乡、马家台乡',
        },
      ],
      杨凌示范区: [{ 杨陵区: '杨陵街道、李台街道、五泉镇、大寨镇、揉谷镇' }],
    },
  ],
  甘肃省: [
    {
      兰州市: [
        {
          城关区:
            '酒泉路街道、张掖路街道、雁南街道、临夏路街道、雁北街道、五泉街道、白银路街道、皋兰路街道、广武门街道、伏龙坪街道、靖远路街道、草场街街道、火车站街道、拱星墩街道、东岗街道、团结新村街道、东岗西路街道、铁路东村街道、铁路西村街道、渭源路街道、盐场路街道、嘉峪关路街道、焦家湾街道、青白石街道',
        },
        {
          七里河区:
            '西园街道、西湖街道、建兰路街道、敦煌路街道、西站街道、晏家坪街道、龚家湾街道、土门墩街道、秀川街道、阿干镇、八里镇、彭家坪镇、西果园镇、魏岭乡、黄峪乡',
        },
        {
          西固区:
            '临洮街街道、西固城街道、四季青街道、福利路街道、先锋路街道、陈坪街道、西柳沟街道、新安路街道、新合路街道、达川乡、河口乡、金沟乡、柳泉乡、新城镇、东川镇',
        },
        {
          安宁区:
            '十里店街道、培黎街道、孔家崖街道、西路街道、银滩路街道、刘家堡街道、安宁堡街道、沙井驿街道',
        },
        {
          红古区:
            '窑街街道、下窑街道、矿区街道、海石湾镇、花庄镇、平安镇、红古乡',
        },
        {
          永登县:
            '城关镇、红城镇、中堡镇、武胜驿镇、河桥镇、连城镇、苦水镇、中川镇、秦川镇、大同镇、龙泉寺镇、树屏镇、上川镇、柳树乡、坪城乡、民乐乡、通远乡、七山乡',
        },
        { 皋兰县: '西岔镇、石洞镇、忠和镇、什川镇、九合镇、黑石川乡、水阜乡' },
        {
          榆中县:
            '甘草店镇、夏官营镇、青城镇、高崖镇、金崖镇、定远镇、和平镇、小康营乡、清水驿乡、中连川乡、园子岔乡、上花岔乡、哈岘乡、连搭乡、马坡乡、新营乡、龙泉乡、韦营乡、贡井乡',
        },
        { 兰州新区: '中川镇、秦川镇、上川镇、树屏镇、西岔镇、水阜乡' },
      ],
      嘉峪关市: [
        { 雄关区: '胜利街道、五一街道、矿山街道、和峪泉镇' },
        { 长城区: '新城镇、嘉东工业园区、魏晋墓旅游景区、新华街道、建设街道' },
        { 镜铁区: '前进街道、峪苑街道、朝阳街道、文殊镇' },
      ],
      金昌市: [
        {
          金川区:
            '滨河路街道、桂林路街道、北京路街道、金川路街道、新华路街道、广州路街道、宁远堡镇、双湾镇',
        },
        {
          永昌县:
            '城关镇、河西堡镇、新城子镇、朱王堡镇、东寨镇、水源镇、红山窑乡、焦家庄乡、六坝乡、南坝乡',
        },
      ],
      白银市: [
        {
          白银区:
            '人民路街道、公园路街道、工农路街道、四龙路街道、纺织路街道、水川镇、四龙镇、王岘镇、强湾乡、武川乡',
        },
        {
          平川区:
            '长征街道、电力路街道、兴平路街道、红会路街道、王家山镇、水泉镇、共和镇、宝积乡、黄峤乡、种田乡、复兴乡',
        },
        {
          靖远县:
            '乌兰镇、东湾镇、北湾镇、刘川乡、糜滩乡、平堡乡、若笠乡、三滩乡、大芦乡、高湾乡、北滩乡、东升乡、靖安乡、石门乡、双龙乡、五合乡、兴隆乡、永新乡',
        },
        {
          会宁县:
            '会师镇、郭城驿镇、河畔镇、头寨子镇、甘沟驿镇、太平店镇、丁家沟乡、中川乡、新添堡回族乡、侯家川乡、党家岘乡、杨崖集乡、老君坡乡、翟家所乡、柴家门乡、八里湾乡、平头川乡、韩家集乡、大沟乡、四房吴乡、汉家岔乡、土门岘乡、新塬乡、刘家寨子乡、草滩乡、土高山乡、白草塬乡、新庄乡',
        },
        {
          景泰县:
            '一条山镇、芦阳镇、上沙沃镇、喜泉镇、草窝滩镇、漫水滩镇、红水镇、中泉乡、正路乡、寺滩乡、五佛乡',
        },
      ],
      天水市: [
        {
          麦积区:
            '道北街道、道南街道、桥南街道、社棠镇、马跑泉镇、甘泉镇、渭南镇、东岔镇、花牛镇、中滩镇、新阳镇、元龙镇、伯阳镇、麦积镇、石佛镇、五龙乡、琥珀乡、党川乡、利桥乡、三岔乡',
        },
        {
          秦州区:
            '大城街道、七里墩街道、东关街道、中城街道、西关街道、石马坪街道、天水郡街道、玉泉镇、太京镇、藉口镇、皂郊镇、汪川镇、牡丹镇、关子镇、平南镇、天水镇、娘娘坝镇、中梁乡、秦岭乡、杨家寺乡、华岐乡、齐寿乡、大门乡',
        },
        {
          甘谷县:
            '大像山镇、安远镇、六峰镇、新兴镇、磐安镇、西坪乡、八里湾乡、白家湾乡、金山乡、大石乡、谢家湾乡、古坡乡、礼辛乡、大庄乡、武家河乡',
        },
        {
          武山县:
            '城关镇、鸳鸯镇、洛门镇、滩歌镇、四门镇、马力镇、桦林乡、高楼乡、山丹乡、龙台乡、温泉乡、榆盘乡、咀头乡、杨河乡、沿安乡',
        },
        {
          秦安县:
            '兴国镇、西川镇、郭嘉镇、莲花镇、陇城镇、叶堡镇、五营镇、魏店镇、刘坪乡、中山乡、安伏乡、王铺乡、千户乡、王窑乡、云山乡、王尹乡、兴丰乡',
        },
        {
          清水县:
            '永清镇、红堡镇、白驼镇、金集镇、秦亭镇、山门镇、白沙乡、松树乡、王河乡、远门乡、土门乡、郭川乡、贾川乡、丰望乡、草川铺乡、陇东乡、黄门乡、新城乡',
        },
        {
          张家川回族自治县:
            '张家川镇、龙山镇、恭门镇、刘堡乡、张棉乡、胡川乡、木河乡、大阳乡、川王乡、马关乡、连五乡、梁山乡、平安乡、阎家乡、马鹿乡',
        },
      ],
      酒泉市: [
        {
          肃州区:
            '东北街街道、东南街街道、工业园街道、新城街道、西北街街道、西南街街道、总寨镇、银达镇、西洞镇、上坝镇、三墩镇、清水镇、金佛寺镇、下河清乡、西峰乡、黄泥堡裕固族乡、铧尖乡、果园乡、丰乐乡、东洞乡、泉湖乡',
        },
        {
          玉门市:
            '南坪街道、北坪街道、新市区街道、玉门镇、玉门东镇、赤金镇、花海镇、下西号乡、黄闸湾乡、柳河乡、昌马乡、清泉乡、柳湖乡、小金湾东乡族乡、独山子东乡族乡',
        },
        {
          敦煌市:
            '沙州镇、肃州镇、莫高镇、转渠口镇、七里镇、月牙泉镇、郭家堡乡、黄渠乡、阳关镇',
        },
        {
          金塔县:
            '中东镇、鼎新镇、金塔镇、东坝镇、航天镇、三合乡、大庄子乡、古城乡、西坝乡、羊井子湾乡',
        },
        {
          瓜州县:
            '渊泉镇、柳园镇、三道沟镇、南岔镇、锁阳城镇、瓜州乡、西湖乡、腰站子乡、河东乡、布隆吉乡、双塔乡、梁湖乡、沙河乡、广至乡、七墩乡',
        },
        { 肃北蒙古族自治县: '党城湾镇、马鬃山镇、盐池湾乡、石包城乡' },
        { 阿克塞哈萨克族自治县: '红柳湾镇、阿克旗乡、阿勒腾乡' },
      ],
      张掖市: [
        {
          甘州区:
            '梁家墩镇、上秦镇、乌江镇、沙井镇、大满镇、小满镇、甘浚镇、新墩镇、碱滩镇、三闸镇、党寨镇、长安乡、西洞乡、廿里堡乡、靖安乡、花寨乡、安阳乡、和平乡、小河乡、明永乡、龙渠乡、平山湖乡',
        },
        {
          民乐县:
            '洪水镇、六坝镇、新天镇、南古镇、永固镇、三堡镇、南丰乡、民联乡、顺化乡、丰乐乡',
        },
        { 临泽县: '沙河镇、新华镇、平川镇、板桥镇、蓼泉镇、鸭暖乡、倪家营乡' },
        {
          高台县:
            '城关镇、宣化镇、南华镇、巷道镇、黑泉乡、罗城乡、合黎镇、骆驼城镇、新坝镇',
        },
        {
          山丹县:
            '清泉镇、位奇镇、霍城镇、东乐乡、大马营乡、陈户乡、老军乡、李桥乡、山丹县',
        },
        {
          肃南裕固族自治县:
            '：红湾寺镇、皇城镇、马蹄藏族乡、康乐乡、白银蒙古族乡、大河乡、明花乡、祁丰藏族乡',
        },
      ],
      武威市: [
        {
          凉州区:
            '东大街街道、西大街街道、东关街街道、西关街街道、火车站街街道、地质新村街街道、荣华街街道、黄羊镇、武南镇、清源镇、永昌镇、双城镇、丰乐镇、高坝镇、金羊镇、和平镇、羊下坝镇、中坝镇、永丰镇、古城镇、张义镇、发放镇、西营镇、四坝镇、洪祥镇、谢河镇五和乡、韩佐乡、松树乡、大柳乡、长城乡、金沙乡、柏树乡、金塔乡、下双乡、九墩乡、怀安乡、金山乡、清水乡、吴家井乡、新华乡、康宁乡、东河乡、河东乡',
        },
        {
          民勤县:
            '东湖镇、西渠镇、收成乡、红沙梁乡、泉山镇、大滩乡、双茨科乡、东坝镇、夹河乡、苏武乡、三雷镇、大坝乡、薛百乡、昌宁乡、重兴乡、蔡旗乡、南湖乡、红沙岗镇',
        },
        {
          古浪县:
            '城关街道、古浪镇、土门镇、大靖镇、定宁镇、泗水镇、裴家营镇、海子滩镇、黄羊川镇、黑松驿镇、永丰滩乡、黄花滩乡、西靖乡、民权乡、直滩乡、新堡乡、干城乡、横梁乡、十八里堡乡、古丰乡',
        },
        {
          天祝藏族自治县:
            '安远镇、哈溪镇、华藏寺镇、打柴沟镇、炭山岭镇、赛什斯镇、石门镇、松山镇、天堂镇、朵什乡、大红沟乡、东大滩乡、西大滩乡、赛拉隆乡、毛藏乡、东坪乡、祁连乡、旦马乡、抓喜秀龙乡',
        },
      ],
      定西市: [
        {
          安定区:
            '中华路街道、永定路街道、凤翔镇、内官镇、巉口镇、称钩驿镇、鲁家沟镇、西巩驿镇、宁远镇、李家堡镇、团结镇、香泉回族镇、符家川镇、葛家岔镇、白碌乡、石峡湾乡、新集乡、青岚山乡、高峰乡、石泉乡、杏园乡',
        },
        {
          通渭县:
            '平襄镇、马营镇、鸡川镇、榜罗镇、常河镇、义岗川镇、陇阳乡、陇山乡、陇川乡、新景乡、碧玉乡、襄南乡、李店乡、什川乡、三铺乡、华岭乡、寺子乡、北城铺乡',
        },
        {
          临洮县:
            '洮阳镇、八里铺镇、新添镇、辛店镇、太石镇、中铺镇、峡口镇、窑店镇、龙门镇、玉井镇、衙下集镇、南屏镇、红旗乡、上营乡、站滩乡、漫洼乡、连儿湾乡、康家集乡',
        },
        {
          漳县: '武阳镇、三岔镇、新寺镇、金钟镇、盐井乡、殪虎桥乡、大草滩乡、马泉乡、四族乡、石川乡、草滩乡、武当乡、东泉乡',
        },
        {
          岷县: '岷阳镇、蒲麻镇、西寨镇、梅川镇、西江镇、闾井镇、十里镇、茶埠镇、中寨镇、清水乡、马坞乡、寺沟乡、麻子川乡、秦许乡、禾驮乡、维新乡、申都乡、锁龙乡',
        },
        {
          渭源县:
            '清源镇、会川镇、莲峰镇、五竹镇、路园镇、北寨镇、新寨镇、麻家集镇、锹峪乡、大安乡、秦祁乡、庆坪乡、祁家庙乡、上湾乡、峡城乡、田家河乡',
        },
        {
          陇西县:
            '巩昌镇、文峰镇、首阳镇、菜子镇、福星镇、通安驿镇、云田镇、碧岩镇、马河镇、渭阳乡、宏伟乡、和平乡、柯寨乡、双泉乡、德兴乡、永吉乡、权家湾乡',
        },
      ],
      陇南市: [
        {
          武都区:
            '城关镇、安化镇、东江镇、两水镇、汉王镇、洛塘镇、角弓镇、马街镇、三河镇、甘泉镇、鱼龙镇、琵琶镇、城郊乡、坪垭藏族乡、蒲池乡、石门乡、汉林乡、柏林乡、马营乡、池坝乡、佛崖乡、黄坪乡、隆兴乡、龙坝乡、龙凤乡、桔柑乡、磨坝藏族乡、外纳乡、玉皇乡、郭河乡、枫相乡、三仓乡、五库乡、月照乡、五马乡、裕河乡',
        },
        {
          成县: '城关镇、抛沙镇、小川镇、红川镇、黄渚镇、店村镇、王磨镇、纸坊镇、沙坝镇、黄陈镇、陈院镇、鸡峰镇、二郎乡、宋坪乡、索池乡、苏元乡、镡河乡',
        },
        {
          文县: '城关镇、碧口镇、尚德镇、中寨镇、丹堡乡、刘家坪乡、玉垒乡、范坝乡、中庙乡、口头坝乡、尖山乡、临江乡、梨坪乡、舍书乡、桥头乡、堡子坝乡、石坊乡、石鸡坝乡、天池乡',
        },
        {
          宕昌县:
            '城关镇、哈达铺镇、理川镇、官亭镇、沙湾镇、南阳镇、阿坞乡、南河乡、木耳乡、庞家乡、八力乡、何家堡乡、贾河乡、将台乡、新城子藏族乡、车拉乡、临江铺乡、竹院乡、韩院乡、兴化乡、好梯乡、甘江头乡、两河口乡、新寨乡、狮子乡',
        },
        {
          康县: '城关镇、平洛镇、大堡镇、岸门口镇、两河镇、长坝镇、云台镇、阳坝镇、望关乡、寺台乡、大南峪乡、迷坝乡、王坝乡、碾坝乡、豆坝乡、店子乡、豆坪乡、白杨乡、太石乡、铜钱乡、三河坝乡',
        },
        {
          西和县:
            '汉源镇、长道镇、何坝镇、姜席镇、石峡镇、洛峪镇、马元镇、大桥镇、西峪镇、石堡乡、苏合乡、卢河乡、兴隆乡、稍峪乡、晒经乡、十里乡、蒿林乡、太石河乡、六巷乡、西高山乡',
        },
        {
          礼县: '城关镇、盐官镇、石桥镇、白河镇、红河镇、宽川镇、祁山镇、永兴镇、永坪镇、中坝镇、马河乡、固城乡、崖城乡、罗坝乡、湫山乡、洮坪乡、上坪乡、江口乡、雷王乡、龙林乡、沙金乡、桥头乡、草坪乡、雷坝乡、王坝乡、肖良乡、三峪乡、滩坪乡、白关乡',
        },
        {
          徽县: '城关镇、伏家镇、江洛镇、泥阳镇、柳林镇、嘉陵镇、永宁镇、银杏树乡、水阳乡、栗川乡、麻沿河乡、高桥乡、榆树乡、大河店乡、虞关乡',
        },
        {
          两当县:
            '城关镇、站儿巷镇、西坡镇、显龙乡、鱼池乡、兴化乡、张家乡、云坪乡、泰山乡、金洞乡、杨店乡、左家乡',
        },
      ],
      平凉市: [
        {
          崆峒区:
            '东关街道、中街街道、西郊街道、四十里铺镇、崆峒镇、白水镇、草峰镇、安国乡、柳湖乡、花所乡、索罗乡、香莲乡、西阳回族乡、大秦回族乡、白庙回族乡、寨河回族乡、大寨回族乡、上杨回族乡、麻武乡、峡门回族乡',
        },
        {
          泾川县:
            '城区街道、城关镇、玉都镇、高平镇、荔堡镇、王村镇、窑店镇、汭丰乡、罗汉洞乡、泾明乡、红河乡、飞云乡、太平乡、丰台乡、党原乡',
        },
        {
          灵台县:
            '台镇、邵寨镇、独店镇、什字镇、朝那镇、新开乡、西屯乡、上良乡、梁原乡、龙门乡、星火乡、百里乡、蒲窝乡',
        },
        { 崇信县: '锦屏镇、新窑镇、柏树乡、黄寨乡、黄花乡、木林乡' },
        {
          华亭县:
            '安口镇、东华镇、西华镇、策底镇、神峪回族乡、马峡镇、华亭工业园区、砚峡乡、上关乡、河西乡、山寨回族乡、东华街道',
        },
        {
          庄浪县:
            '水洛街道、水洛镇、南湖镇、朱店镇、万泉镇、韩店镇、大庄乡、阳川乡、岳堡乡、杨河乡、赵墩乡、柳梁乡、卧龙乡、良邑乡、通化乡、永宁乡、郑河乡、南坪乡、盘安乡',
        },
        {
          静宁县:
            '城关镇、威戎镇、界石铺镇、八里镇、李店镇、城川乡、司桥乡、曹务乡、古城乡、双岘乡、雷大乡、余湾乡、仁大乡、贾河乡、深沟乡、治平乡、新店乡、甘沟乡、四河乡、红寺乡、细巷乡、三合乡、原安乡、灵芝乡',
        },
      ],
      庆阳市: [
        {
          西峰区:
            '北街街道、西街街道、南街街道、董志镇、肖金镇、温泉镇、后官寨镇、彭原镇、什社乡、显胜乡',
        },
        {
          庆城县:
            '庆城镇、驿马镇、马岭镇、卅铺镇、玄马镇、赤城乡、桐川乡、白马铺乡、蔡家庙乡、太白梁乡、土桥乡、蔡口集乡、高楼乡、翟家河乡、南庄乡',
        },
        {
          环县: '曲子镇、木钵镇、甜水镇、环城镇、洪德镇、演武乡、合道乡、天池乡、八珠乡、樊家川乡、罗山川乡、虎洞乡、毛井乡、小南沟乡、芦家湾乡、车道乡、耿湾乡、秦团庄乡、山城乡、南湫乡',
        },
        {
          华池县:
            '柔远镇、悦乐镇、元城镇、南粱镇、乔川乡、五蛟乡、怀安乡、白马乡、王咀子乡、上里塬乡、城壕乡、桥河乡、紫坊畔乡、山庄乡、林镇乡',
        },
        {
          合水县:
            '太白镇、老城镇、西华池镇、板桥镇、何家畔镇、蒿咀铺乡、店子乡、吉岘乡、固城乡、段家集乡、太莪乡、肖咀乡',
        },
        {
          正宁县:
            '山河镇、榆林子镇、宫河镇、永和镇、永正乡、周家乡、西坡乡、湫头乡、三嘉乡、五顷塬回族乡',
        },
        {
          宁县: '新宁镇、平子镇、早胜镇、长庆桥镇、和盛镇、湘乐镇、新庄镇、盘克镇、焦村镇、中村镇、米桥乡、良平乡、太昌乡、南义乡、瓦斜乡、金村乡、九岘乡、春荣乡',
        },
        {
          镇原县:
            '城关镇、屯字镇、太平镇、三岔镇、开边镇、平泉镇、孟坝镇、临泾乡、南川乡、上肖乡、新集乡、方山乡、殷家城乡、马渠乡、庙渠乡、武沟乡、郭原乡、中原乡、新城乡',
        },
      ],
      临夏州: [
        {
          临夏市:
            '城南街道、城北街道、八坊街道、红园街道、西关街道、东关街道、城郊镇、折桥镇、南龙镇、枹罕镇',
        },
        {
          临夏县:
            '韩集镇、土桥镇、马集镇、莲花镇、新集镇、尹集镇、营滩乡、掌子沟乡、麻尼寺沟乡、漠泥沟乡、刁祁乡、铁寨乡、漫路乡、榆林乡、北塬乡、三角乡、坡头乡、桥寺乡、先锋乡、河西乡、南塬乡、红台乡、黄泥湾乡、路盘乡、民主乡、安家坡东乡族乡、井沟东乡族乡',
        },
        {
          永靖县:
            '刘家峡镇、太极镇、盐锅峡镇、西河镇、三塬镇、岘塬镇、陈井镇、王台镇、红泉镇、川城镇、三条岘乡、、徐顶乡、关山乡、杨塔乡、、小岭乡、、新寺乡、坪沟乡',
        },
        {
          广河县:
            '城关镇、三甲集镇、祁家集镇、买家巷镇、齐家镇、庄禾集镇、官坊乡、水泉乡、阿力麻土东乡族乡',
        },
        {
          和政县:
            '城关镇、三合镇、三十里铺镇、马家堡镇、买家集镇、梁家寺东乡族乡、陈家集乡、罗家集乡、卜家庄乡、新营乡、关滩沟乡、新庄乡、松鸣镇、达浪乡',
        },
        {
          康乐县:
            '附城镇、苏集镇、胭脂镇、景古镇、莲麓镇、康丰乡、虎关乡、流川乡、白王乡、八松乡、鸣鹿乡、八丹乡、上湾乡、普巴乡、草滩乡、五户乡',
        },
        {
          东乡族自治县:
            '锁南镇、达板镇、河滩镇、那勒寺镇、唐汪镇、春台乡、柳树乡、东塬乡、坪庄乡、百和乡、关卜乡、赵家乡、五家乡、果园乡、沿岭乡、汪集乡、风山乡、车家湾乡、高山乡、大树乡、北岭乡、龙泉乡、考勒乡、董岭乡',
        },
        {
          积石山保安族东乡族撒拉族自治县:
            '吹麻滩镇、大河家镇、别藏镇、居集镇、刘集乡、石塬乡、柳沟乡、关家川乡、胡林家乡、安集乡、寨子沟乡、郭干乡、徐扈家乡、中咀岭乡、小关乡、铺川乡、银川乡',
        },
      ],
      甘南州: [
        {
          夏河县:
            '拉卜楞镇、王格尔塘镇、阿木去乎镇、桑科乡、甘加乡、达麦乡、麻当乡、曲奥乡、唐尕昂乡、扎油乡、博拉乡、吉仓乡、科才乡',
        },
        {
          玛曲县:
            '尼玛镇、欧拉乡、欧拉秀玛乡、阿万仓乡、木西合乡、齐哈玛乡、采日玛乡、曼日玛乡',
        },
        {
          碌曲县: '郎木寺镇、玛艾镇、尕海乡、西仓乡、拉仁关乡、双岔乡、阿拉乡',
        },
        {
          卓尼县:
            '柳林镇、木耳乡、纳浪乡、洮砚乡、藏巴哇乡、柏林乡、卡车乡、大族乡、申藏乡、恰盖乡、康多乡、阿子滩乡、完冒乡、扎古录乡、刀告乡、尼巴乡、勺哇土族乡',
        },
        {
          迭部县:
            '电尕镇、益哇乡、卡坝乡、达拉乡、尼奥乡、旺藏乡、阿夏乡、多儿乡、桑坝乡、腊子乡、洛大乡',
        },
        {
          临潭县:
            '城关镇、新城镇、冶力关镇、八角乡、羊沙乡、洮滨乡、三岔乡、石门乡、王旗乡、流顺乡、店子乡、羊永乡、长川乡、卓洛乡、古战乡、术布乡',
        },
        {
          舟曲县:
            '城关镇、大川镇、曲瓦乡、巴藏乡、大峪乡、立节乡、憨班乡、峰迭乡、坪定乡、江盘乡、东山乡、南峪乡、果耶乡、八楞乡、武坪乡、插岗乡、拱坝乡、曲告纳乡、博峪乡',
        },
        {
          合作市:
            '佐盖多玛乡、佐盖曼玛乡、卡加道乡、卡加曼乡、勒秀乡、那吾乡、通钦街道、当周街道、坚木克尔街道、伊合昂街道',
        },
      ],
    },
  ],
  青海省: [
    {
      西宁市: [
        {
          城中区:
            '人民街街道、南滩街道、仓门街街道、礼让街街道、饮马街街道、南川东路街道、南川西路街道、总寨镇',
        },
        {
          城东区:
            '东关大街街道、清真巷街道、大众街街道、周家泉街道、火车站街道、八一路街道、林家崖街道、乐家湾镇、韵家口镇',
        },
        {
          城西区:
            '沙北街道办事处、城关镇、孟庙镇、商桥镇、裴城镇、新店镇、龙城镇、李集镇、黑龙潭乡',
        },
        { 城北区: '朝阳街道、小桥大街街道、马坊街道、大堡子镇、廿里铺镇' },
        { 海湖新区: '海湖新区' },
        {
          湟源县:
            '城关镇、大华镇、东峡乡、日月藏族乡、和平乡、波航乡、申中乡、巴燕乡、寺寨乡',
        },
        {
          湟中县:
            '鲁沙尔镇、多巴镇、丹麻乡、田家寨乡、什张家乡、群加藏族乡、土门关乡、升平乡、总寨乡、上新庄乡、马场藏族乡、西堡乡、大源乡、甘河滩乡、坡家乡、汉东回族乡、大才回族乡、维新乡、共和乡、盘道乡、拦隆口乡、拉沙乡、上五庄回族乡、四营乡、李家山乡、海子沟乡',
        },
        {
          大通回族土族自治县:
            '桥头镇、城关镇、塔尔镇、东峡镇、黄家寨镇、长宁镇、景阳镇、多林镇、新庄镇、青林乡、青山乡、逊让乡、极乐乡、宝库乡、斜沟乡、良教乡、向化藏族乡、桦林乡、朔北藏族乡、石山乡',
        },
      ],
      海东市: [
        {
          乐都区:
            '碾伯镇、高庙镇、洪水镇、雨润镇、高店镇、寿乐镇、瞿昙镇、达拉土族乡、共和乡、中岭乡、李家乡、马营乡、芦花乡、马厂乡、中坝藏族乡、下营藏族乡、蒲台乡、峰堆乡、城台乡、小峡镇、三合镇、洪水泉回族乡、石灰窑回族乡、古城回族乡、沙沟回族乡、巴藏沟回族乡',
        },
        {
          平安区:
            '平安镇、小峡镇、三合镇、洪水泉回族乡、石灰窑回族乡、古城回族乡、沙沟回族乡、巴藏沟回族乡',
        },
        {
          民和回族土族自治县:
            '川口镇、古鄯镇、马营镇、官亭镇、巴州镇、满坪镇、李二堡镇、硖门镇、马场垣乡、北山乡、西沟乡、总堡乡、隆治乡、大庄乡、转导乡、前河乡、甘沟乡、中川乡、杏儿藏族乡、核桃庄乡、新民乡、松树乡',
        },
        {
          互助土族自治县:
            '威远镇、丹麻镇、高寨镇、南门峡镇、加定镇、五十镇、五峰镇、塘川镇、红崖子沟乡、哈拉直沟乡、松多藏族乡、东山乡、东和乡、东沟乡、林川镇、台子乡、西山乡、蔡家堡乡、巴扎藏族乡',
        },
        {
          化隆回族自治县:
            '巴燕镇、群科镇、牙什尕镇、甘都镇、扎巴镇、昂思多镇、雄先藏族乡、查甫藏族乡、二塘乡、谢家滩乡、德恒隆乡、沙连堡乡、阿什奴乡、石大仓乡、初么乡、金源藏族乡、塔加藏族乡',
        },
        {
          循化撒拉族自治县:
            '积石镇、道帏藏族乡、白庄镇、清水乡、街子镇、查汗都斯乡、文都藏族乡、尕楞藏族乡、岗察藏族乡',
        },
      ],
      海北藏族自治州: [
        {
          海晏县:
            '三角城镇、西海镇、金滩乡、哈勒景蒙古族乡、青海湖乡、达玉蒙古族乡',
        },
        {
          祁连县: '八宝镇、峨堡镇、默勒镇、扎麻什乡、阿柔乡、野牛沟乡、央隆乡',
        },
        { 刚察县: '沙柳河镇、哈尔盖镇、伊克乌兰乡、泉吉乡、吉尔孟乡' },
        {
          门源回族自治县:
            '浩门镇、青石咀镇、泉口镇、东川镇、皇城蒙古族乡、苏吉滩乡、北山乡、西滩乡、麻莲乡、阴田乡、仙米乡、珠固乡',
        },
      ],
      黄南藏族自治州: [
        {
          同仁县:
            '隆务镇、保安镇、兰采乡、双朋西乡、扎毛乡、黄乃亥乡、曲库乎乡、年都乎乡、多哇乡、瓜什则乡、加吾乡',
        },
        {
          尖扎县:
            '马克堂镇、康扬镇、直岗拉卡乡、坎布拉乡、多加乡、贾加乡、措周乡、加让乡、昂拉乡、能科乡、当顺乡、尖扎滩乡、李家峡',
        },
        {
          泽库县: '泽曲镇、麦秀镇、宁秀乡、和日乡、王家乡、西卜沙乡、多禾茂乡',
        },
        {
          河南蒙古族自治县:
            '优干宁镇、宁木特乡、托叶玛乡、赛尔龙乡、柯生乡、多松乡',
        },
      ],
      海南藏族自治州: [
        {
          共和县:
            '恰卜恰镇、倒淌河镇、龙羊峡镇、塘格木镇、石乃亥乡、沙珠玉乡、铁盖乡、廿地乡、江西沟乡、黑马河乡、切吉乡',
        },
        { 同德县: '尕巴松多镇、唐谷镇、巴沟乡、秀麻乡、河北乡' },
        {
          贵德县:
            '河阴镇、河西镇、常牧镇、拉西瓦镇、河东乡、尕让乡、新街回族乡',
        },
        {
          兴海县:
            '子科滩镇、河卡镇、曲什安镇、温泉乡、龙藏乡、中铁乡、唐乃亥乡、赛什墉牧场',
        },
        { 贵南县: '茫曲镇、过马营镇、茫拉乡、塔秀乡、森多乡、沙沟乡' },
      ],
      果洛藏族自治州: [
        {
          玛沁县:
            '大武镇、拉加镇、大武乡、东倾沟乡、雪山乡、当洛乡、优云乡、下大武乡',
        },
        {
          班玛县:
            '辖江日堂乡、达卡乡、吉卡乡、灯塔乡、多贡麻乡、马可河乡、亚日堂乡、知钦乡、赛来塘镇',
        },
        {
          甘德县:
            '柯曲镇、上贡麻乡、下贡麻乡、岗龙乡、青珍乡、江千乡、下藏科乡',
        },
        {
          达日县:
            '窝赛乡、德昂乡、满掌乡、建设乡、莫坝乡、上红科乡、下红科乡、桑日麻乡、特合土乡、吉迈镇',
        },
        { 久治县: '智青松多镇、白玉乡、索乎日麻乡、哇赛乡、哇尔依乡、门堂乡' },
        { 玛多县: '花石峡镇、玛查理镇、黄河乡、扎陵湖乡' },
      ],
      玉树藏族自治州: [
        {
          玉树市:
            '结古镇、隆宝镇、下拉秀镇、仲达乡、巴塘乡、小苏莽乡、上拉秀乡、哈秀乡、安冲乡',
        },
        {
          杂多县:
            '萨呼腾镇、昂赛乡、结多乡、阿多乡、苏鲁乡、查旦乡、莫云乡、扎青乡',
        },
        { 称多县: '称文镇、歇武镇、扎朵镇、清水河镇、珍秦镇、拉布乡、尕朵乡' },
        { 治多县: '加吉博洛格镇、索加乡、扎河乡、多彩乡、治渠乡、立新乡' },
        {
          囊谦县:
            '香达镇、白扎乡、娘拉乡、毛庄乡、觉拉乡、东坝乡、吉曲乡、尕羊乡、吉尼赛乡、着晓乡',
        },
        { 曲麻莱县: '约改镇、巴干乡、秋智乡、叶格乡、麻多乡、曲麻河乡' },
      ],
      海西蒙古族藏族自治州: [
        {
          德令哈市:
            '河东街道、河西街道、火车站街道、尕海镇、蓄集乡、怀头他拉镇、柯鲁柯镇、柴旦镇、锡铁山镇、冷湖镇、花土沟镇、茫崖镇',
        },
        { 格尔木市: '格尔木市' },
        { 乌兰县: '希里沟镇、茶卡镇、铜普镇、柯柯镇' },
        {
          都兰县:
            '察汗乌苏镇、香日德镇、夏日哈镇、宗加镇、热水乡、香加乡、沟里乡、巴隆乡',
        },
        {
          天峻县:
            '新源镇、木里镇、江河镇、苏里乡、龙门乡、舟群乡、织合玛乡、快尔玛乡、生格乡、阳康乡',
        },
        { 冷湖行政区: '兴湖街社区' },
        { 大柴旦行政区: '柴旦镇、锡铁山镇、柴旦村、马海村' },
        { 茫崖行政区: '花土沟镇、茫崖镇、' },
      ],
    },
  ],
  台湾省: [
    {
      台北市: [
        {
          中正区:
            '城内次分区、南门次分区、东门次分区、崁顶次分区、古亭次分区、公馆次分区',
        },
        {
          大同区:
            '庆葡街道、高台子镇街道、林源镇街道、立志街道、新华街道、大同镇街道、大同镇、高台子镇、太阳升镇、林源镇、祝三乡、老山头乡、八井子乡、双榆树乡',
        },
        {
          中山区:
            '海军广场街道、人民路街道、桂林街道、青泥洼桥街道、昆明街道、葵英街道、桃源街道、老虎滩街道',
        },
        {
          万华区:
            '西门次分区、龙山次分区、大理次分区、西园次分区、东园次分区、青年次分区',
        },
        {
          信义区:
            '三张犁次分区、五分埔次分区、六张犁次分区、吴兴次分区、福德次分区',
        },
        {
          松山区:
            '铁东街道、松州街道、振兴街道、向阳街道、兴安街道、玉龙街道、全宁街道、穆家营子镇、初头朗镇、大庙镇、王府镇、老府镇、哈拉道口镇、上官地镇、安庆镇、太平地镇、当铺地满族乡、城子乡、大夫营子乡、岗子乡、夏家店乡',
        },
        {
          大安区:
            '大安街道、龙井街道、马冲口街道、凉高山街街道、大山铺镇、团结镇、三多寨镇、何市镇、新店镇、新民镇、回龙镇、牛佛镇、庙坝镇、永嘉乡、和平乡、凤凰乡',
        },
        { 南港区: '南港区' },
        { 北投区: '北投区' },
        { 内湖区: '内湖区' },
        {
          士林区:
            '社子次分区、后港次分区、街上次分区、兰雅次分区、芝山岩次分区、天母次分区、阳明山次分区',
        },
        { 文山区: '文山区' },
      ],
      新北市: [
        {
          新北市:
            '板桥区、汐止区、新店区、永和区、中和区、土城区、树林区、三重区、新庄区、芦洲市、瑞芳区、三峡区、莺歌区、淡水区、万里区、金山区、深坑区、石碇区、平溪区、双溪区、贡寮区、坪林区、乌来区、泰山区、林口区、五股区、八里区、三芝区、石门区',
        },
      ],
      桃园市: [
        {
          桃园区:
            '大树林区、市中区、中路区、埔子区、会稽区、公馆次分区、多功能艺文园区',
        },
        { 中坜区: '中坜区' },
        { 平镇区: '平镇区' },
        { 八德区: '八德区' },
        { 杨梅区: '杨梅区、埔心区、富冈区、高山顶区' },
        { 芦竹区: '芦竹区' },
        { 大溪区: '大溪区' },
        {
          龙潭区:
            '龙华街道、土城子街道、东城街道、泡子沿街道、龙潭街道、新吉林街道、山前街道、遵义街道、榆树街道、靠山街道、汉阳街道、承德街道、新安街道、铁东街道、乌拉街满族镇、缸窑镇、江密峰镇、大口钦满族镇、江北乡、金珠乡',
        },
        { 龟山区: '龟山区' },
        { 大园区: '大园区' },
        { 观音区: '观音区' },
        { 新屋区: '新屋区' },
        { 复兴区: '复兴区' },
      ],
      台中市: [
        {
          台中市:
            '中区、东区、西区、南区、北区、西屯区、南屯区、北屯区、丰原区、大里区、太平区、东势区、大甲区、清水区、沙鹿区、梧栖区、后里区、神冈区、潭子区、大雅区、新小区、石冈区、外埔区、大安区、乌日区、大肚区、龙井区、雾峰区、和平区',
        },
      ],
      台南市: [
        {
          台南市:
            '中西区、东区、南区、北区、安平区、安南区、永康区、归仁区、新化区、左镇区、玉井区、南西区、南化区、仁德区、关庙区、龙崎区、官田区、麻豆区、佳里区、西港区、七股区、将军区、学甲区、北门区、新营区、后壁区、白河区、东山区、六甲区、下营区、柳营区、盐水区、善化区、大内区、山上区、新市区、安定区',
        },
      ],
      高雄市: [
        {
          旧高雄市区:
            '楠梓区、左营区、鼓山区、三民区、盐埕区、前金区、新兴区苓雅区、前镇区、旗津区、小港区',
        },
        {
          凤山地区:
            '小港区、凤山区、大寮区、鸟松区、林园区、仁武区、大树区、大社区',
        },
        {
          冈山地区:
            '冈山区、路竹区、桥头区、梓官区、弥陀区、永安区、燕巢区、阿莲区、茄萣区、湖内区',
        },
        {
          旗山地区:
            '旗山区、美浓区、内门区、杉林区、甲仙区、六龟区、茂林区、桃源区、那玛夏区',
        },
      ],
      基隆市: [
        { 基隆市: '七堵区、中山区、中正区、信义区、仁爱区、安乐区、暖暖区' },
      ],
      新竹市: [{ 新竹市: '东区、北区、香山区' }],
      嘉义市: [{ 嘉义市: '东区、西区' }],
    },
  ],
  北京市: [
    {
      东城区: [
        {
          东城区:
            '安定门街道、建国门街道、朝阳门街道、东直门街道、东华门街道、和平里街道、北新桥街道、交道口街道、景山街道、东四街道、天坛街道、东花市街道、前门街道、龙潭街道、永定门外街道、崇文门外街道、体育馆路街道',
        },
      ],
      西城区: [
        {
          西城区:
            '西长安街街道、新街口街道、月坛街道、展览路街道、德胜街道、金融街街道、什刹海街道、大栅栏街道、天桥街道、椿树街道、陶然亭街道、广安门内街道、牛街街道、白纸坊街道、广安门外街道',
        },
      ],
      海淀区: [
        {
          海淀区:
            '万寿路街道、羊坊店街道、甘家口街道、八里庄街道、紫竹院街道、北下关街道、北太平庄街道、海淀街道、中关村街道、学院路街道、清河街道、青龙桥街道、香山街道、西三旗街道、马连洼街道、花园路街道、田村路街道、上地街道、燕园街道、清华园街道、永定路街道、温泉镇、上庄镇、苏家坨镇、西北旺镇、四季青镇、玉渊潭乡、海淀乡、东升乡、万柳地区办事处、东升地区办事处',
        },
      ],
      朝阳区: [
        {
          朝阳区:
            '朝外街道、劲松街道、建外街道、呼家楼街道、八里庄街道、三里屯街道、团结湖街道、双井街道、垡头街道、左家庄街道、小关街道、和平街街道、酒仙桥街道、首都机场街道、潘家园街道、六里屯街道、麦子店街道、香河园街道、亚运村街道、奥运村街道、望京街道、安贞街道、大屯街道、东湖街道',
        },
      ],
      丰台区: [
        {
          丰台区:
            '右安门街道、太平桥街道、西罗园街道、大红门街道、南苑街道、东高地街道、东铁匠营街道、卢沟桥街道、丰台街道、新村街道、长辛店街道、云岗街道、方庄地区、宛平城地区、马家堡街道、和义街道、长辛店镇、王佐镇、卢沟桥乡、花乡乡、南苑乡',
        },
      ],
      石景山区: [
        {
          石景山区:
            '八宝山街道、老山街道、八角街道、古城街道、苹果园街道、金顶街街道、广宁街道、五里坨街道、鲁谷社区',
        },
      ],
      通州区: [
        {
          通州区:
            '永顺镇、梨园镇、宋庄镇、漷县镇、张家湾镇、马驹桥镇、西集镇、永乐店镇、潞城镇、台湖镇、于家务乡、中仓街道、新华街道、玉桥街道、北苑街道',
        },
      ],
      顺义区: [
        {
          顺义区:
            '光明街道、胜利街道、石园街道、双丰街道、旺泉街道、空港街道、仁和地区、后沙峪地区、天竺地区、杨镇地区、牛栏山地区、南法信地区、马坡地区、张镇、大孙各庄镇、北务镇、李遂镇、木林镇、南彩镇、北小营镇、李桥镇、高丽营镇、赵全营镇、北石槽镇、龙湾屯镇',
        },
      ],
      房山区: [
        {
          房山区:
            '东风街道、石楼镇、城关街道、新镇、周口店镇、南窑乡、大安山乡、石亭镇、张坊镇、向阳街道、迎风街道、青龙湖镇、河北镇、良乡镇、长阳镇',
        },
      ],
      大兴区: [
        {
          大兴区:
            '辖黄村镇、西红门、旧宫、亦庄、瀛海、青云店镇、长子营镇、采育镇、礼贤镇、安定镇、榆垡镇、魏善庄镇、庞各庄镇、北臧村14个镇和清源街道、兴丰街道、林校路街道、观音寺街道、天宫院街道',
        },
      ],
      昌平区: [
        {
          昌平区:
            '南口地区、马池口地区、沙河地区、回龙观地区、东小口地区、南口镇、马池口镇、流村镇、阳坊镇、十三陵镇、长陵镇、南邵镇、崔村镇、兴寿镇、小汤山镇、百善镇、沙河镇、回龙观镇、东小口镇、北七家镇',
        },
      ],
      怀柔区: [
        {
          怀柔区:
            '怀柔镇、雁栖镇、北房镇、杨宋镇、庙城镇、桥梓镇、怀北镇、汤河口镇、渤海镇、九渡河镇、琉璃庙镇、宝山镇、长哨营满族乡、喇叭沟门满族乡、泉河街道、龙山街道',
        },
      ],
      平谷区: [
        {
          平谷区:
            '兴谷街道办事处、滨河街道办事处、平谷镇、峪口镇、马坊镇、金海湖镇、东高村镇、山东庄镇、南独乐河镇、大华山镇、夏各庄镇、马昌营镇、王辛庄镇、大兴庄镇、刘家店镇、镇罗营镇、熊儿寨乡、黄松峪乡',
        },
      ],
      门头沟区: [
        {
          门头沟区:
            '大峪街道、城子街道、东辛房街道、大台街道、龙泉地区、永定地区、王平地区、龙泉镇、永定镇、潭柘寺镇、军庄镇、王平镇、雁翅镇、斋堂镇、清水镇、妙峰山镇',
        },
      ],
      密云县: [
        {
          密云县:
            '密云镇、溪翁庄镇、西田各庄镇、十里堡镇、河南寨镇、巨各庄镇、穆家峪镇、太师屯镇、高岭镇、不老屯镇、冯家峪镇、古北口镇、大城子镇、东邵渠镇、北庄镇、新城子镇、石城镇、鼓楼街道、果园街道、檀营地区',
        },
      ],
      延庆县: [
        {
          延庆县:
            '延庆镇、康庄镇、八达岭镇、永宁镇、旧县镇、张山营镇、四海镇、千家店镇、沈家营镇、大榆树镇、井庄镇、刘斌堡乡、大庄科乡、香营乡、珍珠泉乡、百泉街道办事处、香水园街道办事处、儒林街道办事处',
        },
      ],
    },
  ],
  天津市: [
    {
      和平区: [
        {
          和平区:
            '劝业场街道、小白楼街道、南市街道、新兴街道、五大道街道、南营门街道',
        },
      ],
      河西区: [
        {
          河西区:
            '大营门街道、下瓦房街道、桃园街道、挂甲寺街道、马场街道、越秀路街道、友谊路街道、天塔街道、尖山街道、陈塘庄街道、柳林街道、东海街道、梅江街道',
        },
      ],
      南开区: [
        {
          南开区:
            '长虹街道、鼓楼街道、兴南街道、广开街道、万兴街道、学府街道、向阳路街道、嘉陵道街道、王顶堤街道、八里台街道、体育中心街道、华苑街道',
        },
      ],
      河东区: [
        {
          河东区:
            '二号桥街道、中山门街道、富民路街道、大直沽街道、大王庄街道、唐家口街道、春华街道、上杭路街道、向阳楼街道、常州道街道、东新街道、鲁山道街道',
        },
      ],
      河北区: [
        {
          河北区:
            '光复道街道、望海楼街道、鸿顺里街道、新开河街道、铁东路街道、建昌道街道、宁园街道、王串场街道、江都路街道、月牙河街道',
        },
      ],
      红桥区: [
        {
          红桥区:
            '西于庄街道、双环村街道、咸阳北路街道、丁字沽街道、西沽街道、三条石街道、邵公庄街道、芥园街道、铃铛阁街道、大胡同街道',
        },
      ],
      东丽区: [
        {
          东丽区:
            '张贵庄街道、丰年村街道、万新街道、无瑕街道、新立街道、金钟街道、华明街道、东丽湖街道、军粮城街道、金桥街道',
        },
      ],
      津南区: [
        {
          津南区:
            '咸水沽镇、葛沽镇、小站镇、双港镇、辛庄镇、双桥河镇、八里台镇、北闸口镇',
        },
      ],
      西青区: [
        {
          西青区:
            '李七庄街、西营门街、杨柳青镇、张家窝镇、精武镇、大寺镇、辛口镇、中北镇、王稳庄镇',
        },
      ],
      北辰区: [
        {
          北辰区:
            '果园新村街道、集贤里街道、普东街道、瑞景街道、佳荣里街、天穆镇、北仓镇、双街镇、双口镇、青光镇、宜兴埠镇、小淀镇、大张庄镇、西堤头镇',
        },
      ],
      滨海新区: [
        {
          滨海新区:
            '塘沽街道、大沽街道、杭州道街道、汉沽街道、茶淀街道、大港街道、海滨街道、寨上街道、泰达街道、新北街道、新河街道、北塘街道、胡家园街道、古林街道、新城镇、杨家泊镇、中塘镇、小王庄镇、太平镇',
        },
      ],
      武清区: [
        {
          武清区:
            '杨村街道、运河西街道、下朱庄街道、东蒲洼街道、黄庄街道、徐官屯街道、大良镇、梅厂镇、大碱厂镇、崔黄口镇、下伍旗镇、南蔡村镇、大孟庄镇、泗村店镇、河西务镇、城关镇、东马圈镇、黄花店镇、石各庄镇、王庆坨镇、汊沽港镇、河北屯镇、上马台镇、大王古庄镇、陈咀镇、豆张庄镇、曹子里镇、大黄堡镇、高村镇、白古屯镇',
        },
      ],
      宝坻区: [
        {
          宝坻区:
            '宝平街道、钰华街道、海滨街道、周良街道、大白街道、口东街道、朝霞街道、潮阳街道、霍各庄镇、史各庄镇、牛道口镇、大口屯镇、新开口镇、牛家牌镇、郝各庄镇、大唐庄镇、尔王庄镇、王卜庄镇、方家庄镇、林亭口镇、八门城镇、黄庄镇、大钟庄镇、新安镇',
        },
      ],
      宁河区: [
        {
          宁河区:
            '芦台镇、宁河镇、苗庄镇、丰台镇、岳龙镇、板桥镇、潘庄镇、造甲城镇、七里海镇、大北涧沽镇、东棘坨镇、淮淀乡、表口乡、廉庄乡',
        },
      ],
      静海区: [{ 静海区: '劝业场、小白楼、南市、新兴、五大道、南营门' }],
      蓟县: [
        {
          蓟县: '文昌街道、渔阳镇、下营镇、罗庄子镇、马伸桥镇、出头岭镇、西龙虎峪镇、五百户镇、官庄镇、白涧镇、邦均镇、洇溜镇、别山镇、桑梓镇、尤古庄镇、侯家营镇、施古镇、上仓镇、下窝头镇、杨津庄镇、下仓镇、穿芳峪乡、许家台乡、礼明庄乡、东赵各庄乡、东二营乡、孙各庄满族乡',
        },
      ],
    },
  ],
  重庆市: [
    {
      都市区: [
        {
          渝中区:
            '朝天门街道、解放碑街道、南纪门街道、七星岗街道、菜园坝街道、两路口街道、大溪沟街道、上清寺街道、石油路街道、大坪街道、化龙桥街道',
        },
        {
          大渡口区:
            '新山村街道、跃进村街道、九宫庙街道、茄子溪街道、春晖路街道、八桥镇、建胜镇、跳磴镇',
        },
        {
          江北区:
            '华新街街道、江北城街道、石马河街道、大石坝街道、寸滩街道、观音桥街道、五里店街道、郭家沱街道、铁山坪街道、鱼嘴镇、复盛镇、五宝镇',
        },
        {
          南岸区:
            '铜元局街道、花园路街道、南坪街道、海棠溪街道、龙门浩街道、弹子石街道、南山街道、天文街道、南坪镇、涂山镇、鸡冠石镇、峡口镇、长生桥镇、迎龙镇、广阳镇',
        },
        {
          沙坪坝区:
            '小龙坎街道、沙坪坝街道、渝碚路街道、磁器口街道、童家桥街道、石井坡街道、双碑街道、井口街道、歌乐山街道、山洞街道、新桥街道、天星桥街道、土湾街道、覃家岗街道、陈家桥街道、虎溪街道、西永街道、联芳街道、井口镇、歌乐山镇、青木关镇、凤凰镇、回龙坝镇、曾家镇、土主镇、中梁镇',
        },
        {
          九龙坡区:
            '杨家坪街道、黄桷坪街道、谢家湾街道、石坪桥街道、石桥铺街道、中梁山街道、渝州路街道、二郎街道、九龙镇、华岩镇、含谷镇、金凤镇、白市驿镇、走马镇、石板镇、巴福镇、陶家镇、西彭镇、铜罐驿镇',
        },
        {
          北碚区:
            '天生街道、朝阳街道、龙凤桥街道、北温泉街道、东阳街道、歇马镇、金刀峡镇、三圣镇、施家梁镇、童家溪镇、蔡家岗镇、柳荫镇、澄江镇、静观镇、复兴镇、水土镇、天府镇',
        },
        {
          渝北区:
            '双龙湖街道、龙溪街道、回兴街道、双凤桥街道、鸳鸯街道、人和街道、天宫殿街道、翠云街道、龙山街道、龙塔街道、大竹林街道、悦来街道、两路街道、王家街道、礼嘉街道、金山街道、康美街道、宝圣湖街道、石船镇、大盛镇、洛碛镇、古路镇、玉峰山镇、龙兴镇、统景镇、大塆镇、茨竹镇、兴隆镇、木耳镇',
        },
        {
          巴南区:
            '鱼洞街道、李家沱街道、龙洲湾街道、花溪街道、南泉街道、南彭街道、惠民街道、一品街道、界石镇、安澜镇、跳石镇、木洞镇、双河口镇、麻柳嘴镇、丰盛镇、二圣镇、东泉镇、姜家镇、天星寺镇、接龙镇、石滩镇、石龙镇',
        },
      ],
      城市发展新区: [
        {
          城市发展新区:
            '涪陵区、长寿区、江津区、铜梁区、綦江区、合川区、永川区、南川区、大足区、潼南区、荣昌区、璧山区、万盛经开区',
        },
      ],
      渝东北生态涵养发展区: [
        {
          渝东北生态涵养发展区:
            '万州区、梁平县、城口县、丰都县、垫江县、忠县、开县、云阳县、奉节县、巫山县、巫溪县',
        },
      ],
      渝东南生态保护发展区: [{ 渝东南生态保护发展区: '渝东南生态保护发展区' }],
    },
  ],
  上海市: [
    {
      黄浦区: [
        {
          黄浦区:
            '南京东路街道、外滩街道、半淞园路街道、小东门街道、豫园街道、老西门街道、瑞金二路街道、淮海中路街道、打浦桥街道、五里桥街道',
        },
      ],
      浦东新区: [
        {
          浦东新区:
            '潍坊新村街道、陆家嘴街道、周家渡街道、塘桥街道、上钢新村街道、南码头路街道、沪东新村街道、金杨新村街道、洋泾街道、浦兴路街道、东明路街道、花木街道、川沙新镇、高桥镇、北蔡镇、合庆镇、唐镇、曹路镇、金桥镇、高行镇、高东镇、张江镇、三林镇、惠南镇、周浦镇、新场镇、大团镇、康桥镇、航头镇、祝桥镇、泥城镇、宣桥镇、书院镇、万祥镇、老港镇、南汇新城镇',
        },
      ],
      徐汇区: [
        {
          徐汇区:
            '湖南路街道、天平路街道、枫林路街道、徐家汇街道、斜土路街道、长桥街道、漕河泾街道、康健新村街道、虹梅路街道、田林街道、凌云路街道、龙华街道、华泾镇',
        },
      ],
      长宁区: [
        {
          长宁区:
            '华阳路街道、新华路街道、江苏路街道、天山路街道、周家桥街道、虹桥街道、仙霞新村街道、程家桥街道、北新泾街道、新泾镇',
        },
      ],
      静安区: [
        {
          静安区:
            '静安寺街道、曹家渡街道、江宁路街道、石门二路街道、南京西路街道',
        },
      ],
      普陀区: [
        {
          普陀区:
            '长寿路、宜川路、甘泉路、石泉路、长风新村、曹杨新村、万里、真如镇街道、桃浦、长征镇',
        },
      ],
      闸北区: [
        {
          闸北区:
            '天目西路街道、共和新路街道、彭浦新村街道、芷江西路街道、宝山路街道、大宁路街道、临汾路街道、北站街道、彭浦镇',
        },
      ],
      虹口区: [
        {
          虹口区:
            '广中路街道、曲阳路街道、欧阳路街道、嘉兴路街道、凉城新村街道、四川北路街道、提篮桥街道、江湾镇街道 ',
        },
      ],
      杨浦区: [
        {
          杨浦区:
            '定海路街道、平凉路街道、江浦路街道、四平路街道、控江路街道、长白新村街道、延吉新村街道、殷行街道、大桥街道、五角场街道、新江湾城街道、五角场镇',
        },
      ],
      闵行区: [
        {
          闵行区:
            '江川路街道、新虹街道、古美路街道、浦锦街道[2]  、莘庄镇、七宝镇、浦江镇、梅陇镇、虹桥镇、马桥镇、吴泾镇、华漕镇、颛桥镇、古北新城',
        },
      ],
      宝山区: [
        {
          宝山区:
            '吴淞街道、友谊路街道、张庙街道、罗店镇、大场镇、杨行镇、月浦镇、罗泾镇、顾村镇、高境镇、庙行镇、淞南镇、宝山城市工业园区',
        },
      ],
      嘉定区: [
        {
          嘉定区:
            '嘉定镇街道、真新街道、新成路街道、菊园新区、南翔镇、安亭镇、马陆镇、徐行镇、华亭镇、外冈镇、江桥镇、嘉定工业区',
        },
      ],
      金山区: [
        {
          金山区:
            '石化街道、金山工业区、朱泾镇、枫泾镇、张堰镇、亭林镇、吕巷镇、廊下镇、金山卫镇、漕泾镇、山阳镇',
        },
      ],
      松江区: [
        {
          松江区:
            '岳阳街道、永丰街道、方松街道、中山街道、广富林街道、九里亭街道、泗泾镇、佘山镇、车墩镇、新桥镇、洞泾镇、九亭镇、泖港镇、石湖荡镇、新浜镇、叶榭镇、小昆山镇',
        },
      ],
      青浦区: [
        {
          青浦区:
            '朱家角镇、赵巷镇、徐泾镇、华新镇、重固镇、白鹤镇、练塘镇、金泽镇、夏阳街道、盈浦街道、香花桥街道',
        },
      ],
      奉贤区: [
        {
          奉贤区:
            '南桥镇、奉城镇、四团镇、柘林镇、庄行镇、金汇镇、青村镇、海湾镇',
        },
      ],
      崇明县: [
        {
          崇明县:
            '城桥镇、堡镇、新河镇、庙镇、竖新镇、向化镇、三星镇、港沿镇、中兴镇、陈家镇、绿华镇、港西镇、建设镇、新海镇、东平镇、长兴镇、新村乡、横沙乡',
        },
      ],
    },
  ],
  内蒙古自治区: [
    {
      呼和浩特市: [
        {
          回民区:
            '新华西路街道、中山西路街道、光明路街道、海拉尔西路街道、通道街街道、钢铁路街道、攸攸板镇',
        },
        {
          玉泉区:
            '大南街、长和廊、小召前街、兴隆巷、石羊桥东路、鄂尔多斯路、西菜园、昭君路、小黑河',
        },
        {
          新城区:
            '西一路街道、长乐中路街道、中山门街道、韩森寨街道、解放门街道、自强路街道、太华路街道、长乐西路街道、胡家庙街道',
        },
        {
          赛罕区:
            '人民路街道、大学西路街道、乌兰察布路街道、大学东路街道、中专路街道、巧报镇、榆林镇、巴彦镇、黄合少镇、金河镇、太平庄乡、西把栅乡',
        },
        {
          托克托县:
            '双河镇、新营子镇、五申镇、中滩乡、燕山营乡、伍什家乡、永圣域乡、古城乡、乃只盖乡',
        },
        {
          清水河县:
            '宏河镇、喇嘛湾镇、城关镇、窑沟乡、北堡乡、单台子乡、五良太乡、韭菜庄乡',
        },
        {
          武川县:
            '沙北街道办事处、城关镇、孟庙镇、商桥镇、裴城镇、新店镇、龙城镇、李集镇、黑龙潭乡',
        },
        {
          和林格尔县:
            '羊群沟乡、黑老夭乡、大红城乡、舍必崖乡、城关镇、盛乐镇、新店子镇、盛乐经济园区',
        },
        {
          土默特左旗:
            '可可以力更镇、哈乐镇、西乌兰不浪镇、大青山乡、上秃亥乡、德胜沟乡、二份子乡、哈拉合少乡、耗赖山乡',
        },
      ],
      包头市: [
        {
          昆都仑区:
            '少先路街道、沼潭街道、林荫路街道、友谊大街街道、阿尔丁大街街道、团结大街街道、鞍山道街道、前进道街道、市府东路街道、白云路街道、黄河西路街道、昆工路街道、昆北街道、昆河镇、卜尔汉图镇',
        },
        {
          东河区:
            '和平街道、财神庙街道、西脑包街道、南门外街道、南圪洞街道、东站街道、回民街道、二里半街道、河东街道、铁西街道、东兴街道、杨圪楞街道、河东镇、沙尔沁镇',
        },
        {
          青山区:
            '红钢城街、新沟桥街、红卫路街、冶金街、钢花村街、青山镇街、武东街、白玉山街、厂前街、工人村街、钢都管委会、北湖管委会',
        },
        {
          石拐区:
            '石拐街道、大发街道、大磁街道、五当沟街道、白狐沟街道、大德恒街道、五当召镇、吉忽伦图苏木',
        },
        {
          九原区:
            '沙河街道、赛汉街道、萨如拉街道、白音席勒街道、麻池镇、哈林格尔镇、哈业胡同镇、阿嘎如泰苏木',
        },
        { 白云鄂博矿区: '通阳路街道、矿山路街道' },
        {
          土默特右旗:
            '萨拉齐镇、双龙镇、美岱召镇、沟门镇、吴坝乡、大城西乡、明沙淖乡、海子乡、二十四顷地乡、党三尧乡、将军尧乡、小召子乡、三道河乡、沙海子乡、毛岱乡、三间乡、苏波盖乡、九峰山生态管理委员会',
        },
        { 固阳县: '金山镇、西斗铺镇、下湿壕镇、银号镇、怀朔镇、兴顺西镇' },
        {
          达尔罕茂明安联合旗:
            '百灵庙镇、满都拉镇、希拉穆仁镇、希拉穆仁镇、石宝镇、乌克忽洞镇、明安镇、巴音花镇、达尔罕苏木、达茂巴润工业园区',
        },
      ],
      乌海市: [
        {
          海勃湾区:
            '新华街道、新华西街、凤凰岭街道、海北街道、卡布其街道、滨河街道',
        },
        { 海南区: '拉僧仲街道、西卓子山街道、拉僧庙镇、公乌素镇、巴音陶亥镇' },
        {
          乌达区:
            '巴音赛街道、三道坎街道、五虎山街道、梁家沟街道、滨海街道、新达街道、苏海图街道、乌兰淖尔镇',
        },
      ],
      赤峰市: [
        {
          红山区:
            '西屯街道、三中街街道、永巨街道、东城街道、南新街街道、站前街道、铁南街道、长青街道、哈达街道、西城街道、桥北街道、红庙子镇、文钟镇',
        },
        {
          元宝山区:
            '平庄城区街道、平庄东城街道、平庄西城街道、西露天街道、云杉路街道、马林街道、风水沟镇、美丽河镇、元宝山镇、五家镇、平庄镇',
        },
        {
          松山区:
            '铁东街道、松州街道、振兴街道、向阳街道、兴安街道、玉龙街道、全宁街道、穆家营子镇、初头朗镇、大庙镇、王府镇、老府镇、哈拉道口镇、上官地镇、安庆镇、太平地镇、当铺地满族乡、城子乡、大夫营子乡、岗子乡、夏家店乡',
        },
        {
          阿鲁科尔沁旗:
            '天山镇、天山口镇、双胜镇、坤都镇、巴彦花镇、绍根镇、扎嘎斯台镇、新民乡、先峰乡、乌兰哈达乡、罕苏木苏木、赛罕塔拉苏木、巴拉奇如德苏木、巴彦温都苏木',
        },
        {
          巴林左旗:
            '林东镇、碧流台镇、隆昌镇、十三敖包镇、白音勿拉镇、富河镇、哈拉哈达镇、三山乡、花加拉嘎乡、乌兰达坝苏木、查干哈达苏木、林东东城街道、林东西城街道',
        },
        {
          巴林右旗:
            '大板镇 、宝日勿苏镇、索博日嘎镇、查干沐沦镇、查干诺尔镇、幸福之路苏木、巴彦塔拉苏木、西拉沐沦苏木',
        },
        {
          林西县:
            '官地镇、新城子镇、新林镇、五十家子镇、林西镇、大井镇、统部镇、大营子乡、十二吐乡、城南街道、城北街道',
        },
        {
          克什克腾旗:
            '经棚镇、宇宙地镇、土城子镇、达来诺日镇、同兴镇、万合永镇、芝瑞镇、新开地乡、红山子乡、达日罕乌拉苏木、巴彦查干苏木、浩来呼热苏木、乌兰布统苏木',
        },
        {
          翁牛特旗:
            '乌丹镇、桥头镇、梧桐花镇、广德公镇、亿合公镇、五分地镇、乌敦套海镇、海拉苏镇、解放营子乡 毛山东乡、阿什罕苏木、新苏莫苏木、白音套海苏木、 格日僧苏木',
        },
        {
          喀喇沁旗:
            '锦山镇、小牛群镇、王爷府镇、牛家营子镇、乃林镇、西桥镇、美林镇、十家满族乡、南台子乡',
        },
        {
          宁城县:
            '铁东街道、铁西街道、温泉街道、天义镇、汐子镇、忙农镇、大明镇、大双庙镇、八里罕镇、大城子镇、小城子镇、黑里河镇、甸子镇、五化镇、三座店镇、必斯营子镇',
        },
        {
          敖汉旗:
            '新惠镇、长胜镇、下洼镇、四家子镇、贝子府镇、金厂沟梁镇、四道湾子镇、兴隆洼镇、丰收乡、萨力巴乡、牛古吐乡、古鲁板蒿乡、玛尼罕乡、木头营子乡、敖润苏莫苏木',
        },
      ],
      通辽市: [
        {
          科尔沁区:
            '科尔沁街道、西门街道、永清街道、明仁街道、施介街道、清真街道、东郊街道、铁南街道、霍林街道、建国街道、红星街道；大林镇、钱家店镇、余粮堡镇、木里图镇、丰田镇、清河镇、育新镇、庆和镇、敖力布皋镇、莫力庙苏木',
        },
        {
          霍林郭勒:
            '珠斯花街道、莫斯台街道、沙尔呼热街道、宝日呼吉尔街道、达来胡硕街道',
        },
        {
          科尔沁左翼中旗:
            '保康镇、宝龙山镇、舍伯吐镇、巴彦塔拉镇、门达镇、架玛吐镇、腰林毛都镇、希伯花镇、花吐古拉镇、代力吉镇、努日木镇、珠日河镇、胜利乡、七棵树乡、团结乡、新河乡、图布信苏木、乌斯吐苏木、额伦索克苏木、协代苏木、海力锦苏木、哈日干吐苏木、白兴吐苏木、敖本台苏木、花胡硕苏木、巴彦召苏木、敖包苏木、巴彦苏木',
        },
        {
          科尔沁左翼后旗:
            '甘旗卡镇、金宝屯镇、吉尔嘎朗镇、常胜镇、伊胡塔镇、查日苏镇、散都镇、阿古拉镇、努古斯台镇、朝鲁吐镇、海鲁吐镇、胜利镇、海斯改苏木、公河来苏木、浩坦苏木、巴雅斯古楞苏木、向阳乡、双胜乡、巴嘎塔拉苏木、额莫勒苏木、阿都沁苏木、茂道吐苏木、乌兰敖道苏木、巴彦毛都苏木',
        },
        {
          开鲁县:
            '开鲁镇、大榆树镇、黑龙坝镇、麦新镇、建华镇、小街基镇、东风镇、吉日嘎郎吐镇、东来镇、保安镇、北清河乡、义和塔拉苏木',
        },
        {
          库伦旗:
            '库伦镇、扣河子镇、白音花镇、六家子镇、额勒顺镇、茫汗苏木、先进苏木、水泉乡',
        },
        {
          奈曼旗:
            '大沁他拉镇、八仙筒镇、青龙山镇、新镇、治安镇、东明镇、沙日浩来镇、义隆永镇、黄花塔拉苏木、白音他拉苏木、明仁苏木、固日班花苏木、土城子乡、苇莲苏乡、六号农场、大沁他拉街道、八仙筒经济开发区',
        },
        {
          扎鲁特旗:
            '鲁北镇、黄花山镇、嘎亥图镇、巨日合镇、巴雅尔图胡硕镇、香山镇、阿日昆都冷镇、巴彦塔拉苏木、乌力吉木仁苏木、道老杜苏木、格日朝鲁苏木、前德门苏木、乌兰哈达苏木、查布嘎图苏木、乌额格其苏木。旗政府驻鲁北镇',
        },
      ],
      鄂尔多斯市: [
        { 东胜区: '泊尔江海子镇、罕台镇、铜川镇' },
        {
          达拉特旗:
            '工业街道、昭君街道、锡尼街道、白塔街道、西园街道、平原街道、树林召镇、白泥井镇、中和西镇、吉格斯太镇、王爱召镇、昭君镇、恩格贝镇、展旦召苏木',
        },
        {
          准格尔旗:
            '准格尔经济开发区、大路煤化工基地、薛家湾镇、沙圪堵镇、大路镇、龙口镇、纳日松镇、准格尔召镇、十二连城乡、暖水乡、布尔陶亥苏木、友谊街道、蓝天街道、迎泽街道、兴隆街道',
        },
        { 鄂托克前旗: '敖勒召其镇、上海庙镇、昂素镇、城川镇' },
        {
          鄂托克旗:
            '乌兰镇、棋盘井镇、蒙西镇、木凯淖尔镇、苏米图苏木、阿尔巴斯苏木',
        },
        {
          杭锦旗:
            '锡尼镇、巴拉贡镇、呼和木独镇、吉日嘎朗图镇、独贵塔拉镇、伊和乌素苏木、塔然高勒管委会',
        },
        {
          乌审旗:
            '嘎鲁图镇、无定河镇、乌审召镇、图克镇、乌兰陶勒盖镇、苏力德苏木',
        },
        {
          伊金霍洛旗:
            '阿勒腾席热镇、乌兰木伦镇、伊金霍洛镇、设札萨克镇、纳林陶亥镇、红庆河镇、苏布尔嘎镇',
        },
      ],
      呼伦贝尔市: [
        {
          海拉尔区:
            '正阳街道、健康街道、靠山街道、胜利街道、呼伦街道、奋斗街道、建设街道、哈克镇',
        },
        { 满洲里: '扎赉诺尔区、中俄互市贸易区、边境经济合作区、东湖区' },
        {
          扎赉诺尔区:
            '第一街道、第二街道、第三街道、第四街道、第五街道、灵泉街道',
        },
        {
          牙克石:
            '博克图镇、免渡河镇、乌尔其汉镇、库都尔镇、图里河镇、绰源镇、乌奴尔镇、伊图里河镇、塔尔气镇、煤田便民服务中心、巴林便民服务中心、东兴街道、胜利街道、红旗街道、新工街道、永兴街道、建设街道、暖泉街道',
        },
        {
          扎兰屯:
            '兴华街道、正阳街道、繁荣街道、向阳街道、高台子街道、铁东街道、河西街道、磨菇气镇、卧牛河镇、成吉思汗镇、大河湾镇、浩饶山镇、柴河镇、中和镇、哈多河镇、达斡尔民族乡、鄂伦春民族乡、萨马街鄂温克民族乡、洼堤乡。市政府驻兴华街道',
        },
        { 额尔古纳市: '额尔古纳市' },
        {
          根河市:
            '金河镇、阿龙山镇、满归镇、敖鲁古雅鄂温克族乡、好里堡办事处、得耳布尔办事处、河东办事处、河西办事处、森工办事处',
        },
        {
          阿荣旗:
            '那吉镇、亚东镇、霍尔奇镇、六合镇、向阳峪镇、复兴镇、三岔河镇、新发朝鲜族乡、查巴奇鄂温克族乡、音河达斡尔鄂温克民族乡、得力其尔鄂温克族乡',
        },
        {
          鄂伦春自治旗:
            '阿里河镇、大杨树镇、吉文镇、甘河镇、克一河镇、乌鲁布铁镇、诺敏镇、宜里镇、托扎敏乡、古里乡',
        },
        {
          莫力达瓦达斡尔族自治旗:
            '尼尔基镇、红彦镇、宝山镇、哈达阳镇、阿尔拉镇、汉古尔河镇、西瓦尔图镇、腾克镇、塔温敖宝镇、巴彦鄂温克民族乡、库如奇乡、杜拉尔鄂温克民族乡。境内有：国营甘河农场、国营巴彦农场、国营欧肯河农场、国营东方红农场',
        },
        {
          鄂温克族自治旗:
            '巴彦托海镇、大雁镇、伊敏河镇、红花尔基镇、巴彦查岗苏木、锡尼河西苏木、锡尼河东苏木、巴彦塔拉达斡尔族乡、伊敏苏木、辉苏木',
        },
        {
          陈巴尔虎旗:
            '巴彦库仁镇、宝日希勒镇、呼和诺尔镇、西乌珠尔苏木、鄂温克民族苏木、东乌珠尔苏木、巴彦哈达苏木',
        },
        {
          新巴尔虎左旗:
            '阿木古郎镇、嵯岗镇、乌布尔宝力格苏木、吉布胡郎图苏木、新宝力格苏木、甘珠尔苏木、罕达盖苏木',
        },
        {
          新巴尔虎右旗:
            '贝尔苏木、克尔伦苏木、阿拉坦额莫勒镇、阿日哈沙特镇、呼伦镇',
        },
      ],
      巴彦淖尔市: [
        {
          临河区:
            '团结街道、车站街道、先锋街道、解放街道、新华街道、东环街道、铁南街道、西环街道、北环街道、曙光街道、狼山镇、新华镇、干召庙镇、黄羊木头镇、乌兰图克镇、隆胜镇、小召镇、双河镇、古城镇、城关镇、白脑包镇、八一乡、丹达乡、乌兰淖尔乡',
        },
        {
          五原县:
            '隆兴昌镇、塔尔湖镇、巴音套海镇、新公中镇、天吉太镇、胜丰镇、银定图镇、复兴镇、和胜乡',
        },
        { 磴口县: '巴彦高勒镇、补隆淖尔镇、隆盛合镇 、渡口镇、沙金套海苏木' },
        {
          乌拉特前旗:
            '乌拉山镇、白彦花镇、新安镇、大佘太镇、西小召镇、小佘太镇、先锋镇、明安镇、额尔登布拉格苏木',
        },
        {
          乌拉特中旗:
            '海流图镇、德岭山镇、石哈河镇、乌加河镇、巴音乌兰苏木、川井苏木、呼鲁斯太苏木、新忽热苏木',
        },
        {
          乌拉特后旗:
            '巴音宝力格镇、呼和温都尔镇、潮格温都尔镇、获各琦苏木、前达门苏木',
        },
        {
          杭锦后旗:
            '陕坝镇、头道桥镇、二道桥镇、三道桥镇、蛮会镇、团结镇、双庙镇、沙海镇',
        },
      ],
      乌兰察布市: [
        {
          集宁区:
            '马莲渠乡、白海子镇、察哈尔经济技术开发区、新体路街道、桥东街道、前进路街道、常青街道、虎山街道、桥西街道、新华街街道、泉山街道',
        },
        {
          丰镇市:
            '隆盛庄镇、红砂坝镇、巨宝庄镇、黑土台镇、三义泉镇、官屯堡乡、浑源夭乡、旧城区街道、新城区街道、工业区街道、北城区街道、南城区街道',
        },
        {
          卓资县:
            '卓资山镇、旗下营镇、巴音锡勒镇、十八台镇、梨花镇、大榆树乡、复兴乡、和红召乡',
        },
        { 化德县: '长顺镇、七号镇、朝阳镇、德包图乡、公腊胡洞乡' },
        {
          商都县:
            '七台镇、十八顷镇、大黑沙土镇、西井子镇、屯垦队镇、小海子镇、西坊子乡、三虎地乡、大拉子乡、大南坊子乡、格化司台乡、大库伦乡、卯都乡、玻璃忽镜乡、三面井乡',
        },
        {
          兴和县:
            '城关镇、张皋镇、店子镇、赛乌素镇、鄂尔栋镇、大库联乡、团结乡',
        },
        {
          凉城县:
            '岱海镇、麦胡图镇、六苏木镇、永兴镇、蛮汉镇、天成乡、厂汉营乡、曹碾满族乡、岱海旅游区办事处',
        },
        {
          察哈尔右翼前旗:
            '平地泉镇、玫瑰营镇、巴音塔拉镇、黄旗海镇、乌拉哈乌拉乡、黄茂营乡、三岔口乡',
        },
        {
          察哈尔右翼中旗:
            '科镇、铁沙盖镇、乌素图镇、黄羊城镇、广益隆镇、宏盘乡、大滩乡、巴音乡、土城子乡、库伦苏木、乌兰苏木、辉腾锡勒园区',
        },
        {
          察哈尔右翼后旗:
            '白音察干镇、土牧尔台镇、红格尔图镇、贲红镇、大六号镇、当郎忽洞苏木、乌兰哈达苏木、锡勒乡',
        },
        {
          四子王旗:
            '乌兰花镇、吉生太镇、库伦图镇、供济堂镇、白音朝克图镇、红格尔苏木、江岸苏木、查干补力格苏木、脑木更苏木、东八号乡、忽鸡图乡、大黑河乡、巴音敖包苏木',
        },
      ],
      兴安盟: [
        {
          乌兰浩特市:
            '兴安街道、和平街道、爱国街道、胜利街道、铁西街道、都林街道、五一街道、乌兰哈达镇、义勒力特苏木、城郊乡、居力很乡',
        },
        { 阿尔山市: '天池镇、白狼镇、五岔沟镇、温泉街道、新城街道、林海街道' },
        {
          科尔沁右翼前旗:
            '科尔沁镇、索伦镇、德伯斯镇、大石寨镇、归流河镇、居力很镇、察尔森镇、额尔格图镇、俄体镇、巴拉格歹乡、满族屯满族乡、乌兰毛都苏木、阿力得尔苏木、桃合木苏木',
        },
        {
          科尔沁右翼中旗:
            '巴彦呼舒镇、巴仁哲里木镇、吐列毛都镇、杜尔基镇、高力板镇、好腰苏木镇、代钦塔拉苏木、新佳木苏木、哈日诺尔苏木、额木庭高勒苏木、巴彦茫哈苏木、巴彦淖尔苏木',
        },
        {
          扎赉特旗:
            '小城子乡、浩饶山乡、好力保乡、努文木仁乡、巴彦扎拉嘎乡、巴岱乡、二龙山乡、五家户乡、罕达罕乡、巴彦高勒镇、图牧吉镇、新林镇、胡尔勒镇、音德尔镇、图木吉镇、巴达尔胡镇、阿尔本格勒镇、绰勒镇、巴彦乌兰苏木、阿拉坦花苏木、都尔本新苏木',
        },
        {
          突泉县:
            '突泉镇、六户镇、东杜尔基镇、永安镇、水泉镇、宝石镇、溪柳乡、太东乡、学田乡、九龙乡、太平乡、太和乡',
        },
      ],
      锡林郭勒盟: [
        {
          锡林浩特市:
            '朝克乌拉苏木、宝力根苏木、巴彦宝拉格苏木、巴彦锡勒办事处、阿尔善宝拉格镇、希日塔拉街道、宝力根街道、杭盖街道、楚古兰街道、额尔敦街道、南郊街道、巴彦查干街道',
        },
        { 二连浩特市: '乌兰街道、锡林街道、东城街道、格日勒敖都苏木' },
        {
          阿巴嘎旗:
            '别力古台镇、查干淖尔镇、洪格尔高勒镇、那仁宝拉格苏木、吉尔嘎朗图苏木、伊和高勒苏木',
        },
        {
          苏尼特左旗:
            '满都拉图镇、查干敖包镇、巴彦淖尔镇、巴彦乌拉苏木、赛罕高毕苏木、洪格尔苏木、达来苏木',
        },
        {
          苏尼特右旗:
            '赛汉塔拉镇、朱日和镇、乌日根塔拉镇和额仁淖尔苏木、桑宝拉格苏木、赛罕乌力吉苏木',
        },
        {
          东乌珠穆沁旗:
            '乌里雅斯太镇、道特淖尔镇、嘎达布其镇、额吉淖尔镇、满都胡宝拉格镇、萨麦苏木、呼热图淖尔苏木、嘎海乐苏木、阿拉坦合力苏木',
        },
        {
          西乌珠穆沁旗:
            '巴拉嘎尔高勒镇、巴彦花镇、浩勒图高勒镇、吉仁高勒镇、高日罕镇、巴彦胡舒苏木、乌兰哈拉嘎苏木、西乌珠穆沁旗林业总场',
        },
        {
          太仆寺旗:
            '宝昌镇、红旗镇、千斤沟镇、永丰镇、骆驼山镇、幸福乡、贡宝拉格苏木',
        },
        { 镶黄旗: '镶黄旗' },
        {
          正镶白旗:
            '明安图镇、星耀镇、伊和淖尔苏木、乌兰查布苏木、宝拉根陶海苏木',
        },
        {
          正蓝旗:
            '上都镇、桑根达来镇、哈毕日嘎镇和宝绍代苏木、那日图苏木、赛音呼都嘎苏木',
        },
        { 多伦县: '多伦诺尔镇、大北沟镇、西干沟乡、大河口乡、蔡木山乡' },
      ],
      阿拉善盟: [
        {
          阿拉善左旗:
            '巴彦浩特额鲁特街道、巴彦浩特南环路街道、巴彦浩特新华街道、巴彦浩特王府街道、温都尔勒图镇、乌斯太镇、巴润别立镇、巴彦浩特镇、嘉尔格勒赛汉镇、吉兰泰镇、宗别立镇、敖伦布拉格镇、腾格里额里斯镇、巴彦木仁苏木、乌力吉苏木、巴彦诺日公苏木、额尔克哈什哈苏木、银根苏木、超格图呼热苏木',
        },
        {
          阿拉善右旗:
            '巴丹吉林镇、雅布赖镇、阿拉腾敖包镇、曼德拉苏木、阿拉腾朝克苏木、巴音高勒苏木、塔木素布拉格苏木',
        },
        {
          额济纳旗:
            '达来呼布镇、东风镇、哈日布日格德音乌拉镇、赛汉陶来苏木、马鬃山苏木、苏泊淖尔苏木',
        },
      ],
    },
  ],
  广西壮族自治区: [
    {
      南宁市: [
        {
          青秀区:
            '中山街道、建政街道、南湖街道、新竹街道、津头街道、长塘镇、伶俐镇、刘圩镇、南阳镇',
        },
        { 兴宁区: '民生街道、朝阳街道、三塘镇、五塘镇、昆仑镇' },
        {
          西乡塘区:
            '上尧街道、新阳街道、华强街道、北湖街道、衡阳街道、西乡塘街道、石埠街道、安吉街道、安宁街道、心圩街道、金陵镇、双定镇、坛洛镇',
        },
        {
          江南区:
            '福建园街道、江南街道、沙井街道、那洪镇、吴圩镇、苏圩镇、江西镇',
        },
        {
          良庆区:
            '南宁市大沙田经济开发区、良庆镇、大塘镇、南晓镇、那马镇、那陈镇',
        },
        { 邕宁区: '蒲庙镇、新江镇、那楼镇、百济乡、中和乡' },
        {
          武鸣区:
            '城厢镇、双桥镇、罗波镇、两江镇、马头镇、陆斡镇、太平镇、甘圩镇、宁武镇、锣圩镇、仙湖镇、府城镇、灵马镇、武鸣区伊岭经济技术开发区',
        },
        {
          隆安县:
            '城厢镇 、南圩镇 、雁江镇 、那桐镇、乔建镇、丁当镇、古潭乡 、都结乡 、布泉乡、屏山乡 、隆安华侨管理区 ',
        },
        {
          马山县:
            '白山镇、百龙滩镇、古零镇、金钗镇、周鹿镇、林圩镇、永州镇、古寨瑶族乡、加方乡、里当瑶族乡、乔利乡',
        },
        {
          上林县:
            '大丰镇、明亮镇、巷贤镇、白圩镇、三里镇、乔贤镇、西燕镇、澄泰乡、塘红乡、木山乡、镇圩瑶族乡',
        },
        {
          宾阳县:
            '宾州镇、黎塘镇、新桥镇、新圩镇、大桥镇、邹圩镇、甘棠镇、古辣镇、露圩镇、洋桥镇、王灵镇、武陵镇、中华镇、思陇镇、和吉镇、陈平乡',
        },
        {
          横县: '横州镇、百合镇、那阳镇、南乡镇、新福镇、莲塘镇、平马镇、峦城镇、六景镇、石塘镇、陶圩镇、校椅镇、云表镇、马岭镇、马山乡、平朗乡、镇龙乡',
        },
      ],
      柳州市: [
        {
          柳北区:
            '解放街道、雅儒街道、胜利街道、雀儿山街道、钢城街道、锦绣街道、白露街道、柳长街道、石碑坪镇、沙塘镇、长塘镇',
        },
        { 柳南区: '柳南街道、南站街道、柳石街道、鹅山街道、河西街道' },
        {
          城中区:
            '公园街道、城中街道、水上街道、中南街道、潭中街道、河东街道、静兰街道',
        },
        {
          鱼峰区:
            '天马街道、驾鹤街道、箭盘街道、五里亭街道、荣军街道、阳和街道、白莲街道、麒麟街道、雒容镇、洛埠镇',
        },
        {
          柳城县:
            '大埔镇、龙头镇、太平镇、沙埔镇、东泉镇、凤山镇、六塘镇、冲脉镇、寨隆镇、社冲乡、马山乡、古砦乡',
        },
        {
          柳江县:
            '里雍乡、百朋乡、成团乡、洛满乡、福塘乡、三都乡、进德乡、穿山乡、土博乡、拉堡镇 ',
        },
        {
          鹿寨县:
            '鹿寨镇、中渡镇、寨沙镇、平山镇、黄冕乡、江口乡、导江乡、拉沟乡、四排乡',
        },
        {
          融安县:
            '长安镇、浮石镇、泗顶镇、板榄镇、大将镇、大良镇、雅瑶乡、大坡乡、东起乡、沙子乡、桥板乡、潭头乡',
        },
        {
          融水苗族自治县:
            '融水镇、和睦镇、三防镇、怀宝镇、大浪镇、洞头镇、大年乡、良寨乡、拱洞乡、红水乡、白云乡、永乐乡、同练瑶族乡、汪洞乡、杆洞乡、滚贝侗族乡、香粉乡、四荣乡、安太乡、安陲乡',
        },
        {
          三江侗族自治县:
            '古宜镇、丹洲镇、斗江镇、和平乡、独峒乡、八江乡、林溪乡、程村乡、老堡乡、良口乡、洋溪乡、梅林乡、同乐苗族乡、富禄苗族乡、高基瑶族乡',
        },
      ],
      桂林市: [
        { 象山区: '南门街道、象山街道、平山街道、二塘乡' },
        { 秀峰区: '秀峰街道、丽君街道、甲山街道' },
        { 叠彩区: '叠彩街道、北门街道、大河乡' },
        { 七星区: '七星街道、东江街道、穿山街道、朝阳乡' },
        { 雁山区: '雁山街道、柘木镇、雁山镇、大埠乡、草坪回族乡' },
        {
          临桂区:
            '临桂镇、四塘镇、五通镇、中庸镇、两江镇、宛田瑶族乡、茶洞镇、黄沙瑶族乡、六塘镇、南边山镇、会仙镇 ',
        },
        {
          阳朔县:
            '阳朔镇、白沙镇、福利镇、兴坪镇、葡萄镇、高田镇、金宝乡、普益乡、杨堤乡',
        },
        {
          灵川县:
            '灵川镇、大圩镇、定江镇、三街镇、潭下镇、九屋镇、潮田乡、大境瑶族乡、海洋乡、灵田镇、兰田瑶族乡、公平乡',
        },
        {
          全州县:
            '全州镇、黄沙河镇、庙头镇、文桥镇、大西江镇、龙水镇、才湾镇、绍水镇、石塘镇、永岁乡、枧塘乡、咸水乡、凤凰乡、蕉江瑶族乡、安和乡、两河乡、白宝乡、东山瑶族乡',
        },
        {
          平乐县:
            '平乐镇、沙子镇、二塘镇、张家镇、同安镇、源头镇、阳安乡、青龙乡、桥亭乡、大发瑶族自治乡',
        },
        {
          兴安县:
            '兴安镇、湘漓镇、界首镇、高尚镇、严关镇、溶江镇、漠川乡、白石乡、崔家乡、华江瑶族乡',
        },
        {
          灌阳县:
            '灌阳镇、黄关镇、文市镇、洞井瑶族乡、观音阁乡、西山瑶族乡、新街乡、新圩乡、水车乡',
        },
        {
          荔浦县:
            '荔城镇、修仁镇、马岭镇、青山镇、杜莫镇、新坪镇、东昌镇、大塘镇、花篢镇、双江镇、蒲芦镇、龙怀瑶族乡、茶城瑶族乡',
        },
        {
          资源县:
            '资源镇、中峰乡、梅溪乡、瓜里乡、车田苗族乡、两水苗族乡、河口瑶族乡',
        },
        {
          永福县:
            '百寿镇、堡里乡、广福乡、罗锦镇、苏桥镇、龙江乡、永福镇、永安乡、三皇乡',
        },
        {
          龙胜各族自治县:
            '龙胜镇、瓢里镇、三门镇、龙脊镇、泗水乡、江底乡、马堤乡、伟江乡、平等镇、乐江乡',
        },
        {
          恭城瑶族自治县:
            '恭城镇、栗木镇、莲花镇、平安乡、三江乡、嘉会乡、西岭乡、观音乡、龙虎乡',
        },
      ],
      梧州市: [
        { 长洲区: '大塘街道、兴龙街道、长洲镇、大塘街道、兴龙街道' },
        {
          万秀区:
            '城东镇、龙湖镇、城东街道、城南街道、城中街道、城北街道、角嘴街道、东兴街道、富民街道',
        },
        { 龙圩区: '龙圩镇、大坡镇、广平镇、新地镇' },
        {
          岑溪市:
            '岑城镇、糯垌镇、诚谏镇、归义镇、筋竹镇、大业镇、梨木镇、大隆镇、南渡镇、马路镇、三堡镇、波塘镇、安平镇、水汶镇',
        },
        {
          苍梧县:
            '石桥镇、岭脚镇、京南镇、狮寨镇、旺甫镇、夏郢镇、倒水镇、六堡镇、梨埠镇、木双镇、沙头镇、永安乡、三皇乡',
        },
        {
          蒙山县:
            '新圩镇、蒙山镇、文圩镇、陈塘镇、西河镇、黄村镇、汉豪乡、长坪瑶族乡、夏宜瑶族乡',
        },
        {
          藤县: '藤州镇、塘步镇、琅南镇、同心镇、金鸡镇、新庆镇、象棋镇、岭景镇、天平镇、蒙江镇、和平镇、太平镇、古龙镇、东荣镇、大黎镇、平福乡、宁康乡',
        },
      ],
      北海市: [
        {
          海城区:
            '涠洲镇、东街街道、中街街道、西街街道、海角街道、地角街道、驿马街道、高德街道',
        },
        { 银海区: '福成镇、银滩镇、平阳镇、侨港镇' },
        { 铁山港区: '南康镇、营盘镇、兴港镇' },
        {
          合浦县:
            '廉州镇、党江镇、西场镇、沙岗镇、乌家镇、闸口镇、公馆镇、白沙镇、山口镇、沙田镇、石湾镇、石康镇、常乐镇、曲樟乡、星岛湖乡',
        },
      ],
      防城港市: [
        {
          港口区:
            '渔洲坪街道、白沙万街道、企沙镇、光坡镇、公车镇、地角街道、驿马街道、高德街道',
        },
        {
          防城区:
            '水营街道、文昌街道、珠河街道、防城镇、大菉镇、华石镇、那梭镇、那良镇、峒中镇、茅岭乡、扶隆乡、滩营乡、江山乡',
        },
        { 东兴市: '东兴镇、江平镇、马路镇' },
        {
          上思县:
            '思阳镇、在妙镇、思阳乡、七门乡、平福乡、华兰乡、叫安乡、公正乡、东屏乡、那琴乡、南屏瑶族乡',
        },
      ],
      钦州市: [
        {
          钦南区:
            '向阳街道、水东街道、文峰街道、南珠街道、沙埠镇、康熙岭镇、黄屋屯镇、尖山镇、大番坡镇、龙门港镇、久隆镇、东场镇、那丽镇、那彭镇、那思镇',
        },
        {
          钦北区:
            '大寺镇、小董镇、大垌镇、平吉镇、青塘镇、板城镇、那蒙镇、长滩镇、新棠镇、贵台镇、大直镇',
        },
        {
          灵山县:
            '灵城镇、新圩镇、丰塘镇、平山镇、石塘镇、佛子镇、平南镇、烟墩镇、檀圩镇、那隆镇、三隆镇、陆屋镇、旧州镇、太平镇、沙坪镇、武利镇、文利镇、伯劳镇',
        },
        {
          浦北县:
            '小江街道、江城街道、泉水镇、石埇镇、安石镇、张黄镇、大成镇、白石水镇、北通镇、三合镇、龙门镇、福旺镇、寨圩镇、乐民镇、六硍镇、平睦镇、官垌镇',
        },
      ],
      贵港市: [
        {
          港北区:
            '贵城街道、港城镇、大圩镇、庆丰镇、奇石乡、中里乡、根竹乡、武乐乡',
        },
        {
          港南区:
            '江南街道、桥圩镇、木格镇、木梓镇、湛江镇、东津镇、八塘镇、新塘乡、瓦塘乡',
        },
        {
          覃塘区:
            '覃塘镇、东龙镇、三里镇、黄练镇、石卡镇、五里镇、山北乡、樟木乡、蒙公乡、大岭乡',
        },
        {
          桂平市:
            '木圭镇、木乐镇、石咀镇、马皮乡、油麻镇、社坡镇、寻旺乡、罗秀镇、麻垌镇、社步镇、下湾镇、罗播乡、木根镇、中沙镇、大湾镇、大洋镇、白沙镇、厚禄乡、石龙镇、蒙圩镇、西山镇、南木镇、金田镇、江口镇、紫荆镇、垌心乡',
        },
        {
          平南县:
            '平南镇、平山镇、大坡镇、寺面镇、大洲镇、六陈镇、大新镇、大安镇、武林镇、镇隆镇、上渡镇、安怀镇、思旺镇、大鹏镇、官成镇、同和镇、丹竹镇、东华乡、思界乡、马练瑶族乡、国安瑶族乡',
        },
      ],
      玉林市: [
        {
          玉州区:
            '玉城街道、大塘镇、南江街道、城西街道、城北街道、名山街道、茂林镇、仁东镇、福绵镇、成均镇、樟木镇、新桥镇、沙田镇、石和镇、仁厚镇',
        },
        { 福绵区: '福绵镇、成均镇、樟木镇、新桥镇、沙田镇、石和镇' },
        {
          北流市:
            '平政镇、白马镇、扶新镇、大伦镇、六靖镇、石窝镇、清湾镇、沙垌镇、新荣镇、民安镇、民乐镇、山围镇、西埌镇、新圩镇、大里镇、北流镇、塘岸镇、清水口镇、隆盛镇、大坡外镇、六麻镇、新丰镇、城南街道、城北街道、陵城街道',
        },
        {
          容县: '容州镇、石寨镇、杨梅镇、黎村镇、容西镇、六王镇、县底镇、自良镇、十里镇、浪水镇、杨村镇、灵山镇、松山镇、罗江镇、石头镇',
        },
        {
          陆川县:
            '温泉镇、米场镇、马坡镇、珊罗镇、平乐镇、沙坡镇、大桥镇、乌石镇、良田镇、清湖镇、古城镇、沙湖乡、横山乡、滩面乡',
        },
        {
          博白县:
            '博白镇、水鸣镇、顿谷镇、那林镇、江宁镇、三滩镇、黄凌镇、亚山镇、旺茂镇、凤山镇、新田镇、宁潭镇、文地镇、英桥镇、大垌镇、那卜镇、沙陂镇、东平镇、沙河镇、菱角镇、松旺镇、双旺镇、龙潭镇、大坝镇、双凤镇、浪平乡、径口镇、永安镇',
        },
        {
          兴业县:
            '蒲塘镇、卖酒镇、小平山镇、北市镇、洛阳镇、高峰镇、沙塘镇、山心镇、城隍镇、葵阳镇、石南镇、大平山镇、龙安镇',
        },
      ],
      百色市: [
        {
          右江区:
            '百城街道、阳圩镇、四塘镇、龙川镇、龙景街道、永乐镇、汪甸瑶族乡、大楞乡、泮水乡',
        },
        {
          靖西市:
            '新靖镇、化峒镇、湖润镇、安德镇、龙临镇、渠洋镇、岳圩镇、龙邦镇、同德乡、壬庄乡、安宁乡、地州乡、禄峒乡、南坡乡、吞盘乡、果乐乡、新甲乡、武平乡、魁圩乡',
        },
        {
          田阳县:
            '田州镇、那坡镇、坡洪镇、那满镇、百育镇、玉凤镇、头塘镇、洞靖乡、巴别乡、五村乡、坤平乡',
        },
        {
          田东县:
            '平马镇、祥周镇、林逢镇、思林镇、作登瑶族乡、印茶镇、朔良镇、义圩镇、那拔镇',
        },
        {
          平果县:
            '马头镇、新安镇、果化镇、太平镇、坡造镇、四塘镇、旧城镇、榜圩镇、凤梧镇、海城乡、黎明乡、同老乡',
        },
        {
          德保县:
            '城关镇、隆桑镇、敬德镇、足荣镇、都安乡、马隘镇、那甲乡、荣华乡、燕峒乡、龙光乡、巴头乡、东凌乡',
        },
        {
          那坡县:
            '城厢镇、平孟镇、坡荷乡、龙合乡、定业乡、德隆乡、百合乡、百南乡、下华乡、百省乡、百都乡、那隆乡',
        },
        {
          凌云县:
            '泗城镇、逻楼镇、加尤镇、朝里瑶族乡、伶站瑶族乡、沙里瑶族乡、玉洪瑶族乡',
        },
        {
          乐业县:
            '同乐镇、甘田镇、武称乡、逻沙乡、新化镇、马庄乡、逻西乡、幼平乡、花坪镇、雅长乡',
        },
        {
          田林县:
            '乐里镇、旧州镇、定安镇、利周瑶族乡、浪平乡、潞城瑶族乡、百乐乡、平塘乡、高龙乡、者苗乡、八渡瑶族乡、八桂瑶族乡、能良乡、那比乡',
        },
        {
          西林县:
            '八达镇、古障镇、八大河乡、马蚌乡、者夯乡、普合苗族乡、西平乡、弄汪乡、那佐苗族乡、那劳乡、足别瑶族苗族乡',
        },
        {
          隆林各族自治县:
            '新州镇、桠杈镇、天生桥镇、平班镇、德峨镇、沙梨乡、隆或乡、者保乡、者浪乡、革步乡、金钟山乡、猪场乡、蛇场乡、克长乡、岩茶乡、介廷乡',
        },
      ],
      贺州市: [
        {
          八步区:
            '八步街道、城东街道、江南街道、贺街镇、步头镇、莲塘镇、大宁镇、南乡镇、桂岭镇、开山镇、信都镇、黄洞瑶族乡、里松镇、仁义镇、铺门镇',
        },
        {
          平桂管理区:
            '西湾街道、黄田镇、鹅塘镇、沙田镇、公会镇、大平瑶族乡、水口镇、望高镇、羊头镇',
        },
        {
          昭平县:
            '昭平镇、文竹镇、黄姚镇、富罗镇、北陀镇、马江镇、五将镇、走马乡、樟木林乡、凤凰乡、木格乡、仙回瑶族乡',
        },
        {
          钟山县:
            '钟山镇、回龙镇、石龙镇、凤翔镇、珊瑚镇、同古镇、公安镇、燕塘镇、清塘镇、红花镇、花山瑶族乡、两安瑶族乡',
        },
        {
          富川瑶族自治县:
            '富阳镇、白沙镇、莲山镇、古城镇、福利镇、麦岭镇、葛坡镇、城北镇、朝东镇、新华乡、石家乡、柳家乡',
        },
      ],
      河池市: [
        {
          金城江区:
            '金城江街道、东江镇、六圩镇、六甲镇、河池镇、拔贡镇、九圩镇、五圩镇、白土乡、侧岭乡、保平乡、长老乡',
        },
        {
          宜州市:
            '庆远镇、三岔镇、洛西镇、怀远镇、德胜镇、石别镇、北山镇、祥贝乡、刘三姐乡、屏南乡、洛东乡、福龙瑶族乡、北牙瑶族乡、同德乡、安马乡、龙头乡',
        },
        {
          南丹县:
            '城关镇、大厂镇、车河镇、芒场镇、六寨镇、月里镇、吾隘镇、罗富乡、中堡苗族乡、八圩瑶族乡、里湖瑶族乡',
        },
        {
          天峨县:
            '六排镇、向阳镇、岜暮乡、八腊瑶族乡、老鹏乡、纳直乡、燕来乡、更新乡、下老乡、坡结乡、三堡乡',
        },
        {
          凤山县:
            '凤城镇、袍里乡、砦牙乡、长洲乡、乔音乡、林峒乡、金牙瑶族乡、更沙乡、中亭乡、平乐瑶族乡、江洲瑶族乡',
        },
        {
          东兰县:
            '东兰镇、隘洞镇、长乐镇、三石镇、武篆镇、泗孟乡、兰木乡、长江乡、巴畴乡、金谷乡、三弄瑶族乡、大同乡、花香乡、切学乡',
        },
        {
          巴马瑶族自治县:
            '巴马镇、燕洞乡、甲篆乡、那社乡、所略乡、西山乡、东山乡、凤凰乡、百林乡、那桃乡',
        },
        {
          都安瑶族自治县:
            '安阳镇、高岭镇、澄江乡、古山乡、地苏乡、三弄乡、东庙乡、五竹乡、大兴乡、下坳乡、隆福乡、保安乡、板岭乡、永安乡、三只羊乡、龙湾乡、菁盛乡、拉烈乡、百旺乡、加贵乡、拉仁乡、九渡乡',
        },
        {
          大化瑶族自治县:
            '大化镇、都阳镇、岩滩镇、共和乡、贡川乡、百马乡、古河乡、古文乡、江南乡、羌圩乡、乙圩乡、北景乡、板升乡、七百弄乡、雅龙乡、六也乡',
        },
        {
          罗城仫佬族自治县:
            '东门镇、龙岸镇、黄金镇、小长安镇、四把镇、天河镇、怀群镇、兼爱乡、乔善乡、宝坛乡、纳翁乡',
        },
        {
          环江毛南族自治县:
            '思恩镇、水源镇、洛阳镇、川山镇、明伦镇、东兴镇、大才乡、下南乡、大安乡、长美乡、龙岩乡、驯乐苗族乡',
        },
      ],
      来宾市: [
        {
          兴宾区:
            '城北街道、城东街道、河西街道、八一街道、城厢镇、凤凰镇、大湾镇、蒙村镇、寺山镇、良江镇、桥巩镇、迁江镇、小平阳镇、平阳镇、石陵镇；良塘乡、七洞乡、正龙乡、高安乡、南泗乡、石牙乡、三五乡、五山乡、陶邓乡',
        },
        { 合山市: '岭南镇、北泗镇、河里镇' },
        {
          象州县:
            '象州镇、运江镇、寺村镇、罗秀镇、石龙镇、大乐镇、中平镇、马坪镇、水晶乡、百丈乡、妙皇乡',
        },
        {
          武宣县:
            '武宣镇、三里镇、东乡镇、桐岭镇、通挽镇、禄新镇、二塘镇、黄茆镇、思灵乡、金鸡乡',
        },
        {
          忻城县:
            '城关镇、思练镇、大塘镇、马泗乡、红渡镇、北更乡、遂意乡、果遂乡、古蓬镇、欧洞乡、安东乡、新圩乡',
        },
        {
          金秀瑶族自治县:
            '金秀镇、桐木镇、头排镇、三角乡、忠良乡、罗香乡、长垌乡、大樟乡、六巷乡、三江乡',
        },
      ],
      崇左市: [
        {
          江州区:
            '太平镇、新和镇、驮卢镇、濑湍镇、江州镇、左州镇、那隆镇、罗白乡、板利乡',
        },
        { 凭祥市: '凭祥镇、友谊镇、夏石镇、上石镇' },
        {
          扶绥县:
            '渠黎镇、渠旧镇、柳桥镇、东门镇、山圩镇、中东镇、东罗镇、新宁镇、龙头乡、岜盆乡、昌平乡',
        },
        {
          宁明县:
            '城中镇、爱店镇、明江镇、海渊镇、亭亮乡、寨安乡、峙浪乡、东安乡、板棍乡、北江乡、桐棉乡、那堪乡、那楠乡',
        },
        {
          龙州县:
            '龙州镇、下冻镇、水口镇、金龙镇、响水镇、八角乡、上降乡、彬桥乡、上龙乡、武德乡、逐卜乡、上金乡',
        },
        {
          大新县:
            '桃城镇、全茗镇、雷平镇、硕龙镇、下雷镇、五山乡、龙门乡、昌明乡、福隆乡、那岭乡、恩城乡、榄圩乡、宝圩乡、堪圩乡、土湖乡',
        },
        {
          天等县:
            '天等镇、向都镇、龙茗镇、进结镇、把荷乡、东平乡、都康乡、驮堪乡、福新乡、华隆乡、金洞乡、进远乡、宁干乡、上映乡、小山乡',
        },
      ],
    },
  ],
  宁夏回族自治区: [
    {
      银川市: [
        {
          兴庆区:
            '凤凰北街街道、解放西街街道、文化街街道、富宁街街道、新华街街道、玉皇阁北街街道、前进街街道、中山南街街道、银古路街道、胜利街街道、丽景街街道、掌政镇、大新镇、通贵乡、月牙湖乡',
        },
        {
          西夏区:
            '西花园路街道、北京西路街道、文昌路街道、朔方路街道、宁华路街道、贺兰山西路街道、兴泾镇、镇北堡镇',
        },
        {
          金凤区:
            '上海西路街道、北京中路街道、长城中路街道、黄河东路街道、满城北街街道、丰登镇、良田镇',
        },
        { 永宁县: '杨和街道、杨和镇、李俊镇、望远镇、望洪镇、闽宁镇、胜利乡' },
        { 贺兰县: '习岗街道、金贵镇、立岗镇、洪广镇、习岗镇、常信乡' },
        {
          灵武市:
            '城区街道、东塔镇、郝家桥镇、崇兴镇、宁东镇、马家滩镇、临河镇、梧桐树乡、白土岗乡',
        },
      ],
      石嘴山市: [
        {
          大武口区:
            '长胜街道、朝阳街道、人民路街道、长城街道、青山街道、石炭井街道、白芨沟街道、沟口街道、长兴街道、锦林街道、星海镇、隆湖经济开发区',
        },
        {
          惠农区:
            '北街街道、南街街道、中街街道、育才路街道、河滨街街道、火车站街道、红果子镇、尾闸镇、园艺镇、庙台乡、礼和乡、燕子墩乡',
        },
        {
          平罗县:
            '城关镇、黄渠桥镇、宝丰镇、头闸镇、姚伏镇、崇岗镇、陶乐镇、高庄乡、灵沙乡、渠口乡、通伏乡、高仁乡、红崖子乡',
        },
      ],
      吴忠市: [
        {
          利通区:
            '上桥镇、古城镇、金星镇、胜利镇、金积镇、金银滩镇、高闸镇、扁担沟镇、东塔寺乡、板桥乡、马莲渠乡、郭家桥乡、孙家滩管委会、太阳山开发区',
        },
        { 红寺堡区: '新民街道、红寺堡镇、太阳山镇、南川乡、大河乡、沙泉乡' },
        {
          盐池县:
            '花马池镇、大水坑镇、惠安堡镇、高沙窝镇、王乐井乡、冯记沟乡、青山乡、麻黄山乡',
        },
        {
          同心县:
            '豫海镇河西镇、丁塘镇、韦州镇、下马关镇、预旺镇 、王团镇、田老庄乡、马高庄乡、张家塬乡、兴隆乡、石狮开发区管委会、 窑山管委会',
        },
        {
          青铜峡市:
            '小坝镇、大坝镇、青铜峡镇、叶盛镇、瞿靖镇、峡口镇、邵刚镇、陈袁滩镇、裕民街道',
        },
      ],
      固原市: [
        {
          原州区:
            '北塬街道、古雁街道、南关街道、三营镇、开城镇、张易镇、彭堡镇、头营镇、官厅镇、黄铎堡镇、中河乡、河川乡、炭山乡、寨科乡',
        },
        {
          西吉县:
            '吉强镇、兴隆镇、平峰镇 、新营乡、红耀乡、田坪乡、马建乡、震湖乡、兴平乡、西滩乡、王民乡、什字乡、马莲乡、将台乡、硝河乡、偏城乡、沙沟乡、白崖乡、火石寨乡',
        },
        {
          隆德县:
            '城关镇、沙塘镇、联财镇、观庄乡、山河乡、凤岭乡、杨河乡、好水乡、陈靳乡、神林乡、张程乡、奠安乡、温堡乡',
        },
        {
          泾源县: '香水镇、泾河源镇、六盘山镇、新民乡、兴盛乡、黄花乡、大湾乡',
        },
        {
          彭阳县:
            '白阳镇、古城镇、王洼镇、新集乡、红河镇、城阳乡、孟塬乡、草庙乡、冯庄乡、小岔乡、罗洼乡、交岔乡',
        },
      ],
      中卫市: [
        {
          沙坡头区:
            '文昌镇、滨河镇、迎水桥镇、柔远镇、东园镇、宣和镇、永康镇、长乐镇、香山乡、蒿川乡、兴仁镇、镇罗镇',
        },
        {
          中宁县:
            '宁安镇、鸣沙镇、石空镇、新堡镇、恩和镇、大战场镇、白马乡、徐套乡、舟塔乡、余丁乡、喊叫水乡',
        },
        {
          海原县:
            '海城镇、李旺镇、西安镇、七营镇、史店乡、树台乡、关桥乡、高崖乡、郑旗乡、贾塘乡、曹洼乡、九彩乡、李俊乡、红羊乡、关庄乡、甘城乡',
        },
      ],
    },
  ],
  新疆维吾尔自治区: [
    {
      乌鲁木齐市: [
        {
          天山区:
            '燕儿窝街道、胜利路街道、团结路街道、解放南路街道、新华南路街道、和平路街道、解放北路街道、幸福路街道、东门街道、新华北路街道、青年路街道、碱泉街道、延安路街道、红雁街道',
        },
        {
          沙依巴克区:
            '长江路街道、和田街街道、扬子江路街道、友好南路街道、友好北路街道、炉院街街道、西山街道、雅玛里克山街道、红庙子街道、八一街道、沙区直辖村',
        },
        {
          新市区:
            '北京路街道、二工街道、三工街道、石油新村街道、迎宾路街道、喀什东路街道、北站街道、天津路街道、银川路街道、南纬路街道、杭州路街道、二工乡、地窝堡乡、乌鲁木齐经济技术开发区、乌鲁木齐高新技术产业开发区',
        },
        {
          水磨沟区:
            '水磨沟街道、六道湾街道、苇湖梁街道、八道湾街道、新民路街道、南湖南路街道、南湖北路街道、七道湾街道',
        },
        {
          头屯河区:
            '头屯河街道、火车西站街道、王家沟街道、乌昌路街道、北站西路街道、河南庄村',
        },
        {
          达坂城区:
            '艾维尔沟街道、星火街道、乌拉泊街道、鱼儿沟街道、达坂城镇、东沟乡、西沟乡、阿克苏乡',
        },
        {
          米东区:
            '石化街道、地磅街道、卡子湾街道、古牧地东路街道、古牧地西路街道、友好路街道、古牧地镇、铁厂沟镇、长山子镇、羊毛工镇、三道坝镇、柏杨河乡、芦草沟乡',
        },
        {
          乌鲁木齐县: '水西沟镇、萨尔达坂乡、甘沟乡、永丰乡、板房沟乡、托里乡',
        },
      ],
      克拉玛依市: [
        {
          克拉玛依区:
            '天山路街道、胜利路街道、银河路街道、昆仑路街道、金龙镇街道、五五新镇街道、小拐乡',
        },
        { 独山子区: '金山路街道、西宁路街道、新北区街道' },
        { 白碱滩区: '中兴路街道、三平路街道' },
        { 乌尔禾区: '柳树街街道、乌尔禾镇' },
      ],
      吐鲁番市: [
        {
          高昌区:
            '老城路街道、高昌路街道、葡萄沟管委会街道、七泉湖镇、大河沿镇、亚尔乡、艾丁湖乡、葡萄乡、恰特卡勒乡、二堡乡、三堡乡、胜金乡',
        },
        {
          鄯善县:
            '鄯善镇、七克台镇、鄯善火车站镇、连木沁镇、鲁克沁镇、辟展乡、东巴扎回族乡、吐峪沟乡、达朗坎乡、迪坎乡',
        },
        {
          托克逊县:
            '夏乡、伊拉湖乡、郭勒布依乡、博斯坦乡、托克逊镇、库米什镇、克尔碱镇、阿乐惠镇',
        },
      ],
      哈密地区: [
        {
          哈密市:
            '东河区街道、西河区街道、新市区街道、丽园街道、石油新城街道、雅满苏镇、七角井镇、星星峡镇、二堡镇、陶家宫镇、五堡镇、沁城乡、双井子乡、大泉湾乡、回城乡、花园乡、南湖乡、西山乡、天山乡、白石头乡、柳树沟乡、德外里都如克哈萨克民族乡、乌拉台哈萨克民族乡',
        },
        {
          伊吾县:
            '伊吾镇、淖毛湖镇、苇子峡乡、下马崖乡、盐池乡、吐葫芦乡、前山哈萨克族乡',
        },
        {
          巴里坤哈萨克自治县:
            '巴里坤镇、博尔羌吉镇、大河镇、奎苏镇、萨尔乔克乡、海子沿乡、下涝坝乡、石人子乡、花园乡、三塘湖乡、大红柳峡乡、八墙子乡、黄土场开发区',
        },
      ],
      昌吉回族自治州: [
        {
          昌吉市:
            '宁边路街道、延安北路街道、北京南路街道、建国路街道、中山路街道、绿洲路街道、硫磺沟镇、三工镇、榆树沟镇、二六工镇、大西渠镇 、六工镇、滨湖镇、佃坝镇、阿什里哈萨克族乡、庙尔沟乡',
        },
        {
          阜康市:
            '博峰街街道、阜新街街道、准东街道、甘河子镇、城关镇、九运街镇、滋泥泉子镇、三工河哈萨克族乡、上户沟哈萨克族乡、水磨沟乡',
        },
        {
          呼图壁县:
            '呼图壁镇、大丰镇、雀尔沟镇、二十里店镇、园户村镇、五工台镇、石梯子哈萨克族乡、独山子哈萨克族乡',
        },
        {
          玛纳斯县:
            '玛纳斯镇、乐土驿镇、包家店镇、凉州户镇、北五岔镇、六户地镇、兰州湾镇、广东地乡、清水河哈萨克族乡、塔西河哈萨克族乡、旱卡子滩哈萨克族乡',
        },
        {
          奇台县:
            '奇台镇、老奇台镇、吉布库镇、东湾镇、半截沟镇、西地镇、碧流河乡、西北湾乡、五马场乡、坎尔孜乡、乔仁乡、三个庄子乡、古城乡、塔塔尔乡、七户乡',
        },
        {
          吉木萨尔县:
            '吉木萨尔镇、三台镇、泉子街镇、北庭镇、二工乡、庆阳湖乡、老台乡、大有乡、新地乡',
        },
        {
          木垒哈萨克自治县:
            '木垒镇、西吉尔镇、东城镇、英格堡乡、照壁山乡、新户乡、雀仁乡、白杨河乡、大石头乡、博斯坦乡、大南沟乌孜别克族乡',
        },
      ],
      博尔塔拉蒙古自治州: [
        {
          博乐市:
            '青得里街道、顾力木图街道、克尔根卓街道、三台街道、小营盘镇、达勒特镇、乌图布拉格镇、青得里乡、贝林哈日莫墩乡、阿拉山口口岸行政管理区',
        },
        { 精河县: '阿拉套街道、艾比湖镇' },
        { 吉木萨尔县: '精河镇、大河沿子镇、茫丁乡、托里乡、托托乡' },
        {
          温泉县:
            '博格达尔镇、哈日布呼镇、安格里格乡、查干屯格乡、扎勒木特乡、塔秀乡',
        },
      ],
      巴音郭楞蒙古自治州: [
        {
          库尔勒市:
            '萨依巴格街道、天山街道、团结街道、新城街道、建设街道、塔什店镇、上户镇、铁克其乡、英下乡、恰尔巴格乡、兰干乡、托布力其乡、和什力克乡、阿瓦提乡、哈拉玉宫乡、普惠乡',
        },
        {
          轮台县:
            '轮台镇、轮南镇、哈尔巴克乡、群巴克镇、阿克萨来乡、塔尔拉克乡、草湖乡、阳霞镇、铁热克巴扎乡、策大雅乡、野云沟乡',
        },
        {
          尉犁县:
            '尉犁镇、塔里木乡、兴平乡、团结乡、墩阔坦乡、喀尔曲尕乡、阿克苏甫乡、古勒巴格乡、肖唐管委会',
        },
        {
          若羌县:
            '若羌镇、依吞布拉克镇、罗布泊镇、铁干里克乡、吾塔木乡、瓦石峡镇、铁木里克乡、祁曼塔格乡',
        },
        {
          且末县:
            '且末镇、塔提让乡、琼库勒乡、阔什萨特玛乡、阿克提坎墩乡、英吾斯塘乡、巴格艾日克乡、托格拉克勒克乡、阿热勒乡、奥依亚依拉克、库拉木勒克乡、阿羌乡',
        },
        {
          和静县:
            '和静镇、巴仑台镇、巴润哈尔莫墩镇、哈尔莫墩镇、乃门莫墩乡、协比乃尔布呼乡、克尔古提乡、阿拉沟乡、额勒再特乌鲁乡、巴音郭愣乡、巴音乌鲁乡、巩乃斯沟乡',
        },
        {
          博湖县:
            '博湖镇、本布图镇、查干诺尔乡、才坎诺尔乡、乌兰再格森乡、塔温觉肯乡、博斯腾湖乡',
        },
        {
          和硕县:
            '特吾里克镇、塔哈其镇、乌什塔拉回族乡、曲惠乡、新塔热乡、苏哈特乡、乃仁克尔乡',
        },
        {
          焉耆回族自治县:
            '焉耆镇、七个星镇、永宁镇、四十里城子镇、北大渠乡、五号渠乡、查汗采开乡、包尔海乡',
        },
      ],
      阿克苏地区: [
        {
          库车县:
            '乌恰镇、伊西哈拉镇、玉奇吾斯塘乡、阿拉哈格镇、比西巴格乡、齐满镇、哈尼喀塔木乡、墩阔坦镇、牙哈镇、乌尊镇、雅克拉镇、阿克吾斯塘乡、阿格乡、塔里木乡、东城街道、新城街道、热斯坦街道、萨克萨克街道',
        },
        {
          新和县:
            '新和镇、尤鲁都斯巴格镇、依其艾日克乡、排先拜巴扎乡、塔什艾日克乡、渭干乡、玉奇喀特乡、塔木托格拉克乡',
        },
        {
          沙雅县:
            '沙雅镇、托依堡勒迪镇、红旗镇、英买里镇、古勒巴格乡、海楼乡、努尔巴格乡、塔里木乡',
        },
        {
          拜城县:
            '拜城镇、铁热克镇、察尔其镇、赛里木镇、黑英山乡、克孜尔乡、托克逊乡、亚吐尔乡、康其乡、布隆乡、米吉克乡、温巴什乡、大桥乡、老虎台乡',
        },
        {
          温宿县:
            '温宿镇、吐木秀克镇、克孜勒镇、阿热勒镇、托乎拉乡、恰格拉克乡、佳木乡、依希来木其乡、古勒阿瓦提乡、博孜墩柯尔克孜族乡',
        },
        {
          阿瓦提县:
            '阿依巴格乡、塔木托格拉克乡、英艾日克乡、多浪乡、巴格托格拉克乡、阿瓦提镇、拜什艾日克镇、乌鲁却勒镇',
        },
        {
          乌什: '乌什镇、阿克托海依乡、亚科瑞克乡、阿恰塔格乡、阿合亚乡、依麻木乡、英阿瓦提乡、亚曼苏柯尔克孜族乡、奥特贝希乡',
        },
        { 柯坪县: '柯坪镇、盖孜力克乡、阿恰勒乡、启浪乡、玉尔其乡' },
        {
          阿克苏市:
            '栏杆街道、英巴扎街道、红桥街道、新城街道、南城街道、柯柯牙街道、喀勒塔勒镇、阿依库勒镇、依干其乡、拜什吐格曼乡、托普鲁克乡、库木巴希乡',
        },
      ],
      克孜勒苏柯尔克孜自治州: [
        {
          阿图什市:
            '幸福街道、光明路街道、松他克乡、阿扎克乡、阿湖乡、格达良乡、上阿图什乡镇、哈拉峻乡、吐古买提乡',
        },
        {
          阿克陶县:
            '阿克陶镇、奥依塔克镇、玉麦乡、皮拉勒乡、巴仁乡、加马铁热克乡、喀热开其克乡、布伦口乡、木吉乡、克孜勒陶乡、卡尔隆乡、库斯拉甫乡、塔尔塔吉克族乡',
        },
        {
          乌恰县:
            '乌恰镇、康苏镇、黑孜苇乡、乌鲁克恰提乡、吉根乡、波斯坦铁列克乡、巴音库鲁提乡、膘尔托阔依乡、铁列克乡、托云乡、吾合沙鲁乡',
        },
        {
          阿合奇县:
            '阿合奇镇、库兰萨日克乡、色帕巴依乡、苏木塔什乡、哈拉奇乡、哈拉布拉克乡',
        },
      ],
      喀什地区: [
        {
          喀什市:
            '恰萨街道、亚瓦格街道、吾斯塘博依街道、库木代尔瓦扎街道、西域大道街道、东湖街道、乃则尔巴格乡、夏马勒巴格镇、多来特巴格乡、浩罕乡、色满乡、荒地乡、伯什克然木乡、帕哈太克里乡',
        },
        {
          疏附县:
            '托克扎克镇、兰干镇、吾库萨克镇、乌帕尔乡、塔什米里克乡、铁日木乡、布拉克苏乡、萨依巴格乡、站敏乡、木什乡、阿瓦提乡、英吾斯坦乡、阿克喀什乡',
        },
        {
          疏勒县:
            '疏勒镇、罕南力克镇、牙甫泉镇、巴仁乡、洋大曼乡、亚曼牙乡、巴合齐乡、塔孜洪乡、英尔力克乡、库木西力克乡、塔尕尔其乡、艾尔木东乡、阿拉力乡、阿拉甫乡、英阿瓦提乡',
        },
        {
          英吉沙县:
            '英吉沙镇、城关乡、乔勒潘乡、龙甫乡、芒辛乡、色提力乡、萨罕乡、英也尔乡、克孜勒乡、托普鲁克乡、苏盖提乡、乌恰乡、艾古斯乡、依格孜也尔乡',
        },
        {
          泽普县:
            '泽普镇、奎依巴格镇、波斯喀木乡、依玛乡、古勒巴格乡、赛力乡、依克苏乡、图呼其乡、奎依巴格乡、阿克塔木乡、阿依库勒乡、布依鲁克塔吉克族乡',
        },
        {
          莎车县:
            '莎车镇、恰热克镇、艾里西湖镇、荒地镇、阿瓦提镇、白什坎特镇、依盖尔其镇、古勒巴格乡、阿热勒乡、恰热巴格乡、托木吾斯塘乡、英吾斯塘乡、乌达力克乡、阿热斯兰巴格乡、孜热甫夏提塔吉克族乡、亚喀艾日克乡、喀群乡、藿什拉甫乡、达木斯乡、米夏乡、伊什库力乡、拍克其乡、塔尕尔其乡、阔什艾日克乡、墩巴格乡、阿拉买提乡、阿扎特巴格乡、巴格阿瓦提乡、喀拉苏乡',
        },
        {
          叶城县:
            '喀格勒克镇、恰尔巴格镇、乌夏克巴什镇、洛克乡、伯西热克乡、铁提乡、恰斯米其提乡、吐古其乡、江格勒斯乡、加依提勒克乡、巴仁乡、乌吉热克乡、夏合甫乡、依力克其乡、依提木孔乡、宗朗乡、柯克亚乡、西合休乡、棋盘乡、萨依巴格乡',
        },
        {
          麦盖提县:
            '麦盖提镇、巴扎结米乡、希依提敦乡、央塔克乡、吐曼塔勒乡、尕孜库勒乡、克孜勒阿瓦提乡、库木库萨尔乡、昂格特勒克乡、库尔玛乡',
        },
        {
          岳普湖县:
            '岳普湖镇、艾西买镇、岳普湖乡、也克先拜巴扎乡、阿其克乡、色也克乡、铁热木乡、巴依阿瓦提乡、阿洪鲁库木乡',
        },
        {
          伽师县:
            '巴仁镇、西克尔库勒镇、夏普吐勒镇、铁日木乡、英买里乡、江巴孜乡、卧里托格拉克乡、克孜勒博依乡、米夏乡、夏阿瓦提乡、克孜勒苏乡、古勒鲁克乡、玉代克力克乡',
        },
        {
          巴楚县:
            '巴楚镇、色力布亚镇、阿瓦提镇、三岔口镇等4个镇、恰尔巴格乡、多提巴格乡、阿纳库勒乡、夏玛勒乡、阿克萨克马热勒乡、阿拉格尔乡、琼库尔恰克乡、英吾斯塘乡',
        },
        {
          塔什库尔干塔吉克自治县:
            '塔什库尔干镇、塔吉克阿巴提镇、塔什库尔干乡、提孜那甫乡、塔合曼乡、柯克亚乡、达布达尔乡、库科西力克乡、班迪尔乡、瓦恰乡、马尔洋乡、大同乡',
        },
      ],
      和田地区: [
        {
          和田市:
            '奴尔巴格街道、古江巴格街道、古勒巴格街道、纳尔巴格街道、拉斯奎镇、玉龙喀什镇、肖尔巴格乡、伊里其乡、古江巴格乡、吐沙拉乡、吉亚乡、阿克恰勒乡、北京工业园区',
        },
        {
          和田县:
            '巴格其镇、罕艾日克乡、英阿瓦提乡、英艾日克乡、布扎克乡、拉依喀乡、朗如乡、塔瓦库勒乡、伊斯拉木阿瓦提乡、色格孜库勒乡、喀什塔什乡',
        },
        {
          墨玉县:
            '喀拉卡什镇、喀尔赛乡、奎牙乡、扎瓦乡、阿克萨拉依乡、乌尔其乡、托胡拉乡、萨依巴格乡、加汗巴格乡、普恰克其乡、芒来乡、阔依其乡、雅瓦乡、吐外特乡、英也尔乡、喀瓦克乡',
        },
        {
          皮山县:
            '固玛镇、杜瓦镇、阔什塔格乡、克里阳乡、科克铁热克乡、桑株乡、木吉乡、乔达乡、木奎拉乡、藏桂乡、皮亚勒玛乡、皮西那乡、巴什兰干乡、垴阿巴提塔吉克族乡、康克尔柯尔克孜族乡',
        },
        {
          洛浦县:
            '洛浦镇、布亚乡、恰尔巴格乡、纳瓦乡、山普鲁乡、杭桂乡、多鲁乡、拜什托格拉克乡、阿其克乡',
        },
        {
          策勒县:
            '策勒镇、策勒乡、固拉哈玛乡、达玛沟乡、恰哈乡、乌鲁克萨依乡、奴尔乡、博斯坦乡',
        },
        {
          于田县:
            '木尕拉镇、先拜巴扎镇、加依乡、科克亚乡、阿热勒乡、阿日希乡、兰干乡、斯也克乡、托格日尕孜乡、喀拉克尔乡、奥依托格拉克乡、阿羌乡、英巴格乡、希吾勒乡、达里雅布依乡',
        },
        {
          民丰县: '尼雅镇、尼雅乡、若克雅乡、萨勒吾则克乡、叶亦克乡、安迪尔乡',
        },
      ],
      伊犁哈萨克自治州: [
        {
          自治州直辖区:
            '伊宁市、奎屯市、霍尔果斯市、尼勒克县、伊宁县、霍城县、巩留县、新源县、昭苏县、特克斯县、察布查尔锡伯自治县',
        },
        {
          塔城地区:
            '塔城市、乌苏市、额敏县、沙湾县、托里县、裕民县、和布克赛尔蒙古自治县',
        },
        {
          阿勒泰地区:
            '阿勒泰市、布尔津县、富蕴县、福海县、哈巴河县、青河县、吉木乃县',
        },
      ],
      石河子市: [
        {
          石河子市:
            '新城街道、向阳街道、红山街道、老街街道、东城街道、北泉镇、石河子镇',
        },
      ],
      阿拉尔市: [
        {
          阿拉尔市:
            '幸福路街道、金银川路街道、青松路街道、南口街道、托喀依乡、金银川镇',
        },
      ],
      图木舒克市: [
        {
          图木舒克市:
            '齐干却勒街道、前海街道、永安坝街道、兵团四十四团、兵团四十九团、兵团五十团、兵团五十一团、兵团五十三团、兵团图木舒克市喀拉拜勒镇、兵团图木舒克市永安坝',
        },
      ],
      五家渠市: [
        { 五家渠市: '军垦路街道、青湖路街道、人民路街道、101团、102团、103团' },
      ],
      北屯市: [
        { 北屯市: '新城区、老城区、产业园区、一八八团、一八七团、一八三团' },
      ],
      铁门关市: [
        {
          铁门关市:
            '兵团第二师十十九团中心城区、库西经济工业园、博古其镇、双丰镇',
        },
      ],
      双河市: [
        { 双河市: '八十一团、八十四团、八十五团、八十六团、八十九团、九〇团' },
      ],
      可克达拉市: [{ 可克达拉市: '界梁子镇、佛尕善镇' }],
    },
  ],
  西藏自治区: [
    {
      拉萨市: [
        {
          城关区:
            '冲赛康街道、八廓街道、吉日街道、吉崩岗街道、札细街道、公德林街道、嘎玛贡桑街道、两岛街道、蔡公堂乡、纳金乡、娘热乡、夺底乡',
        },
        {
          林周县:
            '甘丹曲果镇、春堆乡、松盘乡、强嘎乡、卡孜乡、边觉林乡、江热夏乡、阿朗乡、唐古乡、旁多乡',
        },
        { 当雄县: '纳木湖乡、公塘乡、宁中乡、格达乡、乌玛塘乡、龙仁乡' },
        {
          尼木县:
            '塔荣镇、麻江乡、普松乡、卡如乡、尼木乡、续迈乡、帕古乡、吞巴乡',
        },
        { 曲水县: '曲水镇、达嘎乡、菜纳乡、南木乡、聂唐乡、茶巴拉乡' },
        { 堆龙德庆县: '东嘎镇、乃琼镇、羊达乡、古荣乡、柳梧乡、马乡、德庆乡' },
        { 达孜县: '德庆镇、塔吉乡、章多乡、唐嘎乡、雪乡、帮堆乡' },
        {
          墨竹工卡县:
            '工卡镇、扎雪乡、扎西岗乡、甲玛乡、日多乡、塘加乡、尼玛江热乡、门巴乡',
        },
      ],
      昌都市: [
        {
          卡若区:
            '城关镇、卡若镇、俄洛镇、沙贡乡、芒达乡、如意乡、日通乡、柴维乡、约巴乡、嘎玛乡、若巴乡、埃西乡、妥坝乡、拉多乡、面达乡',
        },
        {
          江达县:
            '江达镇、岗托镇、邓柯乡、岩比乡、卡贡乡、生达乡、娘西乡、字呷乡、青泥洞乡、汪布顶乡、德登乡、同普乡、波罗乡',
        },
        {
          贡觉县:
            '莫洛镇、敏都乡、则巴乡、罗麦乡、沙东乡、克日乡、木协乡、阿旺乡、拉妥乡、雄松乡、哈加乡、相皮乡',
        },
        {
          类乌齐县:
            '桑多镇、类乌齐镇、吉多乡、岗色乡、滨达乡、卡玛多乡、尚卡乡、伊日乡、加桑卡乡、长毛岭乡',
        },
        {
          丁青县:
            '丁青镇、尺牍镇、沙贡乡、木塔乡、布塔乡、巴达乡、甘岩乡、嘎塔乡、色扎乡、协雄乡、桑多乡、当堆乡、觉恩乡',
        },
        {
          察雅县:
            '烟多镇、吉塘镇、香堆镇、宗沙乡、肯通乡、卡贡乡、扩达乡、新卡乡、王卡乡、阿孜乡、巴日乡、荣周乡、察拉乡',
        },
        {
          八宿县:
            '白玛镇、然乌镇、帮达镇、同卡镇、林卡乡、夏里乡、拥巴乡、瓦乡、吉达乡、卡瓦百庆乡、吉中乡、益庆乡、拉根乡、郭庆乡',
        },
        {
          左贡县:
            '旺达镇、田妥镇、扎玉镇、东坝乡、中林卡乡、美玉乡、下林卡乡、碧土乡、仁果乡、绕金乡',
        },
        {
          芒康县:
            '嘎托镇、如美镇、曲孜卡乡、木许乡、纳西族乡、朱巴龙乡、曲登乡、徐中乡、帮达乡、戈波乡、洛尼乡、措瓦乡、昂多乡、宗西乡、莽岭乡、索多西乡',
        },
        {
          洛隆县:
            '孜托镇、硕督镇、康沙镇、马利镇、玉西乡、新荣乡、达龙乡、腊久乡、俄西乡、中亦乡、白达乡',
        },
        {
          边坝县:
            '草卡镇、边坝镇、马武乡、热玉乡、尼木乡、沙丁乡、金岭乡、加贡乡、马秀乡、都瓦乡、拉孜乡',
        },
      ],
      日喀则市: [
        {
          桑珠孜区:
            '城南街道、城北街道、联乡、年木乡、江当乡、边雄乡、东嘎乡、聂日雄乡、甲措雄乡、曲美乡、曲布雄乡、纳尔乡',
        },
        {
          江孜县:
            '江孜镇、纳如乡、卡麦乡、卡堆乡、藏改乡、日朗乡、达孜乡、热索乡、重孜乡、龙马乡、紫金乡、江热乡、年堆乡、康卓乡、金嘎乡、日星乡、热龙乡、车仁乡、加克西乡',
        },
        {
          白朗县:
            '洛江镇、嘎东镇、巴扎乡、玛乡、旺丹乡、曲奴乡、杜琼乡、强堆乡、嘎普乡、者下乡、东喜乡',
        },
        {
          拉孜县:
            '拉孜镇、曲下镇、查务乡、芸普乡、扎西岗乡、锡钦乡、扎西宗乡、热萨乡、柳乡、曲玛乡、彭措林乡',
        },
        {
          萨迦县:
            '萨迦镇、吉定镇、木拉乡、查荣乡、拉洛乡、赛乡、扯休乡、扎西岗乡、雄玛乡、麻布加乡、雄麦乡',
        },
        { 岗巴县: '岗巴镇、隆中乡、孔玛乡、直克乡、昌龙乡' },
        {
          定结县:
            '江嘎镇、日屋镇、陈塘镇、郭加乡、萨尔乡、琼孜乡、定结乡、确布乡、多布扎乡、扎西岗乡',
        },
        {
          定日县:
            '协格尔镇、岗嘎镇、曲当乡、扎西宗乡、克玛乡、绒辖乡、措果乡、曲洛乡、长所乡、尼辖乡、扎果乡、盆吉乡、加措乡',
        },
        {
          聂拉木县: '聂拉木镇、樟木镇、亚来乡、琐作乡、乃龙乡、门布乡、波绒乡',
        },
        {
          康马县:
            '康马镇、涅如麦乡、涅如堆乡、嘎拉乡、莎玛达乡、康如乡、少岗乡、南尼乡、雄章乡',
        },
        {
          亚东县:
            '下司马镇、帕里镇、上亚东乡、下亚东乡、康布乡、堆纳乡、吉汝乡',
        },
        {
          仁布县:
            '德吉林镇、康雄乡、切娃乡、母乡、仁布乡、帕当乡、康雄乡、然巴乡、查巴乡',
        },
        {
          南木林县:
            '南木林镇、达那乡、卡孜乡、多曲乡、秋木乡、土布加乡、查尔乡、索金乡、达孜乡、奴玛乡、热当乡、拉布普乡、甲措乡、芒热乡、普当乡、仁堆乡、艾玛乡',
        },
        {
          谢通门县:
            '卡嘎镇、通门乡、荣玛乡、塔丁乡、达那普乡、南木切乡、仁钦则乡、达木夏乡、美巴切钦乡、青都乡、切琼乡、纳当乡、措布西乡、娘热乡、则许乡、春哲乡、查布乡、达那答乡、列巴乡',
        },
        { 吉隆县: '宗嘎镇、吉隆镇、折巴乡、贡当乡、差那乡' },
        {
          昂仁县:
            '卡嘎镇、桑桑镇、切热乡、秋窝乡、达居乡、亚木乡、贡久布乡、达若乡、措迈乡、尼果乡、孔隆乡、如沙乡、阿木雄乡、查孜乡、日吾其乡、多白乡、雄巴乡',
        },
        {
          萨嘎县:
            '加加镇、夏如乡、旦嘎乡、达吉岭乡、如角乡、拉藏乡、雄如乡、昌果乡',
        },
        {
          仲巴县:
            '帕羊镇、琼果乡、拉让乡、亚热乡、布多乡、偏吉乡、纳久乡、吉拉乡、霍尔巴乡、吉玛乡、仁多乡、隆格尔乡、帕玛乡',
        },
        { 樟木口岸: '樟木口岸' },
      ],
      林芝市: [
        {
          巴宜区:
            '八一镇、林芝镇、鲁朗镇、百巴镇、布久乡、米瑞乡、更章门巴民族乡',
        },
        {
          工布江达县:
            '工布江达镇、金达镇、巴河镇、错高乡、朱拉乡、仲莎乡、江达乡、娘蒲乡、加兴乡',
        },
        {
          米林县:
            '米林镇、南伊乡、里龙乡、卧龙镇、扎绕乡、派镇、羌纳乡、丹娘乡',
        },
        {
          墨脱县:
            '墨脱镇、甘登乡、加拉萨乡、达木珞巴族乡、帮辛乡、格当乡、德兴乡、背崩乡',
        },
        {
          波密县:
            '扎木镇、倾多镇、松宗镇、古乡、许木乡、八盖乡、玉仁乡、多吉乡、康玉乡、玉普乡、易贡乡',
        },
        { 察隅县: '竹瓦根镇、上察隅镇、下察隅镇、古玉乡、察瓦龙乡、古拉乡' },
        { 朗县: '朗镇、登木乡、金东乡、洞嘎镇、' },
      ],
      山南地区: [
        { 乃东县: '泽当镇、昌珠镇、颇章乡、结巴乡、多颇章乡、索珠乡、亚堆乡' },
        { 扎囊县: '扎塘镇、桑耶镇、阿扎乡、扎期乡、吉汝乡' },
        {
          贡嘎县:
            '吉雄镇、岗堆镇、甲竹林镇、江塘镇、杰德秀镇、朗杰学乡、昌果乡、东拉乡',
        },
        { 桑日县: '桑日镇、增期乡、白堆乡、绒乡' },
        { 琼结县: '琼结镇、拉玉乡、下水乡、加麻乡' },
        { 曲松县: '曲松镇、罗布沙镇、邱多江乡、堆随乡、下江乡' },
        { 措美县: '措美镇、哲古镇、乃西乡、古堆乡' },
        { 洛扎县: '洛扎镇、拉康镇、生格乡、边巴乡、扎日乡、色乡、拉郊乡' },
        { 加查县: '加查镇、安绕镇、拉绥乡、崔久乡、坝乡、冷达乡、洛林乡' },
        {
          隆子县:
            '隆子镇、日当镇、加玉乡、列麦乡、扎日乡、热荣乡、三安曲林乡、准巴乡、斗玉乡、雪萨乡、玉麦乡',
        },
        {
          错那县:
            '错那镇、勒门巴族乡、贡日门巴族乡、吉巴门巴族乡、麻麻门巴族乡、库局乡、曲卓木乡、浪波乡、觉拉乡、卡达乡、勒布街道',
        },
        {
          浪卡子县:
            '浪卡子镇、打隆镇、普马江塘乡、多却乡、阿扎乡、卡龙乡、工布学乡、卡热乡、白地乡、张达乡',
        },
      ],
      那曲地区: [
        {
          那曲县:
            '那曲镇、罗玛镇、古露镇、香茂乡、油恰乡、那玛切乡、孔玛乡、达萨乡、洛麦乡、色雄乡、尼玛乡、达前乡',
        },
        {
          嘉黎县:
            '阿扎镇、嘉黎镇、鸽群乡、藏比乡、忠玉乡、措多乡、措拉乡、林堤乡、夏玛乡、绒多乡',
        },
        {
          比如县:
            '比如镇、夏曲镇、白嘎乡、羊秀乡、香曲乡、达塘乡、茶曲乡、良曲乡、扎拉乡、恰则乡',
        },
        {
          聂荣县:
            '大众镇、色庆乡、尼玛乡、当木江乡、查当乡、桑荣乡、下曲乡、白雄乡、索雄乡、永曲乡',
        },
        {
          安多县:
            '帕那镇、扎仁镇、雁石坪镇、强玛镇、玛曲乡、多玛乡、措玛乡、帮爱乡、滩堆乡、玛荣乡、扎曲乡、色务乡',
        },
        {
          申扎县:
            '申扎镇、雄梅镇、马跃乡、买巴乡、塔尔玛乡、下过乡、卡乡、巴扎乡',
        },
        {
          索县: '亚拉镇、荣布镇、热瓦乡、若达乡、嘎美乡、加勤乡、西昌乡、嘎木乡、江达乡、赤多乡',
        },
        {
          班戈县:
            '普保镇、北拉镇、佳琼镇、德庆镇、马前乡、门当乡、保吉乡、青龙乡、新吉乡、尼玛乡',
        },
        {
          巴青县:
            '雅安镇、拉西镇、杂色镇、江绵乡、岗切乡、巴青乡、阿秀乡、玛如乡、本塔乡、贡日乡',
        },
        {
          尼玛县:
            '尼玛镇、文布乡、中仓乡、卓瓦乡、卓尼乡、吉瓦乡、甲谷乡、阿索乡、俄久乡、荣玛乡、达谷乡、申亚乡、来多乡、军仓乡',
        },
        {
          双湖县:
            '措折罗玛镇、协德乡、雅曲乡、嘎措乡、措折强玛乡、多玛乡、巴岭乡',
        },
      ],
      阿里地区: [
        { 噶尔县: '狮泉河镇、扎西岗乡、门士乡、左左乡、昆莎乡' },
        { 普兰县: '普兰镇、巴嘎乡、霍尔乡' },
        { 札达县: '托林镇、萨让乡、达巴乡、底雅乡、香孜乡、曲松乡' },
        { 日土县: '日土镇、多玛乡、日松乡、热帮乡、东汝乡' },
        { 革吉县: '邦巴区、雄巴区、亚热区、盐湖区' },
        { 改则县: '改则镇、物玛乡、先遣乡、麻米乡、洞措乡、古姆乡、察布乡' },
        { 措勤县: '措勤镇、磁石乡、曲洛乡、江让乡、达雄乡' },
      ],
    },
  ],
  香港特别行政区: [
    {
      香港岛: [{ 香港岛: '中西区、湾仔区、东区、南区' }],
      九龙半岛: [
        { 九龙半岛: '油尖旺区、深水埗区、九龙城区、黄大仙区、观塘区' },
      ],
      新界: [
        {
          新界: '离岛区、葵青区、北区、西贡区、沙田区、大埔区、荃湾区、屯门区、元朗区',
        },
      ],
    },
  ],
  澳门特别行政区: [
    {
      花地玛堂区: [{ 花地玛堂区: '青州、台山、黑沙环、筷子基、水塘' }],
      圣安多尼堂区: [{ 圣安多尼堂区: '新桥、沙梨头' }],
      大堂区: [
        {
          大堂区:
            '中区、新马路以北部分、南湾、水坑尾、新口岸填海地段、新口岸港澳码头',
        },
      ],
      望德堂区: [{ 望德堂区: '荷兰园、东望洋山、塔石一带' }],
      风顺堂区: [{ 风顺堂区: '妈阁山、西望洋山、新马路以南部分' }],
      嘉模堂区: [{ 嘉模堂区: '嘉模堂区' }],
      圣方济各堂区: [{ 圣方济各堂区: '圣方济各堂区' }],
      路氹城: [{ 路氹城: '路氹城' }],
    },
  ],
};
