import store from '@/store';
function checkPermission(el, binding) {
  // 获取绑定的值,此处为角色 ADMIN
  const { value } = binding;
  // 获取用户角色
  const roles = store.state.roles;
  // 匹配对应的指令
  const isAdmin = roles.some((it) => {
    return value.includes(it);
  });
  // 如果无法匹配，则表示当前用户无该权限，那么删除对应的功能
  if (!isAdmin) {
    el.parentNode && el.parentNode.removeChild(el);
  }
}

export default {
  // 在绑定元素的父组件被挂载后调用
  inserted(el, binding) {
    checkPermission(el, binding);
  },
  // 在包含组件的 VNode 及其子组件的 VNode 更新后调用
  update(el, binding) {
    checkPermission(el, binding);
  },
};
