import Vue from 'vue';
import Vuex from 'vuex';
import VueXAlong from 'vuex-along';
import router from '@/router';
import { area } from '@/utils/area.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menuList: [], //用户菜单列表和路由
    activePath: '', // 导航栏激活路径
    tagviews: [
      // {
      //   id: 1,
      //   menuName: '首页',
      //   menuKey: null,
      //   icon: 'el-icon-menu',
      //   router: '/backstage/home',
      //   componentName: 'Home',
      // },
    ],
    userId: '', // 用户id
    username: '',
    roles: [],
    roleNames: [],
    avatar: '',
    isAdmin: false, // 是否是管理员
    organizationName: '',
    organizationId: '',
    areaList: [], // 省市区街道数据
    areaNoStreetList: [], // 省市区数据
  },
  mutations: {
    // 登录时获取导航菜单
    setMenuList(state, payload) {
      state.menuList = payload;
    },
    // 登录时设置用户相关信息
    setUserInfo(state, payload) {
      state.userId = payload.id;
      state.username = payload.username;
      state.roles = payload.roles;
      state.roleNames = payload.roleNames;
      state.isAdmin = payload.isAdmin;
      state.avatar = payload.avatar;
      state.organizationName = payload.organizationName;
      state.organizationId = payload.organizationId;
    },
    // 设置当前激活的路由
    setActivePath(state, payload) {
      state.activePath = payload;
    },
    addTagview(state, payload) {
      // 点击左侧菜单增加tagview标签,如果存在就不插入
      let result = state.tagviews.findIndex(
        (item) => item.menuName === payload.menuName
      );
      result === -1 ? state.tagviews.push(payload) : '';
    },
    // 点击tag标签
    chooseTagview(state, payload) {
      // console.log(state.activePath);
      if (state.activePath != payload) {
        router.push(payload);
        state.activePath = payload;
      }
    },
    // 关闭tag标签
    closeTagview(state, payload) {
      // 判断当前路由
      if (payload != state.activePath) {
        // 不在同个路由
        let result = state.tagviews.findIndex(
          (item) => item.router === payload
        );
        state.tagviews.splice(result, 1);
      } else {
        // 关闭当前路由并跳转到别的路由
        let len = state.tagviews.length;
        // 需要关闭的路由数组中的下标
        let result = state.tagviews.findIndex(
          (item) => item.router === payload
        );
        // 关闭路由
        state.tagviews.splice(result, 1);
        if (state.tagviews.length === 1) {
          // 路由数组只剩一项时
          state.activePath = state.tagviews[0].router;
          router.push(state.activePath);
        } else {
          state.activePath = state.tagviews[len - 2].router;
          router.push(state.activePath);
        }
      }
    },
    // 获取地址数据
    getAddress(state) {
      const areaList = area;
      for (let i in areaList) {
        let children = areaList[i][0];
        let city = [];
        for (let key in children) {
          let children1 = children[key];
          let area = [];
          for (let k in children1) {
            let children2 =
              children1[k][Object.keys(children1[k])[0]].split('、');
            let street = [];
            for (let j in children2) {
              street.push({ label: children2[j] });
            }
            area.push({
              label: Object.keys(children1[k])[0],
              children: street,
            });
          }
          city.push({ label: key, children: area });
        }
        state.areaList.push({ label: i, children: city });
      }
      for (let i in areaList) {
        let children = areaList[i][0];
        let city = [];
        for (let key in children) {
          let children1 = children[key];
          let area = [];
          for (let k in children1) {
            area.push({ label: Object.keys(children1[k])[0] });
          }
          city.push({ label: key, children: area });
        }
        state.areaNoStreetList.push({ label: i, children: city });
      }
    },
  },
  actions: {},
  modules: {},
  plugins: [
    VueXAlong({
      name: 'vuex', //存放在localStroage或者sessionStroage 中的名字
      justSession: true,
      session: {
        list: [
          'menuList',
          'activePath',
          'userId',
          'tagviews',
          'username',
          'roleNames',
          'isAdmin',
          'roles',
          'avatar',
          'organizationName',
          'organizationId',
        ],
      },
      //如果值不为false 那么可以传递对象 其中 当isFilter设置为true时， list 数组中的值就会被过滤调,这些值不会存放在seesion或者local中
    }),
  ],
});
